import { makeStyles, Theme } from '@material-ui/core/styles'

const usePositionCatalogStyles = makeStyles((theme: Theme) => ({
  catalogWrapper: {
    height: 300,
  },
  root: {
    minWidth: 270,
    '& .MuiListSubheader-gutters': {
      paddingRight: '0',
    },
  },
  listItem: {
    display: 'flex',
    height: 'auto',
    minHeight: '45px',
    '&:hover, &:active, &:focus': {
      backgroundColor: '#f2f0f8',
      borderRadius: '5px',
      height: 'auto',
      color: '#4c5ecf',
    },
    '& .MuiListItemText-primary': {
      fontSize: '14px',
      color: '#05082E',
      maxWidth: '270px',
    },
  },
  button: {
    width: '100%',
  },
}))

export default usePositionCatalogStyles
