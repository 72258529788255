import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import * as _ from 'lodash'

import { Authorities } from '@grand-tender/auth-service'

import { Accordion } from '@ifellow/ui-library'
import EditIcon from '@material-ui/icons/Edit'

import { editNotePromise } from '../../actions'
import { usePermissions } from '../../hooks/usePermissions'

import { EditDialog } from './components/edit-note-dialog'
import styles from './styles.module.scss'

export type TNote = {
  lotId: number
  note: string
  disabled: boolean
  getAssignmentDetail: () => void
  editNotePromise: ({ lotId, note }: { lotId: number; note: string }) => any
}

const TenderNoteComponent: React.FC<TNote> = ({
  lotId,
  note,
  disabled,
  getAssignmentDetail,
  editNotePromise: editTenderNotePromise,
}) => {
  const checkPermissions = usePermissions()
  const [isOpenEditNoteModal, setIsOpenEditNoteModal] = useState(false)

  const dispatch = useDispatch()

  const handleEditDialog = (): void => {
    setIsOpenEditNoteModal(true)
  }

  const handleCloseEditDialog = (): void => {
    setIsOpenEditNoteModal(false)
  }

  const handleSubmitEditDialog = (updateNote: string): void => {
    dispatch(editTenderNotePromise({ lotId, note: updateNote }))

    getAssignmentDetail()
    handleCloseEditDialog()
  }

  const editable =
    checkPermissions(Authorities.ASSIGNMENT_DETAILS_CHANGE_TENDER_FOR_LOT) &&
    !disabled

  return (
    <>
      <Accordion title='Примечание'>
        <div>
          {editable && (
            <EditIcon
              className={styles.editNoteIcon}
              fontSize='small'
              htmlColor='#616284'
              onClick={handleEditDialog}
            />
          )}
          {_.isNil(note) || note.length === 0 ? 'Примечание отсутствует' : note}
        </div>
      </Accordion>
      {isOpenEditNoteModal && (
        <EditDialog
          note={note}
          open={isOpenEditNoteModal}
          handleClose={handleCloseEditDialog}
          handleOk={handleSubmitEditDialog}
        />
      )}
    </>
  )
}

export const TenderNote = connect(null, {
  editNotePromise,
})(TenderNoteComponent)
