import { ApiService as ApiService1 } from './apiService/apiService'
import { AxiosResponse } from 'axios'

const defaultHeaders: Record<string, string> = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

export type ApiResponse<T> = [Error, AxiosResponse<T>]

export const API_ENDPOINT = '/api/grand-tender/v1'
export const CPM_API_ENDPOINT = '/api/construction-projects-management/v1'
export const HM_API_ENDPOINT = '/api/hub-management/v1'
export const SPA_KEYCLOAK_URL =
  window.SPA_KEYCLOAK_URL || 'https://auth.dev.gmini.tech/auth/'
export const SPA_KEYCLOAK_REALM = window.SPA_KEYCLOAK_REALM || 'gmini'

export const apiClient = new ApiService1({
  baseUrl: API_ENDPOINT,
  defaultHeaders,
})
