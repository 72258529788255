import React, { useState } from 'react'
import * as Sentry from '@sentry/react'
import { toastr } from 'react-redux-toastr'
import { IAPIResult, TRowData } from '@grand-tender/types'
import { EAPIResponseStatus } from '../../../../../../../constants'
import MessageWrapper from '../../../../../../MessageWrapper/MessageWrapper'
import { ApiResponse } from '@grand-tender/base-api-service/src'

export const useSectionsList = (
  dataSource: (query: string) => Promise<ApiResponse<IAPIResult<TRowData>>>,
  setOptionsListLoading: (isLoading: boolean) => void,
): [typeof state, typeof updateState, typeof clearState] => {
  const [state, setState] = useState<TRowData>([])

  const has = <T extends object, K extends string>(key: K, x: T): x is T =>
    key in x

  const updateState = async (debounced: string) => {
    setOptionsListLoading(true)

    const [_, result] = await dataSource(debounced)
    //TODO move to action
    if (result.data.status === EAPIResponseStatus.SUCCESS) {
      const sectionsValues = result.data.data
      if (
        typeof sectionsValues !== 'object' ||
        !Array.isArray(sectionsValues) ||
        sectionsValues.some(item =>
          Object.keys(item).some(k => !has(k, { key: '', value: '' })),
        )
      ) {
        throw new Error(`Получен неверный аргумент: ${sectionsValues}`)
      }

      setState(sectionsValues)
      setOptionsListLoading(false)
    } else {
      const err =
        result.data.status === EAPIResponseStatus.FAIL
          ? result.data.data
          : result.data.message

      const msg = <div>{err}</div>

      toastr.error('', '', {
        component: <MessageWrapper>{msg}</MessageWrapper>,
        progressBar: false,
        showCloseButton: false,
      })
      toastr.error(
        '',
        'При получении списка тендерных заданий произошла ошибка!',
      )
      Sentry.captureException(err)
      setState([])
      setOptionsListLoading(false)
    }
  }

  const clearState = (): void => {
    setState([])
  }

  return [state, updateState, clearState]
}
