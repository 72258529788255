import { useCallback } from 'react'

import {
  Authorities,
  mapRolesToResourceScope,
} from '@grand-tender/auth-service/src/constants'

import { useAppSelector } from '../store'

export const usePermissions = () => {
  const { permissions } = useAppSelector(state => state.user)

  const checkPermissions = useCallback(
    (role: Authorities) => {
      const combinations = mapRolesToResourceScope[role]
      return combinations.some(({ resource, scopes }) =>
        scopes.every(scope =>
          permissions?.DEFAULT?.[resource]?.includes(scope),
        ),
      )
    },
    [permissions],
  )

  return checkPermissions
}
