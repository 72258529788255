/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import * as _ from 'lodash'
import {
  Drawer,
  Divider,
  TextField,
  List,
  SvgIcon,
  IconButton,
} from '@material-ui/core'
import {
  AssignmentLotTableToolbar,
  AddAssignmentSupplierModal,
  LocalStorageHelper,
  DialogModal,
  MovePositionsModal,
  AddAgreementModal,
  ButtonComponent,
  EButtonIcon,
  EButtonType,
  EEditableModalType,
  EColumnType,
  EAPIResponseStatus,
  formatValue,
} from '@grand-tender/ui'
import {
  TColumn,
  IKeyValuePair,
  TSupplierModel,
  TAgreementNext,
  TAgreementCreateRequest,
  TRowData,
  SupplierInfo,
  ELotStatus,
} from '@grand-tender/types'
import { SupplierService } from '@grand-tender/supplier-service'
import { AgreementService } from '@grand-tender/agreement-service'
import { Authorities } from '@grand-tender/auth-service'
import { AssignmentService } from '@grand-tender/assignment-service'
import { SupplierHistoryField } from '@grand-tender/ui/src/components/SupplierHistoryField'

import { useHistory } from 'react-router'

import { changeLotName } from '../../../actions'
import Accordion from '../../assignment/view/assignment-lot/assignment-lot-accordion/assignment-lot-accordion'

import { NewTenderConditionDialog } from '../../../modules/tender-conditions/new-tender-condition-dialog'
import { TenderConditions } from '../../../modules/tender-conditions/tender-conditions-controller'
import { setDataLoading } from '../../../actions/RequestWrapper'
import { usePermissions } from '../../../hooks/usePermissions'

import useStyles from './style'
import * as I from './assignment-lot-search-types'

interface DialogModalSettings {
  type: 'positions' | 'supplier' | 'lot'
  title: string
  content: string
  leftBtnText: string
  rightBtnText: string
}

const AssignmentLotSearch: React.FC<I.OwnProps> = ({
  lot,
  assignment,
  searchParams,

  addPropertyHandler,
  editPropertyHandler,
  removePropertyHandler,

  addToAgreementHandler,
  removeLotPositionsHandler,
  removeLotHandler,
  moveLotPositionsHandler,
  changeLotAssignmentHandler,
  removeLotSupplierHandler,
  changePageHandler,

  addSupplierHandler,
  updatePriceHandler,

  openRenameLotModal,
  exportTemplateHandler,
  exportLotHandler,
  getSearchAssignmentFilterItems,
}): React.ReactElement => {
  const checkPermissions = usePermissions()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { id: assignmentId, lots, status: assignmentStatus } = assignment
  const emptyData: { [key: string]: TColumn } = {}
  const expandedInitState: { [key: string]: boolean } = {}
  lot.positions.forEach(lp => {
    emptyData[lp.category.key] = []
    expandedInitState[lp.category.key] = false
  })

  const history = useHistory()
  const [selectedRows, setSelectedRows] = useState<Array<string>>([])
  const [openDialogModal, setOpenDialogModal] = useState<boolean>(false)
  const [openMovePositionsModal, setOpenMovePositionModal] = useState<boolean>(
    false,
  )
  const [dialogModalSettings, setDialogModalSettings] = useState<
    DialogModalSettings
  >({
    type: 'positions',
    title: '',
    content: '',
    leftBtnText: '',
    rightBtnText: '',
  })
  const [openDrawer, setOpenDrawer] = useState(false)
  const [dataListDrawer, setDataListDrawer] = useState<TSupplierModel>()
  const [openAgreementModal, setOpenAgreementModal] = useState<boolean>(false)
  const [selectedSupplier, setSelectedSupplier] = useState<SupplierInfo>()

  const [agreementModalModel, setAgreementModalModel] = useState<
    TAgreementNext
  >({
    number: 0,
    supplier: { key: '', value: '' },
    lotId: 0,
    columns: [],
  })

  const [selectedVisibleSuppliers, setSelectedVisibleSuppliers] = useState<
    TRowData
  >([])
  const [
    showSupplierWithAgreementOnly,
    setShowSupplierWithAgreementOnly,
  ] = useState<boolean>(true)

  const [openAddSupplierModal, setOpenAddSupplierModal] = useState<boolean>(
    false,
  )
  const [
    addAssignmentSupplierModalType,
    setAddAssignmentSupplierModalType,
  ] = useState<EEditableModalType>(EEditableModalType.INSERT)

  const [flagAgreementList, setFlagAgreementList] = useState<boolean>(false)
  const [keyCatalogAgreement, setKeyCatalogAgreement] = useState<any>(null)

  const [, setOpenAddPositionsContextModal] = useState<boolean>(false)

  const updateSelectedRowsState = React.useCallback(
    (newState: Array<string>): void => setSelectedRows(newState),
    [],
  )

  const flagAgreementListHandled = React.useCallback(
    (flag: boolean, key?: any): void => {
      setFlagAgreementList(flag)
      if (key && key !== null) {
        setKeyCatalogAgreement(key)
      }
    },
    [],
  )

  const handleRemoveChangesDialogModal = React.useCallback((): void => {
    switch (dialogModalSettings?.type) {
      case 'positions': {
        removeLotPositionsHandler(lot.id, selectedRows, () =>
          updateSelectedRowsState([]),
        )
        break
      }
      case 'supplier': {
        removeLotSupplierHandler(
          lot.id,
          selectedSupplier?.providerId || '',
          (): number => 0,
        )

        break
      }
      case 'lot': {
        removeLotHandler(lot.id)
        break
      }
    }
    //props.onRemoveBtnClick(selectedRows, () => props.updateSelectedRowsState([]));
    setOpenDialogModal(false)
  }, [
    dialogModalSettings,
    lot.id,
    removeLotHandler,
    removeLotPositionsHandler,
    removeLotSupplierHandler,
    selectedRows,
    selectedSupplier,
    updateSelectedRowsState,
  ])

  const handleCloseDialogModal = React.useCallback((): void => {
    setOpenDialogModal(false)
  }, [])

  const handleCloseLot = React.useCallback(() => {
    const specialisationIds = lot.specialization.map(item => Number(item.id))
    dispatch(
      changeLotName({
        lotId: lot.id,
        name: lot.name,
        description: lot.description,
        status: ELotStatus['Закрыт'],
        callback: (): void => history.push(history.location.pathname),
        specializationIds: specialisationIds,
      }),
    )
  }, [dispatch, history, lot.description, lot.id, lot.name, lot.specialization])

  const handleSelectedVisibleSuppliers = React.useCallback(
    (data: TRowData): void => {
      const columns = _.uniqBy(data, 'key')

      setSelectedVisibleSuppliers(columns)

      const page = `assignments_${assignmentId}_lots_${lot.id}_suppliers`

      LocalStorageHelper.set<TRowData>(page, columns)

      if (showSupplierWithAgreementOnly) {
        setShowSupplierWithAgreementOnly(false)
      }
    },
    [assignmentId, lot.id, showSupplierWithAgreementOnly],
  )

  const toggleDrawer = React.useCallback(
    async (open: boolean, supplier?: IKeyValuePair): Promise<void> => {
      if (supplier) {
        dispatch(setDataLoading(true))
        const [err, detail] = await SupplierService.getSupplier(supplier.key)

        if (err) {
          //TODO handle error
          toastr.error('Ошибка', '')
        }

        if (!detail.data.data) {
          return
        }
        if (
          !selectedSupplier ||
          selectedSupplier.providerId !== detail.data.data.id
        ) {
          setSelectedSupplier({
            providerId: detail.data.data.id,
            inn: detail.data.data.inn,
            kpp: detail.data.data.kpp,
            name: detail.data.data.name,
          })
        }
        setDataListDrawer(detail.data.data)
        setOpenDrawer(open)
        dispatch(setDataLoading(false))
      } else {
        // eslint-disable-next-line no-undefined
        setDataListDrawer(undefined)
        setOpenDrawer(open)
      }
    },
    [dispatch, selectedSupplier],
  )

  const drawerEditBtnClickHandler = React.useCallback((): void => {
    if (selectedSupplier) {
      toggleDrawer(false, {
        key: selectedSupplier.providerId,
        value: selectedSupplier.name,
      })
      //props.onEditBtnClick(selectedSupplier.key);
    }
    setAddAssignmentSupplierModalType(EEditableModalType.EDIT)
    setOpenAddSupplierModal(true)
  }, [selectedSupplier, toggleDrawer])

  useEffect(() => {
    if (flagAgreementList) {
      drawerAddAgreementBtnClickHandler()
      flagAgreementListHandled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagAgreementList])

  const drawerAddAgreementBtnClickHandler = React.useCallback(async (): Promise<
    void
  > => {
    if (flagAgreementList) {
      //TODO move to action
      const [err, res] = await AgreementService.getAgreementColumns(
        String(keyCatalogAgreement),
      )

      if (err) {
        console.error(err)
        toastr.error('', 'При получении данных произошла ошибка!')
      }

      if (res.data.data && res.data.data.columns !== null) {
        res.data.data.supplier = selectedSupplier
          ? {
              key: selectedSupplier.providerId,
              value: selectedSupplier.name,
            }
          : { key: '', value: '' }
        res.data.data.lotId = Number(lot.id)
        setAgreementModalModel({
          ...res.data.data,
          columns: res.data.data.columns.filter(
            item => !['Проект', 'Объект'].includes(item.title),
          ),
        })
      } else {
        if (res.data.data) {
          res.data.data.supplier = selectedSupplier
            ? {
                key: selectedSupplier.providerId,
                value: selectedSupplier.name,
              }
            : { key: '', value: '' }
          res.data.data.lotId = Number(lot.id)
          res.data.data.columns = []
          setAgreementModalModel(res.data.data)
        }
      }

      toggleDrawer(
        false,
        selectedSupplier
          ? {
              key: selectedSupplier.providerId,
              value: selectedSupplier.name,
            }
          : undefined,
      )
    } else {
      setOpenAgreementModal(!openAgreementModal)
    }
  }, [
    flagAgreementList,
    keyCatalogAgreement,
    lot.id,
    openAgreementModal,
    selectedSupplier,
    toggleDrawer,
  ])

  const [
    isCreateTenderConditionDialogOpen,
    setIsCreateTenderConditionDialogOpen,
  ] = useState(false)

  const [selectedLotId, setSelectedLotId] = useState('')

  const toggleCreateDialogModal = (lotId: string) => (): void => {
    setIsCreateTenderConditionDialogOpen(!isCreateTenderConditionDialogOpen)
    setSelectedLotId(lotId)
  }

  const handleCloseCreateTenderCondition = (): void => {
    setIsCreateTenderConditionDialogOpen(false)
    setSelectedLotId('')
  }

  const handleSubmitCreateTenderCondition = (): void => {
    handleCloseCreateTenderCondition()
    getSearchAssignmentFilterItems(searchParams, () => ({}))
  }

  const drawerGetBaseSection = React.useCallback(
    (): JSX.Element | null =>
      dataListDrawer ? (
        <>
          <Divider />
          <div className={classes.cardRowContainer}>
            <div>
              <h4 className={classes.cardRowTitle}>Условия поставки</h4>
            </div>
            <div>
              {lot.suppliers.data.find(d => d.rowId === dataListDrawer.id)
                ?.deliveryConditions || '-'}
            </div>
            <div>
              <h4 className={classes.cardRowTitle}>Общие сведения</h4>
            </div>
            <div className={classes.cardRowBody}>
              <TextField
                label='Адрес'
                value={dataListDrawer.address || '-'}
                maxRows={5}
                multiline={true}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
              <TextField
                label='Представитель'
                value={dataListDrawer.representative || '-'}
                maxRows={3}
                multiline={true}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
              <TextField
                label='Телефон'
                value={dataListDrawer.phone || '-'}
                maxRows={3}
                multiline={true}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
              <TextField
                label='Сайт'
                value={dataListDrawer.site || '-'}
                maxRows={3}
                multiline={true}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            </div>
            <SupplierHistoryField data={dataListDrawer.tenderTasks} />
          </div>
        </>
      ) : null,
    [
      classes.cardRowBody,
      classes.cardRowContainer,
      classes.cardRowTitle,
      dataListDrawer,
      lot.suppliers.data,
    ],
  )

  const renderDrawerAssignmentContent = React.useCallback(
    (title: string, dataOtherList: Array<IKeyValuePair>): JSX.Element => (
      <>
        <Divider />
        <div className={classes.cardRowContainer}>
          <div>
            <h4 className={classes.cardRowTitle}>{title}</h4>
          </div>
          {dataOtherList.map(value => {
            const parts = value.value.split('|')
            return (
              <div key={value.key} className={classes.cardRowSpaceBetween}>
                <TextField
                  label=''
                  multiline={true}
                  maxRows={50}
                  value={value.key}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
                {parts.length === 1 ? (
                  <TextField
                    label=''
                    multiline={true}
                    maxRows={50}
                    value={value.value}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                    }}
                    className={classes.cardRowSpaceBetweenText}
                  />
                ) : (
                  <div className={classes.cardMultiline}>
                    <span className='flex-item'>{parts[0]}</span>
                    <div className='flex-item column'>
                      {parts.slice(1).map(p => (
                        <span key={p}>{p}</span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </>
    ),
    [
      classes.cardMultiline,
      classes.cardRowContainer,
      classes.cardRowSpaceBetween,
      classes.cardRowSpaceBetweenText,
      classes.cardRowTitle,
    ],
  )

  const renderDrawerAgreementContent = React.useCallback(
    (title: string, dataOtherList: Array<IKeyValuePair>): JSX.Element => (
      <>
        <Divider />
        <div className={classes.cardRowContainer}>
          <div>
            <h4 className={classes.cardRowTitle}>{title}</h4>
          </div>
          {dataOtherList.map(value => (
            <div key={value.key} className={classes.cardRowSpaceBetween}>
              <TextField
                label=''
                multiline={true}
                maxRows={50}
                value={value.key}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
              <TextField
                label=''
                multiline={true}
                maxRows={50}
                value={formatValue(EColumnType.DATE, value.value, '-')}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
                className={classes.cardRowSpaceBetweenText}
              />
            </div>
          ))}
        </div>
      </>
    ),
    [
      classes.cardRowContainer,
      classes.cardRowSpaceBetween,
      classes.cardRowSpaceBetweenText,
      classes.cardRowTitle,
    ],
  )

  const suppliers = _.uniqBy(lot.suppliers.data, 'rowId')

  const renderDrawerContent = React.useCallback(
    (): JSX.Element => (
      <div role='presentation'>
        <List className={classes.drawerListHeader}>
          <h5>{dataListDrawer && dataListDrawer.name}</h5>
          <IconButton
            onClick={async () => {
              await toggleDrawer(false)
            }}
            className={classes.drawerListHeaderCloseBtn}
          >
            <SvgIcon viewBox={'0 0 14 13'} style={{ fontSize: '16px' }}>
              <path
                d='M12.7692 1.70712C13.1597 1.3166 13.1597 0.683431 12.7692 0.292907C12.3787 -0.0976177 11.7455 -0.0976177 11.355 0.292907L12.7692 1.70712ZM1.23064 10.4173C0.840113 10.8078 0.840113 11.4409 1.23064 11.8315C1.62116 12.222 2.25433 12.222 2.64485 11.8315L1.23064 10.4173ZM11.355 11.8315C11.7455 12.222 12.3787 12.222 12.7692 11.8315C13.1597 11.4409 13.1597 10.8078 12.7692 10.4172L11.355 11.8315ZM2.64485 0.292893C2.25433 -0.0976311 1.62116 -0.0976311 1.23064 0.292893C0.840113 0.683417 0.840113 1.31658 1.23064 1.70711L2.64485 0.292893ZM11.355 0.292907L1.23064 10.4173L2.64485 11.8315L12.7692 1.70712L11.355 0.292907ZM12.7692 10.4172L2.64485 0.292893L1.23064 1.70711L11.355 11.8315L12.7692 10.4172Z'
                fill='#535F77'
              />
            </SvgIcon>
          </IconButton>
        </List>
        <List className={classes.drawerContent}>
          <Divider />
          {drawerGetBaseSection()}
          {dataListDrawer && dataListDrawer.comment && (
            <>
              <Divider />
              <div className={classes.cardRowContainer}>
                <div>
                  <h4 className={classes.cardRowTitle}>Комментарий</h4>
                </div>
                <div className={classes.cardRowBody}>
                  <TextField
                    label=''
                    multiline={true}
                    maxRows={50}
                    value={dataListDrawer.comment}
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {dataListDrawer &&
            dataListDrawer.assignments &&
            dataListDrawer.assignments.length > 0 &&
            renderDrawerAssignmentContent(
              'Тендерные задания',
              dataListDrawer.assignments,
            )}
          {dataListDrawer &&
            dataListDrawer.agreements &&
            dataListDrawer.agreements.length > 0 &&
            renderDrawerAgreementContent(
              'Соглашения',
              dataListDrawer.agreements,
            )}
        </List>
        <List>
          <div>
            {assignmentStatus !== 'Закрыто' && (
              <ButtonComponent
                text={''}
                type={EButtonType.DEFAULT}
                typeIcon={EButtonIcon.DELETE}
                noMargin={true}
                disabled={
                  !checkPermissions(
                    Authorities.ASSIGNMENT_DETAILS_REMOVE_SUPPLIER_FROM_LOT,
                  )
                }
                onClick={(): void => {
                  setOpenDialogModal(true)
                  setDialogModalSettings({
                    type: 'supplier',
                    title: 'Удаление поставщика',
                    content:
                      'Вы действительно хотите удалить данного поставщика ?',
                    leftBtnText: 'Отменить',
                    rightBtnText: 'Удалить',
                  })
                  toggleDrawer(
                    false,
                    selectedSupplier
                      ? {
                          key: selectedSupplier.providerId,
                          value: selectedSupplier.name,
                        }
                      : undefined,
                  )
                }}
              />
            )}
          </div>
          <div className={classes.secondPartFooter}>
            {!lot.hasAgreement && (
              <ButtonComponent
                text={'Обновить прайс'}
                type={EButtonType.DEFAULT}
                typeIcon={EButtonIcon.EDIT}
                disabled={
                  !checkPermissions(
                    Authorities.ASSIGNMENT_DETAILS_ADD_SUPPLIER_TO_LOT,
                  )
                }
                onClick={drawerEditBtnClickHandler}
              />
            )}
          </div>
        </List>
      </div>
    ),
    [
      classes.cardRowBody,
      classes.cardRowContainer,
      classes.cardRowTitle,
      classes.drawerContent,
      classes.drawerListHeader,
      classes.drawerListHeaderCloseBtn,
      classes.secondPartFooter,
      dataListDrawer,
      drawerEditBtnClickHandler,
      drawerGetBaseSection,
      lot.hasAgreement,
      renderDrawerAgreementContent,
      renderDrawerAssignmentContent,
      selectedSupplier,
      toggleDrawer,
      assignmentStatus,
      checkPermissions,
    ],
  )

  return (
    <div key={lot.id}>
      {openDialogModal && (
        <DialogModal
          open={openDialogModal}
          handleDiscardChanges={handleCloseDialogModal}
          handleChanges={handleRemoveChangesDialogModal}
          modalTitle={dialogModalSettings?.title}
          modalContent={dialogModalSettings?.content}
          modalButtonRightText={dialogModalSettings?.rightBtnText}
          modalButtonRightType={EButtonType.WARNING}
          modalButtonLeftText={dialogModalSettings?.leftBtnText}
          modalButtonLeftType={EButtonType.DEFAULT}
        />
      )}
      {openAddSupplierModal && (
        <AddAssignmentSupplierModal
          open={openAddSupplierModal}
          type={addAssignmentSupplierModalType}
          supplier={selectedSupplier}
          onSave={(supplierId: string, price: File): void => {
            if (addAssignmentSupplierModalType === EEditableModalType.INSERT) {
              addSupplierHandler(lot.id, supplierId, price, () => {
                const page = `assignments_${assignmentId}_lots_${lot.id}_suppliers`
                const columns = LocalStorageHelper.get<TRowData>(page)

                if (
                  columns &&
                  !columns.some(c => Number(c.key) === Number(supplierId))
                ) {
                  columns.push({
                    key: supplierId,
                    value:
                      lot.suppliers.data.find(
                        sd => Number(sd.rowId) === Number(supplierId),
                      )?.name || '',
                  })
                  LocalStorageHelper.set<TRowData>(page, columns)
                  setSelectedVisibleSuppliers(columns)
                }

                setOpenAddSupplierModal(false)
              })
            } else {
              updatePriceHandler(lot.id, supplierId, price, () =>
                setOpenAddSupplierModal(false),
              )
            }
          }}
          onClose={(): void => setOpenAddSupplierModal(false)}
        />
      )}
      {openMovePositionsModal && (
        <MovePositionsModal
          open={openMovePositionsModal}
          lots={lots
            .filter(l => Number(l.id) !== Number(lot.id))
            .map(l => ({ key: l.id, value: `Лот ${l.position}: ${l.name}` }))}
          onSave={(lotId: string, removeFromCurrentLot: boolean): void => {
            setOpenMovePositionModal(false)
            moveLotPositionsHandler(
              lot.id,
              lotId,
              selectedRows,
              removeFromCurrentLot,
              () => updateSelectedRowsState([]),
            )
          }}
          onClose={(): void => setOpenMovePositionModal(false)}
        />
      )}
      {openAgreementModal && (
        <AddAgreementModal
          open={openAgreementModal}
          model={agreementModalModel}
          onClose={(): void => {
            setOpenAgreementModal(false)
            setAgreementModalModel({
              number: 0,
              supplier: { key: '', value: '' },
              lotId: 0,
              columns: [],
            })
          }}
          onSave={(agreement: TAgreementCreateRequest): void => {
            addToAgreementHandler(agreement, () => {
              setShowSupplierWithAgreementOnly(true)
            })
            setOpenAgreementModal(false)
            setAgreementModalModel({
              number: 0,
              supplier: { key: '', value: '' },
              lotId: 0,
              columns: [],
            })
          }}
          flagAgreementListHandled={flagAgreementListHandled}
          allProjectsAndObjects={[]}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          refreshProjectList={() => {}}
          hasAgreements={false}
        />
      )}
      {openDrawer && (
        <Drawer
          className={classes.drawerList}
          anchor={'right'}
          open={openDrawer}
          onClose={async () => {
            await toggleDrawer(false)
          }}
        >
          {renderDrawerContent()}
        </Drawer>
      )}
      <AssignmentLotTableToolbar
        search
        lot={lot}
        assignmentId={assignmentId}
        onResetSelectedRows={() => setSelectedRows([])}
        selectedRowsCount={selectedRows.length}
        supplierColumns={suppliers.map(sd => ({
          key: sd.rowId,
          title: sd.name,
          type: EColumnType.STRING,
          required: false,
          base: false,
          hidden: false,
          editable: false,
        }))}
        assignmentClosed={assignmentStatus.toLowerCase() === 'закрыто'}
        disableActionButtons={true}
        onAddBtnClick={(): void => {
          setAddAssignmentSupplierModalType(EEditableModalType.INSERT)
          setOpenAddSupplierModal(true)
        }}
        onAddBtnPositionsClick={(): void => {
          setOpenAddPositionsContextModal(true)
        }}
        onRemovePositionsBtnClick={(): void => {
          setDialogModalSettings({
            type: 'positions',
            title: 'Удаление позиций',
            content: 'Вы действительно хотите удалить позиции ?',
            leftBtnText: 'Отменить',
            rightBtnText: 'Удалить',
          })
          setOpenDialogModal(true)
        }}
        onRemoveLotBtnClick={(): void => {
          setDialogModalSettings({
            type: 'lot',
            title: 'Удаление лота',
            content: 'Вы действительно хотите удалить лот ?',
            leftBtnText: 'Отменить',
            rightBtnText: 'Удалить',
          })
          setOpenDialogModal(true)
        }}
        onExportTemplateBtnClick={(): void => {
          const categories = lot?.positions?.map(item => {
            const categoryId = item?.category?.key
            const visibleCols =
              LocalStorageHelper.get<TColumn>(
                `assignments_${assignmentId}_category_${categoryId}_positions_${lot.id}`,
              ) || []
            const categorySupplierColumns =
              LocalStorageHelper.get<TColumn>(
                `assignments_${assignmentId}_category_${item?.category?.key}_lots_${lot.id}`,
              ) || []
            return {
              categoryId,
              propositionCustomFieldIds: categorySupplierColumns.map(
                column => column.key,
              ),
              columnIds: visibleCols.map(col => col.key),
            }
          })
          exportTemplateHandler({
            lotId: lot.id,
            categories,
          })
        }}
        onExportBtnClick={(): void => {
          const suppliers =
            LocalStorageHelper.get<TColumn>(
              `assignments_${assignmentId}_lots_${lot.id}_suppliers`,
            ) || []
          const categories = lot?.positions?.map(item => {
            const categoryId = item?.category?.key
            const categorySupplierColumns =
              LocalStorageHelper.get<TColumn>(
                `assignments_${assignmentId}_category_${item?.category?.key}_lots_${lot.id}`,
              ) || []
            return {
              categoryId,
              propositionCustomFieldIds: categorySupplierColumns.map(
                column => column.key,
              ),
            }
          })
          exportLotHandler({
            lotId: lot.id,
            providersIds: suppliers?.map(col => col.key),
            categories,
          })
        }}
        onChangeLotAssignmentClick={(assignment: string): void => {
          changeLotAssignmentHandler(lot.id, assignment, (): number => 0)
        }}
        onCloseLot={handleCloseLot}
        onEditLotClick={(): void => openRenameLotModal(true, lot)}
        onMovePositionsBtnClick={(): void => setOpenMovePositionModal(true)}
        toggleCreateDialogModal={toggleCreateDialogModal(lot.id)}
      />
      {lot.positions.map(position => {
        const getParamsSelectAllRequest = async (
          callback: (ids: Array<string>) => void,
        ): Promise<void> => {
          const [err, result] = await AssignmentService.getLotSelectAll(
            lot.id,
            assignmentId,
            position.category.key,
          )

          if (
            result.data.status === EAPIResponseStatus.SUCCESS &&
            result.data.data
          ) {
            callback(result.data.data)
          }
          if (err) {
            toastr.error('', 'При отправке запроса произошла ошибка!', {
              progressBar: false,
              showCloseButton: false,
            })
          }
        }

        return (
          <div key={position.category.key}>
            <Accordion
              agreements={lot.agreements}
              assignmentId={assignmentId}
              assignmentName={assignment.name}
              category={position.category}
              lotId={lot.id}
              positionColumns={position.columns}
              positionData={position.data}
              suppliers={lot.suppliers}
              showSupplierWithAgreementOnly={showSupplierWithAgreementOnly}
              visibleSuppliers={selectedVisibleSuppliers}
              pagination={position.pagination}
              selectedRows={selectedRows}
              updateSelectedRowsState={updateSelectedRowsState}
              handleSelectedVisibleSuppliers={handleSelectedVisibleSuppliers}
              addPropertyHandler={addPropertyHandler}
              editPropertyHandler={editPropertyHandler}
              removePropertyHandler={removePropertyHandler}
              toggleDrawer={toggleDrawer}
              changePageHandler={changePageHandler}
              getParamsSelectAllRequest={getParamsSelectAllRequest}
            />
          </div>
        )
      })}
      <TenderConditions
        assignmentId={assignmentId}
        lotId={parseInt(lot.id, 10)}
        getAssignmentDetail={(): void => {
          getSearchAssignmentFilterItems(searchParams, () => ({}))
        }}
        tenderConditions={lot.tenderConditions}
      />
      {isCreateTenderConditionDialogOpen && (
        <NewTenderConditionDialog
          tenderAssignmentId={parseInt(assignmentId, 10)}
          selectedLotId={parseInt(selectedLotId, 10)}
          open={isCreateTenderConditionDialogOpen}
          handleClose={handleCloseCreateTenderCondition}
          handleSubmit={handleSubmitCreateTenderCondition}
        />
      )}
    </div>
  )
}

export default AssignmentLotSearch
