import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Proxima Nova Regular',
    margin: '0',
    height: '100%',
  },
  root: {
    display: 'flex',
    fontFamily: 'Proxima Nova Regular',
    margin: '0 -15px',
    height: '100%',
    '& .flex-item': {
      margin: '0 15px',
      '&.tab-panel': {
        flex: '1 1 auto',
        width: '100px',
      },
    },
  },
  agreementName: {
    fontSize: '24px',
    lineHeight: '29px',
    margin: '10px 0',
    color: '#1B2B4B',
  },
  agreementInfo: {
    margin: '0',
    '& .info-container': {
      margin: '10px 0',
      width: '100%',
    },
  },
  actions: {
    display: 'flex',
    'align-items': 'center',
    'margin-bottom': '20px',
    '& button': {
      'margin-right': '8px',
    },
  },
  title: {
    'margin-top': '20px !important',
  },
  fullHeight: {
    display: 'flex',
    'flex-direction': 'column',
  },
  selected: {
    flex: '1 1 auto',
  },
}))

export default useStyles
