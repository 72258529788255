import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {},
  drawerList: {
    '& .MuiList-root:last-child': {
      padding: '8px 24px 7px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '59px',
      boxShadow: '0px -5px 30px 0px rgba(50, 50, 50, 0.05)',
    },
    '& .MuiButton-label': {
      fontWeight: 'normal',
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '& > .MuiPaper-root': {
      width: '660px',
      '&::-webkit-scrollbar': {
        width: '8px !important',
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
  },
  drawerListHeader: {
    padding: '20px 20px 5px 35px',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiList-padding': {
      maxWidth: '625px',
    },
  },
  drawerListHeaderTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    '& h5': {
      fontSize: '24px',
      color: '#000',
      fontWeight: 'normal',
      wordWrap: 'break-word',
      maxWidth: '550px',
      padding: '0',
      margin: '0',
    },
  },
  drawerListHeaderHash: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    alignItems: 'center',
    margin: '0',
    padding: '15px 15px 0 0',
    '& p': {
      margin: '0 5px 0 0',
      padding: '0',
    },
  },
  drawerListHeaderCloseBtn: {
    width: '40px',
    height: '40px',
  },
  drawerContent: {
    minHeight: 'calc(100vh - 160px)',
    '& hr': {
      margin: '0 0 0 34px',
    },
    '& .image-gallery-icon': {
      zIndex: '0',
      margin: '0',
      padding: '0',
      filter: 'drop-shadow(0 1px 1px #1a1a1a)',
    },
    '& .image-gallery-swipe': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .image-gallery-image': {
      border: '1px solid #E2E4E9',
      maxWidth: '255px',
      minWidth: '255px',
      minHeight: '255px',
      maxHeight: '255px',
    },
    '& .image-gallery-slides': {
      maxWidth: '255px',
      minWidth: '255px',
      minHeight: '255px',
      maxHeight: '255px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .image-gallery-thumbnail-inner': {
      position: 'relative',
      maxWidth: '75px',
      maxHeight: '75px',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    '& .image-gallery-thumbnail': {
      border: '1px solid #E2E4E9',
      maxWidth: '75px',
      minHeight: '75px',
      cursor: 'pointer',
      margin: '7.5px 0',
    },
    '& .image-gallery-thumbnail-image': {
      maxWidth: '74px',
      maxHeight: '74px',
    },
    '& .image-gallery-thumbnail:focus': {
      border: '1px solid #E2E4E9',
    },
    '& .image-gallery-thumbnail.active': {
      border: '2px solid #807E7E',
    },
    '& .image-gallery-thumbnails': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    '& .image-gallery-content.fullscreen': {
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px 34px 20px 20px',
      background: '#fff',
    },
    '& .image-gallery-slide-wrapper': {
      margin: '0 100px 0 0',
    },
    '& .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right': {
      width: 'calc(100% - 215px)',
    },
    '& .fullscreen .image-gallery-slides': {
      maxWidth: '100%',
      maxHeight: '100%',
      margin: '0 60px',
    },
    '& .fullscreen .image-gallery-image': {
      maxWidth: '100%',
    },
    '& .fullscreen .image-gallery-thumbnails-wrapper': {
      minHeight: '100vh',
    },
    '& .fullscreen .image-gallery-thumbnails': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .fullscreen .image-gallery-icon': {
      zIndex: '1',
    },
    '& .image-gallery:not(:root):fullscreen::backdrop': {
      background: 'rgba(0,0,0,0)',
    },
    '& .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image': {
      maxHeight: 'calc(100vh - 200px)',
    },
    '& :focus': {
      outline: 'none',
      border: 'none',
    },
  },
  drawerFooter: {
    position: 'sticky',
    right: 0,
    bottom: 0,
    backgroundColor: '#ffffff',
  },
  cardRowContainer: {
    margin: '0 24px 20px 34px;',
  },
  secondPartFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& button': {
      margin: '0 0 0 10px',
    },
  },
  cardRowTitle: {
    color: '#4C5ECF',
  },
  cardRowBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    margin: '0 -10px',
    '& .MuiFormControl-root': {
      margin: '0 10px',
      // flex: '1 1 280px',
      maxWidth: '280px',
    },
    '& .MuiInputBase-input': {
      lineHeight: '25px',
    },
    '& .MuiInputBase-root': {
      marginTop: '0',
    },

    '& label': {
      marginLeft: '0',
      position: 'relative',
    },
  },
}))

export default useStyles
