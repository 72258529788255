import React, { useState, useMemo, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  CatalogSearch,
  EButtonType,
  EButtonIcon,
  LocalStorageHelper,
} from '@grand-tender/ui'
import { IColumn, IXGridColumn, TFilterItems } from '@grand-tender/types'

import { Authorities } from '@grand-tender/auth-service'
import { Typography } from '@material-ui/core'
import { GridCellParams, GridRowModel } from '@material-ui/x-grid'

import ImprovedTableHeadDisplayColumns from '@grand-tender/ui/src/components/ImprovedTable/ImprovedTableHead/ImprovedTableHeadDisplayColumns/ImprovedTableHeadDisplayColumns'
import { XGridTable } from '@ifellow/ui-library'

import ButtonComponent from '@grand-tender/ui/src/components/Button/Button'
import { columnsMapper, rowsMapper } from '@grand-tender/ui/src/utils/xgrid'
import { modalService } from '@grand-tender/ui/src/components/Modal/ModalService'
import DialogModal from '@grand-tender/ui/src/components/DialogModal/DialogModal'
import { debounce } from 'lodash'
import { TableParams } from '@ifellow/ui-library/dist/components/x-grid-table/x-grid-table'

import { TitleComponent } from '../../../components/TitleComponent/TitleComponent'
import { TabPanel } from '../../../components/TabPanel/TabPanel'
import { usePermissions } from '../../../hooks/usePermissions'

import { PropsFromRedux } from './agreement-search-container'
import useStyles from './style'

const AgreementSearch: React.FC<PropsFromRedux> = ({
  agreements,
  searchParams,
  categoriesData,
  deleteAgreements,
  addProperty,
  editProperty,
  removeProperty,
  exportAgreementsByIds,
  getSearchResult,
}): React.ReactElement => {
  const checkPermissions = usePermissions()
  const history = useHistory()
  const classes = useStyles()
  const { tab } = useParams<{ tab: string }>()
  const [categoryId, setCategoryId] = useState('')
  const localStorageTableId = useMemo(() => `agreement-list-${categoryId}`, [
    categoryId,
  ])

  const [checkedRows, setCheckedRows] = useState<Array<string | number>>([])
  const [dataGridRows, dataGridColumns] = useMemo(() => {
    const requiredColumns = (agreements?.columns ?? [])
      .filter(column => column.required)
      .reduce<Record<string, boolean>>((obj, column) => {
        obj[column.key as string] = true
        return obj
      }, {})

    const rows = rowsMapper(agreements?.data ?? []).map(row => ({
      ...row,
      hasError: Object.entries(row).some(
        ([key, value]) =>
          requiredColumns.hasOwnProperty(key) && (!value || !value.length),
      ),
    }))

    const cols = columnsMapper({
      columns:
        agreements?.columns.map(c => ({
          ...c,
          hide: false,
          width: 150,
          sortOrder: null,
        })) || [],
    }).map(col => ({
      ...col,
      cellClassName: (
        params: Omit<GridCellParams, 'row'> & {
          row: GridRowModel & { hasError?: boolean }
        },
      ) => (params.row.hasError ? 'rowError' : ''),
    }))

    return [rows, cols]
  }, [agreements?.columns, agreements?.data])

  const handleDeletePositions = useCallback(
    (ids: Array<string>) => {
      modalService.openModal(
        <DialogModal
          open={true}
          handleDiscardChanges={() => modalService.closeModal()}
          handleChanges={() => {
            modalService.closeModal()
            deleteAgreements({
              ids,
              callback: () => {
                getSearchResult({ params: searchParams })
                setCheckedRows([])
              },
            })
          }}
          modalTitle={'Удаление соглашений'}
          modalContent={`Вы действительно хотите удалить соглашения (${ids.length})?`}
          modalButtonRightText={'Удалить соглашения'}
          modalButtonRightType={EButtonType.WARNING}
          modalButtonLeftText='Отменить'
          modalButtonLeftType={EButtonType.DEFAULT}
        />,
      )
    },
    [deleteAgreements, getSearchResult, searchParams],
  )

  const handleExportClick = useCallback(() => {
    exportAgreementsByIds(checkedRows as string[])
  }, [exportAgreementsByIds, checkedRows])

  const handleAddProperty = useCallback(
    (model: IColumn): void => {
      addProperty({
        model,
        categoryId,
      }).then(() => getSearchResult({ params: searchParams }))
    },
    [addProperty, categoryId, getSearchResult, searchParams],
  )

  const handleEditProperty = useCallback(
    (model: IColumn): void => {
      model.key = model.id
      editProperty({
        model,
      }).then(() => getSearchResult({ params: searchParams }))
    },
    [editProperty, getSearchResult, searchParams],
  )

  const handleRemoveProperty = useCallback(
    (key: number): void => {
      removeProperty({
        key: String(key),
      }).then(() => {
        const columnSettings: Array<
          IXGridColumn
        > | null = LocalStorageHelper.get(localStorageTableId)

        if (columnSettings) {
          const withoutColumn = columnSettings.filter(
            el => el.field !== String(key),
          )
          LocalStorageHelper.set(localStorageTableId, withoutColumn)
        }

        getSearchResult({ params: searchParams })
      })
    },
    [getSearchResult, localStorageTableId, removeProperty, searchParams],
  )

  const handleSelectNode = useCallback(
    (nodeId: string): void => {
      getSearchResult({
        params: {
          ...searchParams,
          pageData: {
            ...searchParams.pageData,
            ascending: false,
            fieldKey: undefined,
            page: 1,
            perPage: 10,
            categoryId: nodeId,
          },
        },
      })
      setCheckedRows([])
      setCategoryId(nodeId)
    },
    [getSearchResult, searchParams, setCategoryId],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleParametersChange = useCallback(
    debounce((params: TableParams) => {
      const paramsFilter = params.filters as TFilterItems[]
      getSearchResult({
        params: {
          ...searchParams,
          pageData: {
            ...searchParams.pageData,
            page: params.page + 1,
            perPage: params.size,
            ascending: params.sort?.sort === 'asc',
            fieldKey: params.sort?.field,
          },
          agreementConditions: [
            ...searchParams.agreementConditions,
            ...paramsFilter,
          ],
        },
      })
    }, 500),
    [searchParams],
  )

  if (!checkPermissions(Authorities.AGREEMENTS_SEARCH_AND_FILTER_ITEMS)) {
    return <></>
  }

  if (!agreements) {
    return (
      <div className={classes.root}>
        <p style={{ marginLeft: '18px', fontSize: '22px' }}>
          Ничего не найдено
        </p>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className='flex-item'>
        <CatalogSearch
          onSelectNode={handleSelectNode}
          categoriesData={categoriesData}
        />
      </div>
      <div className='flex-item tab-panel'>
        <TabPanel index={0} className={classes.tabPanel}>
          <TitleComponent
            categoryName={agreements.categoryName}
            path={agreements.categoryPath}
            className={classes.title}
          />
          <div className={classes.actions}>
            {checkedRows.length > 0 && (
              <>
                <Typography
                  className={classes.selected}
                  variant='subtitle1'
                  component='div'
                >
                  {checkedRows.length} Выбрано
                </Typography>
                <ButtonComponent
                  text='Экспорт'
                  type={EButtonType.DEFAULT}
                  typeIcon={EButtonIcon.DOWNLOAD}
                  hidden={!checkPermissions(Authorities.AGREEMENTS_EXPORT)}
                  onClick={handleExportClick}
                />
                <ButtonComponent
                  text='Удалить'
                  type={EButtonType.WARNING}
                  typeIcon={EButtonIcon.DELETE}
                  hidden={!checkPermissions(Authorities.AGREEMENTS_REMOVE_ITEM)}
                  onClick={() => handleDeletePositions(checkedRows as string[])}
                />
              </>
            )}
          </div>
          <XGridTable
            renderCustomColumn={({
              onVisibilityChange,
              columns,
            }: {
              onVisibilityChange: (key: string) => void
              columns: Array<IXGridColumn>
            }) => {
              const mappedColumns = agreements.columns.filter(
                column => !column.hidden,
              )
              const visibleColumns = mappedColumns.filter(
                column =>
                  !column.section &&
                  columns.some(
                    col => col.field === String(column.key) && !col.hide,
                  ),
              )
              return (
                <ImprovedTableHeadDisplayColumns
                  columns={mappedColumns}
                  onColumnVisibilityChanged={(_, { key }) =>
                    onVisibilityChange(String(key))
                  }
                  visibleColumns={visibleColumns}
                  showEditPropertyButtons={true}
                  showSections={false}
                  addPropertyHandler={handleAddProperty}
                  editPropertyHandler={handleEditProperty}
                  removePropertyHandler={handleRemoveProperty}
                  isRowChecked={true}
                  offMenu={true}
                />
              )
            }}
            className={classes.table}
            selectionModel={checkedRows}
            rowsPerPageOptions={[10, 20, 50]}
            pageSize={agreements.pagination.rowsPerPage}
            pageStart={agreements.pagination.page - 1}
            mode='server'
            rows={dataGridRows}
            columns={dataGridColumns}
            rowCount={agreements.pagination.total}
            disableFiltering={false}
            onRowDoubleClick={({ id }) =>
              history.push(`/agreements/${tab}/${id}`)
            }
            onParametersChange={handleParametersChange}
            onSelectionModelChange={selectionModel => {
              setCheckedRows(selectionModel)
            }}
            localStorageCache={localStorageTableId}
            withSettings
          />
        </TabPanel>
      </div>
    </div>
  )
}

export default AgreementSearch
