import { TTreeList } from '@grand-tender/types'
import { EActionTypes } from '@grand-tender/ui'

import { IBaseActionCreator } from '../../actions/BaseAction'

export type ISetCategoriesAction = IBaseActionCreator<
  EActionTypes.SET_CATEGORIES,
  TTreeList
>

export type TCategoriesAction = ISetCategoriesAction

const initialState: TTreeList = []

export default (state = initialState, action: TCategoriesAction): TTreeList => {
  switch (action.type) {
    case EActionTypes.SET_CATEGORIES: {
      return action.payload
    }
    default:
      return state
  }
}
