import { SvgComponentType } from '@gmini/ui-kit'
import React from 'react'

export const WarningIcon = ({ color = '#D9575E', ...props }: SvgComponentType) => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M5.9987 10.0003C5.9987 10.5526 6.44641 11.0003 6.9987 11.0003C7.55098 11.0003 7.9987 10.5526 7.9987 10.0003C7.9987 9.44804 7.55098 9.00033 6.9987 9.00033C6.44641 9.00033 5.9987 9.44804 5.9987 10.0003ZM6.28453 7.00202C6.3113 7.3767 6.62306 7.66699 6.9987 7.66699C7.37433 7.66699 7.6861 7.3767 7.71286 7.00202L7.92745 3.99778C7.96595 3.45879 7.53907 3.00033 6.9987 3.00033C6.45833 3.00033 6.03145 3.45879 6.06995 3.99778L6.28453 7.00202ZM6.9987 13.667C3.3187 13.667 0.332031 10.6803 0.332031 7.00033C0.332031 3.32032 3.3187 0.333658 6.9987 0.333658C10.6787 0.333658 13.6654 3.32032 13.6654 7.00033C13.6654 10.6803 10.6787 13.667 6.9987 13.667Z'
      fill={color}
    />
  </svg>
)
