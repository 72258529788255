/* eslint-disable no-undefined */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { without, difference, uniq } from 'lodash'
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { DraggableCore } from 'react-draggable'
import { IColumn } from '@grand-tender/types'
import * as I from './IImprovedTableHead'
import useImprovedHeadStyles from './Style'
import ImprovedTableHeadDisplayColumns from './ImprovedTableHeadDisplayColumns/ImprovedTableHeadDisplayColumns'

const ImprovedTableHead: React.FC<I.OwnProps> = ({
  handleClickColumnVisibilityCheckbox,
  visibleColumns,
  sortDirection,
  sortColumnKey,
  selectedRows,
  columns,
  isRowChecked,
  showEditPropertyButtons,
  data,
  onRequestSort,
  getParamsSelectAllRequest,
  setSelectedRows,
  addPropertyHandler,
  editPropertyHandler,
  removePropertyHandler,
  onAddSection,
  onEditSection,
  onRemoveSection,
  getSectionsDataSource,
  hideDisplayColumns,
  showSections,
}): React.ReactElement => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null)
  const filterColumnsList = useMemo(
    () =>
      visibleColumns.filter(vc => (!vc.section || showSections) && !vc.hidden),
    [visibleColumns],
  )
  const [isEverySelected, setIsEverySelected] = useState(false)
  const [columnsWidths, setColumnsWidths] = useState<{ [K: string]: string }>(
    {},
  )

  useEffect(() => {
    setColumnsWidths({
      ...filterColumnsList.reduce(
        (acc, { key }) => ({ ...acc, [key]: 150 }),
        {},
      ),
      ...columnsWidths,
    })
  }, [filterColumnsList])

  const createSortHandler = (key: number) => (
    event: React.MouseEvent<unknown>,
  ) => {
    onRequestSort(event, key)
  }

  const classes = useImprovedHeadStyles()

  const handlePageSelect = useCallback(
    event => {
      event.stopPropagation()
      if (event.target.checked) {
        const newSelected = selectedRows.concat(data.map(row => row.rowId))
        setSelectedRows(uniq(newSelected))
        return
      }
      setSelectedRows(without(selectedRows, ...data.map(row => row.rowId)))
    },
    [setSelectedRows, selectedRows, data],
  )

  const handleSelectAllClick = () => {
    if (getParamsSelectAllRequest) {
      getParamsSelectAllRequest((ids: Array<string>) => {
        setSelectedRows(ids)
      })
      return
    }

    const newSelected = data?.map(row => row.rowId)
    setSelectedRows(newSelected)
  }

  const calculateColumnWidth = useCallback(
    (key, deltaX) => {
      const width = parseInt(columnsWidths[key], 10) + deltaX
      setColumnsWidths({
        ...columnsWidths,
        [key]: width < 60 ? 60 : width,
      })
    },
    [columnsWidths],
  )

  useEffect(() => {
    if (!data.length) {
      return
    }

    const isFullySelected = !difference(
      data.map(row => row.rowId),
      selectedRows,
    ).length

    if (isFullySelected && !isEverySelected) {
      setIsEverySelected(true)
    }

    if (!isFullySelected && isEverySelected) {
      setIsEverySelected(false)
    }
  }, [isEverySelected, data, selectedRows])

  return (
    <TableHead className={classes.header}>
      <TableRow>
        {isRowChecked && (
          <TableCell padding='checkbox' className={classes.stickyCheckbox}>
            <Button
              className={classes.checkboxMenuButton}
              size='small'
              endIcon={<ArrowDropDownIcon fontSize='small' />}
              onClick={event => {
                setMenuAnchor(event.currentTarget)
              }}
            >
              <Checkbox
                checked={isEverySelected}
                onClick={handlePageSelect}
                color='primary'
                disableRipple
                className={classes.checkedRow}
                classes={{
                  checked: classes.checkboxChecked,
                }}
              />
            </Button>
            <Menu
              className='table-menu'
              open={!!menuAnchor}
              anchorEl={menuAnchor}
              onClose={() => setMenuAnchor(null)}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              {!!getParamsSelectAllRequest && (
                <MenuItem
                  className='menu-item'
                  onClick={() => {
                    handleSelectAllClick()
                    setMenuAnchor(null)
                  }}
                >
                  Выбрать всё
                </MenuItem>
              )}
              <MenuItem
                className='menu-item'
                onClick={() => {
                  setSelectedRows([])
                  setMenuAnchor(null)
                }}
              >
                Очистить выделение
              </MenuItem>
            </Menu>
          </TableCell>
        )}
        {filterColumnsList.map((column: IColumn, index) => (
          <TableCell
            style={{ width: columnsWidths[column.key] }}
            key={column.key}
            align='left'
            sortDirection={
              sortColumnKey === column.key ? sortDirection : undefined
            }
            classes={{
              stickyHeader: index === 0 ? classes.stickyCell : undefined,
            }}
            title={column.title}
          >
            <TableSortLabel
              active={sortColumnKey === column.key}
              direction={sortColumnKey === column.key ? sortDirection : 'asc'}
              onClick={createSortHandler(column.key)}
            >
              <span className={classes.wrappedTitle}>{column.title}</span>
              {sortColumnKey === column.key ? (
                <span className={classes.visuallyHidden}>
                  {sortDirection === 'desc'
                    ? 'sorted descending'
                    : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
            <DraggableCore
              onDrag={(_, { deltaX }) => {
                calculateColumnWidth(column.key, deltaX)
              }}
              onStart={(_, { node }) => {
                setColumnsWidths({
                  ...columnsWidths,
                  [column.key]: `${node.parentElement?.offsetWidth}px`,
                })
              }}
            >
              <div className={classes.resizeHandle} />
            </DraggableCore>
          </TableCell>
        ))}
        <TableCell></TableCell>
        {!hideDisplayColumns && (
          <ImprovedTableHeadDisplayColumns
            columns={columns.filter(c => !c.hidden)}
            onColumnVisibilityChanged={handleClickColumnVisibilityCheckbox}
            visibleColumns={visibleColumns}
            showEditPropertyButtons={showEditPropertyButtons}
            showSections={showSections}
            addPropertyHandler={addPropertyHandler}
            editPropertyHandler={editPropertyHandler}
            removePropertyHandler={removePropertyHandler}
            isRowChecked={isRowChecked}
            onAddSection={onAddSection}
            onEditSection={onEditSection}
            onRemoveSection={onRemoveSection}
            getSectionsDataSource={getSectionsDataSource}
          />
        )}
      </TableRow>
    </TableHead>
  )
}

export default ImprovedTableHead
