import {
  ColumnSettings,
  IColumn,
  IXGridColumn,
  IXGridColumnWithId,
  PositionTab,
  TTableData,
} from '@grand-tender/types'
import { toastr } from 'react-redux-toastr'
import {
  EColumnType,
  ETreeListItemType,
  HashCodeIcon,
  LocalStorageHelper,
} from '@grand-tender/ui'
import { ExpandButton } from '@grand-tender/ui/src/components/ExpandButton/ExpandButton'
import ImprovedTableHeadDisplayColumns from '@grand-tender/ui/src/components/ImprovedTable/ImprovedTableHead/ImprovedTableHeadDisplayColumns/ImprovedTableHeadDisplayColumns'
import { renderCell } from '@grand-tender/ui/src/utils/renders'
import {
  GridCellParams,
  GridColumnResizeParams,
  GridStateColDef,
  GridRowModel,
  GridColumnOrderChangeParams,
} from '@material-ui/x-grid'

import { CircularProgress, Tooltip as MuiTooltip } from '@material-ui/core'

import {
  columnsMapper,
  rowsMapper,
  xGridTypes,
} from '@grand-tender/ui/src/utils/xgrid'

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { debounce, DebouncedFunc } from 'lodash'
import { TableParams } from '@ifellow/ui-library/dist/components/x-grid-table/x-grid-table'
import { XGridTable } from '@ifellow/ui-library'

import { Tooltip } from '@gmini/ui-kit'

import {
  getUserCategoryColumnSettingsFromStorage,
  setColumnsToStorage,
} from '../../common/storageHelper'

import { formatPrice } from '../../utils'
import { TVisibleColumn } from '../../components/PositionCatalog/components/catalog-view/catalog-view'

import {
  PositionBackground,
  PositionNumber,
} from '../assignment/view/assignment-lot/assignment-lot-accordion/assignment-lot-accordion.styled'

import {
  HashCodeIconButton,
  HashHeader,
  LoaderBox,
  LoaderWrap,
  ProjectTooltip,
  useTableStyles,
} from './position-table.styled'

import CustomPagination from './CustomPagination/CustomPagination'

export const EXPAND_COLUMN_ID = 'expandColumn:1'
const PRICE_COLUMN_NAME = 'Цена'
const PROJECTS_OBJECTS_COLUMN_NAME = 'Проекты/Объекты'
const HASH_COLUMN_NAME = '#'
const WINNER_POSITION_COLUMN_NAME = 'Позиция'

const getBackground = (position: number) => {
  switch (position % 3) {
    case 0:
      return '#ffdec3'
    case 1:
      return '#fef4cc'
    case 2:
      return '#eaeaf1'

    default:
      return undefined
  }
}

const expandColumn: IColumn & ColumnSettings = {
  base: true,
  editable: false,
  hidden: false,
  key: EXPAND_COLUMN_ID,
  required: false,
  section: null,
  title: '',
  type: EColumnType.STRING,
  unit: '',
  width: 40,
  hide: false,
  sortOrder: null,
}

type PositionTableProps = {
  tabIndex: number
  positions: TTableData
  tableParams: TableParams
  handleAddProperty: (model: IColumn) => void
  handleEditProperty: (model: IColumn) => void
  handleRemoveProperty: (key: number) => void
  checkedRows: Array<string | number>
  parentGroupId?: string
  fetchPositionInfo: (id: string) => void
  localStorageTableId: string
  handleParametersChange: DebouncedFunc<(params: TableParams) => void>
  nodeType?: ETreeListItemType
  setCheckedRows: (arr: Array<string | number>) => void
  loading: boolean
  updateFlag: number
}

export const PositionTable = ({
  tabIndex,
  positions,
  tableParams,
  handleAddProperty,
  handleEditProperty,
  handleRemoveProperty,
  checkedRows,
  parentGroupId,
  fetchPositionInfo,
  localStorageTableId,
  handleParametersChange,
  nodeType,
  setCheckedRows,
  loading,
  updateFlag,
}: PositionTableProps) => {
  const [expandedRow, setExpandRow] = useState<Record<string, boolean>>({})
  const [rowWithAgreements, setRowWithAgreements] = useState<
    Record<
      string,
      {
        rowId: string
        id: string
        hasError: boolean
      } & Record<string, string | number>[]
    >
  >({})

  const [initColumns, setInitColumns] = useState<(IColumn & ColumnSettings)[]>(
    [],
  )

  const classes = useTableStyles()
  const columnTitlesByKey: { [x: string]: string } = useMemo(
    () => initColumns.reduce((acc, c) => ({ ...acc, [c.key]: c.title }), {}),
    [initColumns],
  )

  useEffect(() => {
    const cols = getUserCategoryColumnSettingsFromStorage(
      localStorageTableId,
      positions.columns,
    )
    setInitColumns(cols)
  }, [positions.columns, localStorageTableId])

  const [dataGridRows, dataGridColumns] = useMemo(() => {
    const REQUIRED_COLUMNS = ['цена', 'соглашение', 'поставщик']

    const requiredColumns = initColumns
      .filter(column => column.required)
      .reduce<Record<string, boolean>>((obj, column) => {
        obj[column.key] = true
        return obj
      }, {})

    let rows = rowsMapper([...positions.data], columnTitlesByKey).map(row => ({
      ...row,
      hasError: Object.entries(row).some(
        ([key, value]) =>
          requiredColumns.hasOwnProperty(key) && (!value || !value.length),
      ),
    }))

    let groupColumns: Array<TVisibleColumn> = []
    try {
      groupColumns = JSON.parse(
        LocalStorageHelper.get(`position_group_${parentGroupId}`) || '',
      )
      // eslint-disable-next-line no-empty
    } catch {}

    const customCols = positions.itemWithAgreements?.length
      ? [expandColumn, ...initColumns]
      : initColumns

    const columns = customCols.filter(column => {
      if (tabIndex === PositionTab.All) {
        return !REQUIRED_COLUMNS.includes(column.title.toLowerCase())
      }
      if (column.title === WINNER_POSITION_COLUMN_NAME) {
        return false
      }
      return true
    })

    const onExpandClick = (row: GridCellParams['row']) => {
      setExpandRow(old => ({ ...old, [row.rowId]: !old[row.rowId] }))
    }

    const cols = columnsMapper({
      byTitle: true,
      columns,
      cellRenderer: (params, type, unit) => {
        const oneOfAgreements = Object.keys(rowWithAgreements).some(k =>
          params.id.toString().includes(`${k}:`),
        )
        if (
          (rowWithAgreements[params.row.rowId] || oneOfAgreements) &&
          params.field === priceColumn?.field
        ) {
          const value = params.value as any
          if ([null, undefined, ''].includes(value)) {
            return '-'
          }

          return params.row[WINNER_POSITION_COLUMN_NAME] !== '0' ? (
            <>
              <PositionBackground
                fullWidth
                background={getBackground(
                  params.row[WINNER_POSITION_COLUMN_NAME],
                )}
              />
              <div style={{ zIndex: 100 }}>
                <PositionNumber>
                  {params.row[WINNER_POSITION_COLUMN_NAME]}
                </PositionNumber>
                <div>{formatPrice(value)}</div>
              </div>
            </>
          ) : (
            <div>{formatPrice(value)}</div>
          )
        }

        return renderCell(params, type, unit)
      },
      renderExpandedRows: positions.itemWithAgreements?.length
        ? ({ key, title, type }) => ({
            field: String(key),
            headerName: title,
            type: xGridTypes[type],
            editable: false,
            disableColumnMenu: true,
            sortable: false,
            resizable: false,
            minWidth: 40,
            renderCell: (params: GridCellParams) => {
              if (!rowWithAgreements[params.row.rowId]) {
                return null
              }
              const expanded = expandedRow[params.row.rowId] || false

              return (
                <ExpandButton
                  expanded={expanded}
                  onClick={e => {
                    e.preventDefault()
                    onExpandClick(params.row)
                  }}
                />
              )
            },
            width: 40,
            filterable: false,
            renderHeader: () => <div></div>,
          })
        : undefined,
    }).map(col => ({
      ...col,
      hide:
        col.hide ||
        groupColumns.some(
          groupColumn =>
            groupColumn.title === col.headerName && !groupColumn.visible,
        ),
      cellClassName: (
        params: Omit<GridCellParams, 'row'> & {
          row: GridRowModel & { hasError?: boolean }
        },
      ): string => (params.row.hasError ? 'rowError' : ''),
    }))

    const priceColumn = cols.find(col => col.headerName === PRICE_COLUMN_NAME)
    const hashCol = cols.find(col => col.headerName === HASH_COLUMN_NAME)
    if (hashCol) {
      const newHashCol: IXGridColumn & {
        hide: boolean
        cellClassName: () => string
      } = {
        ...hashCol,
        renderCell: ({ value }) =>
          value ? (
            <Tooltip title='Скопировать хэш-код позиции'>
              <HashCodeIconButton
                onClick={() => {
                  navigator.clipboard.writeText(value.toString())
                  toastr.success(
                    '',
                    'Хэш-код позиции скопирован в буфер обмена.',
                  )
                }}
              />
            </Tooltip>
          ) : null,
        // eslint-disable-next-line react/display-name
        renderHeader: () => (
          <MuiTooltip title='Хэш-код позиции'>
            <HashHeader>
              <HashCodeIcon />
            </HashHeader>
          </MuiTooltip>
        ),
        minWidth: 40,
        cellClassName: () => classes.hashCell,
        headerClassName: () => classes.hashHeader,
        disableColumnMenu: true,
        resizable: false,
        sortable: false,
      }
      cols.splice(cols.indexOf(hashCol), 1, newHashCol)
    }
    const projectsAndObjectsCol = cols.find(
      col => col.headerName === PROJECTS_OBJECTS_COLUMN_NAME,
    )
    if (projectsAndObjectsCol) {
      projectsAndObjectsCol.renderCell = ({ value }) => {
        const projectObjectLines = value?.toString().match(/.+?\n/gu)
        return (
          <Tooltip
            enterDelay={200}
            enterNextDelay={200}
            noMaxWidth
            title={
              <ProjectTooltip>
                {projectObjectLines?.length
                  ? projectObjectLines.map(line => (
                      <>
                        {line}
                        <br />
                      </>
                    ))
                  : value?.toString()}
              </ProjectTooltip>
            }
          >
            <span>{value}</span>
          </Tooltip>
        )
      }
    }
    if (Object.values(expandedRow).some(v => v)) {
      const expandedRowIds = Object.keys(expandedRow)
        .filter(k => expandedRow[k])
        .map(k => Number(k))

      expandedRowIds.forEach(id => {
        const cellData = rowWithAgreements[id]
        if (!cellData || !cellData.length) {
          return
        }

        const index = rows.map(r => `${r.rowId}`).indexOf(id.toString())
        if (index === -1) {
          return
        }

        rows = [
          ...rows.slice(0, index + 1),
          ...cellData,
          ...rows.slice(index + 1),
        ] as {
          hasError: boolean
          id: string
          rowId: string
          photo?: string | undefined
          price?: string | undefined
        }[]
      })
    }
    return [rows, cols]
  }, [
    initColumns,
    positions.data,
    positions.itemWithAgreements?.length,
    classes,
    columnTitlesByKey,
    expandedRow,
    parentGroupId,
    tabIndex,
    rowWithAgreements,
  ])

  React.useEffect(() => {
    if (positions.itemWithAgreements?.length) {
      const itemsWithData = positions.itemWithAgreements.filter(
        i => i.data.length,
      )

      setRowWithAgreements(
        itemsWithData.reduce(
          (
            acc: Record<
              string,
              {
                rowId: string
                id: string
                hasError: boolean
              }[]
            >,
            { data, rowId },
          ) => ({
            ...acc,
            [rowId]: data.map((val, i) => ({
              ...val.reduce(
                (acc: Record<string, string | number>, { key, value }) => ({
                  ...acc,
                  [columnTitlesByKey[key]]: value,
                }),
                {},
              ),
              rowId: `${rowId}:${i}`,
              id: `${rowId}:${i}`,
              hasError: false,
            })),
          }),
          {},
        ),
      )
    }
  }, [columnTitlesByKey, positions.itemWithAgreements])

  const agreementRowsCount = Object.keys(expandedRow)
    .filter(k => expandedRow[k])
    .reduce(
      (acc, id) =>
        rowWithAgreements[id] ? acc + rowWithAgreements[id].length : acc,
      0,
    )

  React.useEffect(() => {
    if (loading) {
      setExpandRow({})
    }
  }, [loading])

  const tabWithAssignments =
    tabIndex === PositionTab.Archived || tabIndex === PositionTab.Passed

  const onParametersChange = useCallback(
    (params: TableParams) => {
      const newCols = initColumns.map(c =>
        c.title === params.sort?.field
          ? { ...c, sortOrder: params.sort.sort === 'asc' ? true : false }
          : { ...c, sortOrder: null },
      )
      setColumnsToStorage(localStorageTableId, newCols)
      setInitColumns(newCols)
      handleParametersChange(params)
    },
    [handleParametersChange, initColumns, localStorageTableId],
  )

  const handleColumnOrderChange = useCallback(
    ({ targetIndex, oldIndex }: GridColumnOrderChangeParams) => {
      const shift = positions.itemWithAgreements?.length ? 2 : 1
      const movingCol = initColumns[oldIndex - shift]
      const newCols = initColumns.filter((_, i) => i !== oldIndex - shift)
      newCols.splice(targetIndex - shift, 0, movingCol)
      setColumnsToStorage(localStorageTableId, newCols)
      setInitColumns(newCols)
    },
    [initColumns, localStorageTableId, positions.itemWithAgreements?.length],
  )

  const handleColumnResize = useCallback(
    ({
      colDef,
      width,
    }: GridColumnResizeParams & {
      colDef: GridStateColDef & { id?: string }
    }) => {
      const newCols = initColumns.map(col =>
        col.key.toString?.() === colDef.id ? { ...col, width } : col,
      )
      setColumnsToStorage(localStorageTableId, newCols)
      setInitColumns(newCols)
    },
    [initColumns, localStorageTableId],
  )

  const handleColumnVisibilityChanged = useCallback(
    (id: number) => {
      const newCols = initColumns.map(c =>
        c.key === id ? { ...c, hide: !c.hide } : c,
      )
      setColumnsToStorage(localStorageTableId, newCols)
      setInitColumns(newCols)
    },
    [initColumns, localStorageTableId],
  )

  const startPaginationNumber =
    (positions.pagination.page - 1) * positions.pagination.rowsPerPage

  const tabWithAssignmentsPageSize =
    positions.pagination.total - startPaginationNumber <
    positions.pagination.rowsPerPage
      ? positions.pagination.total - startPaginationNumber
      : positions.pagination.rowsPerPage

  const isAgreementRow = React.useCallback(
    (rowId: string) =>
      Object.values(rowWithAgreements).some(r =>
        r.some(d => d.rowId === rowId),
      ),
    [rowWithAgreements],
  )

  if (loading && !dataGridRows.length) {
    return (
      <LoaderWrap>
        <LoaderBox>
          <CircularProgress
            style={{
              color: '#4C5ECF',
            }}
          />
        </LoaderBox>
      </LoaderWrap>
    )
  }

  return (
    <XGridTable
      loading={loading}
      className={classes.table}
      renderCustomColumn={({
        onVisibilityChange,
        columns,
      }: {
        onVisibilityChange: (key: string) => void
        columns: Array<IXGridColumnWithId>
      }) => {
        const requiredColumn = ['цена', 'соглашение', 'поставщик']
        const mappedColumns = initColumns.filter(column => {
          if (column.hidden || column.title === WINNER_POSITION_COLUMN_NAME) {
            return false
          }
          if (tabIndex === PositionTab.All) {
            return !requiredColumn.includes(column.title.toLowerCase())
          }
          return !column.hidden
        })
        const visibleColumns = mappedColumns.filter(column =>
          columns.some(col => col.id === String(column.key) && !col.hide),
        )

        return (
          <ImprovedTableHeadDisplayColumns
            columns={mappedColumns}
            onColumnVisibilityChanged={(_, { key, id }) => {
              onVisibilityChange(String(key))
              handleColumnVisibilityChanged(id)
            }}
            visibleColumns={visibleColumns}
            showEditPropertyButtons={true}
            showSections={tabIndex === PositionTab.Passed}
            addPropertyHandler={handleAddProperty}
            editPropertyHandler={handleEditProperty}
            removePropertyHandler={handleRemoveProperty}
            isRowChecked={true}
            offMenu={true}
          />
        )
      }}
      selectionModel={checkedRows}
      rowsPerPageOptions={[10, 50, 100]}
      //Таблица не показывает на одной странице больше строк, чем передано в этом параметре
      pageSize={
        tabWithAssignments
          ? tabWithAssignmentsPageSize + agreementRowsCount
          : positions.pagination.rowsPerPage
      }
      pageStart={positions.pagination.page - 1}
      mode='server'
      onColumnResize={debounce(handleColumnResize, 500)}
      onColumnOrderChange={handleColumnOrderChange}
      initSortModel={tableParams.sort?.field ? [tableParams.sort] : []}
      rows={dataGridRows}
      columns={dataGridColumns}
      //Общее число строк в таблице. Таблица не будет показывать строки, не влезшие в это число,
      //даже если фактически они там еще есть. Число для таблиц с аккордеонами взято с запасом,
      //если кто-то решит пошерстить таблицу на 1000 элементов с вложенностью 50 в каждом аккордеоне где нибудь к концу.
      //Так дешевле чем считать и если будут проблемы, то просто можно добавить
      rowCount={tabWithAssignments ? 50000 : positions.pagination.total}
      disableFiltering={false}
      onRowDoubleClick={({ id }) => {
        if (isAgreementRow(id as string)) {
          return
        }
        fetchPositionInfo(id as string)
      }}
      onParametersChange={onParametersChange}
      onSelectionModelChange={selectionModel => {
        const selection = selectionModel.filter(id => Number(id))
        setCheckedRows(selection)
      }}
      getRowClassName={params =>
        isAgreementRow(params.row.rowId) ? classes.accordionRow : ''
      }
      withSettings={nodeType === ETreeListItemType.LIST && !parentGroupId}
      customPagination={
        tabWithAssignments
          ? ({ setParamsState }) => (
              <CustomPagination
                setParamsState={setParamsState}
                options={[10, 50, 100]}
                pagination={positions.pagination}
              />
            )
          : undefined
      }
    />
  )
}
