import React, { useCallback, useEffect, useState } from 'react'
import { Dialog, TextField } from '@material-ui/core'
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ModalTitle } from '@grand-tender/ui/src/components/BaseModalAddContextPositions/ModalTitle/ModalTitle'
import { ButtonComponent, EButtonType } from '@grand-tender/ui'

import { getAssignmentsCondition } from '../../../../actions/Assignments'
import { useAppDispatch } from '../../../../store'

import './styles.scss'

const validationSchema = yup.object({
  conditionName: yup
    .string()
    .trim()
    .required('Поле должно быть заполнено'),
})

type TEditDialog = {
  lotId: number
  open: boolean
  tenderConditionId: number
  tenderConditionName: string
  handleClose: () => void
  handleOk: (values: Record<string, unknown>) => void
}

export const EditDialog: React.FC<TEditDialog> = ({
  handleClose,
  open,
  handleOk,
  tenderConditionId,
  tenderConditionName,
  lotId,
}) => {
  const dispatch = useAppDispatch()
  const [tenderConditions, setTenderConditions] = useState<
    string[] | undefined
  >(undefined)
  const [tenderConditionGroups, setTenderConditionGroups] = useState<
    Record<string, string>
  >({})

  const formik = useFormik({
    initialValues: {
      tenderConditionId,
      lotId,
      conditionName: '',
    },
    validationSchema,
    onSubmit: values => handleOk(values),
  })

  const [inputValue, setInputValue] = useState<string>('')

  useEffect(() => {
    ;(() => {
      if (tenderConditions) {
        return
      }

      dispatch(getAssignmentsCondition({}))
        .unwrap()
        .then(response => {
          const options = [] as string[]
          const groups: Record<string, string> = {}

          Object.keys(response.tenderConditions).forEach((group: any) => {
            const arr = (response.tenderConditions[group] as unknown) as any[]
            arr.forEach((label: string) => {
              options.push(label)
              groups[label] = group
            })
          })
          setTenderConditions(
            options.sort((a, b) => (groups[b] > groups[a] ? -1 : 1)),
          )
          setTenderConditionGroups(groups)

          if (options.includes(tenderConditionName)) {
            formik.setFieldValue('conditionName', tenderConditionName)
            setInputValue(tenderConditionName)
          }
        })
    })()
    // eslint-disable-next-line
  }, [dispatch, tenderConditions, tenderConditionName])

  const renderOptions = useCallback(
    (option: string): string => option ?? '',
    [],
  )

  const groupBy = useCallback(option => tenderConditionGroups[option], [
    tenderConditionGroups,
  ])

  const getOptionLabel = useCallback(
    (option: string): string => option ?? '',
    [],
  )

  const handleAutocompleteChange = useCallback(
    (_, option: string | null) => {
      formik.setFieldValue('conditionName', option ?? '')
    },
    [formik],
  )

  const handleInputChange = useCallback((_, value) => setInputValue(value), [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className='condition-dialog-modal'
    >
      <ModalTitle id='edit-dialog-title' onClose={handleClose}>
        Редактирование ТУ
      </ModalTitle>
      <form className='form' onSubmit={formik.handleSubmit}>
        <div className='body'>
          <div className='form-field'>
            <Autocomplete
              options={tenderConditions ?? []}
              groupBy={groupBy}
              onChange={handleAutocompleteChange}
              getOptionLabel={getOptionLabel}
              renderOption={renderOptions}
              onInputChange={handleInputChange}
              inputValue={inputValue}
              value={formik.values.conditionName}
              renderInput={(
                params: AutocompleteRenderInputParams,
              ): JSX.Element => (
                <TextField
                  {...params}
                  name={'conditionName'}
                  label='Новое имя'
                  variant='outlined'
                  error={
                    formik.touched.conditionName &&
                    Boolean(formik.errors.conditionName)
                  }
                  helperText={
                    formik.touched.conditionName && formik.errors.conditionName
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: params.InputProps.endAdornment,
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className='footer'>
          <ButtonComponent
            htmlType='reset'
            text='Отменить'
            type={EButtonType.DEFAULT}
            onClick={handleClose}
          />
          <ButtonComponent
            htmlType='submit'
            text='Применить'
            type={EButtonType.PRIMARY}
          />
        </div>
      </form>
    </Dialog>
  )
}
