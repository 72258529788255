import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  expanded: {},
  supLeftBorder: {
    'border-left': '2px solid black',
  },
  hasMinPrice: {
    backgroundColor: 'yellow !important',
  },
  supplierHeader: {
    display: 'flex',
    width: '100%',
    padding: 0,
    gap: '5px',
  },
  supplierNameWrap: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },

  supplierName: {
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'right',
  },
  priceText: {
    display: 'flex',
    justifyContent: 'end',
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'right',
  },
  tableWrapper: {
    maxWidth: '100%',
    overflow: 'auto',
    '& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0,
    },
    '& .MuiDataGrid-root .MuiDataGrid-cell': {
      position: 'relative',
    },
  },
  accordionActions: {
    height: '28px',
    '& .MuiCheckbox-root': {
      '& .MuiIconButton-label': {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
    '& .MuiCheckbox-root.Mui-checked': {
      '& .MuiIconButton-label': {
        color: '#3f51b5',
      },
    },
    '& .MuiCheckbox-root.Mui-disabled': {
      '& .MuiIconButton-label': {
        color: 'rgba(0, 0, 0, 0.54)!important',
      },
    },
  },
  accordion: {
    '& .MuiAccordion-root.Mui-expanded:last-child': {
      margin: 0,
    },
  },
}))

export default useStyles
