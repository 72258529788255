import {
  IAPIResult,
  TTableData,
  TRowData,
  IRow,
  TPositionModel,
  IKeyValuePair,
  IColumn,
  TPromptPositions,
  TSort,
  PositionTab,
  TPositionsRequest,
} from '@grand-tender/types'

import { apiClient, ApiResponse } from '@grand-tender/base-api-service/src'

const headers: Record<string, string> = { 'Content-Type': 'application/json' }

const getPositionsPaths = {
  [PositionTab.All]: '/item/page/filter',
  [PositionTab.Passed]: '/item/page/passed/filter',
  [PositionTab.NotPassed]: '/item/page/not-passed/filter',
  [PositionTab.Archived]: '/item/page/archived',
}

export default class PositionService {
  static getPositions(
    params: TPositionsRequest,
    tab: PositionTab,
  ): Promise<ApiResponse<IAPIResult<TTableData>>> {
    return apiClient.post({
      path: getPositionsPaths[tab],
      headers,
      body: params,
    })
  }

  static getPositionsOld(
    categoryId: string,
    page = 1,
    perPage = 10,
    sortColumnKey: number | undefined,
    sortColumnDirection: TSort,
  ): Promise<ApiResponse<IAPIResult<TTableData>>> {
    let body: Record<string, number | string | boolean> = {
      categoryId,
      page,
      perPage,
    }

    if (sortColumnKey) {
      body = {
        ...body,
        fieldKey: sortColumnKey,
        ascending: sortColumnDirection === 'asc',
      }
    }

    return apiClient.post({ path: `/item/page`, headers, body })
  }

  // TODO 1193
  static getPositionsPassedSelectAll(
    categoryId: string,
  ): Promise<ApiResponse<IAPIResult<Array<string>>>> {
    return apiClient.post({
      path: `/item/select/past`,
      headers,
      body: categoryId,
    })
  }

  static getPositionsNotPassedSelectAll(
    categoryId: string,
    inTender: boolean,
    notInTender: boolean,
  ): Promise<ApiResponse<IAPIResult<Array<string>>>> {
    return apiClient.post({
      path: `/item/select/not-passed`,
      headers,
      body: {
        categoryId,
        inTender,
        notInTender,
      },
    })
  }
  // TODO 1193
  static getPositionsSelectAll(
    categoryId: string,
  ): Promise<ApiResponse<IAPIResult<Array<string>>>> {
    return apiClient.post({
      path: `/item/select/all`,
      headers,
      body: categoryId,
    })
  }

  static addPosition(
    position: TRowData,
  ): Promise<ApiResponse<IAPIResult<IRow>>> {
    return apiClient.put({ path: `/item`, headers, body: { data: position } })
  }

  static editPosition(position: IRow): Promise<ApiResponse<IAPIResult<IRow>>> {
    return apiClient.post({ path: `/item`, headers, body: position })
  }

  static deletePositions(
    ids: Array<string>,
  ): Promise<ApiResponse<IAPIResult<string>>> {
    return apiClient.post({ path: `/item/delete`, headers, body: ids })
  }

  static getPositionData(
    id: string,
  ): Promise<ApiResponse<IAPIResult<TPositionModel>>> {
    return apiClient.get({ path: `/item/detail/${id}`, headers })
  }

  static uploadFiles(
    positionId: string,
    file: File,
  ): Promise<ApiResponse<IAPIResult<IKeyValuePair>>> {
    const formData = new FormData()
    formData.append('file', file)
    return apiClient.post({
      path: `/item/photo/${positionId}`,
      body: formData,
    })
  }

  static removeFiles(ids: Array<string>): Promise<ApiResponse<IAPIResult<{}>>> {
    return apiClient.post({ path: `/item/photo/delete`, headers, body: ids })
  }
  // TODO 1193 check
  static exportPositionsByIds(
    ids: Array<string>,
    categoryId?: string,
    isdownloadBycategory?: boolean,
  ): Promise<ApiResponse<IAPIResult<Blob>>> {
    const body = isdownloadBycategory
      ? { categoryId: categoryId }
      : { entityIds: ids }
    return apiClient.post({
      path: `/export/page/all`,
      body,
      responseType: 'blob',
    })
  }

  // TODO 1193 check
  static exportPassedPositionsByIds(
    ids: Array<string>,
    categoryId?: string,
    isdownloadBycategory?: boolean,
  ): Promise<ApiResponse<IAPIResult<Blob>>> {
    const body = isdownloadBycategory
      ? { categoryId: categoryId }
      : { entityIds: ids }
    return apiClient.post({
      path: `/export/page/passed`,
      body,
      responseType: 'blob',
    })
  }

  // TODO 1193 check
  static exportNotPassedPositionsByIds(
    ids: Array<string>,
    categoryId?: string,
    isdownloadBycategory?: boolean,
  ): Promise<ApiResponse<IAPIResult<Blob>>> {
    const body = isdownloadBycategory
      ? { categoryId: categoryId }
      : { entityIds: ids }
    return apiClient.post({
      path: `/export/page/not-passed`,
      body,
      responseType: 'blob',
    })
  }

  // TODO 1193 check
  static exportArchivedPositionsByIds(
    ids: Array<string>,
    categoryId?: string,
    isdownloadBycategory?: boolean,
  ): Promise<ApiResponse<IAPIResult<Blob>>> {
    const body = isdownloadBycategory
      ? { categoryId: categoryId }
      : { entityIds: ids }
    return apiClient.post({
      path: `/export/page/archived`,
      body,
      responseType: 'blob',
    })
  }
  // TODO 1193 check
  static exportPositionsFolderLevel(
    categoryId: string,
    isExportOneFile: boolean,
  ): Promise<ApiResponse<IAPIResult<Blob>>> {
    const data = { categoryId, singlePage: isExportOneFile }
    return apiClient.post({
      path: `/export/items/category`,
      body: data,
      responseType: 'blob',
    })
  }

  // TODO 1193 check
  static exportPositionsFolderLevelPassed(
    categoryId: string,
    isExportOneFile: boolean,
  ): Promise<ApiResponse<IAPIResult<Blob>>> {
    const data = { categoryId, singlePage: isExportOneFile }
    return apiClient.post({
      path: `/export/items/category/passed`,
      body: data,
      responseType: 'blob',
    })
  }

  // TODO 1193 check
  static exportPositionsFolderLevelNotPassed(
    categoryId: string,
    isExportOneFile: boolean,
    inTender: boolean,
    notInTender: boolean,
  ): Promise<ApiResponse<IAPIResult<Blob>>> {
    const data = {
      categoryId,
      singlePage: isExportOneFile,
      inTender,
      notInTender,
    }
    return apiClient.post({
      path: `/export/items/category/not-passed`,
      body: data,
      responseType: 'blob',
    })
  }

  // TODO 1193 check

  static exportPositionsFolderLevelArchived(
    categoryId: string,
    isExportOneFile: boolean,
    inTender: boolean,
    notInTender: boolean,
  ): Promise<ApiResponse<IAPIResult<Blob>>> {
    const data = {
      categoryId,
      singlePage: isExportOneFile,
      inTender,
      notInTender,
    }
    return apiClient.post({
      path: `/export/archived/items/category`,
      body: data,
      responseType: 'blob',
    })
  }

    // TODO 1193 check

  static exportPositionsTemplate(
    categoryId: string,
  ): Promise<ApiResponse<IAPIResult<Blob>>> {
    return apiClient.post({
      path: `/export/headers`,
      body: categoryId,
      responseType: 'blob',
    })
  }

  static importPositions(
    categoryId: string,
    file: File,
  ): Promise<ApiResponse<IAPIResult<IKeyValuePair>>> {
    const formData = new FormData()
    formData.append('file', file)
    return apiClient.post({
      path: `/import/items/${categoryId}`,
      body: formData,
    })
  }

  static addProperty(
    categoryId: number,
    model: IColumn,
  ): Promise<ApiResponse<IAPIResult<IColumn>>> {
    return apiClient.put({
      path: `/category/field`,
      headers,
      body: {
        categoryId,
        property: model,
      },
    })
  }

  static editProperty(
    model: IColumn,
  ): Promise<ApiResponse<IAPIResult<IColumn>>> {
    return apiClient.post({ path: `/category/field`, headers, body: model })
  }

  static removeProperty(key: string): Promise<ApiResponse<IAPIResult<{}>>> {
    return apiClient.delete({ path: `/category/field/${key}`, headers })
  }

  static promptNamePositions(
    data: TPromptPositions,
  ): Promise<ApiResponse<IAPIResult<Array<string>>>> {
    return apiClient.post({
      path: `/filter/item/field/short`,
      headers,
      body: data,
    })
  }
}
