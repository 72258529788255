import { makeStyles, Theme } from '@material-ui/core/styles'

const useImprovedTableRowStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    '&$rowSelected, &$rowSelected:hover,': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    cursor: 'pointer',
    '& .MuiAvatar-root': {
      borderRadius: 0,
      border: '1px solid #E2E4E9;',
      display: 'inline-flex',
      marginRight: '10px',
    },
    '& .flex-container': {
      display: 'flex',
    },
    '& span': {
      alignSelf: 'center',
      wordWrap: 'break-word',
      maxWidth: '350px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  rowSelected: {},
  checkedRow: {
    '&:hover, &:focus, &:active': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '&$checkboxChecked, &$checkboxChecked:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '& .MuiTableCell-root': {
      border: '0',
    },
  },
  checkboxChecked: {},
  cellContainer: {
    display: 'flex',
    margin: '0 -5px',
    '& .cell-label': {
      maxWidth: '100%',
    },
    '& .cell-label, .cell-value': {
      margin: '0 5px',
    },
  },
}))

export default useImprovedTableRowStyles
