import React from 'react'
import get from 'lodash/get'
import { Menu, MenuItem } from '@material-ui/core'
import { ITreeListItem } from '@grand-tender/types'
import {
  EEditableModalType,
  ETreeListItemType,
  ETreeListMovingType,
} from '@grand-tender/ui/src/constants'

import { useSelector } from 'react-redux'

import {
  findParentNodeWithTheGroupType,
  getHasChildrenNodeWithTheGroupType,
} from '../../../../../utils'
import usePositionCatalogStyles from '../../../styles/Style'
import {
  catalogModalType,
  copyNodeAction,
  removeNode,
  setCatalogModal,
  setConfirmDialogOpen,
  setCopyId,
  setMergeType,
} from '../../../store/catalog-slice'
import { getCatalog } from '../../../store/catalog-selectors'
import { useAppDispatch } from '../../../../../store'

const getTitle = (nodeType: string): string => {
  if (nodeType === ETreeListItemType.FOLDER) {
    return 'Действия с разделами'
  }

  if (nodeType === ETreeListItemType.LIST) {
    return 'Действия с разделами'
  }

  return 'Действия с группами'
}

export type MenuItemActionProps = {
  setSelectedAnchorMenuItem: (param: HTMLElement | null) => void
  selectedMenuItemAction: ITreeListItem
  anchorEl: HTMLElement | null
  handleClose: () => void
  depthLevel: number
  disableNodeMovingConcat?: boolean
  withCopyingElements?: boolean
  maxLevel?: number
  getCategories?: () => void
  handleDiscardChanges: () => void
  withGroup?: boolean
  listItems: ITreeListItem[]
}

export const MenuItemAction: React.FC<MenuItemActionProps> = ({
  anchorEl,
  handleClose,
  selectedMenuItemAction,
  depthLevel,
  maxLevel,
  disableNodeMovingConcat,
  withCopyingElements,
  setSelectedAnchorMenuItem,
  getCategories,
  handleDiscardChanges,
  withGroup,
  listItems,
}) => {
  const dispatch = useAppDispatch()
  const { hasUnsavedChanges } = useSelector(getCatalog)

  const classes = usePositionCatalogStyles()
  const parentNodeWithTheGroupType = findParentNodeWithTheGroupType(
    listItems,
    Number(selectedMenuItemAction.id),
  )

  /**
   * (удалить после того как можно будет копировать группы)
   */
  const hasChildrenNodeWithTheGroupType = getHasChildrenNodeWithTheGroupType(
    selectedMenuItemAction,
  )

  const hasMoveItem = get(
    selectedMenuItemAction,
    'disabledActions.move',
    disableNodeMovingConcat,
  )

  const handleMenuItem = (action: catalogModalType) => (): void => {
    dispatch(setCatalogModal(action))
    handleClose()
  }

  const handleRemoveNode = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation()
    dispatch(removeNode(selectedMenuItemAction))
    handleClose()
  }

  const handleMergeMenuItem = (mergeType: ETreeListMovingType) => (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    event.stopPropagation()
    setSelectedAnchorMenuItem(event.currentTarget)
    dispatch(setMergeType({ mergeType }))
  }

  const handleCopyItem = (id: string | number) => (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    event.stopPropagation()
    if (hasUnsavedChanges) {
      dispatch(setConfirmDialogOpen(true))
      dispatch(setCopyId(id))
      return
    }
    dispatch(copyNodeAction(id)).then(() => getCategories && getCategories())
    handleDiscardChanges()
    handleClose()
  }

  return (
    <Menu
      className={classes.settingsMenu}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      getContentAnchorEl={null}
      keepMounted
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuItem className='menu-title'>
        <b>{getTitle(selectedMenuItemAction.type)}</b>
      </MenuItem>
      {selectedMenuItemAction.type !== ETreeListItemType.LIST &&
        !selectedMenuItemAction.disabledActions?.addFolder &&
        !(
          typeof maxLevel !== 'undefined' && depthLevel >= (maxLevel || 0) - 1
        ) && (
          <MenuItem
            onClick={handleMenuItem({
              open: true,
              data: {
                title: 'Добавить раздел',
                submitBtnText: 'Добавить',
                item: selectedMenuItemAction,
                mode: EEditableModalType.INSERT,
                type: ETreeListItemType.FOLDER,
              },
            })}
          >
            Добавить раздел
          </MenuItem>
        )}
      {selectedMenuItemAction.type !== ETreeListItemType.LIST &&
        !selectedMenuItemAction?.disabledActions?.addList &&
        !(
          typeof maxLevel !== 'undefined' && depthLevel >= (maxLevel || 0) - 1
        ) && (
          <MenuItem
            onClick={handleMenuItem({
              open: true,
              data: {
                title: 'Добавить список',
                submitBtnText: 'Добавить',
                item: selectedMenuItemAction,
                type: ETreeListItemType.LIST,
                mode: EEditableModalType.INSERT,
              },
            })}
          >
            Добавить список
          </MenuItem>
        )}
      {withGroup && selectedMenuItemAction.type !== ETreeListItemType.LIST && (
        <MenuItem
          onClick={handleMenuItem({
            open: true,
            data: {
              title: 'Добавить группу',
              submitBtnText: 'Добавить',
              item: selectedMenuItemAction,
              type: ETreeListItemType.GROUP,
              mode: EEditableModalType.INSERT,
            },
          })}
        >
          Добавить группу
        </MenuItem>
      )}
      {selectedMenuItemAction.type === ETreeListItemType.FOLDER &&
        !selectedMenuItemAction?.disabledActions?.concat &&
        !disableNodeMovingConcat && (
          <MenuItem onClick={handleMergeMenuItem(ETreeListMovingType.CONCAT)}>
            Объединить с разделом
          </MenuItem>
        )}
      {!selectedMenuItemAction?.disabledActions?.rename && (
        <MenuItem
          onClick={handleMenuItem({
            open: true,
            data: {
              title: 'Редактировать',
              submitBtnText: 'Изменить',
              mode: EEditableModalType.EDIT,
              item: selectedMenuItemAction,
              type: selectedMenuItemAction.type,
            },
          })}
        >
          Переименовать
        </MenuItem>
      )}
      {!hasMoveItem && (
        <MenuItem onClick={handleMergeMenuItem(ETreeListMovingType.MOVE)}>
          Переместить
        </MenuItem>
      )}
      {withCopyingElements &&
        !hasChildrenNodeWithTheGroupType &&
        parentNodeWithTheGroupType?.type !== ETreeListItemType.GROUP && (
          <MenuItem onClick={handleCopyItem(selectedMenuItemAction.id)}>
            Копировать
          </MenuItem>
        )}
      {!selectedMenuItemAction?.disabledActions?.delete && (
        <MenuItem onClick={handleRemoveNode}>Удалить</MenuItem>
      )}
    </Menu>
  )
}
