/* eslint-disable import/no-anonymous-default-export */
import * as _ from 'lodash'
import {
  TViewDetail,
  IKeyValuePair,
  TAssignmentModel,
  TAgreementModel,
  TAgreementUpdateRequest,
  TAssignmentLotPage,
  IAgreementRow,
} from '@grand-tender/types'
import { EActionTypes } from '@grand-tender/ui'

import { IBaseActionCreator } from '../../actions/BaseAction'

export type ISetAssignmentDetailAction = IBaseActionCreator<
  EActionTypes.SET_ASSIGNMENT_DETAIL,
  TAssignmentModel
>

export type IChangeAssignmentLotCategoryPageAction = IBaseActionCreator<
  EActionTypes.CHANGE_ASSIGNMENT_LOT_CATEGORY_PAGE,
  {
    categoryId: number
    lotId: number
    lotData: TAssignmentLotPage
  }
>

export type IRenameAssignmentLotAction = IBaseActionCreator<
  EActionTypes.RENAME_ASSIGNMENT_LOT,
  IKeyValuePair
>

export type IRemoveAssignmentLotSupplierAction = IBaseActionCreator<
  EActionTypes.REMOVE_ASSIGNMENT_LOT_SUPPLIER,
  IKeyValuePair
>

export type ISetAgreementDetailAction = IBaseActionCreator<
  EActionTypes.SET_AGREEMENT_DETAIL,
  TAgreementModel
>

export type IUpdateAgreementDetailAction = IBaseActionCreator<
  EActionTypes.EDIT_AGREEMENT,
  TAgreementUpdateRequest
>

export type TAssignmentsAction =
  | ISetAssignmentDetailAction
  | IChangeAssignmentLotCategoryPageAction
  | IRenameAssignmentLotAction
  | IRemoveAssignmentLotSupplierAction
  | ISetAgreementDetailAction
  | IUpdateAgreementDetailAction

const initialState: TViewDetail = {
  assignment: {
    id: '',
    name: '',
    agreements: [],
    data: {
      columns: [],
      data: [],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        total: 1,
      },
    },
    lots: [],
    status: '',
    categoryPath: '',
    attachments: [],
  },
  agreement: {
    id: '',
    supplier: {
      columns: [],
      data: [],
      pagination: {
        page: 0,
        rowsPerPage: 0,
        total: 0,
      },
    },
    category: [],
    agreement: {
      columns: [],
      data: {
        rowId: '',
        lot: '',
        tenderTask: '',
        provider: '',
        providerId: '',
        number: 0,
        expire: '',
        start: '',
        positions: '',
        projectsAndObjects: [],
        data: [],
      },
    },
  },
}

export default (
  state = initialState,
  action: TAssignmentsAction,
): TViewDetail => {
  switch (action.type) {
    case EActionTypes.SET_ASSIGNMENT_DETAIL: {
      const { agreements, lots } = action.payload
      const agreementsMap = agreements.reduce(
        (acc: { [x: string]: IAgreementRow[] }, ag) => ({
          ...acc,
          [ag.agreement.lot]: [
            ...(acc[ag.agreement.lot] || []),
            {
              ...ag.agreement,
              projectsAndObjects: ag.projectObjects,
            },
          ],
        }),
        {},
      )
      const newLots = lots.map(l => ({
        ...l,
        agreements: agreementsMap[l.id] || [],
      }))
      const assignment = {
        ...action.payload,
        lots: newLots,
      }
      return _.chain(state)
        .cloneDeep()
        .extend({ assignment })
        .value()
    }
    case EActionTypes.CHANGE_ASSIGNMENT_LOT_CATEGORY_PAGE: {
      const lots = _.cloneDeep(state.assignment.lots)

      const lot = lots.find(l => Number(l.id) === action.payload.lotId)

      if (lot) {
        lot.suppliers = action.payload.lotData.suppliers

        const position = lot.positions.find(
          p => Number(p.category.key) === action.payload.categoryId,
        )
        if (position) {
          position.data = action.payload.lotData.data
          position.pagination = action.payload.lotData.pagination
        }
      }

      return {
        ...state,
        assignment: {
          ...state.assignment,
          lots,
        },
      }
    }
    case EActionTypes.REMOVE_ASSIGNMENT_LOT_SUPPLIER: {
      const assignment = _.cloneDeep(state.assignment)

      const lot = assignment.lots.find(item => item.id === action.payload.key)
      if (lot) {
        lot.suppliers.data = lot.suppliers.data.filter(
          item => item.rowId !== action.payload.value,
        )

        lot.tenderConditions = lot.tenderConditions.map(tc => ({
          ...tc,
          values: tc.values.filter(
            value =>
              value.providerId !==
              ((action.payload.value as unknown) as number),
          ),
        }))
      }

      return {
        ...state,
        assignment,
      }
    }
    case EActionTypes.SET_AGREEMENT_DETAIL: {
      return _.chain(state)
        .cloneDeep()
        .extend({ agreement: action.payload })
        .value()
    }
    case EActionTypes.EDIT_AGREEMENT: {
      return _.chain(state)
        .cloneDeep()
        .extend({
          agreement: {
            ...state.agreement,
            agreement: {
              ...state.agreement.agreement,
              data: {
                ...state.agreement.agreement.data,
                expire: action.payload.expire,
                data: [
                  ...state.agreement.agreement.data.data.filter(
                    d => !action.payload.data.some(pd => pd.key === d.key),
                  ),
                  ...action.payload.data,
                ],
              },
            },
          },
        })
        .value()
    }
    default:
      return state
  }
}
