import _ from 'lodash'
import { useState } from 'react'

export interface AddToAssignmentInternalModel {
  assignmentId: string
  lotId: string
}

type StateModel = {
  [key in keyof AddToAssignmentInternalModel]: string
}

export const useModelState = (
  initState: StateModel,
): [typeof state, typeof updateState, typeof clearState] => {
  const [state, setState] = useState<StateModel>(initState)

  const has = <K extends string>(key: K, x: object): x is StateModel => key in x

  const updateState = (value: object): void => {
    if (
      typeof value !== 'object' ||
      Object.keys(value).some(k => !has(k, state))
    ) {
      throw new Error(`В объекте присутствуют неизвестные свойства: ${value}`)
    }

    setState({ ...state, ...value })
  }

  const clearState = (): void => {
    setState(_.mapValues(state, () => ''))
  }

  return [state, updateState, clearState]
}
