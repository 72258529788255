import { TShortAgreement } from '@grand-tender/types'
import { GridCellParams } from '@material-ui/x-grid'
import { DEFAULT_DISPLAY_DATE_FORMAT, EColumnType } from '../../constants'
import React, { SyntheticEvent, useCallback, useState } from 'react'
import useStyles from './style'
import {
  ClickAwayListener,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Link,
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import moment from 'moment'
import { renderCell } from '../../utils/renders'

type Props = {
  agreements: Array<TShortAgreement>
  params: GridCellParams
  type: EColumnType
  unit?: string
}

export const AssignmentAgreementsPopup: React.FC<Props> = ({
  agreements,
  params,
  type,
  unit,
}): React.ReactElement | null => {
  const [opened, setOpened] = useState(false)
  const handleOpen = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault()
      if (agreements.length) setOpened(true)
    },
    [agreements.length],
  )
  const handleClose = useCallback(() => {
    setOpened(false)
  }, [])
  const classes = useStyles()

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Tooltip
        arrow
        disableFocusListener
        disableTouchListener
        disableHoverListener
        open={opened}
        classes={{
          popper: classes.popper,
          tooltip: classes.tooltip,
        }}
        title={
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='left' classes={{ root: classes.tableCell }}>
                  №
                </TableCell>
                <TableCell align='left' classes={{ root: classes.tableCell }}>
                  Дата начала
                </TableCell>
                <TableCell align='left' classes={{ root: classes.tableCell }}>
                  Дата окончания
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agreements.map((data, index) => (
                <TableRow key={index}>
                  <TableCell classes={{ root: classes.tableCell }}>
                    <RouterLink to={`/agreements/0/${data.number}`}>
                      {data.number}
                    </RouterLink>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCell }}>
                    {moment(data.startDate).format(DEFAULT_DISPLAY_DATE_FORMAT)}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCell }}>
                    {moment(data.expirationDate).format(
                      DEFAULT_DISPLAY_DATE_FORMAT,
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        }
      >
        <Link href='#' onClick={handleOpen}>
          {renderCell(params, type, unit)}
        </Link>
      </Tooltip>
    </ClickAwayListener>
  )
}
