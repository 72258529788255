import { makeStyles, Theme } from '@material-ui/core/styles'

const useImprovedTableStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& .MuiTableCell-sizeSmall': {
      padding: '8px',
      '&:nth-child(even)': {
        background: '#fafafa',
      },
    },
    '& .MuiTableRow-root.error': {
      '& .MuiTableCell-sizeSmall:nth-child(even)': {
        background: '#f3dddd',
      },
    },
  },
  emptyBlock: {
    width: '100%',
    height: 'fill-avaible',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& .content': {
      height: '100%',
      width: '100%',
      border: '1px solid #E2E4E9',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .text': {
      fontSize: '14px',
      lineHeight: '17px',
      color: '#1B2B4B',
      opacity: '0.3',
    },
  },
  container: {
    overflowX: 'auto',
    '& .MuiTable-root': {
      tableLayout: 'fixed',
      overflow: 'hidden',
    },
    '& .MuiTableCell-root': {
      // border: '0',
      fontFamily: 'Proxima Nova Regular',
      color: '#535F77',
      fontSize: '14px',
    },
  },
}))

export default useImprovedTableStyles
