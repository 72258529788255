/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import {
  Dialog,
  FormControlLabel,
  TextField,
  Checkbox,
} from '@material-ui/core'
import Autocomplete, {
  AutocompleteChangeReason,
} from '@material-ui/lab/Autocomplete'
import { IKeyValuePair } from '@grand-tender/types'
import { EButtonType } from '../../constants'
import { ModalTitle } from '../BaseModal/ModalTitle/ModalTitle'
import { ModalContent } from '../BaseModal/ModalContent/ModalContent'
import { ModalActions } from '../BaseModal/ModalActions/ModalActions'
import { useStyles } from './Style'
import * as I from './IMovePositionsModal'
import ButtonComponent from '../Button/Button'
import { AutocompleteRenderInputParams } from '@material-ui/lab'

const MovePositionsModal: React.FC<I.OwnProps> = ({
  open,
  lots,
  onClose,
  onSave,
}): React.ReactElement => {
  const classes = useStyles()

  const [state, setState] = useState<string>('')
  const [removeFromCurrentLot, setRemoveFromCurrentLot] = useState<boolean>(
    false,
  )
  const [errors, setErrors] = useState<string>('')
  const [optionsListOpen, setOptionsListOpen] = React.useState(false)

  const handleSelectedValueChanged = (
    e: React.ChangeEvent<{}>,
    value: IKeyValuePair | null,
    reason: AutocompleteChangeReason,
  ): void => {
    if (value) {
      const error = validateForm(value.key)
      setErrors(error)
      setState(value.key)
    } else {
      setErrors('Обязательное поле')
      setState('')
    }
  }

  const validateForm = (value: string): string => {
    let error = ''
    if (!value) {
      error = 'Значение не выбрано'
    }

    return error
  }

  const saveBtnHandler = (): void => {
    const error = validateForm(state)

    if (error.length) {
      setErrors(error)
      return
    }

    onSave(state, removeFromCurrentLot)
  }

  const onDialogClose = (): void => {
    setErrors('')
    onClose()
  }

  return (
    <div>
      {open && (
        <Dialog
          onClose={onDialogClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          disableBackdropClick={true}
          className={classes.root}
        >
          <div className={classes.form}>
            <ModalTitle id='customized-dialog-title' onClose={onDialogClose}>
              Перемещение позиций
            </ModalTitle>
            <ModalContent dividers>
              <FormControlLabel
                className='fullWidth'
                control={
                  <Autocomplete
                    className={classes.autocomplete}
                    classes={{
                      popper: classes.autocompletePaper,
                    }}
                    style={{ width: '100%' }}
                    fullWidth
                    open={optionsListOpen}
                    onOpen={(): void => {
                      setOptionsListOpen(true)
                    }}
                    onClose={(): void => {
                      setOptionsListOpen(false)
                    }}
                    onChange={handleSelectedValueChanged}
                    noOptionsText={'Ничего не найдено'}
                    loadingText={'Загрузка данных'}
                    getOptionSelected={(
                      option: IKeyValuePair,
                      value: IKeyValuePair,
                    ): boolean => option.key === value.key}
                    getOptionLabel={(option: IKeyValuePair): string =>
                      option.value
                    }
                    options={lots}
                    renderInput={(
                      params: AutocompleteRenderInputParams,
                    ): React.ReactNode => (
                      <TextField
                        {...params}
                        variant='filled'
                        helperText={errors}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                }
                label='Переместить в лот:'
                labelPlacement='top'
              />
              <div style={{ display: 'flex', margin: '0 -10px' }}>
                <Checkbox
                  color={'primary'}
                  onChange={(): void => {
                    setRemoveFromCurrentLot(!removeFromCurrentLot)
                  }}
                />
                <span style={{ alignSelf: 'center' }}>
                  Удалить из текущего лота
                </span>
              </div>
            </ModalContent>
            <ModalActions>
              <div className={classes.buttonContainer}>
                <ButtonComponent
                  text='Отменить'
                  type={EButtonType.DEFAULT}
                  onClick={onDialogClose}
                />
                <ButtonComponent
                  text='Переместить'
                  type={EButtonType.PRIMARY}
                  onClick={saveBtnHandler}
                />
              </div>
            </ModalActions>
          </div>
        </Dialog>
      )}
    </div>
  )
}

export default MovePositionsModal
