import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { Divider, Typography } from '@material-ui/core'
import { map } from 'lodash'
import {
  formatValue,
  EColumnType,
  ButtonComponent,
  EButtonType,
} from '@grand-tender/ui'
import {
  IAssignmentLot,
  TAgreementCreateRequest,
  TNameCatalog,
  ELotStatus,
} from '@grand-tender/types'
import { Authorities } from '@grand-tender/auth-service'

import { TabPanel } from '../../../components/TabPanel/TabPanel'

import { NewTenderConditionDialog } from '../../../modules/tender-conditions/new-tender-condition-dialog'

import { LotModal } from '../../../components/lot-modal/lot-modal'
import { usePermissions } from '../../../hooks/usePermissions'

import { useAppSelector } from '../../../store'

import { PropsFromRedux } from './assignment-view-container'
import AssignmentLot from './assignment-lot/assignment-lot-pl'
import useStyles from './style'

const Assignment: React.FC<PropsFromRedux> = ({
  assignment,
  positions,
  addProperty,
  editProperty,
  removeProperty,
  exportTemplate,
  exportLot,
  removeLotPositions,
  removeLotSupplier,
  removeLot,
  combineLot,
  addLotToAssignment,
  changeLotAssignment,
  changeLotName,
  movePositions,
  getAssignmentDetail,
  addAssignmentLotSupplier,
  updateAssignmentLotSupplier,
  addAgreement,

  addPosition,
  getPositions,
  addToAssignment,
  changeStatusAssignment,
}): React.ReactElement | null => {
  const { assignmentId } = useParams<{ assignmentId: string }>()
  const checkPermissions = usePermissions()
  const { name: author } = useAppSelector(state => state.user)
  const {
    list: specialisations,
    nameList: specialisationsNameList,
  } = useAppSelector(state => state.specialisations)
  const history = useHistory()

  const [shouldGetData, setShouldGetData] = useState<boolean>(true)
  const [openRenameLotModal, setOpenRenameLotModal] = useState<boolean>(false)

  const [selectedLot, setSelectedLot] = useState<IAssignmentLot | undefined>(
    undefined,
  )

  const [selectedLotId, setSelectedLotId] = useState('')
  const [
    isCreateTenderConditionDialogOpen,
    setIsCreateTenderConditionDialogOpen,
  ] = React.useState(false)

  const toggleCreateDialogModal = (lotId: string) => (): void => {
    setIsCreateTenderConditionDialogOpen(!isCreateTenderConditionDialogOpen)
    setSelectedLotId(lotId)
  }

  const handleCloseCreateTenderCondition = (): void => {
    setIsCreateTenderConditionDialogOpen(false)
    setSelectedLotId('')
  }

  const handleSubmitCreateTenderCondition = (): void => {
    handleCloseCreateTenderCondition()
    getAssignmentDetail({
      assignmentId,
      callback: () => {
        setShouldGetData(false)
      },
    })
  }

  const [nameCatalog, setNameCatalog] = useState<TNameCatalog>({})

  useEffect(() => {
    const column = assignment.data.columns.find(
      item => item.type === EColumnType.LIST,
    )
    if (column) {
      setNameCatalog({
        [column.key]: specialisationsNameList,
      })
    }
  }, [specialisationsNameList, assignment.data.columns])

  const updateData = React.useCallback((): void => {
    getAssignmentDetail({
      assignmentId,
      callback: () => {
        setShouldGetData(false)
      },
    })
  }, [getAssignmentDetail, assignmentId])

  useEffect(() => {
    if (shouldGetData && !history.location.pathname.includes('search')) {
      updateData()
    }
    // eslint-disable-next-line
  }, [shouldGetData])

  const classes = useStyles()

  const addSupplierHandler = React.useCallback(
    (
      lotId: string,
      supplierId: string,
      price: File,
      callback: () => void,
    ): void => {
      const cb = (): void => {
        setShouldGetData(true)
        callback()
      }

      addAssignmentLotSupplier({ lotId, supplierId, file: price, callback: cb })
    },
    [addAssignmentLotSupplier],
  )

  const updatePriceHandler = React.useCallback(
    (
      lotId: string,
      supplierId: string,
      price: File,
      callback: () => void,
    ): void => {
      const cb = (): void => {
        callback()
        setShouldGetData(true)
      }

      updateAssignmentLotSupplier({
        lotId,
        supplierId,
        file: price,
        callback: cb,
      })
    },
    [updateAssignmentLotSupplier],
  )

  const addToAgreementHandler = React.useCallback(
    (agreement: TAgreementCreateRequest, cb: () => void): void => {
      agreement.dateEnd = `${agreement.dateEnd}T00:00`
      agreement.dateStart = `${agreement.dateStart}T00:00`
      const callback = (): void => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        getAssignmentDetail({ assignmentId: assignment.id, callback: () => {} })
      }
      addAgreement({ agreement, callback })
    },
    [addAgreement, assignment.id, getAssignmentDetail],
  )

  const removeLotPositionsHandler = React.useCallback(
    (
      lotId: string,
      positionsIds: Array<string>,
      callback: () => void,
    ): void => {
      const cb = (): void => {
        setShouldGetData(true)
        callback()
      }
      removeLotPositions({ lotId, positionIds: positionsIds, callback: cb })
    },
    [removeLotPositions],
  )

  const removeLotHandler = React.useCallback(
    (lotId: string): void => {
      removeLot({ lotId, callback: () => setShouldGetData(true) })
    },
    [removeLot],
  )

  const onSaveLot = React.useCallback(
    data => {
      setOpenRenameLotModal(false)
      const specializationIds =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data?.specialization && map(data.specialization, (spec: any) => spec.id)
      const lotData = !selectedLot
        ? () =>
            addLotToAssignment({
              idAddTo: Number(assignment.id),
              name: data.name,
              discription: data.description,
              specializationIds,
              callback: (): void => history.push(history.location.pathname),
            })
        : () =>
            changeLotName({
              lotId: selectedLot.id,
              name: data.name,
              description: data.description,
              status: ELotStatus[selectedLot.status],
              callback: (): void => history.push(history.location.pathname),
              specializationIds,
            })
      lotData()
    },
    [selectedLot, addLotToAssignment, assignment.id, history, changeLotName],
  )

  const moveLotPositionsHandler = React.useCallback(
    (
      fromLotId: string,
      toLotId: string,
      selectedRows: Array<string>,
      removeFromCurrentLot: boolean,
      callback: () => void,
    ): void => {
      const cb = (): void => {
        setShouldGetData(true)
        callback()
      }
      movePositions({
        fromLotId,
        toLotId,
        positionsIds: selectedRows,
        removeFromCurrentLot,
        callback: cb,
      })
    },
    [movePositions],
  )

  const changeLotAssignmentHandler = React.useCallback(
    (lotId: string, assignmentId: string, callback: () => void): void => {
      const cb = (): void => {
        setShouldGetData(true)
        callback()
      }
      changeLotAssignment({ lotId, assignmentId, callback: cb })
    },
    [changeLotAssignment],
  )

  const removeLotSupplierHandler = React.useCallback(
    (lotId: string, providerId: string, callback: () => void): void => {
      removeLotSupplier({ lotId, providerId, callback })
    },
    [removeLotSupplier],
  )

  const addPropertyHandler = React.useCallback(
    (lotId: string, name: string, callback: () => void): void => {
      const cb = (): void => {
        setShouldGetData(true)
        callback()
      }

      addProperty({ lotId, name, callback: cb })
    },
    [addProperty],
  )

  const editPropertyHandler = React.useCallback(
    (key: string, name: string, callback: () => void): void => {
      const cb = (): void => {
        setShouldGetData(true)
        callback()
      }

      editProperty({ key, name, callback: cb })
    },
    [editProperty],
  )

  const removePropertyHandler = React.useCallback(
    (key: string, cb: () => void): void => {
      const callback = (): void => {
        setShouldGetData(true)
        cb()
      }

      removeProperty({ key, callback })
    },
    [removeProperty],
  )

  const exportTemplateHandler = React.useCallback(
    ({
      lotId,
      categories,
    }: {
      lotId: string
      categories: {
        categoryId: string
        propositionCustomFieldIds: string[]
        columnIds: string[]
      }[]
    }): void => {
      exportTemplate({ lotId, categories })
    },
    [exportTemplate],
  )

  const addLotHandler = (): void => {
    setSelectedLot(undefined)
    setOpenRenameLotModal(true)
  }

  const assignmentsTab =
    checkPermissions(Authorities.ASSIGNMENTS_SHOW_TAB_ACTIVE) ||
    checkPermissions(Authorities.ASSIGNMENTS_SHOW_TAB_COMPLETED)

  if (!assignmentsTab) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className='flex-item tab-panel'>
        <TabPanel index={0}>
          {assignment && (
            <div className={classes.tenderInfo}>
              <Typography
                className={classes.tenderName}
                variant='subtitle1'
                component='div'
              >
                {assignment.name}
              </Typography>
              <div className='info-container'>
                {assignment.data.columns
                  .filter(c => !c.hidden)
                  .map(col => {
                    const cellData = assignment.data.data[0]?.data.find(
                      d => d.key === col.key,
                    )
                    let viewValue = ''
                    if (
                      cellData &&
                      col.type === EColumnType.LIST &&
                      nameCatalog &&
                      nameCatalog[col.key]
                    ) {
                      const arr: Array<string> = []
                      cellData.value.split('|').forEach((specId: string) => {
                        arr.push(nameCatalog[col.key][specId])
                      })

                      viewValue = arr.join('|')
                    }

                    return (
                      <div key={col.key} className='info-item'>
                        <span className='title'>{col.title}</span>
                        <span className='content'>
                          {formatValue(
                            col.type,
                            viewValue || cellData?.value,
                            '-',
                            col.unit,
                          )}
                        </span>
                      </div>
                    )
                  })}
              </div>
            </div>
          )}
          <Divider />
          {openRenameLotModal && (
            <LotModal
              lot={selectedLot}
              onClose={() => setOpenRenameLotModal(false)}
              onSave={onSaveLot}
              specialization={specialisations}
            />
          )}
          {assignment &&
            assignment.lots.map(lot => (
              <AssignmentLot
                key={lot.id}
                lot={lot}
                assignment={assignment}
                author={author}
                exportTemplateHandler={exportTemplateHandler}
                exportLotHandler={exportLot}
                addPropertyHandler={addPropertyHandler}
                editPropertyHandler={editPropertyHandler}
                removePropertyHandler={removePropertyHandler}
                addToAgreementHandler={addToAgreementHandler}
                removeLotSupplierHandler={removeLotSupplierHandler}
                removeLotPositionsHandler={removeLotPositionsHandler}
                removeLotHandler={removeLotHandler}
                moveLotPositionsHandler={moveLotPositionsHandler}
                changeLotAssignmentHandler={changeLotAssignmentHandler}
                addSupplierHandler={addSupplierHandler}
                updatePriceHandler={updatePriceHandler}
                openRenameLotModal={(
                  open: boolean,
                  assignmentLot: IAssignmentLot,
                ): void => {
                  setSelectedLot(assignmentLot)
                  setOpenRenameLotModal(open)
                }}
                positions={positions}
                addPosition={(position, files, callback) =>
                  addPosition({ position, files, callback })
                }
                getPositions={(
                  categoryId,
                  page,
                  perPage,
                  sortColumnKey,
                  sortColumnDirection,
                  callback,
                ) =>
                  getPositions({
                    categoryId,
                    page,
                    perPage,
                    sortColumnKey,
                    sortColumnDirection,
                    callback,
                  })
                }
                addToAssignment={(positionIds, lotId, callback) =>
                  addToAssignment({ positionIds, lotId, callback })
                }
                callbackAssignmentView={(): void => setShouldGetData(true)}
                toggleCreateDialogModal={toggleCreateDialogModal(lot.id)}
                getAssignmentDetail={(id: string) => {
                  getAssignmentDetail({
                    assignmentId: id,
                    callback: () => {
                      setShouldGetData(false)
                    },
                  })
                }}
              />
            ))}
          {assignment && assignment.lots.length === 0 && (
            <div className={classes.addlotBtn}>
              <ButtonComponent
                text={'Добавить лот'}
                type={EButtonType.DEFAULT}
                onClick={addLotHandler}
              />
            </div>
          )}
          {isCreateTenderConditionDialogOpen && (
            <NewTenderConditionDialog
              tenderAssignmentId={Number(assignmentId)}
              selectedLotId={parseInt(selectedLotId, 10)}
              open={isCreateTenderConditionDialogOpen}
              handleClose={handleCloseCreateTenderCondition}
              handleSubmit={handleSubmitCreateTenderCondition}
            />
          )}
        </TabPanel>
      </div>
    </div>
  )
}

export default Assignment
