import React from 'react'
import { Skeleton } from '@material-ui/lab'
import {
  CatalogButton,
  Divider,
  Gradient,
  HeadSettings,
  SkeletonHead,
  SkeletonRow,
} from './Skeleton.styled'

export const TableSkeletonHead = () => (
  <SkeletonHead>
    <Skeleton width={18} style={{ fontSize: '20.98px' }} variant='text' />
    <Skeleton width={30} variant='text' />
    <Skeleton width={95} variant='text' />
    <Skeleton width={188} variant='text' />
    <Skeleton width={118} variant='text' />
    <Skeleton width={120} variant='text' />
    <Skeleton width={140} variant='text' />
    <Skeleton width={80} variant='text' />
    <Skeleton width={98} variant='text' />
    <Skeleton width={118} variant='text' />
    <Skeleton width={129} variant='text' />
    <Skeleton width={129} variant='text' />
    <Skeleton width={80} variant='text' />
    <Skeleton width={80} variant='text' />
    <HeadSettings>
      <Gradient />
      <Divider />
      <CatalogButton style={{ marginBottom: '3.5px' }} />
    </HeadSettings>
  </SkeletonHead>
)

export const TableSkeletonRow = () => (
  <SkeletonRow>
    <Skeleton width={30} variant='text' />
    <Skeleton width={5} style={{ fontSize: '20.98px' }} variant='text' />
    <Skeleton width={84} variant='text' />
    <Skeleton width={187} variant='text' />
    <Skeleton width={118} variant='text' />
    <Skeleton width={120} variant='text' />
    <Skeleton width={140} variant='text' />
    <Skeleton width={80} variant='text' />
    <Skeleton width={98} variant='text' />
    <Skeleton width={118} variant='text' />
    <Skeleton width={129} variant='text' />
    <Skeleton width={129} variant='text' />
    <Skeleton width={80} variant='text' />
    <Skeleton width={80} variant='text' />
  </SkeletonRow>
)
