import React, { useState } from 'react'
import { ModalTitle } from '../BaseModal/ModalTitle/ModalTitle'
import { ModalContent } from '../BaseModal/ModalContent/ModalContent'
import { ModalActions } from '../BaseModal/ModalActions/ModalActions'
import ButtonComponent from '../Button/Button'
import { EButtonType } from '../../constants'
import { Dialog, FormControlLabel, RadioGroup, Radio } from '@material-ui/core'

import { useStyles } from './Styles'

type TPositionExportModal = {
  open: boolean
  onClose: () => void
  onSubmitExport?: (isExportOneFile: boolean) => void
  onSubmitExportPosition?: (isExportAllPosition: boolean) => void
  exportPageOrTabOption?: boolean
  exportBySelectedPositionOption?: boolean
}

export const PositionExportModal: React.FC<TPositionExportModal> = ({
  open,
  onClose,
  onSubmitExport,
  onSubmitExportPosition,
  exportPageOrTabOption,
  exportBySelectedPositionOption,
}) => {
  const styles = useStyles()
  const [isExportOneFile, setIsExportOneFile] = useState<boolean>(true)
  const [isExportAllPosition, setIsExportAllPosition] = useState<boolean>(false)

  const handleSwitchExportFileOption = () =>
    setIsExportOneFile(!isExportOneFile)

  const handleSwitchExportPosition = () => 
    setIsExportAllPosition(!isExportAllPosition)

  const handleSubmit = () => {
    if (exportPageOrTabOption) {
      onSubmitExport && onSubmitExport(isExportOneFile)
    } else {
      onSubmitExportPosition && onSubmitExportPosition(isExportAllPosition)
    }
  }

  return (
    <>
      {open && (
        <Dialog
          onClose={onClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          disableBackdropClick={true}
          classes={{ root: styles.root, paper: styles.paper }}
        >
          <ModalTitle id='position-export-dialog-title' onClose={onClose}>
            <div className={styles.title}>
              Выгрузить список позиций в файл (xlsx)
            </div>
          </ModalTitle>
          <ModalContent>
            <div>
            {exportPageOrTabOption && 
              <RadioGroup
                aria-label='exportType'
                defaultValue='singleFile'
                name='radio-buttons-group'
              >
                <FormControlLabel
                  value='singleFile'
                  control={<Radio color='primary' />}
                  label={<div className={styles.label}>Выгрузить всё на одном листе</div>}
                  onChange={handleSwitchExportFileOption}
                />
                <FormControlLabel
                  value='byTabs'
                  control={<Radio color='primary' />}
                  label={<div className={styles.label}>Разбить на вкладки</div>}
                  onChange={handleSwitchExportFileOption}
                />
              </RadioGroup>
              }
              {exportBySelectedPositionOption && 
              <RadioGroup
                aria-label='exportType'
                defaultValue='selectedPosition'
                name='radio-buttons-group'
              >
                {exportBySelectedPositionOption &&  
                <>
                <FormControlLabel
                  value='selectedPosition'
                  control={<Radio color='primary' />}
                  label={<div className={styles.label}>Выбранные позиции</div>}
                  onChange={handleSwitchExportPosition}
                />
                <FormControlLabel
                  value='allPosition'
                  control={<Radio color='primary' />}
                  label={<div className={styles.label}>Все позиции</div>}
                  onChange={handleSwitchExportPosition}
                />
                </>}
              </RadioGroup>
              }
            </div>
          </ModalContent>
          <ModalActions>
            <ButtonComponent
              text='Выгрузить'
              type={EButtonType.PRIMARY}
              onClick={handleSubmit}
            />
          </ModalActions>
        </Dialog>
      )}
    </>
  )
}
