import { SvgComponentType } from '@gmini/ui-kit'
import React from 'react'


export const CancelIcon = ({ color = '#353B60', ...other }: SvgComponentType) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <path
      d='M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM2 10C2 5.6 5.6 2 10 2C11.8 2 13.5 2.6 14.9 3.7L3.7 14.9C2.6 13.5 2 11.8 2 10ZM10 18C8.2 18 6.5 17.4 5.1 16.3L16.3 5.1C17.4 6.5 18 8.2 18 10C18 14.4 14.4 18 10 18Z'
      fill={color}
    />
  </svg>
)
