import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import {
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Popover,
} from '@material-ui/core'
import { IColumn, ITreeListItem, TTreeList } from '@grand-tender/types'
import {
  addDarkIcon,
  circleXIcon,
  DialogModal,
  EButtonType,
  EColumnType,
  EditablePropertyModal,
  EEditableModalType,
  LocalStorageHelper,
  pencilCreateIcon,
} from '@grand-tender/ui'

import usePositionCatalogStyles from '../../styles/Style'
import { useAppDispatch } from '../../../../store'
import {
  addPositionProperty,
  editPositionProperty,
  removePositionProperty,
} from '../../../../actions'
import {
  getColumns,
  setCurrentGroupId,
  resetColumns,
} from '../../store/catalog-slice'
import { getCurrentGroupId } from '../../store/catalog-selectors'

import { CatalogViewItem } from './components/catalog-view-item'
import { CatalogViewHeader } from './components/catalog-view-header'
import {
  DisplayColumnHeader,
  IconButtonStyled,
  ListItemIconStyled,
  ListItemTextStyled,
} from './display-column-styled'

export type TVisibleColumn = IColumn & { visible: boolean }

export type CatalogViewProps = {
  editable: boolean
  categoryId: number
  treeList: TTreeList
  handleOpenSettingCatalog: (isOpen: boolean, treeData: TTreeList) => void
  setIsCatalogExpanded: (val: boolean) => void
  onSelectNode: (node?: ITreeListItem) => void
  fetchPositions?: VoidFunction
  onUpdateGroupColumnsVisibility?: () => void
  tabIndex?: number
}

export const CatalogView: React.FC<CatalogViewProps> = ({
  editable,
  categoryId,
  treeList,
  handleOpenSettingCatalog,
  setIsCatalogExpanded,
  onSelectNode,
  fetchPositions,
  onUpdateGroupColumnsVisibility,
  tabIndex,
}) => {
  const classes = usePositionCatalogStyles()
  const dispatch = useAppDispatch()

  const currentGroupId = useSelector(getCurrentGroupId)
  /**
   * Обработчик для пунктов меню открытие/закрытие не в режиме просмотра
   */
  const [open, setOpen] = useState<{ [key: string]: boolean }>({})
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const emptyEditablePropertyModalModel: IColumn = {
    key: '',
    title: '',
    type: EColumnType.STRING,
    required: false,
    base: false,
    hidden: false,
    editable: true,
  }
  const initialEditablePropertyModal = {
    open: false,
    model: emptyEditablePropertyModalModel,
    type: EEditableModalType.INSERT,
  }
  const [
    configEditablePropertyModal,
    setConfigEditablePropertyModal,
  ] = useState(initialEditablePropertyModal)
  /**
   * Диалог подтверждения удаления
   */
  const [isOpenConfirmRemoveDialog, setIsOpenConfirmRemoveDialog] = useState<
    boolean
  >(false)
  const [currentColumn, setCurrentColumn] = useState<IColumn>({} as IColumn)
  const [visibleColumns, setVisibleColumns] = useState<TVisibleColumn[]>([])

  const handleTreeItemClick = (
    nodeId: string,
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    event.stopPropagation()
    const newState = _.cloneDeep(open)
    const value = newState[nodeId] || false

    newState[nodeId] = !value
    setOpen(newState)
  }

  const handleMenuOpen = (nodeId: number) => (
    e: React.MouseEvent<HTMLElement>,
  ): void => {
    setMenuAnchorEl(e.currentTarget)
    dispatch(getColumns(nodeId)).then(({ payload }) => {
      const savedColumns: string | null = LocalStorageHelper.get(
        `position_group_${nodeId}`,
      )
      if (!savedColumns) {
        const requiredColumn = ['цена', 'соглашение', 'поставщик']
        const initVisibleColumns = payload
          .filter((column: IColumn) => {
            if (
              window.location.pathname.indexOf('positions') !== -1 &&
              tabIndex === 0
            ) {
              return !requiredColumn.includes(column.title.toLowerCase())
            }

            return true
          })
          .map(
            (el: IColumn): TVisibleColumn => ({
              ...el,
              visible: true,
            }),
          )

        LocalStorageHelper.set(
          `position_group_${nodeId}`,
          JSON.stringify(initVisibleColumns),
        )
        setVisibleColumns(initVisibleColumns)
        return
      }

      const parsedVisibleColumns: TVisibleColumn[] = JSON.parse(savedColumns)
      setVisibleColumns(parsedVisibleColumns)
    })
    dispatch(setCurrentGroupId(nodeId))
  }

  const handleMenuClose = (): void => {
    setMenuAnchorEl(null)
    dispatch(resetColumns())
    dispatch(setCurrentGroupId(null))
  }

  const handleOpenCreatePropertyModal = (): void =>
    setConfigEditablePropertyModal({
      ...configEditablePropertyModal,
      open: true,
    })

  const handleCloseCreatePropertyModal = (): void =>
    setConfigEditablePropertyModal(initialEditablePropertyModal)

  const handleSavePropertyModal = (model: IColumn): void => {
    if (currentGroupId === null) {
      throw Error('Нет id группы')
    }
    const savedColumns = LocalStorageHelper.get(
      `position_group_${currentGroupId}`,
    ) as string
    const parsedSavedColumns = JSON.parse(savedColumns)

    if (configEditablePropertyModal.type === EEditableModalType.INSERT) {
      dispatch(
        addPositionProperty({
          categoryId: currentGroupId,
          model,
          callback: () => {
            setConfigEditablePropertyModal(initialEditablePropertyModal)
            dispatch(getColumns(currentGroupId)).then(({ payload }) => {
              const newItem = payload.find(
                (el: IColumn) => el.title === model.title,
              )
              const newColumnsState: TVisibleColumn[] = [
                ...parsedSavedColumns,
                { ...newItem, visible: true },
              ]
              LocalStorageHelper.set(
                `position_group_${currentGroupId}`,
                JSON.stringify(newColumnsState),
              )
              setVisibleColumns(newColumnsState)
            })
            fetchPositions && fetchPositions()
          },
        }),
      )
    } else {
      dispatch(
        editPositionProperty({
          model,
          callback: () => {
            setConfigEditablePropertyModal(initialEditablePropertyModal)
            dispatch(getColumns(currentGroupId)).then(() => {
              const newColumnsState = parsedSavedColumns.map(
                (el: TVisibleColumn) => {
                  if (el.key === model.key) {
                    return { ...el, ...model }
                  }

                  return el
                },
              )
              LocalStorageHelper.set(
                `position_group_${currentGroupId}`,
                JSON.stringify(newColumnsState),
              )
              setVisibleColumns(newColumnsState)
            })
            fetchPositions && fetchPositions()
          },
        }),
      )
    }
  }

  /**
   * EditablePropertyModal start
   */
  const editablePropertyModalTitle = {
    insert: 'Добавить свойство',
    edit: 'Редактировать свойство',
  }

  /**
   * EditablePropertyModal end
   */

  const handleRemoveItemIcon = (column: IColumn) => (
    e: React.MouseEvent<HTMLElement>,
  ): void => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpenConfirmRemoveDialog(true)
    setCurrentColumn(column)
  }

  const handleEditIcon = (column: IColumn) => (
    e: React.MouseEvent<HTMLElement>,
  ): void => {
    e.preventDefault()
    e.stopPropagation()
    setConfigEditablePropertyModal({
      open: true,
      type: EEditableModalType.EDIT,
      model: column,
    })
  }

  const handleDiscardChanges = (): void => {
    setIsOpenConfirmRemoveDialog(false)
    setCurrentColumn({} as IColumn)
  }

  const handleChanges = (): void => {
    const savedColumns: string = LocalStorageHelper.get(
      `position_group_${currentGroupId}`,
    ) as string
    const parsedSavedColumns: TVisibleColumn[] = JSON.parse(savedColumns)
    dispatch(
      removePositionProperty({
        key: currentColumn.key,
        callback: () => {
          handleDiscardChanges()
          if (currentGroupId !== null) {
            dispatch(getColumns(currentGroupId)).then(() => {
              const newState = parsedSavedColumns.filter(
                (el: TVisibleColumn) => el.key !== currentColumn.key,
              )
              LocalStorageHelper.set(
                `position_group_${currentGroupId}`,
                JSON.stringify(newState),
              )
              setVisibleColumns(newState)
              fetchPositions && fetchPositions()
            })
          }
        },
      }),
    )
  }

  const handleToggleVisibleColumn = (column: TVisibleColumn) => (): void => {
    const newState = visibleColumns.map((el: TVisibleColumn) => {
      if (el.key === column.key) {
        return { ...el, visible: !column.visible }
      }

      return el
    })

    setVisibleColumns(newState)
    LocalStorageHelper.set(
      `position_group_${currentGroupId}`,
      JSON.stringify(newState),
    )
    onUpdateGroupColumnsVisibility && onUpdateGroupColumnsVisibility()
  }

  return (
    <>
      <List
        component='nav'
        aria-labelledby='nested-list-subheader'
        subheader={
          <CatalogViewHeader
            editable={editable}
            categoryId={categoryId}
            treeList={treeList}
            setIsCatalogExpanded={setIsCatalogExpanded}
            handleOpenSettingCatalog={handleOpenSettingCatalog}
          />
        }
        className={classes.root}
      >
        <Divider className={classes.dividedHeight} />
        <div className={classes.catalog}>
          {treeList.map(node => (
            <CatalogViewItem
              key={node.id}
              open={open}
              categoryId={categoryId}
              node={node}
              handleTreeItemClick={handleTreeItemClick}
              onSelectNode={onSelectNode}
              handleMenuOpen={handleMenuOpen}
              level={0}
            />
          ))}
        </div>
      </List>
      {Boolean(menuAnchorEl) && (
        <Popover
          open={Boolean(menuAnchorEl)}
          anchorEl={menuAnchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <List
            subheader={
              <DisplayColumnHeader>
                Отображаемые колонки
                <IconButton onClick={handleOpenCreatePropertyModal}>
                  {addDarkIcon}
                </IconButton>
              </DisplayColumnHeader>
            }
          >
            {visibleColumns.map((el: TVisibleColumn) => (
              <ListItem key={el.key}>
                <ListItemIconStyled>
                  <Checkbox
                    edge='start'
                    color='primary'
                    checked={
                      el.title === 'Автор' || el.title === 'Дата создания'
                        ? el.visible
                        : el.required || el.visible
                    }
                    disableRipple
                    disabled={
                      el.title === 'Автор' || el.title === 'Дата создания'
                        ? false
                        : el.required
                    }
                    onChange={handleToggleVisibleColumn(el)}
                  />
                </ListItemIconStyled>
                <ListItemTextStyled>{el.title}</ListItemTextStyled>
                {!el.required && (
                  <ListItemIcon>
                    <IconButtonStyled onClick={handleEditIcon(el)}>
                      {pencilCreateIcon}
                    </IconButtonStyled>
                    <IconButtonStyled onClick={handleRemoveItemIcon(el)}>
                      {circleXIcon}
                    </IconButtonStyled>
                  </ListItemIcon>
                )}
              </ListItem>
            ))}
          </List>
        </Popover>
      )}
      {configEditablePropertyModal.open && (
        <EditablePropertyModal
          useSections={false}
          title={editablePropertyModalTitle}
          open={configEditablePropertyModal.open}
          type={configEditablePropertyModal.type}
          model={configEditablePropertyModal.model}
          onSave={handleSavePropertyModal}
          onClose={handleCloseCreatePropertyModal}
        />
      )}
      {isOpenConfirmRemoveDialog && (
        <DialogModal
          open={isOpenConfirmRemoveDialog}
          modalTitle={'Удалить'}
          modalContent={'Вы действительно хотите удалить свойство?'}
          modalButtonLeftText={'Отменить'}
          modalButtonRightText={'Удалить'}
          modalButtonLeftType={EButtonType.DEFAULT}
          modalButtonRightType={EButtonType.WARNING}
          handleDiscardChanges={handleDiscardChanges}
          handleChanges={handleChanges}
        />
      )}
    </>
  )
}
