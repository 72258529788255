import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  Typography,
  DialogTitle as MuiDialogTitle,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ButtonComponent from '../Button/Button'
import { useStyles } from './Style'
import * as I from './IDialogModal'

const DialogModal: React.FC<I.OwnProps> = ({
  open,
  modalTitle,
  modalContent,
  modalButtonRightType,
  modalButtonRightText,
  modalButtonLeftType,
  modalButtonLeftText,
  handleDiscardChanges,
  handleChanges,
  handleClose = handleDiscardChanges,
}): React.ReactElement => {
  const classes = useStyles()

  return (
    <>
      {open && (
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            className={classes.root}
          >
            <MuiDialogTitle className={classes.dialogTitle} disableTypography>
              <Typography variant='h6'>{modalTitle}</Typography>
              <IconButton aria-label='close' onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </MuiDialogTitle>
            <Divider />
            <DialogContent className={classes.dialogContent}>
              <DialogContentText id='alert-dialog-description'>
                {modalContent}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {modalButtonLeftType && modalButtonLeftText && (
                <ButtonComponent
                  text={modalButtonLeftText}
                  type={modalButtonLeftType}
                  onClick={handleDiscardChanges}
                />
              )}
              {modalButtonRightText && modalButtonRightType && (
                <ButtonComponent
                  text={modalButtonRightText}
                  type={modalButtonRightType}
                  onClick={handleChanges}
                />
              )}
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  )
}

export default DialogModal
