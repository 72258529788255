import React, { Key, useCallback, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import { IconButton, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  Attachments,
  TSetSendFileRequest,
  UploadDocumentParams,
} from '@grand-tender/types'
import { ADD_DOCUMENT_FORM_ID, COLUMNS } from './constants'
import { DocumentAddForm } from './DocumentsAddForm'
import { documentsMapper } from './utils'
import { Accordion } from '../Accordion/Accordion'
import { DumbTable } from '../DumbTable/DumbTable'
import { DumbModal } from '../DumbModal/DumbModal'
import Button from '../Button/Button'
import { Loader } from '../Loader/Loader'
import { EButtonType } from '../..'

import { useStyles } from './Style'

export type AddDocumentData = {
  type?: string
  file?: File
  sendFile?: boolean
}

type DocumentsFieldProps = {
  services: {
    add: (params: UploadDocumentParams) => Promise<unknown>
    delete?: (id: Key) => Promise<unknown>
    setSendFile?: (params: TSetSendFileRequest) => Promise<unknown>
  }
  afterFetch?: () => void
  id: string
  documents: Attachments[]
  showCheckBox?: boolean
  checkBoxTitle?: string
}

export const DocumentsField: React.FC<DocumentsFieldProps> = ({
  id,
  documents,
  services,
  showCheckBox,
  checkBoxTitle,
  afterFetch,
}) => {
  const classes = useStyles()
  const [docs, setDocs] = useState(documents)
  const [isLoading, setIsLoading] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const closeEditModal = () => setIsEditModalOpen(false)
  const openEditModal = (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsEditModalOpen(true)
  }

  const handleAddDocument = async (params: AddDocumentData) => {
    setIsLoading(true)

    const reqData = { id, ...params } as UploadDocumentParams

    try {
      const res = (await services.add(reqData)) as Attachments
      setDocs(prevDocs => [...prevDocs, res])
    } catch (error) {
      toastr.error('', 'При добавлении документа произошла ошибка!', {
        progressBar: false,
        showCloseButton: false,
      })
    } finally {
      setIsLoading(false)
      setIsEditModalOpen(false)

      if (afterFetch) {
        afterFetch()
      }
    }
  }

  const handleDeleteDocument = async (id: string | number, fileId: string | number) => {
    setIsLoading(true)

    try {
      if (services.delete) {
        await services.delete(fileId)
        setDocs(docs.filter(item => item.id !== fileId))
      }
    } catch (error) {
      toastr.error('', 'При удалении документа произошла ошибка!', {
        progressBar: false,
        showCloseButton: false,
      })
    } finally {
      setIsLoading(false)
      setIsEditModalOpen(false)

      if (afterFetch) {
        afterFetch()
      }
    }
  }

  const handleCheckBoxChange = async (docId: Key) => {
    const currentValue =
      documents.find(doc => doc.id === docId)?.sendFile ?? false

      setIsLoading(true)

      const reqData = { id: docId, value: !currentValue }

      try {
        if(services.setSendFile) {
          await services.setSendFile(reqData)
        }
      } catch (error) {
        toastr.error('', 'Произошла ошибка', {
          progressBar: false,
          showCloseButton: false,
        })
      } finally {
        setIsLoading(false)
        setIsEditModalOpen(false)

        if (afterFetch) {
          afterFetch()
        }
      }
  }

  return (
    <>
      <Accordion
        title='Документы'
        actions={
          <Tooltip title='Добавить документ'>
            <IconButton
              className={classes.actionButton}
              onClick={openEditModal}
            >
              <ControlPointIcon />
            </IconButton>
          </Tooltip>
        }
      >
        <DumbTable
          columns={COLUMNS}
          rows={documentsMapper(docs)}
          actions={({ id: rowId }) => (
            <IconButton
              className={classes.actionButton}
              onClick={() => {
                toastr.confirm('Вы действительно хотите удалить этот файл?', {
                  onOk: () => handleDeleteDocument(id, rowId),
                })
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
          showCheckBox={showCheckBox}
          checkBoxTitle={checkBoxTitle}
          onChangeCheckBox={handleCheckBoxChange}
        />
      </Accordion>
      <DumbModal
        title='Добавить документ'
        visible={isEditModalOpen}
        onClose={closeEditModal}
        actions={
          <>
            <Button
              type={EButtonType.DEFAULT}
              text='Отменить'
              onClick={closeEditModal}
            />
            <Button
              type={EButtonType.PRIMARY}
              text='Сохранить'
              htmlType='submit'
              formId={ADD_DOCUMENT_FORM_ID}
              disabled={isLoading}
            />
          </>
        }
      >
        <div className={classes.contentContainer}>
          <Loader visible={isLoading} />
          <DocumentAddForm
            onSubmit={params => handleAddDocument(params)}
            showCheckBox={showCheckBox}
          />
        </div>
      </DumbModal>
    </>
  )
}
