import React from 'react'
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { OwnProps } from './IListEditorItem'
import { useStyles } from './Style'

export const ListEditorItem: React.FC<OwnProps> = ({ value, onDelete }) => {
  const classes = useStyles()

  return (
    <ListItem className={classes.root}>
      <ListItemText primary={value} />
      <ListItemSecondaryAction>
        <IconButton edge='end' aria-label='delete' onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
