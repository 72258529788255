import React, { useEffect, useState } from 'react'
import { ModalContent, modalService } from './ModalService'

const Modal: React.FC = () => {
  const [content, setContent] = useState<ModalContent>(null)

  useEffect(() => {
    modalService.subscribe(setContent)

    return modalService.unsubscribe
  }, [])

  return content
}

export default Modal
