import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  popper: {
    pointerEvents: 'auto',
  },
  tooltip: {
    backgroundColor: 'white',
    border: '1px solid gray',
  },
  tableCell: {
    padding: '7px'
  }
}))

export default useStyles
