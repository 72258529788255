/* eslint-disable require-unicode-regexp */
import { ITreeListItem, TTreeList } from '@grand-tender/types'

const findTreeElement = (
  node: ITreeListItem,
  key: string,
): ITreeListItem | null => {
  if (node.id.toString() === key.toString()) {
    return node
  }
  let result: ITreeListItem | null = null
  for (let i = 0; result === null && i < node.content.length; i++) {
    result = findTreeElement(node.content[i], key)
  }
  return result
}

export const findTreeNode = (
  treeData: TTreeList,
  key: string,
): ITreeListItem | null => {
  let result: ITreeListItem | null = null
  for (let i = 0; result === null && i < treeData.length; i++) {
    result = findTreeElement(treeData[i], key)
    if (result) {
      return result
    }
  }

  return result
}

export const treeify = (list: TTreeList): TTreeList => {
  const treeList: TTreeList = []
  const lookup: { [key: string]: ITreeListItem } = {}

  list.forEach((node: ITreeListItem) => {
    lookup[node.id] = node

    node.content = []
  })
  list.forEach((node: ITreeListItem) => {
    if (node.parentId != null) {
      // lookup[node.parentId].count = (lookup[node.parentId].count || 0) + 1;
      lookup[node.parentId].content.push(node)
    } else {
      treeList.push(node)
    }
  })
  return treeList
}
