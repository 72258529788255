/* eslint-disable import/no-anonymous-default-export */
import { TAvailableColumn, TFilterFieldsModel } from '@grand-tender/types'
import { EActionTypes } from '@grand-tender/ui'

import { IBaseActionCreator } from '../../actions/BaseAction'

export type IFilterFields = IBaseActionCreator<
  EActionTypes.SET_FILTER_FIELDS,
  TFilterFieldsModel
>

export type ISetAvailableColumnsAction = IBaseActionCreator<
  EActionTypes.SET_AVAILABLE_COLUMNS,
  Array<TAvailableColumn>
>

export type TSearchAction = IFilterFields | ISetAvailableColumnsAction

const initialState: TFilterFieldsModel = {
  items: [],
  provider: [],
  agreement: [],
  tender: [],
  availableColumns: [],
}

export default (
  state = initialState,
  action: TSearchAction,
): TFilterFieldsModel => {
  switch (action.type) {
    case EActionTypes.SET_FILTER_FIELDS: {
      return {
        ...action.payload,
        availableColumns: state.availableColumns,
      }
    }
    case EActionTypes.SET_AVAILABLE_COLUMNS: {
      return {
        ...state,
        availableColumns: action.payload,
      }
    }
    default:
      return state
  }
}
