import React, { useState } from 'react'
import * as Sentry from '@sentry/react'
import { toastr } from 'react-redux-toastr'
import { EAPIResponseStatus } from '../../../constants'
import { AssignmentService } from '@grand-tender/assignment-service'
import { IAPIResult, TRowData } from '@grand-tender/types'
import MessageWrapper from '../../MessageWrapper/MessageWrapper'
import _ from 'lodash'

export const useAssignmentLotList = (
  setOptionsListLoading: (isLoading: boolean) => void,
): [typeof state, typeof updateState, typeof clearState] => {
  const [state, setState] = useState<TRowData>([])

  const has = <T, K extends string>(key: K, x: T): x is T => key in x

  const updateState = async (assignmentId: string): Promise<void> => {
    if (!assignmentId) {
      throw new Error(
        `Получено неизвестное значение для тендерного задания: ${assignmentId}`,
      )
    }

    setOptionsListLoading(true)

    //TODO handle error in middleware
    const [, result] = await AssignmentService.getAssignmentLots(assignmentId)

    const returnValue: IAPIResult<TRowData> = {
      status: result.data.status,
      message: result.data.message,
    }

    if (result.data) {
      returnValue.data = _.map(result.data.data, (value, key, i) => ({
        key,
        value,
      })).map((item, index) => ({
        ...item,
        value: `Лот ${index + 1}: ${item.value}`,
      }))
    }


    if (returnValue.status === EAPIResponseStatus.SUCCESS) {
      const value = returnValue.data
      if (
        typeof value !== 'object' ||
        !Array.isArray(value) ||
        value.some(item =>
          Object.keys(item).some(k => !has(k, { key: '', value: '' })),
        )
      ) {
        throw new Error(`Получен неверный аргумент: ${value}`)
      }
      setOptionsListLoading(false)
      setState(value)
    } else {
      const err =
        result.data.status === EAPIResponseStatus.FAIL
          ? result.data.data
          : result.data.message

      const msg = <div>{err}</div>

      toastr.error('', '', {
        component: <MessageWrapper>{msg}</MessageWrapper>,
        progressBar: false,
        showCloseButton: false,
      })
      toastr.error('', 'При получении списка лотов произошла ошибка!')
      Sentry.captureException(err)
      setOptionsListLoading(false)

      setState([])
    }
  }

  const clearState = (): void => {
    setState([])
  }

  return [state, updateState, clearState]
}
