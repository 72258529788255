/* eslint-disable import/no-anonymous-default-export */
import { TSearchModel, TSearchResponse } from '@grand-tender/types'
import { EActionTypes } from '@grand-tender/ui'

import { IBaseActionCreator } from '../../actions/BaseAction'

export type IFilterFields = IBaseActionCreator<
  EActionTypes.SEARCH_OTHER_PAGE,
  TSearchModel
>

export type IResetSearchOtherPage = IBaseActionCreator<
  EActionTypes.RESET_SEARCH_OTHER_PAGE,
  string
>

export type TSearchAction = IFilterFields

const initialState: TSearchModel = {
  responseParams: {
    categoriesData: [],
    categoryId: '',
    positionPage: {
      columns: [],
      data: [],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        total: 0,
      },
    },
  },
  requestParams: {
    keyword: null,
    table: 'providers',
    fetchCategories: true,
    pageData: {
      page: 1,
      perPage: 10,
      categoryId: '',
    },
    agreementConditions: [],
    itemConditions: [],
    tenderTaskConditions: [],
    providerConditions: [],
  },
}

export default (state = initialState, action: TSearchAction): TSearchModel => {
  switch (action.type) {
    case EActionTypes.SEARCH_OTHER_PAGE: {
      const {
        payload: { requestParams, responseParams, isSearchRequest },
      } = action
      const { responseParams: prevResponse } = state

      // При использовании фильтрации в xGrid, если ничего не находится в текущей рубрике,
      // в ответе могут приходить не все данные
      const isFilterRequest = Boolean(
        (requestParams.itemConditions?.length ||
          requestParams.agreementConditions?.length) &&
          !isSearchRequest,
      )
      const newResponseParams: TSearchResponse = {
        categoriesData: isFilterRequest
          ? prevResponse.categoriesData
          : responseParams.categoriesData,
        categoryId: isFilterRequest
          ? prevResponse.categoryId
          : responseParams.categoryId,
        positionPage: {
          categoryPath: isFilterRequest
            ? prevResponse.positionPage.categoryPath
            : responseParams.positionPage.categoryPath,
          categoryName: isFilterRequest
            ? prevResponse.positionPage.categoryName
            : responseParams.positionPage.categoryName,
          columns: isFilterRequest
            ? prevResponse.positionPage.columns
            : responseParams.positionPage.columns,
          data: responseParams.positionPage.data,
          pagination: responseParams.positionPage.pagination,
        },
      }

      return {
        requestParams,
        responseParams: newResponseParams,
      }
    }
    default:
      return state
  }
}
