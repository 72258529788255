import { CompleteIcon, LongText, Select } from '@gmini/ui-kit'
import React from 'react'

import { Winner } from './LotWinnerModal'
import {
  List,
  WinnerPositionText,
  ListItem,
  PositionInfoText,
  IconWrap,
  SelectContainer,
} from './LotWinnerSelect.styled'

interface LotWinnerSelectProps {
  options: Omit<Winner, 'position'>[]
  position: string
  onSelect: ({ winner, position }: { winner: Winner; position: string }) => void
  selectedValue?: Winner[]
  getOptionLabel: (winner: Winner, position: string) => string
  getPositionInfo: (winnerId: string, position: string) => string
  isSelected: (winnerId: string, position: string) => boolean
  disabled?: boolean
  error?: React.ReactNode
}

export const LotWinnerSelect = ({
  options,
  position,
  onSelect,
  selectedValue,
  getOptionLabel,
  getPositionInfo,
  disabled,
  isSelected,
  error,
}: LotWinnerSelectProps) => (
  <SelectContainer>
    <WinnerPositionText
      selected={!!selectedValue}
    >{`${position}-е место`}</WinnerPositionText>
    <Select
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onInputChange={val => {}}
      getOptionLabel={option => getOptionLabel(option, position)}
      allowDelete={false}
      renderSelectValue={({ option }) =>
        option ? (
          <LongText withoutRightText partSize={30}>
            {getOptionLabel(option, position)}
          </LongText>
        ) : null
      }
      options={options}
      disabled={disabled}
      emptyOptionListMessage='Нет доступных совпадений'
      placeholder='Выберите победителя'
      // error={true}
      renderOption={({ option, onClose, active, hover }) => (
        <List
          onClick={() => {
            onSelect({ winner: option, position })
            onClose()
          }}
          active={active}
          hover={hover}
        >
          <ListItem>
            <LongText partSize={30}>{`${option.name} ` || ''}</LongText>
          </ListItem>

          <IconWrap>
            <PositionInfoText>
              {getPositionInfo(option.rowId, position)}
            </PositionInfoText>
            {isSelected(option.rowId, position) && <CompleteIcon />}
          </IconWrap>
        </List>
      )}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onChange={() => {}}
      value={selectedValue?.[0] || null}
    />
    {error}
  </SelectContainer>
)
