import React from 'react'

// TODO: Make spinner more beautiful =)
import { apiClient } from '@grand-tender/base-api-service/src'
import { AuthProvider } from '@gmini/auth'
import { Provider } from 'react-redux'
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'
import ReduxToastr from 'react-redux-toastr'
import { AuthClientTokens } from '@react-keycloak/core'

import * as Sentry from '@sentry/react'
import Modal from '@grand-tender/ui/src/components/Modal/Modal'

import { App } from '../../components/App/App'

import Loader from '../../components/Loader/Loader'
import { SpinnedBackdrop } from '../../components/SpinnedBackdrop'
import store from '../../store'

import { authClient } from '../../keycloak'

const tokenLogger = (tokens: AuthClientTokens): void => {
  apiClient.setToken(tokens.token || '')
}

// const keycloakOptions = {
//   onLoad: 'login-required',
// }

// TODO mover auth component to common library
export const Auth = (): JSX.Element => (
  <AuthProvider
    authClient={authClient}
    onTokens={tokenLogger}
    // initOptions={keycloakOptions}
  >
    {({ initialized, authClient }) => {
      if (!initialized) {
        return <SpinnedBackdrop />
      }

      if (initialized && authClient.authenticated) {
        authClient.updateToken(5)

        apiClient.setToken(authClient.token)
      }

      return (
        <Provider store={store}>
          <>
            <Loader />
            <Router>
              <Sentry.ErrorBoundary fallback={'An error has occur'}>
                <Switch>
                  <Route
                    path={[
                      '/positions/:tab/search',
                      '/positions/:tab',
                      '/suppliers',
                      '/agreements/:tab/search',
                      '/agreements/:tab/:agreementId',
                      '/agreements/:tab',
                      '/assignments/:tab/search',
                      '/assignments/:tab/:assignmentId',
                      '/assignments/:tab',
                    ]}
                    render={() => <App />}
                  />
                  <Redirect to='/positions/0' />
                </Switch>
              </Sentry.ErrorBoundary>
            </Router>
            <ReduxToastr
              timeOut={5000}
              newestOnTop={true}
              preventDuplicates={false}
              position='top-center'
              transitionIn='fadeIn'
              transitionOut='fadeOut'
              progressBar={false}
              confirmOptions={{
                cancelText: 'Отменить',
                okText: 'Подтвердить',
              }}
              closeOnToastrClick
            />
            <Modal />
          </>
        </Provider>
      )
    }}
  </AuthProvider>
)
