import React from 'react'
import { Spinner } from '@grand-tender/ui'

import useStyles from './Style'
import * as I from './ILoader'

export const LoaderPL: React.FC<I.StateProps> = ({
  dataIsLoading,
}): React.ReactElement | null => {
  const classes = useStyles()

  return dataIsLoading ? (
    <div className={classes.loaderBackground}>
      <Spinner />
    </div>
  ) : null
}
