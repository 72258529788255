import { EActionTypes } from '@grand-tender/ui'

export interface IBaseActionCreator<T, P> {
  type: T
  payload: P
}

export interface IActionCreator<P> extends IBaseActionCreator<EActionTypes, P> {
  type: EActionTypes
  payload: P
}

export function actionCreator<T>(
  actionType: EActionTypes,
  data: T,
): IActionCreator<T> {
  return {
    type: actionType,
    payload: data,
  }
}

export const storage = (): Storage =>
  localStorage.getItem('rm') === '1' ? localStorage : sessionStorage
