import styled from 'styled-components'

export const PositionNumber = styled.div`
  position: absolute;
  top: 11px;
  left: 3px;
  font-family: Roboto;
  font-size: 38px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.15000000596046448px;
  text-align: right;
  color: rgba(26, 26, 26, 0.1);
`

type PositionBackgroundProps = {
  background?: string
  fullWidth?: boolean
}
export const PositionBackground = styled.div`
  position: absolute;
  width: ${({ fullWidth }: PositionBackgroundProps) =>
    fullWidth ? '100%' : 'calc(100% - 1px)'};
  height: 100%;
  left: 0;
  background: ${({ background }: PositionBackgroundProps) =>
    background && `${background} `};
`
export const AgreementTooltip = styled.div`
  padding: 8px 12px 12px 12px;
  width: 350px;
`
export const AgreementTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  span {
    font-weight: 700;
  }
`
export const AgreementDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 12px;
  span {
    font-size: 10px;
    line-height: 12px;
    opacity: 0.7;
  }
`
export const AgreementProject = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
  span {
    font-weight: 400;
    opacity: 0.8;
  }
`
