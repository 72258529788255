import React, { useEffect } from 'react'
import { Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { FilterOptionsState } from '@material-ui/lab'
import { IKeyValuePair } from '@grand-tender/types'
import AddKeyValuePairModal from '../../../../../AddKeyValuePairModal/AddKeyValuePairModal'
import { SearchInput } from '../../../../../SearchInput/SearchInput'
import DialogModal from '../../../../../DialogModal/DialogModal'
import * as I from './ISections'
import { useSectionsListHooks } from './hooks/useSectionListHooks'
import {
  EButtonType,
  EEditableModalType,
  EValidationMessage,
} from '../../../../../../constants'

interface OptionType extends IKeyValuePair {
  inputValue?: string
}

export const SectionsList: React.FC<I.OwnProps> = ({
  disabled = false,
  initValue,
  helperText,
  onAddSection,
  onEditSection,
  onRemoveSection,
  getSectionsDataSource,
  updateModel,
  updateError,
  toggleParentCtrl,
}): React.ReactElement => {
  const [
    useSectionsList,
    useDropDownSearch,
    useBooleanState,
    useModalValidation,
  ] = useSectionsListHooks()

  const [optionsListLoading, setOptionsListLoading] = useBooleanState()
  const [optionsListOpen, setOptionsListOpen] = useBooleanState()
  const [sectionsList, setSectionsList] = useSectionsList(
    getSectionsDataSource,
    setOptionsListLoading,
  )

  const [openAddSectionModal, setOpenAddSectionModal] = useBooleanState()
  const [addSectionModalSettings, setAddSectionModalSettings] = React.useState({
    type: EEditableModalType.INSERT,
    title: 'Создать раздел',
    label: 'Название',
    saveBtnText: 'Добавить',
    cancelBtnText: 'Отменить',
  })
  const [dialogValue, setDialogValue] = React.useState({
    key: '',
    value: '',
  })

  const [openRemoveSectionModal, setOpenRemoveSectionModal] = useBooleanState()

  const [onSearch$] = useDropDownSearch()

  const modalValidation = useModalValidation()

  useEffect(() => {
    const subscriptions: Array<Subscription> = new Array<Subscription>()

    subscriptions.push(
      onSearch$.pipe(debounceTime(400)).subscribe(setSectionsList),
    )

    return (): void => {
      if (subscriptions.length) {
        subscriptions.forEach(s => s.unsubscribe())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSearch$])

  const toggleOptionsList = React.useCallback(
    (isOpen: boolean): void => {
      if (isOpen) {
        setOptionsListOpen(true)
      } else {
        setOptionsListOpen(false)
      }
    },
    [setOptionsListOpen],
  )

  const onSearchInputChange = React.useCallback(
    (value: string): void => {
      onSearch$.next(value)
    },
    [onSearch$],
  )

  const filterFunc = React.useCallback(
    (
      options: Array<OptionType>,
      params: FilterOptionsState<OptionType>,
    ): Array<OptionType> => {
      const filtered = [...options]

      if (
        params.inputValue !== '' &&
        !options.some(o => o.value === params.inputValue)
      ) {
        filtered.push({
          inputValue: params.inputValue,
          value: `Добавить "${params.inputValue}"`,
          key: '',
        })
      }

      return filtered
    },
    [],
  )

  const onAddOption = React.useCallback(
    (value: string): void => {
      if (toggleParentCtrl) {
        toggleParentCtrl(true)
      }

      setAddSectionModalSettings({
        type: EEditableModalType.INSERT,
        title: 'Создать раздел',
        label: 'Название:',
        saveBtnText: 'Добавить',
        cancelBtnText: 'Отменить',
      })

      setDialogValue({
        value,
        key: '',
      })
      setOpenAddSectionModal(true)
    },
    [setOpenAddSectionModal, toggleParentCtrl],
  )

  const onEditOption = React.useCallback(
    (value: IKeyValuePair): void => {
      if (toggleParentCtrl) {
        toggleParentCtrl(true)
      }

      setAddSectionModalSettings({
        type: EEditableModalType.EDIT,
        title: 'Редактировать раздел',
        label: 'Название:',
        saveBtnText: 'Сохранить',
        cancelBtnText: 'Отменить',
      })
      setDialogValue(value)
      setOpenAddSectionModal(true)
    },
    [setOpenAddSectionModal, toggleParentCtrl],
  )

  const onRemoveOption = React.useCallback(() => {
    setOpenRemoveSectionModal(true)
  }, [setOpenRemoveSectionModal])

  const onSelectedValueChanged = React.useCallback(
    (value: OptionType | null): void => {
      if (value) {
        const validationResult = modalValidation.validateRequiredFiled(
          value.key,
        )
        updateError(validationResult.message)
        updateModel(value)
      } else {
        updateError(EValidationMessage.NONE)
        updateModel(null)
      }
    },
    [modalValidation, updateError, updateModel],
  )

  const onCloseAddSectionModal = React.useCallback((): void => {
    if (toggleParentCtrl) {
      toggleParentCtrl(false)
    }

    setDialogValue({
      key: '',
      value: '',
    })
    setOpenAddSectionModal(false)
  }, [setOpenAddSectionModal, toggleParentCtrl])

  const onSaveSectionHandler = React.useCallback(
    (model: IKeyValuePair): void => {
      const cb = (data: IKeyValuePair): void => {
        onCloseAddSectionModal()
        onSearch$.next(data.value)
        updateModel(data)
      }

      if (addSectionModalSettings.type === EEditableModalType.INSERT) {
        if (onAddSection) {
          onAddSection(model.value, cb)
        } else {
          cb(model)
        }
      } else {
        if (onEditSection) {
          onEditSection(model, cb)
        } else {
          cb(model)
        }
      }
    },
    [
      addSectionModalSettings.type,
      onAddSection,
      onCloseAddSectionModal,
      onEditSection,
      onSearch$,
      updateModel,
    ],
  )

  const onRemoveSectionHandler = React.useCallback((): void => {
    const cb = (): void => {
      onSearch$.next('')
      updateModel(null)
      setOpenRemoveSectionModal(false)
    }
    if (onRemoveSection) {
      onRemoveSection(initValue?.key, cb)
    } else {
      cb()
    }
  }, [
    initValue,
    onRemoveSection,
    onSearch$,
    setOpenRemoveSectionModal,
    updateModel,
  ])

  return (
    <React.Fragment>
      <SearchInput
        disabled={disabled}
        open={optionsListOpen}
        freeSolo={true}
        label={'Раздел:'}
        data={sectionsList}
        dataIsLoading={optionsListLoading}
        helperText={helperText}
        fullWidth={true}
        presetValue={initValue}
        filterFunc={filterFunc}
        onOpen={(): void => toggleOptionsList(true)}
        onClose={(): void => toggleOptionsList(false)}
        onChange={onSelectedValueChanged}
        onInputChange={onSearchInputChange}
        onAddOption={onAddOption}
        onEditOption={onEditOption}
        onRemoveOption={onRemoveOption}
      />

      {openAddSectionModal && (
        <AddKeyValuePairModal
          open={openAddSectionModal}
          model={dialogValue}
          title={addSectionModalSettings.title}
          label={addSectionModalSettings.label}
          saveBtnText={addSectionModalSettings.saveBtnText}
          cancelBtnText={addSectionModalSettings.cancelBtnText}
          onSave={onSaveSectionHandler}
          onClose={onCloseAddSectionModal}
        />
      )}

      {openRemoveSectionModal && (
        <DialogModal
          open={openRemoveSectionModal}
          modalTitle={'Удаление раздела'}
          modalContent={'Вы действительно хотите удалить раздел?'}
          handleDiscardChanges={(): void => setOpenRemoveSectionModal(false)}
          handleChanges={onRemoveSectionHandler}
          modalButtonRightText={'Удалить'}
          modalButtonRightType={EButtonType.WARNING}
          modalButtonLeftText={'Отменить'}
          modalButtonLeftType={EButtonType.DEFAULT}
        />
      )}
    </React.Fragment>
  )
}
