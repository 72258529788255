import { HashCodeIcon } from '@grand-tender/ui'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const useTableStyles = makeStyles(() => ({
  table: {
    '.MuiDataGrid-iconSeparator': {
      visibility: 'hidden',
    },
    "& .MuiDataGrid-cell[data-field='Цена']": {
      position: 'relative',
    },
  },

  accordionRow: {
    '& .MuiDataGrid-cell': {
      border: 'none',
      background: 'rgba(248,248,250,255) !important',
    },
    '& .MuiDataGrid-cell:nth-child(even)': {
      border: 'none',
      background: 'rgba(241,240,245,255) !important',
    },
    '& .MuiDataGrid-checkboxInput': {
      visibility: 'hidden !important',
    },
  },
  hashCell: {
    padding: '0 8px !important',
  },
  hashHeader: {
    padding: '0 2px !important',
  },
}))

export const LoaderWrap = styled.div`
  color: '#4C5ECF';
  z-index: 300;
  height: 100%;
  width: 100%;
`

export const LoaderBox = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`
export const ProjectTooltip = styled.div`
  max-width: 338px;
`
export const HashCodeIconButton = styled(HashCodeIcon)`
  &:hover {
    cursor: pointer;
    background: rgba(53, 59, 96, 0.1);
    border-radius: 4px;
    & path {
      fill: rgba(53, 59, 96, 1);
    }
  }
`
export const HashHeader = styled.div`
  display: flex;
`
