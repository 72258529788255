import { connect } from 'react-redux'
import { TStoreInitialState } from '@grand-tender/types'

import * as I from './ILoader'
import container from './LoaderContainer'
import { LoaderPL } from './LoaderPL'

export default connect<I.StateProps, {}, {}, TStoreInitialState>(
  container.mapStateToProps,
)(LoaderPL)
