import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  drawerList: {
    '& .MuiList-root:first-child': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '& .MuiList-root:last-child': {
      padding: '8px 34px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      height: '59px',
      boxShadow: 'none',
      width: '100%',
    },
    '& .MuiButton-label': {
      fontWeight: 'normal',
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '& .MuiDrawer-paper': {
      width: '768px',
    },
    '& .MuiPaper-root': {
      //  width: '660px',
      '&::-webkit-scrollbar': {
        width: '8px !important',
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
  },
  drawerListHeader: {
    '& h5': {
      fontSize: '24px',
      color: '#000',
      margin: '20px 0 15px 34px',
      fontWeight: 'normal',
    },
    '& .MuiList-padding': {
      maxWidth: '625px',
    },
  },
  drawerListHeaderCloseBtn: {
    width: '40px',
    height: '40px',
    marginRight: '20px',
  },
  drawerContent: {
    minHeight: 'calc(100vh - 100px)',
    '& hr': {
      margin: '0',
      width: '100%',
    },
  },

  cardEmptyDiv: {
    minWidth: '114px',
  },
  cardOtherPhoto: {
    minWidth: '75px',
    minHeight: '75px',
    margin: '7.5px 0',
    border: '1px solid #E2E4E9',
    cursor: 'pointer',
  },
  cardRowContainer: {
    margin: '24px 0',
    width: '100%',
  },
  cardRowTitle: {
    color: '#4C5ECF',
  },
  cardRowBody: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0 -10px',
    '& .MuiFormControl-root': {
      margin: '0 10px',
    },
    '& .MuiInputBase-input': {
      lineHeight: '25px',
    },
    '& label': {
      marginLeft: '0',
    },
  },
  cardRowSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardRowSpaceBetweenText: {
    '& input': {
      color: '#616284',
      fontSize: '0.85em',
    },
    '& .MuiInputBase-input': {
      textAlign: 'right',
    },
  },
  cardMultiline: {
    display: 'flex',
    margin: '0 -5px',
    '& .flex-item': {
      margin: '0 5px',
    },
    '& .column': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}))

export default useStyles
