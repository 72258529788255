import React from 'react'
import _ from 'lodash'
import { Checkbox, IconButton, TextField, Tooltip } from '@material-ui/core'
import { editIcon, formatValue } from '@grand-tender/ui'
import { TRowData } from '@grand-tender/types'

import { Accordion } from '@grand-tender/ui/src/components/Accordion/Accordion'

import * as I from './IAssignmentDetailsSection'
import useStyles from './Style'

export const AssignmentDetailsSection: React.FC<I.OwnProps> = ({
  data,
  onOpenEditAssignmentModalClick,
  onSetExportSectionFlag,
}): React.ReactElement => {
  const classes = useStyles()

  const valuesData: TRowData = []

  data.listView.data.forEach(d => _.extend(valuesData, d.data))

  return (
    <Accordion
      title={data.sectionName}
      actions={
        data.listView.columns?.length ? (
          <>
            <Tooltip title='Отображать секцию в экспорте'>
              <Checkbox
                checked={data.export}
                onClick={(event): void => {
                  event.stopPropagation()
                  onSetExportSectionFlag(data.sectionId, !data.export)
                }}
              />
            </Tooltip>
            <Tooltip title='Редактировать поля в секции'>
              <IconButton
                onClick={(event): void => {
                  event.stopPropagation()
                  onOpenEditAssignmentModalClick(
                    data.listView.columns,
                    data.listView.data[0],
                    data.sectionName,
                  )
                }}
                className={classes.drawerEditBtn}
              >
                {editIcon}
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <></>
        )
      }
    >
      <div className={classes.lot}>
        <div className={classes.cardRowBody}>
          {data.listView.columns.map(c => (
            <TextField
              key={c.key}
              label={c.title}
              multiline={true}
              maxRows={50}
              value={
                data.listView.data
                  ? formatValue(
                      c.type,
                      valuesData.find(d => d.key === c.key)?.value,
                      '-',
                      c.unit,
                    )
                  : '-'
              }
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
            />
          ))}
        </div>
      </div>
    </Accordion>
  )
}
