import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import {
  EButtonType,
  EditAgreementModal,
  ButtonComponent,
  EButtonIcon,
  DialogModal,
  EColumnType,
  SectionEditableModal,
} from '@grand-tender/ui'
import {
  TAgreementUpdateRequest,
  TAgreementSectionData,
  TColumn,
  IRow,
  TSectionModalInitialState,
} from '@grand-tender/types'

import { Authorities } from '@grand-tender/auth-service'
import { useQuery } from '@gmini/utils'

import { usePermissions } from '../../../../hooks/usePermissions'

import useStyles from './Style'
import * as I from './IAppHeaderAgreement'
import { AgreementsDetails } from './AgreementDetails/AgreementDetails'

export const AppHeaderAgreement: React.FC<I.OwnProps> = ({
  agreement,
  removeAgreement,
  editAgreement,
  editLotAgreement,
  getAgreementSectionData,
}): React.ReactElement => {
  const classes = useStyles()
  const history = useHistory()
  const query = useQuery()
  const { tab } = useParams<{ tab: string }>()

  const checkPermissions = usePermissions()

  const [
    agreementMenuAnchorEl,
    setAgreementMenuAnchorEl,
  ] = useState<HTMLElement | null>(null)

  const [openEditModal, setOpenEditModal] = useState(false)
  const [openDialogModal, setOpenDialogModal] = useState(false)
  // Уточниить тут по составу интерфейса
  const [agreementDetailsData, setAgreementDetailsData] = useState<
    TAgreementSectionData
  >({
    sectionsAgreementResponses: [],
    agreementId: Number(agreement.id),
    attachments: [],
  })
  const [openAgreementDetails, setOpenAgreementDetails] = useState<boolean>(
    false,
  )

  const [
    editAgreementSectionModalConfig,
    setEditAgreementSectionModalConfig,
  ] = useState<TSectionModalInitialState>({
    data: {
      row: {
        rowId: '0',
        data: [],
      },
      settings: [],
    },
    title: '',
  })
  const [
    openEditAgreementSectionModal,
    setOpenEditAgreementSectionModal,
  ] = useState<boolean>(false)

  const openAgreementMenu = React.useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      if (event.isPropagationStopped()) {
        return
      }
      event.stopPropagation()
      setAgreementMenuAnchorEl(event.currentTarget.parentElement || null)
    },
    [],
  )

  const closeAgreementMenu = React.useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      event.stopPropagation()
      setAgreementMenuAnchorEl(null)
    },
    [],
  )

  const deleteAgreement = React.useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      event.stopPropagation()
      setAgreementMenuAnchorEl(null)
      removeAgreement(agreement.id, () => history.push('/agreements/0'))
      setOpenDialogModal(false)
    },
    [agreement.id, history, removeAgreement],
  )

  const saveChangeEditAgreement = React.useCallback(
    (pAgreement: TAgreementUpdateRequest): void => {
      if (pAgreement.expire.indexOf('T') === -1) {
        pAgreement.expire = `${pAgreement.expire}T00:00`
      }
      if (pAgreement.start.indexOf('T') === -1) {
        pAgreement.start = `${pAgreement.start}T00:00`
      }
      setAgreementMenuAnchorEl(null)
      setOpenEditModal(false)
      editAgreement(pAgreement, () => history.push(history.location.pathname))
    },
    [editAgreement, history],
  )

  const openEditAgreement = React.useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      event.stopPropagation()
      setAgreementMenuAnchorEl(null)
      setOpenEditModal(true)
    },
    [],
  )

  const toggleAgreementDetails = (open: boolean): void => {
    if (open) {
      const cb = (data: TAgreementSectionData): void => {
        setAgreementDetailsData(data)
        setOpenAgreementDetails(true)
      }
      getAgreementSectionData(agreement.id, cb)
    } else {
      setAgreementDetailsData({
        sectionsAgreementResponses: [],
        agreementId: Number(agreement.id),
        attachments: [],
      })

      setOpenAgreementDetails(false)
    }
  }

  const onOpenEditAgreementModalClick = (
    columns: TColumn,
    row: IRow,
    sectionName: string,
  ): void => {
    const data = columns.map(d => ({
      key: d.key,
      value:
        d.type === EColumnType.BOOLEAN
          ? row.data.find(rd => rd.key === d.key)?.value || 'false'
          : row.data.find(rd => rd.key === d.key)?.value || '',
    }))

    setEditAgreementSectionModalConfig({
      data: {
        row: {
          ...row,
          data,
        },
        settings: columns,
      },
      title: sectionName,
    })

    setOpenEditAgreementSectionModal(true)
  }

  const saveEditAgreementSectionHandler = (row: IRow): void => {
    const cb = (): void => {
      toggleAgreementDetails(true)

      setOpenEditAgreementSectionModal(false)
    }
    editLotAgreement(row, cb)
  }

  return (
    <div className={classes.assignmentsHeader}>
      <div className={'left-side'}>
        <ButtonComponent
          text={''}
          type={EButtonType.DEFAULT}
          typeIcon={EButtonIcon.ARROW_BACK}
          noMargin
          hidden={false}
          onClick={() =>
            history.push({
              pathname: `/agreements/${tab}`,
              search: query.toString(),
            })
          }
        />
        <h3>Соглашение №{agreement.id}</h3>
        <ButtonComponent
          className={classes.detailsBtn}
          text={'Детали соглашения'}
          type={EButtonType.DEFAULT}
          typeIcon={EButtonIcon.DETAILS}
          hidden={false}
          onClick={(): void => toggleAgreementDetails(true)}
        />
      </div>
      <div className={'right-side'}>
        <IconButton
          onClick={openAgreementMenu}
          className={`${classes.menuButton}${
            agreementMenuAnchorEl ? ' active' : ''
          }`}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id='long-menu'
          getContentAnchorEl={null}
          anchorEl={agreementMenuAnchorEl}
          open={Boolean(agreementMenuAnchorEl)}
          onClose={closeAgreementMenu}
          className={classes.assignmentMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <MenuItem disabled className={classes.menuTitle}>
            <b>Действия с Соглашением</b>
          </MenuItem>
          <MenuItem
            disabled={!checkPermissions(Authorities.AGREEMENTS_EDIT_ITEM)}
            onClick={openEditAgreement}
          >
            Редактировать
          </MenuItem>
          <MenuItem
            disabled={!checkPermissions(Authorities.AGREEMENTS_REMOVE_ITEM)}
            onClick={(event: React.MouseEvent<HTMLElement>): void => {
              event.stopPropagation()
              setAgreementMenuAnchorEl(null)
              setOpenDialogModal(true)
            }}
          >
            Удалить
          </MenuItem>
        </Menu>
        {openDialogModal && (
          <DialogModal
            open={openDialogModal}
            handleDiscardChanges={(): void => setOpenDialogModal(false)}
            handleChanges={deleteAgreement}
            modalTitle={'Удаление'}
            modalContent={`Вы действительно хотите удалить соглашение?`}
            modalButtonRightText={'Удалить'}
            modalButtonRightType={EButtonType.WARNING}
            modalButtonLeftText={'Отменить'}
            modalButtonLeftType={EButtonType.DEFAULT}
          />
        )}
        {openEditModal && (
          <EditAgreementModal
            open={openEditModal}
            model={{
              agreementId: agreement.id,
              expire: agreement.agreement.data.expire,
              start: agreement.agreement.data.start,
              data: agreement.agreement.data.data,
              number: agreement.agreement.data.number,
              tenderTaskName: agreement.agreement.data.tenderTask,
              lotName: agreement.agreement.data.lot,
              providerName: agreement.agreement.data.provider,
              columns: agreement.agreement.columns,
            }}
            onClose={(): void => setOpenEditModal(false)}
            onSave={saveChangeEditAgreement}
          />
        )}
        {openAgreementDetails && (
          <AgreementsDetails
            open={openAgreementDetails}
            data={agreementDetailsData}
            toggleOpen={toggleAgreementDetails}
            onOpenEditAgreementModalClick={onOpenEditAgreementModalClick}
          />
        )}
        {openEditAgreementSectionModal && (
          <SectionEditableModal
            open={openEditAgreementSectionModal}
            initialState={editAgreementSectionModalConfig.data}
            title={editAgreementSectionModalConfig.title}
            onSave={saveEditAgreementSectionHandler}
            onClose={(): void => setOpenEditAgreementSectionModal(false)}
          />
        )}
      </div>
    </div>
  )
}
