import React, { useState, useMemo } from 'react'
import { isArray, uniqBy } from 'lodash'
import ButtonComponent from '@grand-tender/ui/src/components/Button/Button'
import { EButtonIcon, EButtonType } from '@grand-tender/ui/src/constants'
import {
  TextField,
  Drawer,
  List,
  IconButton,
  Typography,
} from '@material-ui/core'
import { DialogModal } from '@grand-tender/ui'
import CloseIcon from '@material-ui/icons/Close'
import { mapConclusionToRus, formatValue } from '@grand-tender/ui/src/utils'
import {
  createDumbTableRows,
  getDateValue,
} from '@grand-tender/ui/src/components/ImprovedTable/utils'
import { DocumentsField } from '@grand-tender/ui/src/components/DocumentsField/DocumentsField'
import { SupplierService } from '@grand-tender/supplier-service'

import { SupplierHistoryField } from '@grand-tender/ui/src/components/SupplierHistoryField'
import { DumbTable } from '@grand-tender/ui/src/components/DumbTable/DumbTable'
import { Accordion } from '@grand-tender/ui/src/components/Accordion/Accordion'
import { AGREEMENT_TABLE_COLUMNS } from '@grand-tender/ui/src/components/ImprovedTable/constants'
import { IKeyValuePair, TSupplierModel } from '@grand-tender/types'

import useStyles from './Style'

export type SupplierDetailModalProps = {
  open: boolean
  columns: any[]
  data: Partial<TSupplierModel>
  showEditButton?: boolean
  disableEditButton?: boolean
  showRemoveButton?: boolean
  disableRemoveButton?: boolean
  onClose?: () => void
  updateDrawer?: () => void
  onRemove?: (ids: string[]) => void
  onEdit?: () => void
}

export const SupplierDetailModal: React.FC<SupplierDetailModalProps> = ({
  open,
  columns,
  data,
  showEditButton = true,
  disableEditButton = false,
  showRemoveButton = true,
  disableRemoveButton = false,
  onClose,
  updateDrawer,
  onRemove,
  onEdit,
}): React.ReactElement => {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(false)
  const sections = useMemo(
    () =>
      uniqBy(
        columns
          .filter(column => column.section)
          .map(column => column.section) as IKeyValuePair[],
        'key',
      ),
    [columns],
  )
  const agreements = useMemo(() => createDumbTableRows(data.agreements || []), [
    data.agreements,
  ])

  return (
    <>
      <Drawer
        className={classes.root}
        anchor={'right'}
        open={open}
        onClose={onClose}
      >
        <div role='presentation'>
          <List className={classes.drawerHeader}>
            <div className={classes.drawerTitle}>
              <b>{data && data.name}</b>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </List>
          <List className={classes.drawerContent}>
            <div className={classes.cardRowContainer}>
              <div className={classes.cardRowBody}>
                <div title='Контактные данные'>
                  <Typography variant='h6' style={{ padding: '0 10px' }}>
                    Контактные данные
                  </Typography>
                  <div className={classes.accordionBody}>
                    <TextField
                      label='ФИО контактного лица'
                      value={data.representative || '-'}
                      maxRows={3}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label='Телефон'
                      value={data.phone || '-'}
                      maxRows={3}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label='Email'
                      value={data.email || '-'}
                      maxRows={3}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                    <TextField
                      fullWidth={true}
                      style={{ maxWidth: '100%' }}
                      label='Юридический адрес'
                      value={data.address || '-'}
                      maxRows={5}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                    <TextField
                      fullWidth={true}
                      style={{ maxWidth: '100%' }}
                      label='Фактический адрес'
                      value={data.actualAddress || '-'}
                      maxRows={3}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label='ИНН'
                      value={data.inn || '-'}
                      maxRows={3}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label='КПП'
                      value={data.kpp || '-'}
                      maxRows={3}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label='Заключение СБ'
                      value={mapConclusionToRus(data.conclusionSB || '')}
                      maxRows={3}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label='Дата заключения СБ'
                      value={getDateValue(data?.sbdate || '') || '-'}
                      maxRows={3}
                      multiline={true}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.cardRowContainer}>
              <div className={classes.cardRowBody}>
                {sections.map((section, ind) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Accordion title={section.value} key={ind}>
                    <div className={classes.accordionBody}>
                      {columns
                        .filter(col => col.section?.key === section.key)
                        .map((col, index) => {
                          const colData = data.data?.find(
                            field => field.key === col.key,
                          )

                          return (
                            <TextField
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              fullWidth={true}
                              label={col.title}
                              value={
                                colData?.value
                                  ? formatValue(
                                      col.type,
                                      colData?.value,
                                      '-',
                                      col.unit,
                                    )
                                  : '-'
                              }
                              maxRows={3}
                              multiline={true}
                              InputProps={{
                                disableUnderline: true,
                                readOnly: true,
                              }}
                            />
                          )
                        })}
                    </div>
                  </Accordion>
                ))}
              </div>
            </div>
            <div className={classes.cardRowContainer}>
              <div className={classes.cardRowBody}>
                <DocumentsField
                  services={{
                    add: SupplierService.uploadDocument,
                    delete: SupplierService.deleteDocument,
                  }}
                  afterFetch={updateDrawer}
                  id={data.id || ''}
                  documents={data.attachments || []}
                />
              </div>
            </div>
            <div className={classes.cardRowContainer}>
              <div className={classes.cardRowBody}>
                <SupplierHistoryField data={data.tenderTasks || []} />
              </div>
            </div>
            {isArray(agreements) && (
              <div className={classes.cardRowContainer}>
                <Accordion title='Соглашения'>
                  <DumbTable
                    columns={AGREEMENT_TABLE_COLUMNS}
                    rows={agreements}
                  />
                </Accordion>
              </div>
            )}
          </List>
          <List className={classes.drawerFooter}>
            {showRemoveButton && (
              <div>
                <ButtonComponent
                  disabled={disableRemoveButton}
                  text={''}
                  type={EButtonType.DEFAULT}
                  typeIcon={EButtonIcon.DELETE}
                  noMargin={true}
                  onClick={() => setOpenDialog(true)}
                />
              </div>
            )}
            {showEditButton && (
              <div className={classes.secondPartFooter}>
                <ButtonComponent
                  disabled={disableEditButton}
                  text={'Редактировать'}
                  type={EButtonType.DEFAULT}
                  typeIcon={EButtonIcon.EDIT}
                  onClick={(): void => {
                    if (onEdit) {
                      onEdit()
                    }
                    if (onClose) {
                      onClose()
                    }
                  }}
                />
              </div>
            )}
          </List>
        </div>
      </Drawer>
      <DialogModal
        open={openDialog}
        handleDiscardChanges={() => setOpenDialog(false)}
        handleChanges={() => {
          if (onRemove) {
            onRemove([data.id as string])
          }
          if (onClose) {
            onClose()
          }
          setOpenDialog(false)
        }}
        modalTitle='Удаление поставщика'
        modalContent='Вы действительно хотите удалить поставщика?'
        modalButtonRightText='Удалить поставщика'
        modalButtonRightType={EButtonType.WARNING}
        modalButtonLeftText='Отменить'
        modalButtonLeftType={EButtonType.DEFAULT}
      />
    </>
  )
}
