import { TTreeList, ITreeListItem } from '@grand-tender/types/src'
import moment from 'moment'
import { isNil } from 'lodash'
import { DEFAULT_DISPLAY_DATE_FORMAT, EColumnType } from '../constants'

export const uuid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })

export const formatValue = (
  type: EColumnType,
  value: string | undefined,
  defaultValue = '',
  unit?: string,
  location?: string | null,
  title?: string,
): string => {
  switch (type) {
    case EColumnType.STRING:
    case EColumnType.INTEGER: {
      if (title === 'Заключение СБ') {
        return mapConclusionToRus(value || '')
      }
      return `${
        value
          ? `${value}${unit ? ` ${unit}` : ''}`
          : value === '' && type === EColumnType.INTEGER
          ? '-'
          : defaultValue
      }`
    }
    case EColumnType.BOOLEAN: {
      return `${value ? `${value === 'true' ? 'Да' : 'Нет'}` : defaultValue}`
    }
    case EColumnType.LIST: {
      if (title?.toLocaleLowerCase() === 'цена') {
        const newValue = value?.split('|').map((el: any) =>
          isNaN(el)
            ? el
            : Intl.NumberFormat('ru-RU', {
                minimumFractionDigits: 2,
              }).format(Number(el)),
        )

        return (newValue && newValue.join(', ')) || defaultValue
      }
      return value?.split('|').join(', ') || defaultValue
    }
    case EColumnType.DATE: {
      if (!value || value.trim().length < 10) {
        return defaultValue
      }
      return `${
        value ? moment(value).format(DEFAULT_DISPLAY_DATE_FORMAT) : defaultValue
      }`
    }
    case EColumnType.DOUBLE:
    case EColumnType.CURRENCY: {
      const parseNumberValue = Number(value).toFixed(2)
      // eslint-disable-next-line no-undefined
      const formatNumber = Intl.NumberFormat(undefined, {
        minimumFractionDigits: 2,
      }).format(Number(parseNumberValue))

      return `${
        !isNaN(Number(value)) && value !== ''
          ? `${formatNumber}${
              type === EColumnType.CURRENCY ? ' ₽' : `${unit ? ` ${unit}` : ''}`
            }`
          : value === ''
          ? '-'
          : defaultValue
      }`
    }
    case EColumnType.PHONE: {
      return `${value ? `${value}` : defaultValue}`
    }
    default: {
      return value || ' '
    }
  }
}

export class LocalStorageHelper {
  public static set<T>(name: string, value: T): void {
    localStorage.setItem(name, JSON.stringify(value) || '')
  }

  public static get<T>(name: string): T | null {
    const item = localStorage.getItem(name)
    return item ? JSON.parse(item) : null
  }

  public static remove(name: string): void {
    localStorage.removeItem(name)
  }

  public static clear(): void {
    localStorage.clear()
  }
}

export const treeify = (list: TTreeList): TTreeList => {
  const treeList: TTreeList = []
  const lookup: { [key: string]: ITreeListItem } = {}

  list.forEach((node: ITreeListItem) => {
    lookup[node.id] = node
    node.content = []
  })

  list.forEach((node: ITreeListItem) => {
    if (isNil(node.parentId)) {
      treeList.push(node)
      return
    }

    lookup[node.parentId].content.push(node)
  })

  return treeList
}

export const flatten = (catalog: TTreeList): TTreeList =>
  catalog.reduce(
    (accum: TTreeList, item: ITreeListItem) =>
      accum.concat([item], flatten(item.content)),
    [],
  )

export const mapConclusionToRus = (conclusion: string): string => {
  switch (conclusion) {
    case 'NotFound':
      return 'Не найден'

    case 'Agreed':
      return 'Согласован'

    case 'NotAgreed':
      return 'Не согласован'

    case 'Expired':
      return 'Истекший'

    default:
      return conclusion
  }
}

export const isConclusionStatus = (value: string): boolean =>
  value === 'NotFound' || 'Agreed' || 'NotAgreed' || 'Expired' ? true : false
