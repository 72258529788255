import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useQuery } from '@gmini/utils'
import {
  CatalogSkeleton,
  EAPIResponseStatus,
  EButtonIcon,
  EButtonType,
  EColumnType,
  EditableModal,
  EEditableModalType,
  ETreeListItemType,
  LocalStorageHelper,
  PositionExportModal,
  TableSkeleton,
} from '@grand-tender/ui'
import {
  ColumnSettings,
  IColumn,
  IFileResult,
  IRow,
  IXGridColumn,
  PositionTab,
  TEditModalInitialState,
  TFilterItems,
  TPositionModel,
  TPositionsRequest,
  TRowData,
  TTreeList,
  TTreeModifyDict,
} from '@grand-tender/types'
import { PositionService } from '@grand-tender/position-service'
import { Authorities } from '@grand-tender/auth-service'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { toastr } from 'react-redux-toastr'

import { debounce, find } from 'lodash'

import { CollapsedCatalog } from '@ifellow/ui-library'
import { TableParams } from '@ifellow/ui-library/dist/components/x-grid-table/x-grid-table'

import ButtonComponent from '@grand-tender/ui/src/components/Button/Button'
import TableUpload from '@grand-tender/ui/src/components/ImprovedTable/TableUpload/TableUpload'
import moment from 'moment'
import { modalService } from '@grand-tender/ui/src/components/Modal/ModalService'
import DialogModal from '@grand-tender/ui/src/components/DialogModal/DialogModal'
import AddToAssignmentModal from '@grand-tender/ui/src/components/AddToAssignmentModal/AddToAssignmentModal'

import { useDispatch } from 'react-redux'

import { useHistory } from 'react-router-dom'

import PositionCatalog from '../../components/PositionCatalog/PositionCatalog'
import { exportPositionsTemplate } from '../../actions'
import { PositionInfo } from '../../components/position-info/position-info'
import {
  findParentNodeWithTheGroupType,
  catalogMapper,
  getNodeTypeById,
} from '../../utils'
import { useAppSelector } from '../../store'
import { CatalogState } from '../../components/PositionCatalog/store/catalog-slice'
import { usePermissions } from '../../hooks/usePermissions'

import { PropsFromRedux } from './position-container'

import {
  Root,
  TabPanel,
  PathComponent,
  Path,
  Title,
  Actions,
  Selected,
  CatalogWrapper,
  TabPanelWrapper,
} from './position.styled'

import * as I from './position-types'
import { PositionTable } from './postion-table'

const title = {
  insert: 'Добавить позицию',
  edit: 'Редактировать позицию',
}

const maximumNumberOfCopiedElements = 500

const Position: React.FC<PropsFromRedux & I.OwnProps> = ({
  tabIndex,
  positions,
  dataIsLoading,
  getPositions,
  addPosition,
  deletePositions,
  editPosition,
  exportPositions,
  exportPassedPositions,
  exportNotPassedPositions,
  exportArchivedPositions,
  exportPositionsFolderLevel,
  exportPositionsFolderLevelPassed,
  exportPositionsFolderLevelNotPassed,
  exportPositionsFolderLevelArchived,
  getCategories,
  saveCategoriesChanges,
  importPositions,
  addProperty,
  editProperty,
  removeProperty,
  addToAssignment,
  resetPosition,
  copyPositions,
  user,
}): React.ReactElement | null => {
  const history = useHistory()
  const query = useQuery()
  const positionId = query.get('positionId')
  const initCategoryId = query.get('categoryId')
  const [categoryId, setCategory] = useState(initCategoryId || '')

  const localStorageTableId = `positions-categoryId-${categoryId}`
  const checkPermissions = usePermissions()
  const [isCatalogExpanded, setIsCatalogExpanded] = useState(true)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const [positionInfo, setPositionInfo] = useState<TPositionModel | undefined>()
  const [currentRow, setCurrentRow] = useState<IRow | undefined>()
  const [isCategoriesPending, setIsCategoriesPending] = useState(false)
  const [searchDate, setSearchDate] = useState<string | null>(null)
  const modalInitialState = useRef<TEditModalInitialState>({
    settings: [],
    files: {
      current: [],
      add: [],
      remove: [],
    },
    row: {
      rowId: '',
      photo: '',
      data: [],
    },
  })
  const [nodeType, setNodeType] = useState<ETreeListItemType | undefined>()
  const [parentGroupId, setParentGroupId] = useState<string | undefined>()
  const [inTender, setInTender] = useState<boolean>(true)
  const [hideTable, setHideTable] = useState(true)
  const [notInTender, setNotInTender] = useState<boolean>(true)
  const [checkedRows, setCheckedRows] = useState<Array<string | number>>([])
  const [updateFlag, update] = useState<number>(0)

  const [tableParams, setTableParams] = useState<TableParams>({
    size: 10,
    page: 0,
    sort: {
      field: '',
      sort: 'asc',
    },
    filters: [],
  })

  const [nameCol, hashCol] = React.useMemo(
    () => [
      positionInfo?.columns.find(
        c =>
          c.base &&
          ['название', 'наименование'].includes(c.title.toLowerCase()),
      ),
      positionInfo?.columns.find(c => c.base && c.title === '#'),
    ],
    [positionInfo],
  )

  const { filter } = useAppSelector<CatalogState>((state: any) => state.catalog)
  const categories = useAppSelector(state => state.categories)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useEffect(() => (): void => resetPosition && resetPosition(), [resetPosition])

  const handleGetCategories = useCallback(
    async (filterOptions?: { endDate: string; startDate: string }) => {
      const setLoading = (data: boolean) => setIsCategoriesPending(data)
      const res = await getCategories({
        setLoading,
        tabIndex,
        filterOptions,
      }).unwrap()
      return res
    },
    [getCategories, tabIndex],
  )

  useEffect(() => {
    handleGetCategories().then(tree => {
      if (initCategoryId) {
        handleSelectNode(initCategoryId, null, tree)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex])

  const getCurrentRequestParams = useCallback(() => {
    const sortFieldKey = positions.columns.find(
      c => c.title === tableParams.sort?.field,
    )?.key
    const filterOptions =
      tabIndex === PositionTab.Passed && filter.searchDate
        ? { startDate: filter.searchDate, endDate: filter.searchDate }
        : undefined

    const params: TPositionsRequest = {
      pageData: {
        page: tableParams.page + 1,
        perPage: tableParams.size,
        categoryId,
        ascending: tableParams.sort?.sort === 'asc',
        fieldKey: sortFieldKey,
      },
      userContract: {
        endDate: '',
        object: '',
        project: '',
        startDate: '',
      },
      ...filterOptions,
      itemConditions: tableParams.filters as TFilterItems[],
      fetchCategories: true,
      table: 'items',
      isActive: true,
      deleted: tabIndex === PositionTab.Archived,
    }
    if (tabIndex === PositionTab.NotPassed) {
      params.pageData.inTender = inTender
      params.pageData.notInTender = notInTender
    }
    return params
  }, [
    tabIndex,
    filter.searchDate,
    tableParams,
    categoryId,
    inTender,
    notInTender,
    positions.columns,
  ])

  const getInitRequestParams = useCallback(
    (localStorageId?: string) => {
      let storageSortColumn: ColumnSettings | undefined
      const storageData = localStorage.getItem(localStorageId || '')
      if (typeof storageData === 'string') {
        try {
          storageSortColumn = (JSON.parse(
            storageData,
          ) as ColumnSettings[]).find(s => s.sortOrder !== null)
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err)
        }
      }
      const filterOptions =
        tabIndex === PositionTab.Passed && filter.searchDate
          ? { endDate: filter.searchDate, startDate: filter.searchDate }
          : undefined
      const params: TPositionsRequest = {
        pageData: {
          page: 1,
          perPage: tableParams.size || 10,
          categoryId,
          fieldKey: storageSortColumn ? storageSortColumn.key : '',
          ascending:
            storageSortColumn?.sortOrder !== null
              ? storageSortColumn?.sortOrder
              : true,
          sortColumnFieldTitle: storageSortColumn?.title,
        },
        fetchCategories: true,
        table: 'items',
        isActive: true,
        deleted: tabIndex === PositionTab.Archived,
        ...filterOptions,
        userContract: {
          endDate: '',
          object: '',
          project: '',
          startDate: '',
        },
      }
      if (tabIndex === PositionTab.NotPassed) {
        params.pageData.inTender = inTender
        params.pageData.notInTender = notInTender
      }
      return params
    },
    [
      tabIndex,
      filter.searchDate,
      tableParams.size,
      categoryId,
      inTender,
      notInTender,
    ],
  )
  // TODO необходим рефакторинг компонента в частности разделение зон ответственности
  React.useEffect(() => {
    if (searchDate !== filter.searchDate && tabIndex === PositionTab.Passed) {
      setSearchDate(filter.searchDate)

      if (filter.searchDate) {
        handleGetCategories({
          endDate: filter.searchDate,
          startDate: filter.searchDate,
        })
      } else {
        handleGetCategories()
      }

      if (categoryId) {
        getPositions({
          params: getCurrentRequestParams(),
          tab: tabIndex,
          setLoading,
        })
      }
    }
  }, [
    categoryId,
    filter,
    getCurrentRequestParams,
    getPositions,
    handleGetCategories,
    searchDate,
    tabIndex,
  ])

  const handleSelectNode = (
    nodeId?: string,
    incomingNodeType?: ETreeListItemType | null,
    incomingCategories?: TTreeList,
  ): void => {
    const initParams = getInitRequestParams(`positions-categoryId-${nodeId}`)

    const currentCategories = incomingCategories?.length
      ? incomingCategories
      : categories
    setTableParams({
      size: tableParams.size,
      page: 0,
      sort: {
        field: initParams.pageData.sortColumnFieldTitle || '',
        sort: initParams.pageData.ascending ? 'asc' : 'desc',
      },
      filters: [],
    })

    const currentNodeType = incomingNodeType
      ? incomingNodeType
      : getNodeTypeById(catalogMapper(currentCategories), nodeId || '')

    setNodeType(currentNodeType)

    setParentGroupId(
      findParentNodeWithTheGroupType(currentCategories, Number(nodeId))?.id,
    )

    getPositions({
      params: {
        ...initParams,
        pageData: {
          ...initParams.pageData,
          categoryId: nodeId ?? '',
        },
      },
      tab: tabIndex,
      setLoading: val => {
        setLoading(val)
        setHideTable(val)
      },
      isNotList: currentNodeType !== 'list',
    })
    setCategory(nodeId ?? '')
    if (!incomingCategories?.length) {
      query.set('categoryId', nodeId || '')
      history.replace({ search: query.toString() })
    }
  }

  const handleSubmitExportModal = useCallback(
    (isExportOneFile: boolean) => {
      if (tabIndex === PositionTab.All) {
        exportPositionsFolderLevel({
          categoryId,
          isExportOneFile,
          cb: () => {
            modalService.closeModal()
          },
        })
      } else if (tabIndex === PositionTab.Passed) {
        exportPositionsFolderLevelPassed({
          categoryId,
          isExportOneFile,
          cb: () => {
            modalService.closeModal()
          },
        })
      } else if (tabIndex === PositionTab.Archived) {
        exportPositionsFolderLevelArchived({
          categoryId,
          isExportOneFile,
          inTender,
          notInTender,
          cb: () => {
            modalService.closeModal()
          },
        })
      } else {
        exportPositionsFolderLevelNotPassed({
          categoryId,
          isExportOneFile,
          inTender,
          notInTender,
          cb: () => {
            modalService.closeModal()
          },
        })
      }
    },
    [
      categoryId,
      exportPositionsFolderLevel,
      inTender,
      notInTender,
      exportPositionsFolderLevelNotPassed,
      exportPositionsFolderLevelPassed,
      exportPositionsFolderLevelArchived,
      tabIndex,
    ],
  )

  const handleExportFolderLevelClick = useCallback(() => {
    modalService.openModal(
      <PositionExportModal
        open={true}
        onClose={() => modalService.closeModal()}
        onSubmitExport={handleSubmitExportModal}
        exportPageOrTabOption
      />,
    )
  }, [handleSubmitExportModal])

  const handleToggleInTenderCheckbox = useCallback(() => {
    const request = getCurrentRequestParams()
    getPositions({
      params: {
        ...request,
        pageData: {
          ...request.pageData,
          inTender: !inTender,
        },
      },
      tab: tabIndex,
      setLoading,
    })

    handleGetCategories()
    setInTender(!inTender)
  }, [
    getCurrentRequestParams,
    getPositions,
    handleGetCategories,
    inTender,
    tabIndex,
  ])

  const handleToggleNotInTenderCheckbox = useCallback(() => {
    const request = getCurrentRequestParams()
    getPositions({
      params: {
        ...request,
        pageData: {
          ...request.pageData,
          notInTender: !notInTender,
        },
      },
      tab: tabIndex,
    })
    handleGetCategories()
    setNotInTender(!notInTender)
  }, [
    getCurrentRequestParams,
    getPositions,
    handleGetCategories,
    notInTender,
    tabIndex,
  ])

  const fetchPositionInfo = useCallback(async (id: string) => {
    const [, detail] = await PositionService.getPositionData(id)
    const { data, status, message } = detail.data
    if (status === EAPIResponseStatus.SUCCESS && data) {
      setCurrentRow({ ...data, rowId: data.id })
      setPositionInfo(data)
    } else {
      const error =
        status === EAPIResponseStatus.ERROR
          ? message || ''
          : // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (detail as any).data || ''
      toastr.error('', error || 'При обращении к серверу произошла ошибка!')
    }
  }, [])

  const savePosition = useCallback((): void => {
    const item: TRowData = [...modalInitialState.current.row.data]
    if (modalInitialState.current.row.rowId === '') {
      addPosition({
        position: item,
        files: modalInitialState.current.files?.add.map(i => i.file) || [],
        callback: () => {
          getPositions({
            params: getCurrentRequestParams(),
            tab: tabIndex,
            setLoading,
          })
          handleGetCategories()
          modalService.closeModal()
        },
      })
    } else {
      if (!currentRow) {
        return
      }
      const uneditableColsMap = modalInitialState.current.settings.reduce(
        (acc: { [x: number]: boolean }, c) =>
          c.editable ? acc : { ...acc, [c.key]: true },
        {},
      )
      const initialPositionValuesMap = currentRow.data.reduce(
        (acc: { [x: number]: string }, p) => ({ ...acc, [p.key]: p.value }),
        {
          [nameCol?.key]: positionInfo?.name || '',
        },
      )
      const filteredData = item.filter(
        field =>
          !uneditableColsMap[field.key] &&
          initialPositionValuesMap[field.key] !== field.value,
      )
      if (!filteredData.length) {
        return
      }
      editPosition({
        position: {
          rowId: modalInitialState.current.row.rowId,
          data: filteredData,
        },
        files: modalInitialState.current.files
          ? { ...modalInitialState.current.files }
          : { current: [], add: [], remove: [] },
        callback: () => {
          getPositions({
            params: getCurrentRequestParams(),
            tab: tabIndex,
            setLoading,
          })
          fetchPositionInfo(positionInfo?.id || '')
          handleGetCategories()
          modalService.closeModal()
        },
      })
    }
  }, [
    addPosition,
    editPosition,
    getCurrentRequestParams,
    getPositions,
    handleGetCategories,
    tabIndex,
    currentRow,
    positionInfo,
    nameCol?.key,
    fetchPositionInfo,
  ])

  const handleAddPosition = useCallback(() => {
    const data = positions.columns.map(d => ({
      key: d.key,
      value: d.type === EColumnType.BOOLEAN ? 'false' : '',
    }))
    const REQUIRED_COLUMNS = ['цена', 'соглашение', 'поставщик']
    modalInitialState.current = {
      files: {
        current: [],
        add: [],
        remove: [],
      },
      row: {
        rowId: '',
        photo: '',
        data,
      },
      settings: positions.columns.filter(column => {
        if (tabIndex === PositionTab.All) {
          return !REQUIRED_COLUMNS.includes(column.title.toLowerCase())
        }

        return true
      }),
    }

    modalService.openModal(
      <EditableModal
        open={true}
        initialState={modalInitialState}
        type={EEditableModalType.INSERT}
        title={title}
        dataIsLoading={dataIsLoading}
        onClose={(): void => modalService.closeModal()}
        onSave={savePosition}
        isMultiline
        maxRows={4}
        author={user?.name}
        path='positions'
      />,
    )
  }, [positions.columns, dataIsLoading, savePosition, user?.name, tabIndex])

  const handleImportClick = useCallback(
    (file: File) => {
      importPositions({
        categoryId,
        file,
        callback: () => {
          getPositions({
            params: getInitRequestParams(),
            tab: tabIndex,
            setLoading,
          })
          handleGetCategories()
        },
      })
    },
    [
      categoryId,
      getInitRequestParams,
      getPositions,
      handleGetCategories,
      importPositions,
      tabIndex,
    ],
  )

  const handleExportTemplateClick = useCallback(() => {
    dispatch(exportPositionsTemplate(categoryId))
  }, [categoryId, dispatch])

  const handleExportClick = useCallback(
    (isExportAllPosition: boolean) => {
      const exportPosition = isExportAllPosition
        ? positions.data.map(position => position.rowId)
        : checkedRows

      switch (tabIndex) {
        case PositionTab.All:
          exportPositions({
            ids: exportPosition as string[],
            categoryId,
            isdownloadBycategory: isExportAllPosition,
          })
          break

        case PositionTab.Passed:
          exportPassedPositions({
            ids: exportPosition as string[],
            categoryId,
            isdownloadBycategory: isExportAllPosition,
          })
          break

        case PositionTab.NotPassed:
          exportNotPassedPositions({
            ids: exportPosition as string[],
            categoryId,
            isdownloadBycategory: isExportAllPosition,
          })
          break

        case PositionTab.Archived:
          exportArchivedPositions({
            ids: exportPosition as string[],
            categoryId,
            isdownloadBycategory: isExportAllPosition,
          })
          break
      }
      modalService.closeModal()
    },
    [
      positions.data,
      checkedRows,
      tabIndex,
      exportPositions,
      categoryId,
      exportPassedPositions,
      exportNotPassedPositions,
      exportArchivedPositions,
    ],
  )

  const handleExportSelectedPositionClick = useCallback(() => {
    modalService.openModal(
      <PositionExportModal
        open={true}
        onClose={() => modalService.closeModal()}
        onSubmitExportPosition={handleExportClick}
        exportBySelectedPositionOption
      />,
    )
  }, [handleExportClick])

  const handleArchivePositions = useCallback(
    (ids: Array<string>) => {
      modalService.openModal(
        <DialogModal
          open={true}
          handleDiscardChanges={() => modalService.closeModal()}
          handleChanges={() => {
            modalService.closeModal()
            deletePositions({
              ids,
              callback: () => {
                getPositions({
                  params: getCurrentRequestParams(),
                  tab: tabIndex,
                  setLoading,
                })
                handleGetCategories()
                setCheckedRows([])
                setCurrentRow(undefined)
                positionInfo && setPositionInfo(undefined)
              },
            })
          }}
          modalTitle={'Архивирование позиций'}
          modalContent={`Вы действительно хотите архивировать позиции (${ids.length})?`}
          modalButtonRightText={'Архивировать'}
          modalButtonRightType={EButtonType.WARNING}
          modalButtonLeftText='Отменить'
          modalButtonLeftType={EButtonType.DEFAULT}
        />,
      )
    },
    [
      deletePositions,
      getCurrentRequestParams,
      getPositions,
      handleGetCategories,
      positionInfo,
      tabIndex,
    ],
  )

  const handleCreateCopyPosition = useCallback(() => {
    if (checkedRows.length > maximumNumberOfCopiedElements) {
      modalService.openModal(
        <DialogModal
          open={true}
          modalTitle='Ограничение по копированию'
          modalContent={`Выберите максимум ${maximumNumberOfCopiedElements} элементов`}
          handleClose={(): void => modalService.closeModal()}
        />,
      )
      return
    }
    const date = moment().format('YYYY-MM-DDT00:00')
    const authorKey = find(positions.columns, i => i.title === 'Автор')
    const dateCreationKey = find(
      positions.columns,
      i => i.title === 'Дата создания',
    )
    copyPositions({
      selectedRows: checkedRows as string[],
      author: user?.name || '',
      date,
      authorKeyToCopy: authorKey?.key || 0,
      dateKeyToCopy: dateCreationKey?.key || 0,
    })
    setCheckedRows([])
  }, [checkedRows, positions.columns, copyPositions, user])

  const handleEditClick = useCallback((): void => {
    if (!positionInfo || !currentRow) {
      return
    }
    const files: IFileResult = {
      current: positionInfo.photos.map(p => ({
        id: p.key,
        file: p.value,
      })),
      add: [],
      remove: [],
    }
    const data = positionInfo.columns.map(column => {
      if (column.key === nameCol?.key) {
        return {
          key: column.key,
          value: positionInfo.name,
        }
      }
      if (column.key === hashCol?.key) {
        return {
          key: column.key,
          value: positionInfo.hash,
        }
      }

      return {
        key: column.key,
        value:
          column.type === EColumnType.BOOLEAN
            ? currentRow.data.find(rd => rd.key === column.key)?.value ||
              'false'
            : currentRow.data.find(rd => rd.key === column.key)?.value || '',
      }
    })

    modalInitialState.current = {
      row: {
        ...currentRow,
        data,
      },
      settings: positionInfo.columns,
      files,
    }

    modalService.openModal(
      <EditableModal
        open={true}
        initialState={modalInitialState}
        type={EEditableModalType.EDIT}
        title={title}
        dataIsLoading={dataIsLoading}
        onClose={(): void => modalService.closeModal()}
        onSave={savePosition}
        isMultiline
        maxRows={4}
        author={user?.name}
        path='positions'
      />,
    )
  }, [
    positionInfo,
    currentRow,
    dataIsLoading,
    savePosition,
    user?.name,
    nameCol?.key,
    hashCol?.key,
  ])

  useEffect(() => {
    if (positionId) {
      fetchPositionInfo(positionId)
    }
  }, [positionId, fetchPositionInfo])

  const handleAddToAssignment = useCallback(
    (assignmentId: string): void => {
      addToAssignment({
        positionIds: currentRow
          ? [currentRow.rowId]
          : (checkedRows as string[]),
        lotId: assignmentId,
        callback: () => {
          setCheckedRows([])
          setCurrentRow(undefined)
          setPositionInfo(undefined)
          modalService.closeModal()
        },
      })
    },
    [addToAssignment, checkedRows, currentRow],
  )

  const handleMoveToAssignmentsClick = useCallback(() => {
    modalService.openModal(
      <AddToAssignmentModal
        open={true}
        dataIsLoading={dataIsLoading}
        onClose={(): void => modalService.closeModal()}
        onSave={handleAddToAssignment}
      />,
    )
  }, [handleAddToAssignment, dataIsLoading])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleParametersChange = useCallback(
    debounce((params: TableParams) => {
      const curParams = getCurrentRequestParams()
      const sortFieldKey = positions.columns.find(
        c => c.title === params.sort?.field,
      )?.key
      setCheckedRows([])
      getPositions({
        params: {
          ...curParams,
          pageData: {
            ...curParams.pageData,
            page: params.page + 1,
            perPage: params.size,
            categoryId,
            ascending: params.sort?.sort === 'asc',
            fieldKey: sortFieldKey,
          },
          itemConditions: params.filters as TFilterItems[],
        },
        tab: tabIndex,
        setLoading,
      })
      setTableParams(params)
    }, 500),
    [categoryId, tabIndex, positions.columns],
  )

  const handleAddProperty = useCallback(
    (model: IColumn): void => {
      addProperty({
        categoryId: Number(categoryId),
        model,
        callback: () => {
          getPositions({
            params: getCurrentRequestParams(),
            tab: tabIndex,
            setLoading,
          })
        },
      })
    },
    [addProperty, categoryId, getCurrentRequestParams, getPositions, tabIndex],
  )

  const handleEditProperty = useCallback(
    (model: IColumn): void => {
      model.key = model.id
      model.type = model.type.split('_')[0] as EColumnType
      editProperty({
        model,
        callback: () => {
          getPositions({
            params: getCurrentRequestParams(),
            tab: tabIndex,
            setLoading,
          })
        },
      })
    },
    [editProperty, getCurrentRequestParams, getPositions, tabIndex],
  )

  const fetchPositions = () => {
    const params = getCurrentRequestParams()
    if (!params.pageData.categoryId) {
      return
    }
    getPositions({
      params,
      tab: tabIndex,
      setLoading,
    })
  }

  const handleRemoveProperty = useCallback(
    (key: number): void => {
      removeProperty({
        key: String(key),
        callback: () => {
          const columnSettings: Array<
            IXGridColumn
          > | null = LocalStorageHelper.get(localStorageTableId)

          if (columnSettings) {
            const withoutColumn = columnSettings.filter(
              el => el.field !== String(key),
            )
            LocalStorageHelper.set(localStorageTableId, withoutColumn)
          }
          getPositions({
            params: getCurrentRequestParams(),
            tab: tabIndex,
            setLoading,
          })
        },
      })
    },
    [
      getCurrentRequestParams,
      getPositions,
      localStorageTableId,
      removeProperty,
      tabIndex,
    ],
  )

  const arePositionsAvailable = useCallback(
    () =>
      checkPermissions(Authorities.ITEMS_SHOW_TAB_ALL) ||
      checkPermissions(Authorities.ITEMS_SHOW_TAB_PASSED_GT),
    [checkPermissions],
  )

  const saveCategories = useCallback(
    (modifyDict: TTreeModifyDict) => {
      saveCategoriesChanges({ modifyDict, callback: handleGetCategories })
    },
    [handleGetCategories, saveCategoriesChanges],
  )

  if (!arePositionsAvailable()) {
    return null
  }

  return (
    <Root>
      {isCatalogExpanded &&
        (isCategoriesPending ? (
          <CatalogSkeleton />
        ) : (
          <CatalogWrapper>
            <PositionCatalog
              editable={
                tabIndex === PositionTab.All &&
                checkPermissions(Authorities.ITEMS_EDIT_CATALOG)
              }
              onSave={saveCategories}
              onSelectNode={node => handleSelectNode(node?.id, node?.type)}
              setIsCatalogExpanded={setIsCatalogExpanded}
              categoryId={categoryId}
              categories={categories}
              withCopyingElements={true}
              getCategories={handleGetCategories}
              withGroup={true}
              fetchPositions={fetchPositions}
              onUpdateGroupColumnsVisibility={() => update(value => ~value)}
              tabIndex={tabIndex}
            />
          </CatalogWrapper>
        ))}
      {loading && <TableSkeleton />}
      <TabPanelWrapper hidden={loading}>
        <TabPanel index={tabIndex}>
          {positionInfo && (
            <PositionInfo
              onClose={() => {
                setCurrentRow(undefined)
                setPositionInfo(undefined)
                query.delete('positionId')
                history.replace({ search: query.toString() })
              }}
              data={positionInfo}
              disableEditBtn={
                !(
                  checkPermissions(Authorities.ITEMS_EDIT_ITEM) &&
                  tabIndex === PositionTab.All
                )
              }
              disableRemoveBtn={
                !(
                  checkPermissions(Authorities.ITEMS_REMOVE_ITEM) &&
                  tabIndex === PositionTab.All
                )
              }
              showMoveToAssignmentsBtn={
                checkPermissions(Authorities.ITEMS_ADD_ITEM_TO_TENDER) &&
                tabIndex === PositionTab.All
              }
              onDelete={(id: string) => handleArchivePositions([id])}
              onEdit={handleEditClick}
              onAddToAssignment={handleMoveToAssignmentsClick}
            />
          )}

          <PathComponent>
            <Path>
              {categoryId && (
                <Title
                  categoryName={positions.categoryName}
                  path={positions.categoryPath}
                />
              )}
            </Path>
          </PathComponent>

          {categoryId && (
            <>
              <Actions>
                {!isCatalogExpanded && (
                  <CollapsedCatalog
                    title='Каталог'
                    items={catalogMapper(categories) as any}
                    onItemClick={id => {
                      handleSelectNode(id as string)
                    }}
                    onSwitch={() => setIsCatalogExpanded(true)}
                    needSwitchBtn
                  />
                )}
                {[ETreeListItemType.FOLDER, ETreeListItemType.GROUP].includes(
                  nodeType!,
                ) && (
                  <ButtonComponent
                    text='Экспортировать'
                    type={EButtonType.DEFAULT}
                    typeIcon={EButtonIcon.DOWNLOAD}
                    hidden={!checkPermissions(Authorities.ITEMS_EXPORT)}
                    onClick={handleExportFolderLevelClick}
                  />
                )}

                {nodeType === ETreeListItemType.LIST &&
                  checkedRows.length === 0 && (
                    <>
                      <ButtonComponent
                        text='Добавить позицию'
                        type={EButtonType.DEFAULT}
                        typeIcon={EButtonIcon.ADD}
                        hidden={
                          !(
                            tabIndex === PositionTab.All &&
                            checkPermissions(Authorities.ITEMS_CREATE_ITEM)
                          )
                        }
                        onClick={handleAddPosition}
                      />
                      <TableUpload
                        hidden={
                          !(
                            tabIndex === PositionTab.All &&
                            checkPermissions(Authorities.ITEMS_IMPORT)
                          )
                        }
                        uploadHandler={handleImportClick}
                      />
                      <ButtonComponent
                        text='Экспорт шаблона'
                        type={EButtonType.DEFAULT}
                        typeIcon={EButtonIcon.DOWNLOAD}
                        hidden={
                          !(
                            tabIndex === PositionTab.All &&
                            checkPermissions(Authorities.ITEMS_IMPORT)
                          )
                        }
                        onClick={handleExportTemplateClick}
                      />
                      {tabIndex === PositionTab.NotPassed && (
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={inTender}
                                onChange={handleToggleInTenderCheckbox}
                                name='inTenderCheckbox'
                                color='primary'
                                size='small'
                              />
                            }
                            label='Есть в ТЗ'
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={notInTender}
                                onChange={handleToggleNotInTenderCheckbox}
                                name='notInTenderCheckbox'
                                color='primary'
                                size='small'
                              />
                            }
                            label='Нет в ТЗ'
                          />
                        </div>
                      )}
                    </>
                  )}
                {checkedRows.length > 0 && (
                  <>
                    <Selected variant='subtitle1' component='div'>
                      {checkedRows.length} Выбрано
                    </Selected>
                    {tabIndex === PositionTab.All && (
                      <ButtonComponent
                        text='Скопировать выбранные позиции'
                        type={EButtonType.DEFAULT}
                        hidden={
                          !checkPermissions(Authorities.ITEMS_CREATE_ITEM)
                        }
                        onClick={handleCreateCopyPosition}
                      />
                    )}
                    <ButtonComponent
                      text='Экспорт'
                      type={EButtonType.DEFAULT}
                      typeIcon={EButtonIcon.DOWNLOAD}
                      hidden={!checkPermissions(Authorities.ITEMS_EXPORT)}
                      onClick={handleExportSelectedPositionClick}
                    />
                    {tabIndex !== PositionTab.Archived && (
                      <ButtonComponent
                        text='Архивировать'
                        type={EButtonType.WARNING}
                        typeIcon={EButtonIcon.DELETE}
                        hidden={
                          !(
                            tabIndex === PositionTab.All &&
                            checkPermissions(Authorities.ITEMS_REMOVE_ITEM)
                          )
                        }
                        onClick={() =>
                          handleArchivePositions(checkedRows as string[])
                        }
                      />
                    )}
                    <ButtonComponent
                      text='Добавить в тендерное задание'
                      type={EButtonType.PRIMARY}
                      typeIcon={EButtonIcon.ADD}
                      hidden={
                        !(
                          checkPermissions(
                            Authorities.ITEMS_ADD_ITEM_TO_TENDER,
                          ) && tabIndex === PositionTab.All
                        )
                      }
                      onClick={handleMoveToAssignmentsClick}
                    />
                  </>
                )}
              </Actions>
              {[ETreeListItemType.LIST, ETreeListItemType.GROUP].includes(
                nodeType!,
              ) &&
              positions.columns.length &&
              !hideTable ? (
                <PositionTable
                  checkedRows={checkedRows}
                  fetchPositionInfo={(positionId: string) => {
                    query.set('positionId', positionId)
                    history.replace({ search: query.toString() })
                    fetchPositionInfo(positionId)
                  }}
                  tableParams={tableParams}
                  handleAddProperty={handleAddProperty}
                  handleEditProperty={handleEditProperty}
                  handleParametersChange={handleParametersChange}
                  handleRemoveProperty={handleRemoveProperty}
                  localStorageTableId={localStorageTableId}
                  positions={positions}
                  setCheckedRows={data => setCheckedRows(data)}
                  tabIndex={tabIndex}
                  nodeType={nodeType}
                  parentGroupId={parentGroupId}
                  loading={loading}
                  updateFlag={updateFlag}
                />
              ) : null}
            </>
          )}
        </TabPanel>
      </TabPanelWrapper>
    </Root>
  )
}

export default Position
