import { TStoreInitialState } from '@grand-tender/types'

import * as I from './ILoader'

const mappers = {
  mapStateToProps: (state: TStoreInitialState): I.StateProps => ({
    dataIsLoading: state.viewState.dataIsLoading,
  }),
}

export default mappers
