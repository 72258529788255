import React from 'react'
import { Skeleton } from '@material-ui/lab'
import {
  CatalogButton,
  CatalogHeader,
  CatalogItem,
  CatalogSkeletonWrapper,
  CatalogTitle,
} from './Skeleton.styled'
import { SkeletonArrow } from '../../icons/SkeletonArrow'

export const CatalogSkeleton = () => (
  <CatalogSkeletonWrapper>
    <CatalogHeader>
      <CatalogTitle width={138} variant='text' />
      <CatalogButton variant='text' />
      <CatalogButton variant='text' />
    </CatalogHeader>
    <CatalogItem>
      <CatalogButton variant='text' />
      <Skeleton variant='text' width={87} />
    </CatalogItem>
    <CatalogItem>
      <SkeletonArrow />
      <CatalogButton variant='text' />
      <Skeleton variant='text' width={87} />
    </CatalogItem>
    <CatalogItem>
      <SkeletonArrow />
      <CatalogButton variant='text' />
      <Skeleton variant='text' width={106} />
    </CatalogItem>
    <CatalogItem>
      <SkeletonArrow />
      <CatalogButton variant='text' />
      <Skeleton variant='text' width={115} />
    </CatalogItem>
    <CatalogItem>
      <SkeletonArrow />
      <CatalogButton variant='text' />
      <Skeleton variant='text' width={97} />
    </CatalogItem>
    <CatalogItem>
      <SkeletonArrow />
      <CatalogButton variant='text' />
      <Skeleton variant='text' width={87} />
    </CatalogItem>
    <CatalogItem>
      <SkeletonArrow />
      <CatalogButton variant='text' />
      <Skeleton variant='text' width={106} />
    </CatalogItem>
    <CatalogItem>
      <SkeletonArrow />
      <CatalogButton variant='text' />
      <Skeleton variant='text' width={115} />
    </CatalogItem>
    <CatalogItem>
      <SkeletonArrow />
      <CatalogButton variant='text' />
      <Skeleton variant='text' width={97} />
    </CatalogItem>
  </CatalogSkeletonWrapper>
)
