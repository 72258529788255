import { connect } from 'react-redux'
import { TStoreInitialState } from '@grand-tender/types'

import * as I from './IAppHeader'
import container from './AppHeaderContainer'
import pl from './AppHeaderPL'

export default connect<
  I.StateProps,
  I.DispatchProps,
  I.OwnProps,
  TStoreInitialState
>(
  container.mapStateToProps,
  container.mapDispatchToProps,
)(pl)
