import {
  ITreeListItem,
  TSearchDataOtherPage,
  TTreeList,
} from '@grand-tender/types'
import { ETreeListItemType } from '@grand-tender/ui'

export const findParentNodeWithTheGroupType = (
  tree: TTreeList,
  id: number,
): ITreeListItem | null => {
  const node = findTreeNode(tree, String(id))
  if (node === null) {
    return null
  }

  if (node.type === ETreeListItemType.GROUP) {
    return node
  }

  if (!node.parentId) {
    return null
  }

  return findParentNodeWithTheGroupType(tree, Number(node.parentId))
}

export const getParentNode = (
  tree: TTreeList,
  id: string,
): ITreeListItem | null => {
  const node = findTreeNode(tree, id)
  if (node === null) {
    return null
  }
  if (!node.parentId) {
    return null
  }

  return findTreeNode(tree, node.parentId)
}

export const findTreeNode = (
  treeData: TTreeList,
  key: string,
): ITreeListItem | null => {
  let result: ITreeListItem | null = null
  for (let i = 0; result === null && i < treeData.length; i++) {
    result = findTreeElement(treeData[i], key)
    if (result) {
      return result
    }
  }

  return result
}

export const getHasChildrenNodeWithTheGroupType = (
  treeItem: ITreeListItem,
): boolean => {
  if (treeItem.type === ETreeListItemType.GROUP) {
    return true
  }

  if (!treeItem.content) {
    return false
  }

  let result = false
  for (let i = 0; i < treeItem.content.length; i++) {
    result = getHasChildrenNodeWithTheGroupType(treeItem.content[i])
  }
  return result
}

const findTreeElement = (
  node: ITreeListItem,
  key: string,
): ITreeListItem | null => {
  if (node.id.toString() === key.toString()) {
    return node
  }
  let result: ITreeListItem | null = null
  for (let i = 0; result === null && i < node.content.length; i++) {
    result = findTreeElement(node.content[i], key)
  }
  return result
}

export const catalogMapper = (catalog: TTreeList) =>
  catalog?.reduce<TTreeList>(
    (acc, { parentId = null, name, id, type, content }) => {
      let children: TTreeList = []
      if (
        (type === ETreeListItemType.FOLDER ||
          type === ETreeListItemType.GROUP) &&
        content?.length
      ) {
        children = catalogMapper(content)
      }
      const obj = {
        name,
        parentId,
        id,
        type,
      }
      return acc.concat(obj as any, children)
    },
    [],
  )

export const searchOptionsMapper = (options: TSearchDataOtherPage[]) =>
  options?.reduce((acc, { categoryPath, itemNames }) => {
    if (itemNames?.length) {
      return acc.concat(
        itemNames.map(item => ({ value: item, group: categoryPath })),
      )
    }
    return acc
  }, [] as any)

export function formatPrice(price: number | string): string {
  return parseFloat(price as string).toLocaleString('ru-RU', {
    style: 'currency',
    currency: 'RUB',
  })
}

export const getNodeTypeById = (
  catalog: TTreeList,
  nodeId: string,
): ETreeListItemType | undefined =>
  catalog.find(catalogItem => Number(catalogItem.id) === Number(nodeId))?.type
