import styled from 'styled-components'
import { Typography } from '@gmini/ui-kit/lib/Typography'

import { TabPanel as TabPanelInit } from '../../components/TabPanel/TabPanel'
import { TitleComponent } from '../../components/TitleComponent/TitleComponent'

export const CatalogWrapper = styled.div`
  margin: 0 15px;
`

export const TabPanelWrapper = styled.div`
  margin: 0 15px;
  flex: 1 1 auto;
  width: 100px;
`

export const Root = styled.div`
  display: flex;
  font-family: 'Proxima Nova Regular';
  margin: 0 -15px;
  height: 100%;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  & button {
    margin-right: 8px;
  }
`

export const Selected = styled(Typography)`
  flex: 1 1 auto;
`

export const Title = styled(TitleComponent)`
  margin-top: 20px !important;
`

export const TabPanel = styled(TabPanelInit)`
  display: flex;
  flex-direction: column;
`

export const Table = styled.div`
  & .MuiDataGrid-cell.rowError {
    background-color: #fce6e6;
  }
  & .MuiDataGrid-cell.rowError:nth-child(even) {
    background-color: #f3dddd;
  }
`

export const Row = styled.div`
  color: red;
`

export const PathComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Path = styled.div`
  width: auto;
  max-width: 1200px;
`
