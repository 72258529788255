import { useHistory } from 'react-router-dom'
import { useQuery } from '@gmini/utils'

import { AppBar, Tab, Tabs, Toolbar } from '@material-ui/core'
import { ButtonComponent, EButtonIcon, EButtonType } from '@grand-tender/ui'
import { Authorities } from '@grand-tender/auth-service'
import { EPageType } from '@grand-tender/types'
import React from 'react'

import { usePermissions } from '../../../hooks/usePermissions'

import * as I from './IAppHeader'

import { useStyles } from './Style'
import { AppHeaderSearchAndFilter } from './AppHeaderSearchAndFilter/AppHeaderSearchAndFilter'

type AppHeaderSuppliersContainerProps = I.StateProps &
  I.DispatchProps &
  I.OwnProps & {
    showDrawerHandler: (isShow: boolean) => void
    flagBackPageHandler: () => void
    refreshCategoryId: () => void
    a11yProps: (index: number) => Record<string, unknown>
    flagBackPage: boolean
    openDrawer: boolean
    setFlagBackPage: React.Dispatch<React.SetStateAction<boolean>>
    search: boolean
  }

export const AppHeaderSuppliersContainer = ({
  ...props
}: AppHeaderSuppliersContainerProps) => {
  const {
    showDrawerHandler,
    flagBackPageHandler,
    refreshCategoryId,
    a11yProps,
    flagBackPage,
    openDrawer,
    categoryIdSuppliers,
    setFlagBackPage,
    changeActiveTab,
    activeTabIndex,
    shouldResetFilter,
    filterFields,
    getFilterFields,
    getSearchOtherPageFilterItems,
    availableColumns,
    search,
  } = props

  const classes = useStyles()
  const query = useQuery()

  const checkPermissions = usePermissions()
  const history = useHistory()

  return (
    <AppBar position='static' className={classes.appBar}>
      <Toolbar>
        {search ? (
          <div className={classes.searchBackBlock}>
            <ButtonComponent
              text={''}
              type={EButtonType.DEFAULT}
              typeIcon={EButtonIcon.ARROW_BACK}
              noMargin
              hidden={false}
              onClick={(): void => {
                history.push({
                  pathname: `/suppliers/${activeTabIndex.suppliers}`,
                  search: query.toString(),
                })
                setFlagBackPage(true)
              }}
            />
            <h5>Поиск по поставщикам</h5>
          </div>
        ) : (
          <Tabs
            className={classes.tabs}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons='auto'
            value={activeTabIndex.suppliers}
            onChange={(_, value: number) =>
              changeActiveTab(value, refreshCategoryId)
            }
            aria-label='view-tabs'
          >
            {checkPermissions(Authorities.SUPPLIERS_SHOW_ALL_ITEMS) && (
              <Tab label='Все' {...a11yProps(0)} />
            )}
            {checkPermissions(Authorities.SUPPLIERS_SHOW_TAB_ACTIVE) && (
              <Tab wrapped label='Активные' {...a11yProps(1)} />
            )}
          </Tabs>
        )}
        {checkPermissions(Authorities.SUPPLIERS_SEARCH_AND_FILTER_ITEMS) && (
          <AppHeaderSearchAndFilter
            openDrawer={openDrawer}
            shouldResetFilter={shouldResetFilter}
            filterFields={filterFields}
            flagBackPage={flagBackPage}
            flagBackPageHandler={flagBackPageHandler}
            getFilterFields={getFilterFields}
            showDrawerHandler={showDrawerHandler}
            getSearchOtherPageFilterItems={getSearchOtherPageFilterItems}
            refreshCategoryId={refreshCategoryId}
            categoryId={categoryIdSuppliers}
            availableColumns={availableColumns}
            currentPage={EPageType.PROVIDER}
          />
        )}
      </Toolbar>
    </AppBar>
  )
}
