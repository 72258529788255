import React from 'react'
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import {
  ETreeListItemType,
  LocalStorageHelper,
  settingIcon,
} from '@grand-tender/ui'
import { ArrowForwardIos, ExpandLess } from '@material-ui/icons'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { ITreeListItem } from '@grand-tender/types'
import { useLocation } from 'react-router-dom'

import usePositionCatalogStyles from '../../../styles/Style'

export type CatalogViewItemProps = {
  open: { [key: string]: boolean }
  categoryId: number
  node: ITreeListItem
  handleTreeItemClick: (
    nodeId: string,
    event: React.MouseEvent<HTMLElement>,
  ) => void
  onSelectNode: (node?: ITreeListItem) => void
  handleMenuOpen: (nodeId: number) => (e: React.MouseEvent<HTMLElement>) => void
  level: number
}

const getNodeName = (node: ITreeListItem) =>
  `${node.name}${node.count !== undefined ? ` (${node.count})` : ''}`

export const CatalogViewItem: React.FC<CatalogViewItemProps> = ({
  open,
  node,
  handleTreeItemClick,
  onSelectNode,
  handleMenuOpen,
  level,
  categoryId,
}) => {
  const location = useLocation()
  const classes = usePositionCatalogStyles()

  return (
    <div key={node.id}>
      <ListItem
        button
        onClick={(event: React.MouseEvent<HTMLElement>): void => {
          if (node.type !== ETreeListItemType.LIST) {
            handleTreeItemClick(node.id, event)
            if (location.pathname.indexOf('positions') !== -1) {
              onSelectNode(node)
            }
          } else {
            LocalStorageHelper.set<string>(
              `${location.pathname}_categoryId`,
              node.id,
            )

            onSelectNode(node)
          }
        }}
        className={classes.listItem}
        selected={Number(node.id) === categoryId}
        disableGutters={false}
        style={{ paddingLeft: `${level === 0 ? 5 : level * 30}px` }}
      >
        {node.type === ETreeListItemType.LIST ? null : open[node.id] ? (
          <ExpandLess
            style={{
              fontSize: '20px',
              color: '#5162d0',
            }}
          />
        ) : (
          <ArrowForwardIos
            style={{
              fontSize: '12px',
              marginRight: '5px',
              color: '#5162d0',
            }}
          />
        )}
        <ListItemText primary={getNodeName(node)} />
        {node.type === ETreeListItemType.GROUP && (
          <ListItemSecondaryAction>
            {' '}
            <IconButton onClick={handleMenuOpen(Number(node.id))}>
              {settingIcon}
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {node.type !== ETreeListItemType.LIST && (
        <Collapse in={open[node.id]} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {node.content.map((data: ITreeListItem) => (
              <CatalogViewItem
                key={data.id}
                open={open}
                categoryId={categoryId}
                node={data}
                handleTreeItemClick={handleTreeItemClick}
                onSelectNode={onSelectNode}
                handleMenuOpen={handleMenuOpen}
                level={level + 1}
              />
            ))}
          </List>
        </Collapse>
      )}
    </div>
  )
}
