/* eslint-disable import/no-anonymous-default-export */
import * as _ from 'lodash'
import {
  IRow,
  TSupplier,
  TTableData,
  TSuppliersFilters,
} from '@grand-tender/types'
import { EActionTypes } from '@grand-tender/ui'

import { IBaseActionCreator } from '../../actions/BaseAction'

export type IResetSuppliersAction = IBaseActionCreator<
  EActionTypes.RESET_SUPPLIERS,
  null
>

export type ISetSelectedSuppliersAction = IBaseActionCreator<
  EActionTypes.SET_SELECTED_SUPPLIERS,
  string[]
>

export type ISetSuppliersAction = IBaseActionCreator<
  EActionTypes.SET_SUPPLIERS,
  TTableData
>

export type IAddSupplierAction = IBaseActionCreator<
  EActionTypes.ADD_SUPPLIER,
  IRow
>

export type IEditSupplierAction = IBaseActionCreator<
  EActionTypes.EDIT_SUPPLIER,
  IRow
>

export type IDeleteSuppliersAction = IBaseActionCreator<
  EActionTypes.REMOVE_SUPPLIERS,
  string
>

export type ISetSuppliersCategoryAction = IBaseActionCreator<
  EActionTypes.SET_CATEGORY_SUPPLIER,
  string
>

export type ISetSuppliersFiltersAction = IBaseActionCreator<
  EActionTypes.SET_SUPPLIERS_FILTERS,
  TSuppliersFilters
>

export type TSuppliersAction =
  | ISetSuppliersAction
  | IAddSupplierAction
  | IEditSupplierAction
  | IDeleteSuppliersAction
  | ISetSuppliersCategoryAction
  | IResetSuppliersAction
  | ISetSelectedSuppliersAction
  | ISetSuppliersFiltersAction

const initialState: TSupplier = {
  categoryId: '',
  data: {
    columns: [],
    data: [],
    pagination: {
      page: 1,
      rowsPerPage: 20,
      total: 1,
    },
  },
  selectedIds: [],
  suppliersFilters: {
    agreedSuppliers: true,
    notAgreedSuppliers: true,
    expiredSuppliers: true,
  },
}

export default (state = initialState, action: TSuppliersAction): TSupplier => {
  switch (action.type) {
    case EActionTypes.RESET_SUPPLIERS: {
      return initialState
    }
    case EActionTypes.SET_SUPPLIERS: {
      const newState = _.chain(state)
        .cloneDeep()
        .extend({ data: action.payload })
        .value()
      return newState
    }
    case EActionTypes.ADD_SUPPLIER:
      return _.chain(state)
        .cloneDeep()
        .extend({
          data: {
            columns: [...state.data.columns],
            data: [action.payload, ...state.data.data],
            pagination: {
              ...state.data.pagination,
              total: state.data.pagination.total + 1,
            },
          },
        })
        .value()
    case EActionTypes.EDIT_SUPPLIER: {
      const clonedState = _.cloneDeep(state)

      const row = clonedState.data.data.find(
        item => item.rowId === action.payload.rowId,
      )

      if (row) {
        _.extend(row, { ...action.payload })
      }

      return clonedState
    }
    case EActionTypes.REMOVE_SUPPLIERS: {
      return _.chain(state)
        .cloneDeep()
        .extend({
          data: {
            columns: [...state.data.columns],
            data: [
              ...state.data.data.filter(
                item => action.payload.indexOf(item.rowId) === -1,
              ),
            ],
            pagination: { ...state.data.pagination },
          },
        })
        .value()
    }
    case EActionTypes.SET_CATEGORY_SUPPLIER: {
      return _.chain(state)
        .cloneDeep()
        .extend({ categoryId: action.payload, selectedIds: [] })
        .value()
    }
    case EActionTypes.SET_SELECTED_SUPPLIERS: {
      return { ...state, selectedIds: action.payload }
    }
    case EActionTypes.SET_SUPPLIERS_FILTERS: {
      return { ...state, suppliersFilters: action.payload }
    }
    default:
      return state
  }
}
