import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  loaderBackground: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    background: '#F0F8FF',
    opacity: '0.3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100000,
    '& .MuiCircularProgress-root': {
      margin: '0 200px 0 0',
    },
  },
}))

export default useStyles
