/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import {
  Dialog,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core'
import * as _ from 'lodash'
import { IColumn, IKeyValuePair } from '@grand-tender/types'
import {
  EButtonType,
  EColumnType,
  EEditableModalType,
} from '../../../../../constants'
import { ModalTitle } from '../../../../BaseModal/ModalTitle/ModalTitle'
import { ModalContent } from '../../../../BaseModal/ModalContent/ModalContent'
import { ModalActions } from '../../../../BaseModal/ModalActions/ModalActions'
import { useStyles } from './Style'
import * as I from './IEditablePropertyModal'
import ButtonComponent from '../../../../Button/Button'
import { SectionsList } from './Sections/Sections'

const EditablePropertyModal: React.FC<I.OwnProps> = ({
  open,
  type,
  title,
  model,
  useSections = false,
  onAddSection,
  onEditSection,
  onRemoveSection,
  getSectionsDataSource,
  onClose,
  onSave,
}): React.ReactElement => {
  const ColumnType = EColumnType

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapEnumToSelect: { [key in EColumnType]: any } = {
    [EColumnType.BOOLEAN]: 'Логический',
    [EColumnType.CURRENCY]: 'Денежный',
    [EColumnType.DATE]: 'Дата',
    [EColumnType.DOUBLE]: 'Дробный',
    [EColumnType.INTEGER]: 'Целый',
    [EColumnType.PHONE]: 'Телефон',
    [EColumnType.STRING]: 'Строковый',
    [EColumnType.LIST]: 'Список',
    [EColumnType.TREE_LIST]: 'Древовидный список',
  }

  const classes = useStyles()

  const [state, setState] = useState<{ [key in keyof IColumn]: any }>({
    ...model,
    unit: model.unit ?? '',
  })
  const [errors, setErrors] = useState<{ [key: string]: string }>({
    title: '',
    type: '',
    unit: '',
    section: '',
  })

  const [hideModal, setHideModal] = useState<boolean>(false)

  const handleStringPropChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof IColumn,
  ): void => {
    validate(key !== 'unit', event.target.value, key)

    const newState = _.cloneDeep(state)
    newState[key] = event.target.value
    if (
      key === 'type' &&
      [
        EColumnType.BOOLEAN,
        EColumnType.PHONE,
        EColumnType.DATE,
        EColumnType.CURRENCY,
      ].includes(event.target.value as EColumnType)
    ) {
      newState.unit = ''
    }

    setState(newState)
  }

  const handleBooleanPropChanged = (key: keyof IColumn): void => {
    const newState = _.cloneDeep(state)
    newState[key] = !newState[key]
    setState(newState)
  }

  const validate = (required: boolean, value: string, key: string): string => {
    const msg =
      required && validateRequiredProp(value) ? 'Обязательное поле' : ''

    setErrors({ ...errors, [key]: msg })
    return msg
  }

  const validateRequiredProp = (value: string): boolean =>
    !value || !value.trim()

  const validateForm = (): { [key: string]: string } => {
    const error: { [key: string]: string } = {}
    const keysToValidate = ['title', 'type', 'unit']
    const pairs = _.toPairs(state)

    if (useSections && state.section) {
      keysToValidate.push('section')
    }

    pairs.forEach(pair => {
      if (keysToValidate.indexOf(pair[0]) !== -1) {
        const msg = validate(
          ['unit'].indexOf(pair[0]) === -1,
          `${pair[1]}`,
          pair[0],
        )
        if (msg.length) {
          error[pair[0]] = msg
        }
      }
    })

    return error
  }

  const saveBtnHandler = (): void => {
    const error = validateForm()

    if (_.keys(error).length) {
      setErrors({ ...errors, ...error })
      return
    }

    onSave(state, state.section?.key)
  }

  const onDialogClose = (): void => {
    setErrors({
      title: '',
      type: '',
      unit: '',
      section: '',
    })
    onClose()
  }

  const updateSectionState = (value: IKeyValuePair | null): void =>
    setState({ ...state, section: value })

  const updateSectionError = (message: string): void =>
    setErrors({ ...errors, section: message })

  const toggleParentCtrl = (hide: boolean): void => setHideModal(hide)

  return (
    <div>
      {open && (
        <Dialog
          onClose={onDialogClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          disableBackdropClick={true}
          className={`${classes.root}${hideModal ? ` ${classes.hidden}` : ''}`}
        >
          <ModalTitle id='customized-dialog-title' onClose={onDialogClose}>
            {type === EEditableModalType.INSERT ? title.insert : title.edit}
          </ModalTitle>
          <ModalContent dividers>
            <FormControlLabel
              className='fullWidth'
              control={
                <TextField
                  required={true}
                  error={errors.title.length > 0}
                  helperText={errors.title}
                  fullWidth
                  variant='filled'
                  value={state.title}
                  placeholder='Введите название'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    handleStringPropChanged(e, 'title')
                  }
                  inputProps={{ maxLength: 64 }}
                />
              }
              label='Название'
              labelPlacement='top'
            />
            <div className='propContainer'>
              <FormControlLabel
                className='prop'
                control={
                  <TextField
                    type='text'
                    select
                    disabled={type === EEditableModalType.EDIT}
                    required={false}
                    error={errors.type.length > 0}
                    helperText={errors.type}
                    variant='filled'
                    value={state.type}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      handleStringPropChanged(e, 'type')
                    }
                  >
                    {_.keys(EColumnType)
                      .filter(
                        (v: string) =>
                          EColumnType[v as keyof typeof EColumnType] !==
                          EColumnType.LIST,
                      )
                      .filter(
                        (v: string) =>
                          EColumnType[v as keyof typeof EColumnType] !==
                          EColumnType.TREE_LIST,
                      )
                      .map((v: string) => (
                        <MenuItem
                          key={v}
                          value={ColumnType[v as keyof typeof EColumnType]}
                        >
                          {
                            mapEnumToSelect[
                              ColumnType[v as keyof typeof EColumnType]
                            ]
                          }
                        </MenuItem>
                      ))}
                  </TextField>
                }
                label={'Тип'}
                labelPlacement='top'
              />
              <FormControlLabel
                className='prop'
                control={
                  <TextField
                    type='text'
                    required={false}
                    disabled={[
                      EColumnType.CURRENCY,
                      EColumnType.DATE,
                      EColumnType.PHONE,
                      EColumnType.BOOLEAN,
                    ].includes(state.type)}
                    error={errors.unit.length > 0}
                    helperText={errors.unit}
                    variant='filled'
                    value={state.unit}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      handleStringPropChanged(e, 'unit')
                    }
                  />
                }
                label={'Единица измерения'}
                labelPlacement='top'
              />
            </div>
            <FormControlLabel
              className='prop'
              control={
                <Switch
                  checked={state.required || false}
                  onChange={(): void => handleBooleanPropChanged('required')}
                  name={'required'}
                />
              }
              label={'Обязательное'}
              labelPlacement='top'
            />
            {useSections && onAddSection && getSectionsDataSource && (
              <SectionsList
                initValue={state.section}
                disabled={false}
                helperText={errors.section}
                onAddSection={onAddSection}
                onEditSection={onEditSection}
                onRemoveSection={onRemoveSection}
                getSectionsDataSource={getSectionsDataSource}
                updateModel={updateSectionState}
                updateError={updateSectionError}
                toggleParentCtrl={toggleParentCtrl}
              />
            )}
          </ModalContent>
          <ModalActions>
            <div className={classes.buttonContainer}>
              <ButtonComponent
                text='Отменить'
                type={EButtonType.DEFAULT}
                onClick={onDialogClose}
              />
              <ButtonComponent
                text={
                  type === EEditableModalType.INSERT
                    ? 'Добавить свойство'
                    : 'Сохранить'
                }
                type={EButtonType.PRIMARY}
                onClick={saveBtnHandler}
              />
            </div>
          </ModalActions>
        </Dialog>
      )}
    </div>
  )
}

export default EditablePropertyModal
