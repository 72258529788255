import { makeStyles, Theme } from '@material-ui/core/styles'

const usePositionCatalogStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    margin: '25px 0 0 0',
    minWidth: 270,
    background: '#f9f9fb',
    minHeight: '180px',
    borderRadius: '5px',
    '& .MuiListSubheader-gutters': {
      paddingRight: '0',
    },
  },
  listTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    display: 'flex',
    color: ' #616284',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '44px',
    width: 'inherit',
    maxWidth: 'inherit',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      padding: '0',
      margin: '0 15px',
    },
    '& .MuiSvgIcon-root': {
      cursor: 'pointer',
      color: 'white',
      fontSize: '15px',
    },
    '& .MuiDivider-root': {
      height: '44px',
    },
  },
  dividedHeight: {
    height: '1.5px',
    margin: '0 0 20px 0',
  },
  catalog: {
    padding: '0 15px',
    marginTop: '-10px',
    maxHeight: 'calc(100vh - 230px)',
    overflowY: 'auto',
    '& .MuiTypography-root': {
      wordBreak: 'break-word',
    },
    '&::-webkit-scrollbar': {
      width: '8px !important',
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    '& .Mui-selected': {
      borderRadius: '5px !important',
      backgroundColor: '#f2f0f8 !important',
    },
  },
  listItem: {
    display: 'flex',
    height: 'auto',
    minHeight: '45px',
    width: '270px',
    '&:hover, &:active, &:focus': {
      backgroundColor: '#f2f0f8',
      borderRadius: '5px',
      height: 'auto',
      color: '#4c5ecf',
    },
    '& .MuiListItemText-primary': {
      fontSize: '14px',
      color: '#05082E',
      maxWidth: '200px',
    },
  },
  listItemInCollapse: {
    paddingLeft: theme.spacing(4.5),
  },
  settingsTree: {
    margin: '0 0 0 24px',
    minWidth: 270,
    minHeight: 'calc(100vh - 140px)',
    borderRadius: '5px',
    maxHeight: 'calc(100vh - 140px)',
    overflowY: 'auto',
    '& .MuiTypography-root': {
      wordBreak: 'break-word',
      width: '240px',
    },
    '&::-webkit-scrollbar': {
      width: '8px !important',
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    '& .MuiListItem-root': {
      paddingLeft: '5px',
      paddingRight: '5px',
    },
  },
  settingsMenu: {
    '& li': {
      fontFamily: 'Proxima Nova Regular',
    },
    // '& .menu-title': {
    //     fontSize: '12px',
    //     fontWeight: 'bold'
    // },
  },
  moveItemTree: {
    margin: '0 0 0 10px',
    minWidth: 270,
    borderRadius: '5px',
    maxHeight: '625px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px !important',
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1 !important',
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#888 !important',
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555 !important',
    },
    '& .MuiListItem-root': {
      paddingRight: '10px !important',
    },
  },
  drawerTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '20px 24px',
    '& h5': {
      fontSize: '24px',
      color: '#000',
      fontWeight: 'normal',
      margin: 0,
      lineHeight: '20px',
      height: '20px',
    },
    '& .MuiSvgIcon-root': {
      cursor: 'pointer',
    },
  },
  drawerList: {
    '& .MuiDrawer-paper': {
      minWidth: '405px',
    },

    '& .MuiDrawer-paperAnchorLeft': {
      marginLeft: '120px',
    },
    '& .MuiButton-label': {
      fontWeight: 'normal',
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '& hr': {
      margin: '20px 24px',
    },
    '& .MuiList-padding': {
      maxWidth: '625px',
      padding: 0,
    },
  },
  drawerFooter: {
    margin: '0 24px',
  },
  menuButton: {
    padding: 0,
    borderRadius: '5px',
    width: '27px',
    height: '27px',
    '&.active': {
      background: '#4C5ECF',
      color: '#FFFFFF',
    },
  },
  secondPartFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '0 -5px',
    '& button': {
      margin: '0 5px',
    },
    height: '59px',
    boxShadow: '0px -5px 30px 0px rgba(50, 50, 50, 0.05)',
    minWidth: '335px',
  },
  closeButton: {
    '& .MuiSvgIcon-root': {
      cursor: 'pointer',
    },
  },
  checkedRow: {
    '&:hover, &:focus, &:active': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '&$checkboxChecked, &$checkboxChecked:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '& .MuiTableCell-root': {
      border: '0',
    },
    '&.MuiCheckbox-colorPrimary': {
      color: '#E2E4E9',
      '&.Mui-checked': {
        color: '#4C5ECF',
      },
    },
  },
  checkboxChecked: {},
}))

export default usePositionCatalogStyles
