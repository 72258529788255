import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: '-5px',
  },
  header: {
    fontSize: '24px',
    lineHeight: '29px',
    fontFamily: 'Proxima Nova Regular',
    color: '#1B2B4B',
    wordBreak: 'break-all',
  },
  content: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'Proxima Nova Regular',
    color: '#535F77',
    marginTop: '5px',
    wordBreak: 'break-all',
  },
}))

export default useStyles
