import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { ModalTitle } from '@grand-tender/ui/src/components/BaseModalAddContextPositions/ModalTitle/ModalTitle'
import { EButtonType, ButtonComponent } from '@grand-tender/ui'

type TAlertDialog = {
  open: boolean
  handleClose: () => void
  handleOk: () => void
}

export const AlertDialog: React.FC<TAlertDialog> = ({
  open,
  handleClose,
  handleOk,
}) => (
  <Dialog open={open} onClose={handleClose} className='condition-dialog-modal'>
    <ModalTitle id='alert-dialog-title' onClose={handleClose}>
      Вы действительно хотите удалить условие ?
    </ModalTitle>
    <div className='footer'>
      <ButtonComponent
        text='Отменить'
        type={EButtonType.DEFAULT}
        onClick={handleClose}
      />
      <ButtonComponent
        text='Удалить'
        type={EButtonType.PRIMARY}
        onClick={handleOk}
      />
    </div>
  </Dialog>
)
