import React, { useState, useEffect, useMemo, useCallback } from 'react'
import cn from 'classnames'
import { toastr } from 'react-redux-toastr'
import { useParams } from 'react-router'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { Typography } from '@material-ui/core'
import {
  CatalogSearch,
  EAPIResponseStatus,
  EButtonType,
  EButtonIcon,
} from '@grand-tender/ui'
import {
  TFilterItems,
  TPositionModel,
  TAgreementCategoryRequest,
} from '@grand-tender/types'

import ImprovedTableHeadDisplayColumns from '@grand-tender/ui/src/components/ImprovedTable/ImprovedTableHead/ImprovedTableHeadDisplayColumns/ImprovedTableHeadDisplayColumns'
import { XGridTable } from '@ifellow/ui-library'
import { TableParams } from '@ifellow/ui-library/dist/components/x-grid-table/x-grid-table'
import { columnsMapper, rowsMapper } from '@grand-tender/ui/src/utils/xgrid'
import { debounce } from 'lodash'
import { PositionService } from '@grand-tender/position-service'
import ButtonComponent from '@grand-tender/ui/src/components/Button/Button'

import { TitleComponent } from '../../../components/TitleComponent/TitleComponent'
import { PositionInfo } from '../../../components/position-info/position-info'

import { PropsFromRedux } from './agreement-view-container'
import useStyles from './style'

const AgreementViewPL: React.FC<PropsFromRedux> = ({
  agreement,
  positions,
  getAgreementCategoryPositions,
  getAgreementDetail,
  exportPositions,
}): React.ReactElement => {
  const classes = useStyles()
  const { agreementId } = useParams<{ agreementId: string }>()

  const [tableParams, setTableParams] = useState<TableParams>({
    size: 10,
    page: 0,
    sort: {
      field: '',
      sort: 'asc',
    },
    filters: [],
  })

  const [isCatalogExpanded, setIsCatalogExpanded] = useState(true)
  const [shouldGetData, setShouldGetData] = useState<boolean>(true)
  const [categoryId, setCategory] = useState<string>('')
  const [positionInfo, setPositionInfo] = useState<TPositionModel | undefined>()

  const [xgridRows, xgridColumns] = useMemo(
    () => [
      rowsMapper(positions.data),
      columnsMapper({
        columns: positions.columns.map(c => ({
          ...c,
          hide: false,
          width: 150,
          sortOrder: null,
        })),
      }),
    ],
    [positions.data, positions.columns],
  )
  const localStorageTableId = useMemo(() => `agreement-view-${categoryId}`, [
    categoryId,
  ])
  const [checkedRows, setCheckedRows] = useState<Array<string | number>>([])

  const getCurrentRequestParams = useCallback(() => {
    const params: TAgreementCategoryRequest = {
      pageData: {
        page: tableParams.page + 1,
        perPage: tableParams.size,
        ascending: tableParams.sort?.sort === 'asc',
        fieldKey: tableParams.sort?.field,
      },
      itemConditions: tableParams.filters as TFilterItems[],
      fetchCategories: true,
      table: 'items',
    }
    return params
  }, [tableParams])

  const getInitRequestParams = useCallback(() => {
    const params: TAgreementCategoryRequest = {
      pageData: {
        page: 1,
        perPage: tableParams.size,
      },
      fetchCategories: true,
      table: 'items',
    }
    return params
  }, [tableParams.size])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleParametersChange = useCallback(
    debounce((params: TableParams) => {
      const curParams = getCurrentRequestParams()
      getAgreementCategoryPositions({
        agreementId: agreement.id,
        categoryId,
        params: {
          ...curParams,
          pageData: {
            ...curParams.pageData,
            page: params.page + 1,
            perPage: params.size,
            ascending: params.sort?.sort === 'asc',
            fieldKey: params.sort?.field,
          },
          itemConditions: params.filters as TFilterItems[],
        },
      })
      setTableParams(params)
    }, 500),
    [categoryId],
  )

  const fetchPositionInfo = useCallback(async (id: string) => {
    const [err, detail] = await PositionService.getPositionData(id)
    if (
      !err &&
      detail.data.status === EAPIResponseStatus.SUCCESS &&
      detail.data.data
    ) {
      setPositionInfo(detail.data.data)
    } else {
      const error =
        !err && detail.data.status === EAPIResponseStatus.ERROR
          ? detail.data.message || ''
          : // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (detail as any).data || ''
      toastr.error('', error || 'При обращении к серверу произошла ошибка!')
    }
  }, [])

  useEffect(() => {
    if (shouldGetData) {
      getAgreementDetail({
        agreementId,
        callback: () => {
          setShouldGetData(false)
        },
      })
      setIsCatalogExpanded(true)
    }
    // eslint-disable-next-line
  }, [shouldGetData])

  const handleExportPositions = useCallback(() => {
    exportPositions({ ids: checkedRows as string[] })
  }, [exportPositions, checkedRows])

  const handleSelectNode = React.useCallback(
    (nodeId: string): void => {
      setCategory(nodeId)
      getAgreementCategoryPositions({
        agreementId: agreement.id,
        categoryId: nodeId,
        params: getInitRequestParams(),
      })
    },
    [agreement.id, getAgreementCategoryPositions, getInitRequestParams],
  )

  return (
    <div className={classes.pageRoot}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          margin: '0',
        }}
      >
        {agreement && agreement.supplier && (
          <div className={classes.agreementInfo}>
            <Typography
              className={classes.agreementName}
              variant='subtitle1'
              component='div'
            >
              {agreement.agreement.data.provider} до{' '}
              {agreement.agreement.data.expire &&
                format(parseISO(agreement.agreement.data.expire), 'dd.MM.yyyy')}
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.root}>
        {positionInfo && (
          <PositionInfo
            onClose={() => {
              setPositionInfo(undefined)
            }}
            data={positionInfo}
            disableEditBtn={true}
            disableRemoveBtn={true}
            showMoveToAssignmentsBtn={false}
          />
        )}
        {isCatalogExpanded && (
          <div className='flex-item'>
            <CatalogSearch
              onSelectNode={handleSelectNode}
              setCatalogStateArgeements={setIsCatalogExpanded}
              categoriesData={agreement.category}
              categoryId={categoryId}
              catalogStateArgeements={isCatalogExpanded}
            />
          </div>
        )}
        <div className={cn('flex-item tab-panel', classes.fullHeight)}>
          {positions &&
            categoryId &&
            positions.columns.length > 0 &&
            !shouldGetData && (
              <>
                <TitleComponent
                  categoryName={positions.categoryName}
                  path={positions.categoryPath}
                  className={classes.title}
                />
                <div className={classes.actions}>
                  {checkedRows.length > 0 && (
                    <>
                      <Typography
                        className={classes.selected}
                        variant='subtitle1'
                        component='div'
                      >
                        {checkedRows.length} Выбрано
                      </Typography>
                      <ButtonComponent
                        text='Выгрузить позиции'
                        type={EButtonType.DEFAULT}
                        typeIcon={EButtonIcon.DOWNLOAD}
                        onClick={handleExportPositions}
                      />
                    </>
                  )}
                </div>
                <XGridTable
                  renderCustomColumn={({ onVisibilityChange, columns }) => {
                    const mappedColumns = positions.columns.filter(
                      column => !column.hidden,
                    )
                    const visibleColumns = mappedColumns.filter(
                      column =>
                        !column.section &&
                        columns.some(
                          col => col.field === String(column.key) && !col.hide,
                        ),
                    )
                    return (
                      <ImprovedTableHeadDisplayColumns
                        columns={mappedColumns}
                        onColumnVisibilityChanged={(_, { key }) =>
                          onVisibilityChange(String(key))
                        }
                        visibleColumns={visibleColumns}
                        showEditPropertyButtons={false}
                        showSections={true}
                        isRowChecked={true}
                        offMenu={true}
                      />
                    )
                  }}
                  selectionModel={checkedRows}
                  rowsPerPageOptions={[10, 20, 50]}
                  pageSize={positions.pagination.rowsPerPage}
                  pageStart={positions.pagination.page - 1}
                  mode='server'
                  rows={xgridRows}
                  columns={xgridColumns}
                  rowCount={positions.pagination.total}
                  disableFiltering={false}
                  onRowDoubleClick={({ id }) => fetchPositionInfo(id as string)}
                  onParametersChange={handleParametersChange}
                  onSelectionModelChange={selectionModel => {
                    setCheckedRows(selectionModel)
                  }}
                  localStorageCache={localStorageTableId}
                  withSettings
                />
              </>
            )}
        </div>
      </div>
    </div>
  )
}

export default AgreementViewPL
