import { apiClient } from '@grand-tender/base-api-service/src'

import { IAPIResult } from '@grand-tender/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { setDataLoading } from '../../../../actions/RequestWrapper'

export const getColumns = createAsyncThunk(
  'catalog/getColumns',
  async (id: number, { dispatch, rejectWithValue }) => {
    dispatch(setDataLoading(true))

    const [err, res] = await apiClient.post<any, IAPIResult<{ columns: any }>>({
      path: '/item/page',

      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        categoryId: id,
        page: 1,
        perPage: 10,
      },
    })
    dispatch(setDataLoading(false))

    if (err) {
      throw rejectWithValue(err)
    }

    if (res.data.data) {
      return res.data.data.columns
    }
  },
)
