/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useLocation, useHistory, useParams, Redirect } from 'react-router-dom'
import { CssBaseline } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { Authorities } from '@grand-tender/auth-service'

import { Maintenance } from '@gmini/ui-kit'

import { Route, Switch } from 'react-router'

import PositionPL from '../../pages/position/position-container'
import PositionSearch from '../../pages/position-search/position-search-container'
import SuppliersPL from '../../pages/suppliers/supplier-container'
import AgreementPL from '../../pages/agreement/list/agreement-container'
import AgreementViewPL from '../../pages/agreement/view/agreement-view-container'
import AgreementSearchPL from '../../pages/agreement-search/list/agreement-search-container'
import AssignmentsListPL from '../../pages/assignment/list/assignment-list-container'
import AssignmentViewSearchPL from '../../pages/assignment-search/assignment-view-search-container'
import AssignmentViewPL from '../../pages/assignment/view/assignment-view-container'
import { getMaintenance } from '../../reducers/maintenance/maintenance-selectors'

import { getCurrentUser } from '../../actions'
import { useAppDispatch, useAppSelector } from '../../store'
import { usePermissions } from '../../hooks/usePermissions'

import useStyles from './Style'
import AppHeader from './AppHeader/AppHeader'
import SidebarPL from './Sidebar/SidebarPL'

export const App = () => {
  const history = useHistory()
  const { tab } = useParams<{ tab: string }>()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const maintenanceEnabled = useSelector(getMaintenance)
  const { permissions } = useAppSelector(state => state.user)
  const checkPermissions = usePermissions()

  const [shouldResetFilter, setShouldResetFilter] = useState<boolean>(false)
  const [activeTabIndex, setActiveTabIndex] = useState({
    positions: history.location.pathname.includes('positions')
      ? Number(tab)
      : 0,
    suppliers: history.location.pathname.includes('suppliers')
      ? Number(tab)
      : 0,
    agreements: history.location.pathname.includes('agreements')
      ? Number(tab)
      : 0,
    assignments: history.location.pathname.includes('assignments')
      ? Number(tab)
      : 0,
  })

  const location = useLocation()

  useEffect(() => {
    dispatch(getCurrentUser())
  }, [dispatch])

  const changeActiveTab = React.useCallback(
    (newValue: number, refreshCategoryId?: () => void): void => {
      if (location.pathname.includes('/positions')) {
        setActiveTabIndex({ ...activeTabIndex, positions: newValue })
        history.push(`/positions/${newValue}`)
      }
      if (location.pathname.includes('/suppliers')) {
        setActiveTabIndex({ ...activeTabIndex, suppliers: newValue })
        history.push(`/suppliers`)
      }
      if (location.pathname.includes('/agreements')) {
        setActiveTabIndex({ ...activeTabIndex, agreements: newValue })
        history.push(`/agreements/${newValue}`)
      }
      if (location.pathname.includes('/assignments')) {
        setActiveTabIndex({ ...activeTabIndex, assignments: newValue })
        history.push(`/assignments/${newValue}`)
      }
      if (refreshCategoryId) {
        refreshCategoryId()
      }
    },
    [activeTabIndex, location.pathname, history],
  )

  if (!Object.keys(permissions).length) {
    return null
  }

  if (maintenanceEnabled) {
    return <Maintenance />
  }

  const agreementsTab =
    checkPermissions(Authorities.AGREEMENTS_SHOW_TAB_ACTIVE) ||
    checkPermissions(Authorities.AGREEMENTS_SHOW_TAB_COMPLETED)
  const assignmentsTab =
    checkPermissions(Authorities.ASSIGNMENTS_SHOW_TAB_ACTIVE) ||
    checkPermissions(Authorities.ASSIGNMENTS_SHOW_TAB_COMPLETED)

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Helmet>
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
        />
      </Helmet>
      <SidebarPL
        activeTabIndex={activeTabIndex}
        resetFilters={(): void => setShouldResetFilter(!shouldResetFilter)}
      />
      <AppHeader
        activeTabIndex={activeTabIndex}
        shouldResetFilter={shouldResetFilter}
        changeActiveTab={changeActiveTab}
      />
      <main className={classes.content}>
        <div
          className={classes.assignmentsStickyHeader}
          id='assignment-sticky-header'
        />
        <div className={classes.view}>
          <Switch>
            {checkPermissions(Authorities.ITEMS_PAGE) &&
              checkPermissions(Authorities.ITEMS_SEARCH_AND_FILTER_ITEMS) && (
                <Route
                  path='/positions/:tab/search'
                  render={() => (
                    <PositionSearch tabIndex={activeTabIndex.positions} />
                  )}
                  exact
                />
              )}
            {checkPermissions(Authorities.ITEMS_PAGE) && (
              <Route
                path='/positions/:tab'
                render={() => (
                  <PositionPL tabIndex={activeTabIndex.positions} />
                )}
                exact
              />
            )}
            {checkPermissions(Authorities.SUPPLIERS_PAGE) && (
              <Route path='/suppliers'>
                <SuppliersPL />
              </Route>
            )}
            {agreementsTab &&
              checkPermissions(
                Authorities.AGREEMENTS_SEARCH_AND_FILTER_ITEMS,
              ) && (
                <Route
                  path='/agreements/:tab/search'
                  render={() => <AgreementSearchPL />}
                  exact
                />
              )}
            {agreementsTab && (
              <Route
                path='/agreements/:tab/:agreementId'
                render={() => <AgreementViewPL />}
                exact
              />
            )}
            {agreementsTab && (
              <Route
                path='/agreements/:tab'
                render={() => (
                  <AgreementPL tabIndex={activeTabIndex.agreements} />
                )}
                exact
              />
            )}
            {assignmentsTab &&
              checkPermissions(
                Authorities.ASSIGNMENTS_SEARCH_AND_FILTER_ITEMS,
              ) && (
                <Route
                  path='/assignments/:tab/search'
                  render={() => <AssignmentViewSearchPL />}
                  exact
                />
              )}
            {assignmentsTab && (
              <Route
                path='/assignments/:tab/:assignmentId'
                render={() => <AssignmentViewPL />}
                exact
              />
            )}
            {assignmentsTab && (
              <Route
                path='/assignments/:tab'
                render={() => (
                  <AssignmentsListPL tabIndex={activeTabIndex.assignments} />
                )}
                exact
              />
            )}
            <Redirect to='/positions/0' />
          </Switch>
        </div>
      </main>
    </div>
  )
}
