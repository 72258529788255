import { useQuery } from '@gmini/utils'
import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react'

import {
  EEditableModalType,
  EColumnType,
  EAPIResponseStatus,
  EditableModal,
  CatalogSearch,
  EButtonType,
  LocalStorageHelper,
  EButtonIcon,
} from '@grand-tender/ui'
import {
  TEditModalInitialState,
  IColumn,
  IRow,
  IFileResult,
  TRowData,
  TPositionModel,
  IXGridColumn,
  PositionTab,
} from '@grand-tender/types'
import { Authorities } from '@grand-tender/auth-service'
import { Typography } from '@material-ui/core'
import { GridCellParams, GridRowModel } from '@material-ui/x-grid'
import { toastr } from 'react-redux-toastr'

import ImprovedTableHeadDisplayColumns from '@grand-tender/ui/src/components/ImprovedTable/ImprovedTableHead/ImprovedTableHeadDisplayColumns/ImprovedTableHeadDisplayColumns'
import { XGridTable } from '@ifellow/ui-library'
import { TableParams } from '@ifellow/ui-library/dist/components/x-grid-table/x-grid-table'
import { modalService } from '@grand-tender/ui/src/components/Modal/ModalService'
import DialogModal from '@grand-tender/ui/src/components/DialogModal/DialogModal'
import { PositionService } from '@grand-tender/position-service'
import AddToAssignmentModal from '@grand-tender/ui/src/components/AddToAssignmentModal/AddToAssignmentModal'
import { debounce } from 'lodash'
import ButtonComponent from '@grand-tender/ui/src/components/Button/Button'
import { columnsMapper, rowsMapper } from '@grand-tender/ui/src/utils/xgrid'

import { useHistory } from 'react-router'

import { PositionInfo } from '../../components/position-info/position-info'
import { TitleComponent } from '../../components/TitleComponent/TitleComponent'
import { TabPanel } from '../../components/TabPanel/TabPanel'
import { usePermissions } from '../../hooks/usePermissions'

import * as I from './position-search-types'
import { PropsFromRedux } from './position-search-container'
import useStyles from './style'

const title = {
  insert: 'Добавить позицию',
  edit: 'Редактировать позицию',
}

const PositionSearch: React.FC<PropsFromRedux & I.OwnProps> = ({
  positions,
  categoriesData,
  searchParams,
  dataIsLoading,
  deletePositions,
  editPosition,
  addProperty,
  editProperty,
  removeProperty,
  addToAssignment,
  exportPositions,
  getSearchResult,
  user,
  tabIndex,
}): React.ReactElement => {
  const checkPermissions = usePermissions()
  const classes = useStyles()

  const query = useQuery()
  const history = useHistory()
  const positionId = query.get('positionId')
  const [positionInfo, setPositionInfo] = useState<TPositionModel | undefined>()
  const [categoryId, setCategoryId] = useState(searchParams.pageData.categoryId)
  const localStorageTableId = useMemo(
    () => `positions-categoryId-${categoryId}`,
    [categoryId],
  )

  const currentRow = useRef<IRow | undefined>()
  const modalInitialState = useRef<TEditModalInitialState>({
    settings: [],
    files: {
      current: [],
      add: [],
      remove: [],
    },
    row: {
      rowId: '',
      photo: '',
      data: [],
    },
  })

  const [checkedRows, setCheckedRows] = useState<Array<string | number>>([])
  const [dataGridRows, dataGridColumns] = useMemo(() => {
    const requiredColumns = (positions?.columns ?? [])
      .filter(column => column.required)
      .reduce<Record<string, boolean>>((obj, column) => {
        obj[column.key as string] = true
        return obj
      }, {})

    const rows = rowsMapper(positions?.data ?? []).map(row => ({
      ...row,
      hasError: Object.entries(row).some(
        ([key, value]) =>
          requiredColumns.hasOwnProperty(key) && (!value || !value.length),
      ),
    }))

    const cols = columnsMapper({
      columns:
        positions?.columns.map(c => ({
          ...c,
          hide: false,
          width: 150,
          sortOrder: null,
        })) || [],
    }).map(col => ({
      ...col,
      cellClassName: (
        params: Omit<GridCellParams, 'row'> & {
          row: GridRowModel & { hasError?: boolean }
        },
      ) => (params.row.hasError ? 'rowError' : ''),
    }))

    return [rows, cols]
  }, [positions?.columns, positions?.data])

  const handleSelectNode = useCallback(
    (nodeId: string): void => {
      getSearchResult({
        params: {
          ...searchParams,
          pageData: {
            ...searchParams.pageData,
            ascending: false,
            fieldKey: undefined,
            page: 1,
            perPage: 10,
            categoryId: nodeId,
          },
        },
      })
      setCheckedRows([])
      setCategoryId(nodeId)
    },
    [getSearchResult, searchParams, setCategoryId],
  )

  const savePosition = useCallback((): void => {
    const item: TRowData = [...modalInitialState.current.row.data]
    if (modalInitialState.current.row.rowId !== '') {
      editPosition({
        position: { rowId: modalInitialState.current.row.rowId, data: item },
        files: modalInitialState.current.files
          ? { ...modalInitialState.current.files }
          : { current: [], add: [], remove: [] },
        callback: () => {
          getSearchResult({ params: searchParams })
          modalService.closeModal()
        },
      })
    }
  }, [editPosition, getSearchResult, searchParams])

  const handleArchivePositions = useCallback(
    (ids: Array<string>) => {
      modalService.openModal(
        <DialogModal
          open={true}
          handleDiscardChanges={() => modalService.closeModal()}
          handleChanges={() => {
            modalService.closeModal()
            deletePositions({
              ids,
              callback: () => {
                getSearchResult({ params: searchParams })
                setCheckedRows([])
                currentRow.current = undefined
                positionInfo && setPositionInfo(undefined)
              },
            })
          }}
          modalTitle={'Архивирование позиций'}
          modalContent={`Вы действительно хотите архивировать позиции (${ids.length})?`}
          modalButtonRightText={'Архивировать'}
          modalButtonRightType={EButtonType.WARNING}
          modalButtonLeftText='Отменить'
          modalButtonLeftType={EButtonType.DEFAULT}
        />,
      )
    },
    [deletePositions, getSearchResult, positionInfo, searchParams],
  )

  const handleEditClick = useCallback((): void => {
    const { current } = currentRow
    if (!positionInfo || !current) {
      return
    }
    const files: IFileResult = {
      current: positionInfo.photos.map(p => ({
        id: p.key,
        file: p.value,
      })),
      add: [],
      remove: [],
    }

    const nameCol = positionInfo.columns.find(
      c =>
        c.base &&
        ['название[строковый]', 'наименование[строковый]'].indexOf(
          c.title.toLowerCase(),
        ) !== -1,
    )

    const data = positionInfo.columns.map(column =>
      column.key === nameCol?.key
        ? {
            key: column.key,
            value: positionInfo.name,
          }
        : {
            key: column.key,
            value:
              column.type === EColumnType.BOOLEAN
                ? current.data.find(rd => rd.key === column.key)?.value ||
                  'false'
                : current.data.find(rd => rd.key === column.key)?.value || '',
          },
    )

    modalInitialState.current = {
      row: {
        ...current,
        data,
      },
      settings: positionInfo.columns,
      files,
    }

    modalService.openModal(
      <EditableModal
        open={true}
        initialState={modalInitialState}
        type={EEditableModalType.EDIT}
        title={title}
        dataIsLoading={dataIsLoading}
        onClose={(): void => modalService.closeModal()}
        onSave={savePosition}
        isMultiline
        maxRows={4}
        author={user?.name}
        path='positions'
      />,
    )
  }, [positionInfo, dataIsLoading, savePosition, user?.name])

  const fetchPositionInfo = useCallback(async (id: string) => {
    const [err, detail] = await PositionService.getPositionData(id)
    const { data, status, message } = detail.data
    if (!err && status === EAPIResponseStatus.SUCCESS && data) {
      currentRow.current = { ...data, rowId: data.id }
      setPositionInfo(data)
    } else {
      const error =
        !err && status === EAPIResponseStatus.ERROR
          ? message || ''
          : // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (detail as any).data || ''
      toastr.error('', error || 'При обращении к серверу произошла ошибка!')
    }
  }, [])

  useEffect(() => {
    if (positionId && searchParams.keyword !== null) {
      fetchPositionInfo(positionId)
    }
  }, [positionId, fetchPositionInfo, searchParams.keyword])

  const handleAddToAssignment = useCallback(
    (assignmentId: string): void => {
      addToAssignment({
        positionIds: currentRow.current
          ? [currentRow.current.rowId]
          : (checkedRows as string[]),
        lotId: assignmentId,
        callback: () => {
          setCheckedRows([])
          currentRow.current = undefined
          setPositionInfo(undefined)
          modalService.closeModal()
        },
      })
    },
    [addToAssignment, checkedRows],
  )

  const handleMoveToAssignmentsClick = useCallback(() => {
    modalService.openModal(
      <AddToAssignmentModal
        open={true}
        dataIsLoading={dataIsLoading}
        onClose={(): void => modalService.closeModal()}
        onSave={handleAddToAssignment}
      />,
    )
  }, [handleAddToAssignment, dataIsLoading])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleParametersChange = useCallback(
    debounce((params: TableParams) => {
      getSearchResult({
        params: {
          ...searchParams,
          pageData: {
            ...searchParams.pageData,
            page: params.page + 1,
            perPage: params.size,
            ascending: params.sort?.sort === 'asc',
            fieldKey: params.sort?.field,
          },
        },
      })
    }, 500),
    [searchParams],
  )

  const handleAddProperty = useCallback(
    (model: IColumn): void => {
      addProperty({
        categoryId: Number(categoryId),
        model,
        callback: () => getSearchResult({ params: searchParams }),
      })
    },
    [addProperty, categoryId, getSearchResult, searchParams],
  )

  const handleEditProperty = useCallback(
    (model: IColumn): void => {
      model.type = model.type.split('_')[0] as EColumnType
      editProperty({
        model,
        callback: () => getSearchResult({ params: searchParams }),
      })
    },
    [editProperty, getSearchResult, searchParams],
  )

  const handleRemoveProperty = useCallback(
    (key: number): void => {
      removeProperty({
        key: String(key),
        callback: () => {
          const columnSettings: Array<
            IXGridColumn
          > | null = LocalStorageHelper.get(localStorageTableId)

          if (columnSettings) {
            const withoutColumn = columnSettings.filter(
              column => column.field !== String(key),
            )
            LocalStorageHelper.set(localStorageTableId, withoutColumn)
          }

          getSearchResult({ params: searchParams })
        },
      })
    },
    [getSearchResult, localStorageTableId, removeProperty, searchParams],
  )

  const handleExportClick = useCallback(() => {
    exportPositions({ ids: checkedRows as string[] })
  }, [exportPositions, checkedRows])

  if (!checkPermissions(Authorities.ITEMS_SEARCH_AND_FILTER_ITEMS)) {
    return <></>
  }

  if (!positions) {
    return (
      <div className={classes.root}>
        <p style={{ marginLeft: '18px', fontSize: '22px' }}>
          Ничего не найдено
        </p>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className='flex-item'>
        <CatalogSearch
          onSelectNode={handleSelectNode}
          categoriesData={categoriesData}
        />
      </div>
      <div className='flex-item tab-panel'>
        <TabPanel index={0} className={classes.tabPanel}>
          {positionInfo && (
            <PositionInfo
              onClose={() => {
                currentRow.current = undefined
                setPositionInfo(undefined)
                query.delete('positionId')
                history.replace({ search: query.toString() })
              }}
              data={positionInfo}
              disableRemoveBtn={true}
              disableEditBtn={
                !checkPermissions(Authorities.ITEMS_EDIT_ITEM) ||
                tabIndex === PositionTab.Archived
              }
              showMoveToAssignmentsBtn={checkPermissions(
                Authorities.ITEMS_ADD_ITEM_TO_TENDER,
              )}
              onDelete={(id: string) => handleArchivePositions([id])}
              onEdit={handleEditClick}
              onAddToAssignment={handleMoveToAssignmentsClick}
            />
          )}

          <>
            <TitleComponent
              categoryName={positions.categoryName}
              path={positions.categoryPath}
              className={classes.title}
            />
            <div className={classes.actions}>
              {checkedRows.length > 0 && (
                <>
                  <Typography
                    className={classes.selected}
                    variant='subtitle1'
                    component='div'
                  >
                    {checkedRows.length} Выбрано
                  </Typography>
                  <ButtonComponent
                    text='Экспорт'
                    type={EButtonType.DEFAULT}
                    typeIcon={EButtonIcon.DOWNLOAD}
                    hidden={!checkPermissions(Authorities.ITEMS_EXPORT)}
                    onClick={handleExportClick}
                  />
                  <ButtonComponent
                    text='Архивировать'
                    type={EButtonType.WARNING}
                    typeIcon={EButtonIcon.DELETE}
                    hidden={
                      !checkPermissions(Authorities.ITEMS_REMOVE_ITEM) ||
                      tabIndex === PositionTab.Archived
                    }
                    onClick={() =>
                      handleArchivePositions(checkedRows as string[])
                    }
                  />
                  <ButtonComponent
                    text='Добавить в тендерное задание'
                    type={EButtonType.PRIMARY}
                    typeIcon={EButtonIcon.ADD}
                    hidden={
                      !checkPermissions(Authorities.ITEMS_ADD_ITEM_TO_TENDER) ||
                      tabIndex === PositionTab.Archived
                    }
                    onClick={handleMoveToAssignmentsClick}
                  />
                </>
              )}
            </div>
            <XGridTable
              renderCustomColumn={({
                onVisibilityChange,
                columns,
              }: {
                onVisibilityChange: (key: string) => void
                columns: Array<IXGridColumn>
              }) => {
                const mappedColumns = positions.columns.filter(
                  column => !column.hidden,
                )
                const visibleColumns = mappedColumns.filter(
                  column =>
                    !column.section &&
                    columns.some(
                      col => col.field === String(column.key) && !col.hide,
                    ),
                )
                return (
                  <ImprovedTableHeadDisplayColumns
                    columns={mappedColumns}
                    onColumnVisibilityChanged={(_, { key }) =>
                      onVisibilityChange(String(key))
                    }
                    visibleColumns={visibleColumns}
                    showEditPropertyButtons={true}
                    showSections={false}
                    addPropertyHandler={handleAddProperty}
                    editPropertyHandler={handleEditProperty}
                    removePropertyHandler={handleRemoveProperty}
                    isRowChecked={true}
                    offMenu={true}
                  />
                )
              }}
              className={classes.table}
              selectionModel={checkedRows}
              rowsPerPageOptions={[10, 20, 50]}
              pageSize={positions.pagination.rowsPerPage}
              pageStart={positions.pagination.page - 1}
              mode='server'
              rows={dataGridRows}
              columns={dataGridColumns}
              rowCount={positions.pagination.total}
              disableFiltering={true}
              onRowDoubleClick={({ id: positionId }) => {
                query.set('positionId', positionId.toString())
                history.replace({ search: query.toString() })
              }}
              onParametersChange={handleParametersChange}
              onSelectionModelChange={selectionModel => {
                setCheckedRows(selectionModel)
              }}
              localStorageCache={localStorageTableId}
              withSettings
            />
          </>
        </TabPanel>
      </div>
    </div>
  )
}

export default PositionSearch
