import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import { useQuery } from '@gmini/utils'
import {
  EditableModal,
  EColumnType,
  EEditableModalType,
  LocalStorageHelper,
  EButtonType,
  EButtonIcon,
  PositionExportModal,
  TableSkeleton,
  CatalogSkeleton,
} from '@grand-tender/ui'
import {
  IColumn,
  TEditModalInitialState,
  TRowData,
  IAPIResult,
  IKeyValuePair,
  TTreeModifyDict,
  TFilterItems,
  TAssignmentRequest,
  AssignmentTab,
  IXGridColumn,
  ColumnSettings,
} from '@grand-tender/types'
import {
  GridColumnResizeParams,
  GridColumnOrderChangeParams,
} from '@material-ui/x-grid'
import { AssignmentService } from '@grand-tender/assignment-service'
import { Authorities } from '@grand-tender/auth-service'

import {
  popupAssignmentColumnsMapper,
  rowsMapper,
} from '@grand-tender/ui/src/utils/xgrid'
import { TableParams } from '@ifellow/ui-library/dist/components/x-grid-table/x-grid-table'
import { debounce } from 'lodash'

import ImprovedTableHeadDisplayColumns from '@grand-tender/ui/src/components/ImprovedTable/ImprovedTableHead/ImprovedTableHeadDisplayColumns/ImprovedTableHeadDisplayColumns'
import { XGridTable, CollapsedCatalog } from '@ifellow/ui-library'
import ButtonComponent from '@grand-tender/ui/src/components/Button/Button'
import { modalService } from '@grand-tender/ui/src/components/Modal/ModalService'
import DialogModal from '@grand-tender/ui/src/components/DialogModal/DialogModal'
import { useHistory } from 'react-router-dom'

import { ApiResponse } from '@grand-tender/base-api-service/src'

import {
  getUserCategoryColumnSettingsFromStorage,
  setColumnsToStorage,
} from '../../../common/storageHelper'

import PositionCatalog from '../../../components/PositionCatalog/PositionCatalog'
import { exportAssignmentsByIds } from '../../../actions'
import { catalogMapper } from '../../../utils'
import { usePermissions } from '../../../hooks/usePermissions'

import { useAppDispatch, useAppSelector } from '../../../store'

import {
  CatalogWrapper,
  Root,
  TabPanelWrapper,
  TabPanel,
  PathComponent,
  Path,
  Title,
  Actions,
  Selected,
} from './assignment-list.styled'
import { PropsFromRedux } from './assignment-list-container'
import * as I from './assignment-list-types'

const title = {
  insert: 'Добавить тендерное задание',
  edit: 'Редактировать тендерное задание',
}

const Assignment: React.FC<PropsFromRedux & I.OwnProps> = ({
  tabIndex,
  categories,
  assignments,
  dataIsLoading,
  getAssignments,
  addAssignment,
  deleteAssignments,
  addProperty,
  editProperty,
  removeProperty,
  getCategories,
  saveCategoriesChanges,
  addSection,
  editSection,
  removeSection,
}): React.ReactElement | null => {
  const checkPermissions = usePermissions()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const specialisationsNameList = useAppSelector(
    state => state.specialisations.nameList,
  )
  const query = useQuery()
  const initCategoryId = query.get('categoryId')

  const [categoryId, setCategoryId] = useState(initCategoryId || '')
  const localStorageTableId = `assignment-list-${categoryId}`
  const [isCatalogExpanded, setIsCatalogExpanded] = useState(true)
  const [isCategoriesPending, setIsCategoriesPending] = useState(false)
  const [isAssignmentsPending, setIsAssignmentsPending] = useState(false)
  const [checkedRows, setCheckedRows] = useState<Array<string | number>>([])
  const [initColumns, setInitColumns] = useState<(IColumn & ColumnSettings)[]>(
    getUserCategoryColumnSettingsFromStorage(
      localStorageTableId,
      assignments.columns,
    ),
  )
  const [hideTable, setHideTable] = useState(false)

  const handleAssignmentsPending = (data: boolean) =>
    setIsAssignmentsPending(data)
  const dataGridColumns = useMemo(
    () =>
      popupAssignmentColumnsMapper(initColumns, assignments.agreements ?? []),
    [initColumns, assignments.agreements],
  )
  const dataGridRows = useMemo(() => {
    let data = (rowsMapper(assignments.data) as unknown) as Array<
      Record<string, string>
    >

    const listColumn = assignments.columns.find(
      item => item.type === EColumnType.LIST,
    )
    if (listColumn) {
      data = data.map(item => ({
        ...item,
        [listColumn.key]: item[listColumn.key]
          ? item[listColumn.key]
              .split('|')
              .filter(Boolean)
              .map(code => specialisationsNameList[code] ?? '???')
              .join(', ')
          : '',
      }))
    }

    return data
  }, [assignments.columns, assignments.data, specialisationsNameList])

  const [tableParams, setTableParams] = useState<TableParams>({
    size: 10,
    page: 0,
    sort: {
      field: '',
      sort: 'asc',
    },
    filters: [],
  })

  const getCurrentRequestParams = useCallback(() => {
    const params: TAssignmentRequest = {
      pageData: {
        page: tableParams.page + 1,
        perPage: tableParams.size,
        categoryId,
        ascending: tableParams.sort?.sort === 'asc',
        fieldKey: tableParams.sort?.field,
      },
      userContract: {
        endDate: '',
        object: '',
        project: '',
        startDate: '',
      },
      tenderTaskConditions: tableParams.filters as TFilterItems[],
      fetchCategories: true,
      table: 'assignments',
    }
    return params
  }, [categoryId, tableParams])

  const getInitRequestParams = useCallback(
    (localStorageId?: string) => {
      let storageSortColumn: ColumnSettings | undefined
      const storageData = localStorage.getItem(localStorageId || '')
      if (typeof storageData === 'string') {
        try {
          storageSortColumn = (JSON.parse(
            storageData,
          ) as ColumnSettings[]).find(s => s.sortOrder !== null)
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err)
        }
      }

      const params: TAssignmentRequest = {
        pageData: {
          page: 1,
          perPage: tableParams.size,
          categoryId,
          fieldKey: storageSortColumn ? storageSortColumn.key.toString() : '',
          ascending:
            storageSortColumn?.sortOrder !== null
              ? storageSortColumn?.sortOrder
              : true,
        },
        userContract: {
          endDate: '',
          object: '',
          project: '',
          startDate: '',
        },
        fetchCategories: true,
        table: 'assignments',
      }
      return params
    },
    [categoryId, tableParams],
  )

  const modalInitialState = useRef<TEditModalInitialState>({
    settings: [],
    row: {
      rowId: '',
      data: [],
    },
  })

  const fetchCategories = useCallback(() => {
    const setLoading = (data: boolean) => setIsCategoriesPending(data)
    const params = {
      endDate: '',
      object: '',
      project: '',
      startDate: '',
    }

    getCategories({ setLoading, tabIndex, params })
  }, [getCategories, tabIndex])

  useEffect(() => {
    setCategoryId('')
  }, [tabIndex])

  useEffect(() => {
    fetchCategories()
    if (initCategoryId) {
      handleSelectNode(initCategoryId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCategories])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleParametersChange = useCallback(
    debounce((params: TableParams) => {
      const curParams = getCurrentRequestParams()

      getAssignments({
        setLoading: handleAssignmentsPending,
        params: {
          ...curParams,
          pageData: {
            ...curParams.pageData,
            page: params.page + 1,
            perPage: params.size,
            categoryId,
            ascending: params.sort?.sort === 'asc',
            fieldKey: params.sort?.field,
          },
          tenderTaskConditions: params.filters as TFilterItems[],
        },
        tab: tabIndex,
      })
        .unwrap()
        .then(t => {
          const cols = getUserCategoryColumnSettingsFromStorage(
            localStorageTableId,
            t.columns,
          )
          const newCols = cols.map(c =>
            c.key?.toString() === params.sort?.field
              ? { ...c, sortOrder: params.sort?.sort === 'asc' ? true : false }
              : { ...c, sortOrder: null },
          )

          setColumnsToStorage(localStorageTableId, newCols)
          setInitColumns(newCols)
        })
      setTableParams(params)
    }, 500),
    [categoryId, tabIndex, localStorageTableId],
  )

  const handleSelectNode = useCallback(
    (nodeId?: string): void => {
      setCategoryId(nodeId ?? '')
      const storageKey = `assignment-list-${nodeId}`
      const initParams = getInitRequestParams(storageKey)
      setTableParams({
        size: initParams.pageData.perPage,
        page: 0,
        filters: [],
        sort: {
          field: initParams.pageData.fieldKey?.toString() || '',
          sort: initParams.pageData.ascending ? 'asc' : 'desc',
        },
      })
      query.set('categoryId', nodeId || '')
      history.replace({ search: query.toString() })
      getAssignments({
        setLoading: val => {
          handleAssignmentsPending(val)
          setHideTable(val)
        },
        params: {
          ...initParams,
          pageData: {
            ...initParams.pageData,
            categoryId: nodeId ?? '',
          },
        },
        tab: tabIndex,
      })
        .unwrap()
        .then(t => {
          setInitColumns(
            getUserCategoryColumnSettingsFromStorage(storageKey, t.columns),
          )
        })
    },
    [
      getAssignments,
      getInitRequestParams,
      setCategoryId,
      tabIndex,
      history,
      query,
    ],
  )

  const handleSaveAssignment = React.useCallback((): void => {
    const item: TRowData = [...modalInitialState.current.row.data]

    if (!modalInitialState.current.row.rowId) {
      addAssignment({
        tender: item,
        callback: () => {
          modalService.closeModal()
          getAssignments({
            setLoading: handleAssignmentsPending,
            params: getCurrentRequestParams(),
            tab: tabIndex,
          })
          fetchCategories()
        },
      })
    }
  }, [
    addAssignment,
    fetchCategories,
    getAssignments,
    getCurrentRequestParams,
    tabIndex,
  ])

  const handleAddAssignment = useCallback(() => {
    const excludeColumnList = [
      'специализация',
      'номер',
      'статус',
      'создано',
      'дата',
      '№',
      'количество поставщиков',
      'количество соглашений',
      'объект',
      'проект',
    ]
    const excludeColumns = assignments.columns.filter(
      el => !excludeColumnList.includes(el.title.toLowerCase()),
    )

    const data = assignments.columns.map(d => ({
      key: d.key,
      value: d.type === EColumnType.BOOLEAN ? 'false' : '',
    }))
    modalInitialState.current = {
      row: {
        rowId: '',
        data,
      },
      settings: excludeColumns,
    }

    modalService.openModal(
      <EditableModal
        open={true}
        initialState={modalInitialState}
        type={EEditableModalType.INSERT}
        title={title}
        dataIsLoading={dataIsLoading}
        onClose={() => modalService.closeModal()}
        onSave={handleSaveAssignment}
        isMultiline
        maxRows={4}
        path='/assignment/'
      />,
    )
  }, [assignments.columns, dataIsLoading, handleSaveAssignment])

  const handleExport = useCallback(
    (isExportAllPosition: boolean) => {
      const exportPosition = isExportAllPosition
        ? dataGridRows.map(position => position.id)
        : checkedRows

      dispatch(
        exportAssignmentsByIds({
          ids: exportPosition as string[],
        }),
      )
      modalService.closeModal()
    },
    [dataGridRows, checkedRows, dispatch],
  )

  const handleExportSelectedPositionClick = useCallback(() => {
    modalService.openModal(
      <PositionExportModal
        open={true}
        onClose={() => modalService.closeModal()}
        onSubmitExportPosition={handleExport}
        exportBySelectedPositionOption
      />,
    )
  }, [handleExport])

  const handleRemoveRows = useCallback(() => {
    modalService.openModal(
      <DialogModal
        open={true}
        handleDiscardChanges={() => modalService.closeModal()}
        handleChanges={() => {
          modalService.closeModal()
          deleteAssignments({
            ids: checkedRows as string[],
            callback: () => {
              setCheckedRows([])
              getAssignments({
                setLoading: handleAssignmentsPending,
                params: getCurrentRequestParams(),
                tab: tabIndex,
              })
              fetchCategories()
            },
          })
        }}
        modalTitle='Архивирование тендерных заданий'
        modalContent={`Вы действительно хотите архивировать тендерные задания (${checkedRows.length})?`}
        modalButtonRightText='Архивировать тендерные задания'
        modalButtonRightType={EButtonType.WARNING}
        modalButtonLeftText='Отменить'
        modalButtonLeftType={EButtonType.DEFAULT}
      />,
    )
  }, [
    checkedRows,
    deleteAssignments,
    fetchCategories,
    getAssignments,
    getCurrentRequestParams,
    tabIndex,
  ])

  const handleAddProperty = React.useCallback(
    (model: IColumn, sectionId?: string) => {
      addProperty({
        model,
        categoryId,
        sectionId: sectionId || '',
      }).then(() => {
        getAssignments({
          setLoading: handleAssignmentsPending,
          params: getCurrentRequestParams(),
          tab: tabIndex,
        })
          .unwrap()
          .then(({ columns }) =>
            setInitColumns(
              getUserCategoryColumnSettingsFromStorage(
                localStorageTableId,
                columns,
              ),
            ),
          )
      })
    },
    [
      addProperty,
      categoryId,
      getAssignments,
      getCurrentRequestParams,
      localStorageTableId,
      tabIndex,
    ],
  )

  const handleEditProperty = React.useCallback(
    (model: IColumn, sectionId?: string): void => {
      model.key = model.id
      editProperty({
        model,
        sectionId: sectionId || '',
      }).then(() =>
        getAssignments({
          setLoading: handleAssignmentsPending,
          params: getCurrentRequestParams(),
          tab: tabIndex,
        })
          .unwrap()
          .then(({ columns }) => {
            const cols = getUserCategoryColumnSettingsFromStorage(
              localStorageTableId,
              columns,
            )
            const newCols = cols.map(c => ({
              ...c,
              title: columns.find(col => col.key === c.key)?.title || c.title,
            }))
            setColumnsToStorage(localStorageTableId, newCols)
            setInitColumns(newCols)
          }),
      )
    },
    [
      editProperty,
      getAssignments,
      getCurrentRequestParams,
      localStorageTableId,
      tabIndex,
    ],
  )

  const handleRemoveProperty = useCallback(
    (key: number): void => {
      removeProperty({
        key: String(key),
      }).then(() => {
        const columnSettings: Array<
          IXGridColumn
        > | null = LocalStorageHelper.get(localStorageTableId)

        if (columnSettings) {
          const withoutColumn = columnSettings.filter(
            el => el.field !== String(key),
          )
          LocalStorageHelper.set(localStorageTableId, withoutColumn)
        }

        getAssignments({
          setLoading: handleAssignmentsPending,
          params: getCurrentRequestParams(),
          tab: tabIndex,
        })
          .unwrap()
          .then(({ columns }) =>
            setInitColumns(
              getUserCategoryColumnSettingsFromStorage(
                localStorageTableId,
                columns,
              ),
            ),
          )
      })
    },
    [
      getAssignments,
      getCurrentRequestParams,
      localStorageTableId,
      removeProperty,
      tabIndex,
    ],
  )

  const handleAddSection = useCallback(
    (name: string, callback: (model: IKeyValuePair) => void): void => {
      addSection({ categoryId, name, callback })
    },
    [addSection, categoryId],
  )

  const handleEditSection = React.useCallback(
    (value: IKeyValuePair, callback: (model: IKeyValuePair) => void): void => {
      editSection({ value, callback })
    },
    [editSection],
  )

  const handleRemoveSection = React.useCallback(
    (key: string, callback: () => void): void => {
      removeSection({ key, callback })
    },
    [removeSection],
  )

  const handleGetSections = React.useCallback(
    (query: string): Promise<ApiResponse<IAPIResult<TRowData>>> =>
      AssignmentService.searchSections(categoryId, query),
    [categoryId],
  )

  const checkPermission = React.useCallback(
    () =>
      assignments !== null &&
      (checkPermissions(Authorities.ASSIGNMENTS_SHOW_TAB_ACTIVE) ||
        checkPermissions(Authorities.ASSIGNMENTS_SHOW_TAB_COMPLETED)),
    [assignments, checkPermissions],
  )

  const handleColumnVisibilityChanged = useCallback(
    (id: number) => {
      const newCols = initColumns.map(c =>
        c.key === id ? { ...c, hide: !c.hide } : c,
      )
      setColumnsToStorage(localStorageTableId, newCols)
      setInitColumns(newCols)
    },
    [initColumns, localStorageTableId],
  )

  const handleColumnResize = useCallback(
    ({ colDef, width }: GridColumnResizeParams) => {
      const newCols = initColumns.map(col =>
        col.key.toString?.() === colDef.field ? { ...col, width } : col,
      )
      setColumnsToStorage(localStorageTableId, newCols)
      setInitColumns(newCols)
    },
    [initColumns, localStorageTableId],
  )

  const handleColumnOrderChange = useCallback(
    ({ targetIndex, oldIndex }: GridColumnOrderChangeParams) => {
      const movingCol = initColumns[oldIndex - 1]
      const newCols = initColumns.filter((_, i) => i !== oldIndex - 1)
      newCols.splice(targetIndex - 1, 0, movingCol)
      setColumnsToStorage(localStorageTableId, newCols)
      setInitColumns(newCols)
    },
    [initColumns, localStorageTableId],
  )

  const saveCategories = React.useCallback(
    (modifyDict: TTreeModifyDict) => {
      saveCategoriesChanges({ modifyDict, callback: fetchCategories })
    },
    [fetchCategories, saveCategoriesChanges],
  )

  if (!checkPermission()) {
    return null
  }

  return isCategoriesPending ? (
    <CatalogSkeleton />
  ) : (
    <Root>
      {isCatalogExpanded && (
        <CatalogWrapper>
          <PositionCatalog
            editable={
              tabIndex === AssignmentTab.All &&
              checkPermissions(Authorities.ASSIGNMENTS_EDIT_CATALOG)
            }
            onSave={saveCategories}
            onSelectNode={node => handleSelectNode(node?.id)}
            setIsCatalogExpanded={setIsCatalogExpanded}
            categoryId={categoryId}
            categories={categories}
          />
        </CatalogWrapper>
      )}
      {isAssignmentsPending && <TableSkeleton />}
      <TabPanelWrapper hidden={isAssignmentsPending}>
        <TabPanel index={tabIndex}>
          {categoryId && (
            <>
              <PathComponent>
                <Path>
                  <Title path={assignments.categoryPath} />
                </Path>
              </PathComponent>
              <Actions>
                {!isCatalogExpanded && (
                  <CollapsedCatalog
                    title='Каталог'
                    items={catalogMapper(categories) as any}
                    onItemClick={id => handleSelectNode(id as string)}
                    onSwitch={() => setIsCatalogExpanded(true)}
                    needSwitchBtn
                  />
                )}
                {checkedRows.length === 0 && (
                  <ButtonComponent
                    text='Добавить тендерное задание'
                    type={EButtonType.DEFAULT}
                    typeIcon={EButtonIcon.ADD}
                    hidden={
                      !(
                        tabIndex === AssignmentTab.All &&
                        checkPermissions(Authorities.ASSIGNMENTS_CREATE_ITEM)
                      )
                    }
                    onClick={handleAddAssignment}
                  />
                )}
                {checkedRows.length > 0 && (
                  <>
                    <Selected variant='subtitle1' component='div'>
                      {checkedRows.length} Выбрано
                    </Selected>
                    <ButtonComponent
                      text='Экспорт'
                      type={EButtonType.DEFAULT}
                      typeIcon={EButtonIcon.DOWNLOAD}
                      onClick={handleExportSelectedPositionClick}
                    />
                    {tabIndex !== AssignmentTab.Archived && (
                      <ButtonComponent
                        text='Архивировать'
                        type={EButtonType.WARNING}
                        typeIcon={EButtonIcon.DELETE}
                        hidden={
                          !checkPermissions(Authorities.ASSIGNMENTS_REMOVE_ITEM)
                        }
                        onClick={handleRemoveRows}
                      />
                    )}
                  </>
                )}
              </Actions>
              {initColumns.length && !hideTable ? (
                <XGridTable
                  renderCustomColumn={({
                    onVisibilityChange,
                    columns,
                  }: {
                    onVisibilityChange: (key: string) => void
                    columns: Array<IXGridColumn>
                  }) => {
                    const mappedColumns = assignments.columns.filter(
                      column => !column.hidden,
                    )
                    const visibleColumns = mappedColumns.filter(column =>
                      columns.some(
                        col => col.field === String(column.key) && !col.hide,
                      ),
                    )
                    return (
                      <ImprovedTableHeadDisplayColumns
                        columns={mappedColumns}
                        onColumnVisibilityChanged={(_, { key, id }) => {
                          onVisibilityChange(String(key))
                          handleColumnVisibilityChanged(id)
                        }}
                        visibleColumns={visibleColumns}
                        showEditPropertyButtons={true}
                        showSections
                        addPropertyHandler={handleAddProperty}
                        editPropertyHandler={handleEditProperty}
                        removePropertyHandler={handleRemoveProperty}
                        onAddSection={handleAddSection}
                        onEditSection={handleEditSection}
                        onRemoveSection={handleRemoveSection}
                        getSectionsDataSource={handleGetSections}
                        isRowChecked={true}
                        offMenu={true}
                      />
                    )
                  }}
                  selectionModel={checkedRows}
                  rowsPerPageOptions={[10, 20, 50]}
                  pageSize={assignments.pagination.rowsPerPage}
                  pageStart={assignments.pagination.page - 1}
                  mode='server'
                  onColumnResize={debounce(handleColumnResize, 500)}
                  onColumnOrderChange={handleColumnOrderChange}
                  initSortModel={
                    tableParams.sort?.field ? [tableParams.sort] : []
                  }
                  rows={dataGridRows}
                  columns={dataGridColumns}
                  rowCount={assignments.pagination.total}
                  disableFiltering={false}
                  onRowDoubleClick={({ id }) =>
                    history.push({
                      pathname: `/assignments/${tabIndex}/${id}`,
                      search: query.toString(),
                    })
                  }
                  onParametersChange={handleParametersChange}
                  onSelectionModelChange={selectionModel => {
                    setCheckedRows(selectionModel)
                  }}
                  localStorageCache={localStorageTableId}
                  withSettings
                />
              ) : null}
            </>
          )}
        </TabPanel>
      </TabPanelWrapper>
    </Root>
  )
}

export default Assignment
