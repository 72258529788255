import { Button, Popup, RoundedCross, Tooltip } from '@gmini/ui-kit'
import { ProviderPosition, TLotSuppliersRowData } from '@grand-tender/types'

import React, { useState } from 'react'

import { WarningIcon } from '@grand-tender/ui/src/icons/WarningIcon'

import {
  CloseButton,
  Container,
  Content,
  MessageText,
  Header,
  HelpIcon,
  Title,
  MessagePositionText,
  Footer,
  WinnersContainer,
  ErrorText,
  PositionError,
} from './LotWinnerModal.styled'
import { LotWinnerSelect } from './LotWinnerSelect'
import { getPositionText } from './LotWinnerModal.utils'
import { TooltipText } from './LotWinnerSelect.styled'

type Position = string
export type Winner = { rowId: string; name: string; items: string[] }
export type NotAssignedPositions = Record<Position, { itemCount: number }>

type LotWinnerModalProps = {
  open: boolean
  onClose: () => void
  positionSelected?: string[]
  suppliers: TLotSuppliersRowData
  currentWinners: (ProviderPosition & { name: string })[]
  onSubmit: (winners: Record<Position, Winner[]>) => void
  positionTotal: number
}

export default function LotWinnerModal({
  open,
  onClose,
  positionSelected,
  suppliers,
  currentWinners,
  onSubmit,
  positionTotal,
}: LotWinnerModalProps) {
  const [winners, setWinners] = useState<Record<Position, Winner[]>>({})
  const [supplierList, setSupplierList] = useState<Winner[]>([])
  const [notAssignedPositions, setNotAssignedPositions] = useState<
    NotAssignedPositions
  >({})

  React.useEffect(() => {
    if (positionSelected?.length) {
      return
    }
    if (!currentWinners.length) {
      setSupplierList(suppliers.map(supplier => ({ ...supplier, items: [] })))
    } else {
      const supplierList = suppliers.map(supplier => ({
        ...supplier,
        items:
          currentWinners.find(w => w.providerId === supplier.rowId)?.itemId ||
          [],
      }))

      setSupplierList(supplierList)

      const data: Record<Position, Winner[]> = {}
      currentWinners.forEach(val => {
        const oldData = data[val.position]
        const winner = {
          rowId: val.providerId,
          name: val.name,
          items: val.itemId,
        }
        if (oldData && oldData.length) {
          data[val.position] = [...data[val.position], winner]
          return
        }
        data[val.position] = [winner]
      })

      setWinners(data)
    }
  }, [
    currentWinners,
    currentWinners.length,
    positionSelected?.length,
    suppliers,
  ])

  React.useEffect(() => {
    // Выбраны позиции
    if (positionSelected?.length) {
      if (!currentWinners.length) {
        setSupplierList(suppliers.map(supplier => ({ ...supplier, items: [] })))
      } else {
        // ***Кейс когда победителей несколько в селекте**
        setSupplierList(
          suppliers.map(supplier => ({
            ...supplier,
            items:
              currentWinners.find(w => w.providerId === supplier.rowId)
                ?.itemId || [],
          })),
        )

        const winnerList = currentWinners.reduce(
          (acc: Record<Position, Winner[]>, next) => {
            if (
              next.itemId?.every(id => positionSelected.some(iId => iId === id))
            ) {
              const winner = {
                rowId: next.providerId,
                name: next.name,
                items: next.itemId,
              }
              return {
                ...acc,
                [next.position]: acc[next.position]?.length
                  ? [...acc[next.position], winner]
                  : [winner],
              }
            }
            return acc
          },
          {},
        )
        const lostPositions: NotAssignedPositions = {}

        Object.entries(winnerList).forEach(([position, winnersArr]) => {
          const positions = winnersArr.reduce<string[]>(
            (acc, next) => [...acc, ...next.items],
            [],
          )
          const notAssignedPosition = positionSelected.filter(
            p => !positions.includes(p),
          )
          if (!notAssignedPosition.length) {
            return
          }

          lostPositions[position] = {
            itemCount: notAssignedPosition.length,
          }
        })

        setNotAssignedPositions(lostPositions)

        setWinners(winnerList)
      }
    }
  }, [currentWinners, currentWinners.length, positionSelected, suppliers])

  const onSelectSupplier = React.useCallback(
    ({ winner, position }: { winner: Winner; position: string }) => {
      const hasAgreement = suppliers.find(s => s.rowId === winner.rowId)
        ?.hasAgreement

      if (hasAgreement) {
        return
      }
      const newWinners = { ...winners }

      Object.keys(newWinners).forEach(positionKey => {
        const newWinnersData = newWinners[positionKey]

        if (positionKey === position || !newWinnersData) {
          return
        }

        // Проходим по позициям и если победитель есть в другой колонке то удаляем его из этой колонки
        if (newWinnersData.some(w => w.rowId === winner.rowId)) {
          const lostPositions = newWinnersData.reduce(
            (acc: NotAssignedPositions, next) => {
              if (next.rowId === winner.rowId && next.items.length) {
                return {
                  ...acc,
                  [positionKey]: {
                    itemCount: next.items.length,
                  },
                }
              }
              return acc
            },
            {},
          )

          setNotAssignedPositions(old => {
            const newData = { ...old }
            Object.keys(lostPositions).forEach(position => {
              if (old[position]?.itemCount) {
                newData[position] = {
                  itemCount:
                    newData[position].itemCount +
                    lostPositions[position].itemCount,
                }
              } else {
                newData[position] = {
                  itemCount: lostPositions[position].itemCount,
                }
              }
            })
            return newData
          })

          newWinners[positionKey] = newWinners[positionKey].filter(
            w => w.rowId !== winner.rowId,
          )
        }
      })

      const currentWinners = {
        ...newWinners,
        [position]: [{ ...winner, items: positionSelected || [] }],
      }

      if (
        notAssignedPositions[position] &&
        currentWinners[position].length === 1
      ) {
        setNotAssignedPositions(old => {
          const newData = { ...old }
          delete newData[position]
          return newData
        })
      }

      setWinners(currentWinners)
    },
    [suppliers, winners, positionSelected, notAssignedPositions],
  )

  React.useEffect(() => {
    if (!open) {
      setNotAssignedPositions({})
      setWinners({})
    }
  }, [open])

  const getSelectedValue = (position: string) => winners[position] || []

  const getOptionLabel = (winner: Winner, position: string) => {
    if (winners[position] && winners[position].length > 1) {
      const name = winners[position]?.reduce((acc, next, i, arr) => {
        if (next.items?.length) {
          return `${acc} ${next.name}${' '}(${
            next.items.length
          } ${getPositionText(next.items.length)})${
            i + 1 === arr.length ? '' : ','
          }`
        }
        return `${acc}, ${next.name}`
      }, '')
      return name
    }
    return winner.name
  }

  const getPositionInfo = (id: string, position: string) => {
    if (winners[position] && winners[position].length) {
      const supplier = winners[position].find(s => s.rowId === id)

      return supplier?.items && winners[position].length > 1
        ? `${supplier?.items.length}${' '}из ${positionTotal} позиций`
        : ''
    }
    return ''
  }
  const isSelected = (id: string, position: string) => {
    const positionData = winners[position]

    if (!positionData) {
      return false
    }
    return positionData.some(({ rowId }) => rowId === id)
  }
  const tooltipText = React.useMemo(() => {
    if (positionSelected?.length) {
      return (
        <TooltipText>
          Если вы хотите назначить победителя на все позиции разом, снимите
          выделение с отдельных позиций и нажмите на кнопку “Определить
          победителей”.
        </TooltipText>
      )
    }
    return (
      <TooltipText>
        Если вы хотите назначить победителя на отдельные позиции, выделите эти
        позиции в списке и нажмите на кнопку “Определить победителей”
      </TooltipText>
    )
  }, [positionSelected?.length])

  const getPositionError = (position: string) => {
    const positions = notAssignedPositions[position]

    if (!positionSelected?.length) {
      const notOneWinner = winners[position]?.length > 1

      return (
        notOneWinner && (
          <PositionError>
            <WarningIcon />

            <ErrorText>
              Назначить на все позиции можно только одного победителя
            </ErrorText>
          </PositionError>
        )
      )
    }
    return (
      positions && (
        <PositionError>
          <WarningIcon />

          <ErrorText>{`${positions?.itemCount} позициям не присвоен победитель`}</ErrorText>
        </PositionError>
      )
    )
  }

  const isSelectDisabled = React.useCallback(
    (position: string) =>
      !!suppliers.find(s => winners[position]?.some(w => s.rowId === w.rowId))
        ?.hasAgreement,
    [suppliers, winners],
  )

  const disableSubmit =
    !positionSelected?.length &&
    Object.values(winners).some(winnerList => winnerList.length > 1)

  const suppliersWithoutAgreement = supplierList.filter(s =>
    suppliers.some(
      supplier => supplier.rowId === s.rowId && !supplier.hasAgreement,
    ),
  )

  return (
    <Popup open={open} onClose={onClose} width='600px'>
      <Container>
        <Header>
          <Title>Победители лота</Title>
          <CloseButton onClick={onClose}>
            <RoundedCross />
          </CloseButton>
        </Header>
        <Content>
          <div>
            <MessageText>
              Победители будут назначены на
              <MessagePositionText>
                {positionSelected?.length
                  ? `${positionSelected?.length}${'  '}выделенные позиции`
                  : 'все позиции'}
              </MessagePositionText>
              в лоте
              <Tooltip title={tooltipText || ''} style={{ maxWidth: '350px' }}>
                <HelpIcon
                  color='rgba(53, 59, 96, 0.25)'
                  viewBox='0 0 18 18'
                  height='18'
                />
              </Tooltip>
            </MessageText>

            <WinnersContainer>
              {supplierList.map((s, i) => {
                const position = `${i + 1}`
                return (
                  <LotWinnerSelect
                    key={s.rowId}
                    selectedValue={getSelectedValue(position)}
                    options={suppliersWithoutAgreement}
                    position={position}
                    onSelect={onSelectSupplier}
                    getOptionLabel={getOptionLabel}
                    getPositionInfo={getPositionInfo}
                    isSelected={isSelected}
                    disabled={isSelectDisabled(position)}
                    error={getPositionError(position)}
                  />
                )
              })}
            </WinnersContainer>
          </div>
        </Content>
        <Footer>
          <Button color='secondary' onClick={onClose}>
            Отменить
          </Button>

          <Button
            onClick={() => {
              onSubmit(winners)
              onClose()
            }}
            disabled={disableSubmit}
          >
            Применить
          </Button>
        </Footer>
      </Container>
    </Popup>
  )
}
