import React from 'react'
import cn from 'classnames'
import Modal from '@material-ui/core/Modal'
import styles from './styles.module.scss'
import { ModalTitle } from '../BaseModalAddContextPositions/ModalTitle/ModalTitle'

export type TModalComponent = {
  open: boolean
  onClose: () => void
  title?: string
  modalClassName?: string
  contentClassName?: string
  wrapClassName?: string
}

export const ModalComponent: React.FC<TModalComponent> = ({
  open,
  onClose,
  children,
  title,
  modalClassName,
  contentClassName,
  wrapClassName,
}) => {
  const rootRef = React.useRef<HTMLDivElement>(null)

  return (
    <div ref={rootRef}>
      <Modal
        className={cn(styles.modal, modalClassName)}
        onClose={onClose}
        open={open}
        container={(): HTMLDivElement | null => rootRef.current}
      >
        <div className={cn(styles.contains, wrapClassName)}>
          {Boolean(title) && (
            <ModalTitle id='modal-title' onClose={onClose}>
              {title}
            </ModalTitle>
          )}
          <div className={cn(styles.content, contentClassName)}>{children}</div>
        </div>
      </Modal>
    </div>
  )
}
