import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Proxima Nova Regular',
    height: '100%',
    '& a': {
      textDecoration: 'none',
    },
    '& .MuiToolbar-gutters': {
      paddingLeft: '0px',
      paddingRight: '0px',
      margin: '0 24px',
    },
    '& .MuiToolbar-root': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    width: '100%',
    backgroundColor: 'white',
    boxShadow: 'none',
    color: '#616284',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flex: '1 1 auto',
    padding: 0,
    backgroundColor: 'white',
  },
  view: {
    padding: '0 34px',
    height: '100%',
  },
  assignmentsStickyHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 11,
  },
  assignmentsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px',
    width: '100%',
    '& .left-side': {
      display: 'flex',
      alignItems: 'center',
      '& h3': {
        marginLeft: '10px',
      },
    },
    '& .right-side': {
      display: 'flex',
      alignItems: 'center',
      '& .status-info': {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'flex-end',
        '& .name': {
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '17px',
          color: '#4C5ECF',
          margin: '0',
        },
        '& .date': {
          fontSize: '11px',
          lineHeight: '13px',
          textAlign: 'right',
          color: '#616284',
        },
      },
    },
  },
  menuButton: {
    padding: 0,
    borderRadius: '5px',
    width: '27px',
    height: '27px',
    marginLeft: '15px',
    '&.active': {
      background: '#4C5ECF',
      color: '#FFFFFF',
    },
  },
  assignmentMenu: {
    '& li': {
      fontFamily: 'Proxima Nova Regular',
    },
  },
}))

export default useStyles
