import { makeStyles, Theme } from '@material-ui/core/styles'

export const useImprovedHeadStyles = makeStyles((theme: Theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  checkedRow: {
    '&:hover, &:focus, &:active': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '&$checkboxChecked, &$checkboxChecked:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '& .MuiTableCell-root': {
      border: '0',
    },
  },
  checkboxChecked: {},
  header: {
    height: '46px',
    backgroundColor: '#F9F9FB',
    '& .MuiSvgIcon-root': {
      height: '24px',
      cssFloat: 'right',
    },
    '& .MuiTableSortLabel-root': {
      width: '100%',
    },
  },
  checkboxCell: {
    '&.MuiTableCell-paddingCheckbox': {
      width: '40px',
    },
  },
  headerCell: {
    fontWeight: 'bold',
    position: 'relative',
    border: '1px solid rgba(224, 224, 224, 1)',
    borderCollapse: 'collapse',
  },
  headerTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  supplierInfoBtn: {
    backgroundColor: '#EBEBF1',
    borderRadius: '5px',
    height: '22px',
    fontFamily: 'Proxima Nova Regular',
    fontSize: '12px',
    textTransform: 'none',
  },
  hasAgreement: {
    backgroundColor: '#E8EEFA',
  },
  supplierName: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  resizeHandle: {
    position: 'absolute',
    right: '0',
    top: '50%',
    transform: 'translate(0, -50%)',
    width: '12px',
    height: '100%',
    cursor: 'col-resize',
  },
}))

export default useImprovedHeadStyles
