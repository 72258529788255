import React from 'react'
import cn from 'classnames'
import { TabPanelProps } from '@grand-tender/types'

import useStyles from './Styles'

export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  index,
  className,
  ...other
}): React.ReactElement => {
  const classes = useStyles()

  return (
    <div
      role='tabpanel'
      className={cn(classes.root, className)}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {children}
    </div>
  )
}
