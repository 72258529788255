import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '0 0 65px',
    backgroundColor: '#4C5ECF',
    paddingLeft: '24px',
  },
  column: {
    display: 'flex',
  },
  headerUserInfo: {
    color: '#ffffff',
    paddingRight: '10px',
    paddingTop: '13px',
    fontSize: '14px',
    letterSpacing: '0.3px',
    textAlign: 'right',
  },
  headerUserInfoDesc: {
    fontSize: '9px',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '19px',
  },
  logoName: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    letterSpacing: '0.1px',
    lineHeight: '22px',
    fontWeight: 700,
    textTransform: 'uppercase',
    marginLeft: '8px',
    color: '#fff',
    marginRight: '8px',
  },
  action: {
    marginLeft: '15px',
    '&:last-child': {
      marginLeft: '40px',
    },
  },
  divider: {
    background: 'rgba(255, 255, 255, 0.2)',
    width: '1px',
    margin: '14px 0 15px',
  },

  // Кнопка ВЫХОД
  logoutContainer: {
    display: 'flex',
    // position: 'fixed',
    bottom: '0px',
    width: '100%',
    //height: '100%',
    padding: '10px 10px 10px 10px',
    '& .userContainer': {
      width: '50%',
      display: 'flex',
      cursor: 'pointer',
      '& .userRole': {
        display: 'block',
        width: '50%',
        writingMode: 'vertical-lr',
        textAlign: 'left',
        transform: 'rotate(180deg)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: '#fff',
        opacity: '0.7',
        lineHeight: '15px',
        marginBottom: '5px',
      },
      '& .userName': {
        display: 'block',
        width: '50%',
        writingMode: 'vertical-rl',
        textAlign: 'left',
        transform: 'rotate(180deg)',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: '#fff',
        lineHeight: '19px',
        marginBottom: '5px',
      },
    },
    '& .MuiDivider-root': {
      width: '1px',
      height: '100%',
    },
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: '5px',
  },
  actionLink: {
    height: '42px',
    '& .actionButton': {
      flexDirection: 'column',
      justifyContent: 'center',
      color: '#FFFFFF',
      opacity: '0.7',
      height: '100%',
      width: '40px',
      padding: '12px',
      borderRadius: '5px',
      '&.MuiListItem-button:hover': {
        backgroundColor: 'rgba(235, 236, 240, 0.1)',
      },
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: 'rgba(235, 236, 240, 0.1)',
        opacity: '1',
      },
      '& .actionButtonContent': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        '& .MuiSvgIcon-root': {
          fontSize: '16px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
      },
      '& .MuiListItemIcon-root': {
        justifyContent: 'center',
        minWidth: '17px',
      },
    },
  },
  sidebarList: {
    justifyContent: 'space-between',
    height: '100%',
  },
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    maxHeight: 700,
    overflow: 'hidden',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  userAccountWrapper: {
    position: 'relative',
  },
}))

export default useStyles
