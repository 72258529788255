import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  lot: {
    display: 'flex',
    width: '100%',
  },
  drawerEditBtn: {
    width: '40px',
    height: '40px',
    '& .MuiSvgIcon-root': {
      fontSize: '16px',
    },
  },
  cardRowBody: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '20px',
    margin: '0 -10px',
    '& .MuiFormControl-root': {
      margin: '5px 10px',
      // flex: '1 1 280px',
      maxWidth: '280px',
    },
    '& div.MuiInput-formControl': {
      marginTop: '0 !important',
    },
    '& .MuiInputBase-input': {
      lineHeight: '25px',
    },
    '& label': {
      marginLeft: '0',
      position: 'relative',
    },
  },
  cardRowSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardRowSpaceBetweenText: {
    '& input': {
      color: '#616284',
      fontSize: '0.85em',
    },
    '& .MuiInputBase-input': {
      textAlign: 'right',
    },
  },
  cardMultiline: {
    display: 'flex',
    margin: '0 -5px',
    '& .flex-item': {
      margin: '0 5px',
    },
    '& .column': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  accordionRoot: {
    borderTop: '1px solid rgb(224, 224, 224)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    '&.MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
    '& .MuiCollapse-container': {
      maxWidth: 'calc(100vw - 205px)',
    },
    '& .MuiAccordionSummary-root:hover:not(.Mui-disabled)': {
      cursor: 'default',
    },
  },
  accordionSummaryRoot: {
    backgroundColor: '#F9F9FB',
    borderBottom: '1px solid rgb(224, 224, 224)',
    marginBottom: -1,
    minHeight: '50px !important',
    maxHeight: '50px !important',
    padding: '0 12px',
    '&$expanded': {
      minHeight: '50px !important',
      maxHeight: '50px !important',
    },
  },
  accordionSummaryContent: {
    '&$expanded': {
      margin: '12px 0',
    },
    '& .MuiTypography-root': {
      marginLeft: '12px',
      color: '#4C5ECF',
      fontSize: '16px',
      fontWeight: 600,
      fontFamily: 'Proxima Nova Regular',
    },
  },
  accordionDetailsRoot: {
    padding: 0,
  },
  accordionSummaryHeader: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    '& .accordionSummaryHeaderContentLeft': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      cursor: 'pointer',
      '& .MuiTypography-root': {
        maxWidth: '1000px',
        flexWrap: 'wrap',
      },
    },
    '& .accordionSummaryHeaderContentRight': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      cursor: 'pointer',
    },
  },
  expanded: {},
}))

export default useStyles
