import styled from 'styled-components'

import { Option } from '@gmini/ui-kit/lib/Select/Select.styled'

export const SelectContainer = styled.div`
  position: relative;
`

export const ListItem = styled(Option)``

type WinnerPositionTextProps = {
  selected?: boolean
}

export const WinnerPositionText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${({ selected }: WinnerPositionTextProps) =>
    selected ? 'rgba(0, 3, 53, 1)' : 'rgba(0, 3, 53, 0.4)'};
  margin-bottom: 6px;
  display: flex;
`
export const PositionInfoText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #a2a3b7;
  padding-right: 8px;
`

type ListItemProps = {
  hover?: boolean
  active?: boolean
}

export const List = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props: ListItemProps) =>
    props.hover && 'background: rgba(53, 59, 96, 0.05);'}
  ${(props: ListItemProps) =>
    props.active && 'background: rgba(92, 130, 229, 0.15);'}
  ${(props: ListItemProps) =>
    props.active &&
    props.hover &&
    'background: linear-gradient(rgba(53, 59, 96, 0.05), rgba(53, 59, 96, 0.05)), linear-gradient(rgba(92, 130, 229, 0.15), rgba(92, 130, 229, 0.15));'}
    &:hover {
    background: rgba(53, 59, 96, 0.05);
  }
  cursor: default;
`

export const IconWrap = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
`

export const TooltipText = styled.div`
  width: 100%;
  display: flex;
`
