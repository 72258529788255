/* eslint-disable import/no-anonymous-default-export */
import * as _ from 'lodash'
import { IRow, TPosition, TTableData } from '@grand-tender/types'
import { EActionTypes } from '@grand-tender/ui'

import { IBaseActionCreator } from '../../actions/BaseAction'

export type ISetPositionsAction = IBaseActionCreator<
  EActionTypes.SET_POSITIONS,
  TTableData
>

export type IAddPositionAction = IBaseActionCreator<
  EActionTypes.ADD_POSITION,
  IRow
>

export type IEditPositionAction = IBaseActionCreator<
  EActionTypes.EDIT_POSITION,
  IRow
>

export type IDeletePositionsAction = IBaseActionCreator<
  EActionTypes.REMOVE_POSITIONS,
  string
>

export type IExportPositionsAction = IBaseActionCreator<
  EActionTypes.EXPORT_POSITIONS,
  string
>

export type ISetPositionsCategoryAction = IBaseActionCreator<
  EActionTypes.SET_CATEGORY,
  string
>

export type IResetPositions = IBaseActionCreator<
  EActionTypes.RESET_POSITIONS,
  string
>

export type TPositionsAction =
  | ISetPositionsAction
  | IAddPositionAction
  | IEditPositionAction
  | IDeletePositionsAction
  | IExportPositionsAction
  | ISetPositionsCategoryAction
  | IResetPositions

const initialState: TPosition = {
  categoryId: '',
  data: {
    columns: [],
    data: [],
    pagination: {
      page: 1,
      rowsPerPage: 10,
      total: 1,
    },
  },
}

export default (state = initialState, action: TPositionsAction): TPosition => {
  switch (action.type) {
    case EActionTypes.SET_POSITIONS: {
      const newState = _.chain(state)
        .cloneDeep()
        .extend({ data: action.payload })
        .value()
      return newState
    }
    case EActionTypes.ADD_POSITION:
      return _.chain(state)
        .cloneDeep()
        .extend({
          data: {
            columns: [...state.data.columns],
            data: [...state.data.data, action.payload],
            pagination: {
              ...state.data.pagination,
              total: state.data.pagination.total + 1,
            },
          },
        })
        .value()
    case EActionTypes.EDIT_POSITION: {
      /*
      const row = state.data.data.find(
        item => item.rowId === action.payload.rowId,
      )

      if (row) {
        _.extend(row, { ...action.payload }) // todo мутирует состояние переделать
      }
       */

      return _.cloneDeep(state)
    }
    case EActionTypes.REMOVE_POSITIONS: {
      return _.chain(state)
        .cloneDeep()
        .extend({
          data: {
            columns: [...state.data.columns],
            data: [
              ...state.data.data.filter(
                item => action.payload.indexOf(item.rowId) === -1,
              ),
            ],
            pagination: { ...state.data.pagination },
          },
        })
        .value()
    }
    case EActionTypes.EXPORT_POSITIONS: {
      return _.cloneDeep(state)
    }
    case EActionTypes.SET_CATEGORY: {
      return _.chain(state)
        .cloneDeep()
        .extend({ categoryId: action.payload })
        .value()
    }
    case EActionTypes.RESET_POSITIONS: {
      return initialState
    }
    default:
      return state
  }
}
