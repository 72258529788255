import React from 'react'

import { Dialog } from '@material-ui/core'
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete'
import { IKeyValuePair } from '@grand-tender/types'
import { EButtonType } from '../../constants'
import { ModalTitle } from '../BaseModal/ModalTitle/ModalTitle'
import { ModalContent } from '../BaseModal/ModalContent/ModalContent'
import { ModalActions } from '../BaseModal/ModalActions/ModalActions'
import { useStyles } from './Style'
import * as I from './IChangeAssignmentModal'
import ButtonComponent from '../Button/Button'
import { SearchInput } from '../SearchInput/SearchInput'

const ChangeAssignmentModal: React.FC<I.EnchancedProps> = ({
  open,
  dataIsLoading,
  assignmentOptionsListOpen,
  assignmentValues,
  errors,
  onSelectedValueChanged,
  toggleAssignmentOptionsList,
  onAssignmentSearchInputChange,
  onSave,
  onClose,
}): React.ReactElement => {
  const classes = useStyles()

  return (
    <div>
      {open && (
        <Dialog
          onClose={onClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          disableBackdropClick={true}
          className={classes.root}
          maxWidth='xs'
          fullWidth
        >
          <ModalTitle id='customized-dialog-title' onClose={onClose}>
            Сменить тендерное задание
          </ModalTitle>
          <ModalContent dividers>
            <SearchInput
              label={'Тендерное задание:'}
              open={assignmentOptionsListOpen}
              data={assignmentValues}
              dataIsLoading={dataIsLoading}
              helperText={errors.assignmentId}
              onOpen={(): void => toggleAssignmentOptionsList(true)}
              onClose={(): void => toggleAssignmentOptionsList(false)}
              filterFunc={(
                options: Array<IKeyValuePair>,
                s: FilterOptionsState<IKeyValuePair>,
              ): Array<IKeyValuePair> => options}
              onChange={onSelectedValueChanged}
              onInputChange={onAssignmentSearchInputChange}
            />
          </ModalContent>
          <ModalActions>
            <div className={classes.buttonContainer}>
              <ButtonComponent
                text='Отменить'
                type={EButtonType.DEFAULT}
                onClick={onClose}
              />
              <ButtonComponent
                text='Сохранить'
                type={EButtonType.PRIMARY}
                disabled={dataIsLoading}
                onClick={onSave}
              />
            </div>
          </ModalActions>
        </Dialog>
      )}
    </div>
  )
}

export default ChangeAssignmentModal
