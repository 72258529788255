import React from 'react'
import { Divider, IconButton, ListSubheader } from '@material-ui/core'
import { collapseIcon, settingIcon } from '@grand-tender/ui'
import { TTreeList } from '@grand-tender/types'

import usePositionCatalogStyles from '../../../styles/Style'

export type CatalogViewHeaderProps = {
  editable: boolean
  categoryId: number
  treeList: TTreeList
  setIsCatalogExpanded: (val: boolean) => void
  handleOpenSettingCatalog: (isOpen: boolean, treeData: TTreeList) => void
}

export const CatalogViewHeader: React.FC<CatalogViewHeaderProps> = ({
  editable,
  categoryId,
  treeList,
  setIsCatalogExpanded,
  handleOpenSettingCatalog,
}) => {
  const classes = usePositionCatalogStyles()

  return (
    <ListSubheader
      component='div'
      id='nested-list-subheader'
      className={classes.listTitle}
    >
      Каталог
      <div className={classes.iconContainer}>
        {editable && (
          <IconButton
            onClick={(): void => handleOpenSettingCatalog(true, treeList)}
            className={classes.closeButton}
          >
            {settingIcon}
          </IconButton>
        )}
        {categoryId !== 0 && (
          <>
            <Divider orientation='vertical' flexItem />
            <IconButton onClick={() => setIsCatalogExpanded(false)}>
              {collapseIcon}
            </IconButton>
          </>
        )}
      </div>
    </ListSubheader>
  )
}
