import {
  IAPIResult,
  TTreeList,
  TTreeModifyDict,
  TContractorsDoc,
} from '@grand-tender/types'
import {
  apiClient,
  ApiResponse,
} from '@grand-tender/base-api-service/src'

const headers: Record<string, string> = { 'Content-Type': 'application/json' }

export default class CategoryService {
  static getCategories(): Promise<ApiResponse<IAPIResult<TTreeList>>> {
    return apiClient.get<IAPIResult<TTreeList>>({
      path: `/category/all`,
      headers,
    })
  }
  static getCategoriesPassed(filterOptions?: {
    startDate: string
    endDate: string
  }): Promise<ApiResponse<IAPIResult<TTreeList>>> {
    return filterOptions
      ? apiClient.get({
          path: `/category/all/passed?start-date=${filterOptions.startDate}&end-date=${filterOptions.endDate}`,
          headers,
        })
      : apiClient.get({ path: `/category/all/passed`, headers })
  }
  static getCategoriesNotPassed(): Promise<ApiResponse<IAPIResult<TTreeList>>> {
    return apiClient.get({ path: `/category/all/active`, headers })
  }
  static getCategoriesNotArchive(): Promise<
    ApiResponse<IAPIResult<TTreeList>>
  > {
    return apiClient.get({ path: `/category/archived`, headers })
  }
  static getCategoriesPost(params: {
    userContract: Omit<TContractorsDoc, 'contractName'> | null | undefined
  }): Promise<ApiResponse<IAPIResult<TTreeList>>> {
    return apiClient.get({
      path: '/category/all',
      headers,
      params: params || {},
    })
  }
  static getCategoriesPassedPost(params: {
    userContract: Omit<TContractorsDoc, 'contractName'> | null | undefined
  }): Promise<ApiResponse<IAPIResult<TTreeList>>> {
    return apiClient.get({
      path: `/category/all/passed`,
      headers,
      params: params || {},
    })
  }
  static getCategoriesNotPassedPost(params: {
    userContract: Omit<TContractorsDoc, 'contractName'> | null | undefined
  }): Promise<ApiResponse<IAPIResult<TTreeList>>> {
    return apiClient.get({
      path: `/category/all/active`,
      headers,
      params: params || {},
    })
  }

  static getCategoriesSupplier(): Promise<ApiResponse<IAPIResult<TTreeList>>> {
    return apiClient.get({ path: `/category/provider/all`, headers })
  }
  static getCategoriesSupplierActive(): Promise<
    ApiResponse<IAPIResult<TTreeList>>
  > {
    return apiClient.get({ path: `/category/provider/all/active`, headers })
  }

  static modifyCategories(
    changes: TTreeModifyDict,
  ): Promise<ApiResponse<IAPIResult<TTreeList>>> {
    return apiClient.post({ path: `/category/modify`, headers, body: changes })
  }

  static modifyCategoriesSupplier(
    changes: TTreeModifyDict,
  ): Promise<ApiResponse<IAPIResult<TTreeList>>> {
    return apiClient.post({
      path: `/category/provider/modify`,
      headers,
      body: changes,
    })
  }

  static getCategoriesAgreement(): Promise<ApiResponse<IAPIResult<TTreeList>>> {
    return apiClient.get({ path: `/category/agreement/all`, headers })
  }

  static getCategoriesAgreementFinished(): Promise<
    ApiResponse<IAPIResult<TTreeList>>
  > {
    return apiClient.get({ path: `/category/agreement/all/finished`, headers })
  }

  static getCategoriesAgreementPost(params: {
    userContract: Omit<TContractorsDoc, 'contractName'> | null | undefined
  }): Promise<ApiResponse<IAPIResult<TTreeList>>> {
    return apiClient.post({
      path: `/category/agreement/all`,
      headers,
      body: params,
    })
  }

  static getCategoriesAgreementFinishedPost(params: {
    userContract: Omit<TContractorsDoc, 'contractName'> | null | undefined
  }): Promise<ApiResponse<IAPIResult<TTreeList>>> {
    return apiClient.post({
      path: `/category/agreement/all/finished`,
      headers,
      body: params,
    })
  }

  static modifyCategoriesAgreement(
    changes: TTreeModifyDict,
  ): Promise<ApiResponse<IAPIResult<TTreeList>>> {
    return apiClient.post({
      path: `/category/agreement/modify`,
      headers,
      body: changes,
    })
  }

  static getCategoriesAssignments(): Promise<
    ApiResponse<IAPIResult<TTreeList>>
  > {
    return apiClient.get({ path: `/category/tender/all`, headers })
  }

  static getCategoriesAssignmentsFinished(): Promise<
    ApiResponse<IAPIResult<TTreeList>>
  > {
    return apiClient.get({ path: `/category/tender/all/finished`, headers })
  }

  static getCategoriesAssignmentsArchived(): Promise<
    ApiResponse<IAPIResult<TTreeList>>
  > {
    return apiClient.get({ path: `/category/tender/archived`, headers })
  }

  static getCategoriesAssignmentsPost(params: {
    userContract: Omit<TContractorsDoc, 'contractName'> | null | undefined
  }): Promise<ApiResponse<IAPIResult<TTreeList>>> {
    return apiClient.post({
      path: `/category/tender/all`,
      headers,
      body: params,
    })
  }

  static getCategoriesAssignmentsFinishedPost(params: {
    userContract: Omit<TContractorsDoc, 'contractName'> | null | undefined
  }): Promise<ApiResponse<IAPIResult<TTreeList>>> {
    return apiClient.post({
      path: `/category/tender/all/finished`,
      headers,
      body: params,
    })
  }

  static modifyCategoriesAssignments(
    changes: TTreeModifyDict,
  ): Promise<ApiResponse<IAPIResult<TTreeList>>> {
    return apiClient.post({
      path: `/category/tender/modify`,
      headers,
      body: changes,
    })
  }
}
