import React, { HTMLAttributes, useState } from 'react'
import { Tooltip } from '@gmini/ui-kit'
import { IAgreementRow, ITreeListItem, TTreeList } from '@grand-tender/types'

import {
  useToolbarStyles,
  Item,
  ItemContainer,
  ItemContent,
  ItemTitle,
  Name,
  ProjectsAndObjects,
  ProjectsItemContent,
  ProjectsItem,
  ShowAllButton,
  ArrowDown,
  ProjectsItemWrapper,
} from './assignment-lot-description.styled'

type AssignmentLotDescriptionProps = HTMLAttributes<HTMLDivElement> & {
  description: string
  specialization: TTreeList
  lotStatus: string
  agreements: IAgreementRow[]
}

const specializationRender = (spec: TTreeList) =>
  spec.map((sp: ITreeListItem, index) => (
    <div key={sp.id}>
      {sp.name}
      {spec.length > 0 && index !== spec.length - 1 && <>,</>}
    </div>
  ))

export const AssignmentLotDescription = ({
  description,
  specialization,
  lotStatus,
  agreements,
}: AssignmentLotDescriptionProps) => {
  const [isAgreementsExpanded, setIsSuppliersExpanded] = useState(false)
  const classes = useToolbarStyles()

  const agreementsRender = agreements.map(ag => (
    <ProjectsItemContent key={ag.provider}>
      <Name>{ag.provider}</Name>
      <ProjectsAndObjects>
        {ag.projectsAndObjects.length
          ? ag.projectsAndObjects.reduce(
              (acc, item, i) =>
                acc.concat(
                  `${i === 0 ? '' : ' | '}${
                    item.project.name
                  }: ${item.objects
                    .map(o => o?.name || 'Все объекты')
                    .join(', ')}`,
                ),
              ' / ',
            )
          : ' / Все проекты: Все объекты'}
      </ProjectsAndObjects>
    </ProjectsItemContent>
  ))

  return (
    <ItemContainer>
      <Item>
        <ItemTitle>Описание</ItemTitle>
        <ItemContent>
          {description ? (
            <Tooltip
              placement='bottom-start'
              classes={{
                tooltip: classes.DesTooltip,
              }}
              title={description || ''}
            >
              <div>{description}</div>
            </Tooltip>
          ) : (
            '-'
          )}
        </ItemContent>
      </Item>
      <Item>
        <ItemTitle>Специализация</ItemTitle>
        <ItemContent>
          {specialization.length ? (
            <Tooltip
              placement='bottom-start'
              classes={{
                tooltip: classes.DesTooltip,
              }}
              title={specializationRender(specialization) || ''}
            >
              <div>{specializationRender(specialization)}</div>
            </Tooltip>
          ) : (
            '-'
          )}
        </ItemContent>
      </Item>
      <Item>
        <ItemTitle>Статус</ItemTitle>
        <ItemContent>{lotStatus ? <div>{lotStatus}</div> : '-'}</ItemContent>
      </Item>
      <ProjectsItemWrapper>
        <ProjectsItem expanded={isAgreementsExpanded}>
          <ItemTitle>Поставщик / Проект: Объект</ItemTitle>
          {agreementsRender.length ? agreementsRender : '-'}
        </ProjectsItem>
        {agreementsRender.length > 3 ? (
          <ShowAllButton onClick={() => setIsSuppliersExpanded(s => !s)}>
            <span>Показать {isAgreementsExpanded ? 'меньше' : 'все'}</span>
            <ArrowDown expanded={isAgreementsExpanded} color='#4c5ecf' />
          </ShowAllButton>
        ) : null}
      </ProjectsItemWrapper>
    </ItemContainer>
  )
}
