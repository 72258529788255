export const AGREEMENT_TABLE_COLUMNS = [
  { key: 'number', title: 'Соглашение' },
  { key: 'providerName', title: 'Поставщик' },
  { key: 'tenderTask', title: 'Тендерное задание' },
  { key: 'startDate', title: 'Дата начала действия' },
  { key: 'expirationDate', title: 'Дата окончания действия' },
  { key: 'price', title: 'Цена' },
  { key: 'project', title: 'Проект' },
  { key: 'object', title: 'Объект' },
]

export const AGREEMENT_TABLE_COLUMNS_WITHOUT_PRICE = [
  { key: 'number', title: 'Соглашение' },
  { key: 'providerName', title: 'Поставщик' },
  { key: 'tenderTask', title: 'Тендерное задание' },
  { key: 'startDate', title: 'Дата начала действия' },
  { key: 'expirationDate', title: 'Дата окончания действия' },
  { key: 'project', title: 'Проект' },
  { key: 'object', title: 'Объект' },
]
