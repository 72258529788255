import ReactDOM from 'react-dom'
import React from 'react'

import './index.css'
import * as serviceWorker from '@grand-tender/ui/src/serviceWorker'

import store from './store'
import { Auth } from './pages/auth/auth'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.getState = store.getState

ReactDOM.render(<Auth />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
