import * as _ from 'lodash'
import { ETreeListMovingType } from '@grand-tender/ui'

import { findTreeNode } from '../../../../utils'
import { CatalogState } from '../catalog-slice'

export const mergeNodeAction = <
  T extends { payload: { destinationId: string } }
>(
  state: CatalogState,
  { payload: { destinationId } }: T,
): CatalogState => {
  const { selectedMenuItemAction, mergeType } = state
  const modifyDictState = _.cloneDeep(state.modifyDict)
  const newSettingsData = _.cloneDeep(state.settingsTreeData)

  if (!selectedMenuItemAction) {
    return state
  }

  _.remove(
    modifyDictState.move,
    i => i.sourceId.toString() === selectedMenuItemAction.id.toString(),
  )

  if (selectedMenuItemAction.parentId) {
    const parentNode = findTreeNode(
      newSettingsData,
      selectedMenuItemAction.parentId,
    )
    if (parentNode) {
      _.remove(parentNode.content, i => i.id === selectedMenuItemAction.id)
    }
  } else {
    _.remove(newSettingsData, i => i.id === selectedMenuItemAction.id)
  }

  const destinationNode = findTreeNode(newSettingsData, destinationId)
  if (destinationNode) {
    if (mergeType === ETreeListMovingType.MOVE) {
      modifyDictState.move.push({
        sourceId: selectedMenuItemAction.id,
        destinationId,
      })

      destinationNode.content.push({
        ...selectedMenuItemAction,
        parentId: destinationId,
      })
    } else {
      modifyDictState.concat.push({
        sourceId: selectedMenuItemAction.id,
        destinationId,
      })

      destinationNode.content.push(
        ...selectedMenuItemAction.content.map((c: any) => ({
          ...c,
          parentId: destinationNode?.id,
        })),
      )
    }
  }

  return {
    ...state,
    modifyDict: modifyDictState,
    settingsTreeData: newSettingsData,
    hasUnsavedChanges: true,
  }
}
