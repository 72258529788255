import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import {
  ButtonComponent,
  CheckboxField,
  EButtonType,
  ModalComponent,
} from '@grand-tender/ui'

import { FormHelperText, TextField } from '@material-ui/core'

import { useAppDispatch } from '../../store'
import { createTenderCondition, getAssignmentsCondition } from '../../actions'

import { ConditionList } from './components/condition-list'

import styles from './styles.module.scss'

export type TNewTenderConditionDialog = {
  open: boolean
  handleClose: () => void
  handleSubmit: (values: unknown) => void
  tenderAssignmentId: number
  selectedLotId: number
}

export type TTenderCondition = Array<{
  label: string
  conditions: Array<string>
}>

export const NewTenderConditionDialog: React.FC<TNewTenderConditionDialog> = ({
  open,
  handleClose,
  handleSubmit,
  tenderAssignmentId,
  selectedLotId,
}) => {
  const dispatch = useAppDispatch()
  const [tenderConditions, setTenderConditions] = useState<TTenderCondition>([])
  const [filter, setFilter] = useState<string>('')

  const filteredTenderConditions = useMemo(
    () =>
      tenderConditions
        .map(item => ({
          ...item,
          conditions: item.conditions.filter(
            condition =>
              condition.toLowerCase().indexOf(filter.toLowerCase()) >= 0,
          ),
        }))
        .filter(item => item.conditions.length),
    [filter, tenderConditions],
  )

  const formik = useFormik({
    initialValues: {
      isAllLots: false,
      selectedList: [],
    },
    validate: values => {
      const errors: Record<string, string> = {}

      if (values.selectedList.length === 0) {
        errors.selectedList = 'Выберите одно или несколько ТУ'
      }

      return errors
    },
    onSubmit: values => {
      const sendValues = {
        ...values,
        additionalConditions: values.selectedList,
        selectedLotId,
        tenderAssignmentId,
      }
      dispatch(createTenderCondition(sendValues)).then(() =>
        handleSubmit(sendValues),
      )
    },
  })

  useEffect(() => {
    ;(() => {
      try {
        dispatch(getAssignmentsCondition({}))
          .unwrap()
          .then(response => {
            const conditions = Object.keys(response.tenderConditions).map(
              (label: string) => ({
                label,
                conditions: response.tenderConditions[label as any] as any,
              }),
            )
            setTenderConditions(conditions)
          })

        // eslint-disable-next-line no-empty
      } catch {}
    })()
  }, [dispatch])

  const handleSelectedList = useCallback(
    (condition: Array<string>): void => {
      formik.setFieldValue('selectedList', condition)
    },
    [formik],
  )

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void | null => {
    setFilter(event.target.value)
  }

  return (
    <ModalComponent
      onClose={handleClose}
      open={open}
      title='Добавить условие'
      wrapClassName={styles.modal}
      contentClassName={styles.modalContent}
    >
      <form
        onSubmit={formik.handleSubmit}
        className={styles.newTenderCondition}
      >
        <TextField
          name='conditionName'
          type='text'
          fullWidth
          placeholder='Поиск'
          variant='outlined'
          onChange={handleInputChange}
        />
        <div className={styles.formContent}>
          <ConditionList
            list={filteredTenderConditions}
            handleSelectedList={handleSelectedList}
          />
          <FormHelperText error={true}>
            {formik.errors.selectedList}
          </FormHelperText>
        </div>
        <CheckboxField
          name='isAllLots'
          label='Добавить условие ко всем Лотам'
          checked={formik.values.isAllLots}
          handleChange={formik.handleChange}
        />
        <div className={styles.formActions}>
          <ButtonComponent
            htmlType='reset'
            text='Отменить'
            type={EButtonType.DEFAULT}
            onClick={handleClose}
            className={styles.cancelButton}
          />
          <ButtonComponent
            htmlType='submit'
            text='Добавить условие'
            type={EButtonType.PRIMARY}
          />
        </div>
      </form>
    </ModalComponent>
  )
}
