import React, { useState } from 'react'
import { useFormik } from 'formik'
import {
  Dialog,
  FormControlLabel,
  List,
  Popover,
  PopoverOrigin,
  TextField,
} from '@material-ui/core'
import {
  ButtonComponent,
  EButtonType,
  PositionCatalogMini,
  ListEditorItem,
  ModalActions,
  ModalContent,
  ModalTitle,
} from '@grand-tender/ui'

import { IAssignmentLot, TTreeList } from '@grand-tender/types'
import * as yup from 'yup'

import styles from './styles.module.scss'

const REQUIRED_FIELD = 'Обязательное поле'

export const validationSchema = yup.object().shape({
  name: yup.string().required(REQUIRED_FIELD),
  specialization: yup.array().min(1, REQUIRED_FIELD),
})

const validationSchemaNameOnly = yup.object().shape({
  name: yup.string().required(REQUIRED_FIELD),
})

type TProps = {
  lot?: IAssignmentLot
  onClose: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSave: (data: any) => void
  specialization: TTreeList
}

export const LotModal: React.FC<TProps> = ({
  lot,
  onClose,
  onSave,
  specialization,
}) => {
  const formik = useFormik({
    initialValues: {
      name: lot?.name || '',
      description: lot?.description || '',
      specialization: lot?.specialization || [],
    },
    validationSchema: lot?.hasAgreement
      ? validationSchemaNameOnly
      : validationSchema,
    onSubmit: values => onSave(values),
  })

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)

  const openPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closePopup = () => setAnchorEl(null)

  const popupAnchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  }

  const popupTransformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'left',
  }

  const handleSelectNode = (node: any) => {
    setAnchorEl(null)
    formik.setFieldValue('specialization', [
      ...formik.values.specialization,
      node,
    ])
  }

  return (
    <Dialog
      onClose={onClose}
      open={true}
      disableBackdropClick={true}
      className={styles.root}
    >
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <ModalTitle id='lot-modal' onClose={onClose}>
          {lot ? 'Изменить лот' : 'Добавить лот'}
        </ModalTitle>
        <ModalContent>
          <div>
            <FormControlLabel
              key='name'
              className='fullWidth'
              control={
                <TextField
                  id='name'
                  name='name'
                  value={formik.values.name}
                  type='text'
                  multiline={false}
                  maxRows={4}
                  fullWidth
                  variant='filled'
                  onChange={value =>
                    formik.setFieldValue('name', value.target.value)
                  }
                />
              }
              label='Название'
              labelPlacement='top'
            />
            {formik.touched.name && formik.errors.name && (
              <div className={styles.err}>{formik.errors.name}</div>
            )}
          </div>
          <div>
            <FormControlLabel
              disabled={lot?.hasAgreement}
              key='description'
              className='fullWidth'
              control={
                <TextField
                  id='description'
                  name='description'
                  value={formik.values.description}
                  type='text'
                  rows={4}
                  multiline
                  fullWidth
                  variant='filled'
                  onChange={value =>
                    formik.setFieldValue('description', value.target.value)
                  }
                />
              }
              label='Описание'
              labelPlacement='top'
            />
            {formik.touched.description && formik.errors.description && (
              <div className={styles.err}>{formik.errors.description}</div>
            )}
          </div>
          <div>
            <div className={styles.label}>Специализация</div>
            <ButtonComponent
              text='Добавить'
              type={EButtonType.DEFAULT}
              onClick={openPopup}
              disabled={lot?.hasAgreement}
            />
            <Popover
              anchorEl={anchorEl}
              id='catalog-popover'
              className={styles.catalogSpec}
              anchorOrigin={popupAnchorOrigin}
              transformOrigin={popupTransformOrigin}
              open={Boolean(anchorEl)}
              onClose={closePopup}
            >
              <PositionCatalogMini
                anchorEl={anchorEl}
                catalog={specialization}
                onSelectFullNode={handleSelectNode}
                isFullNodeNeed
              />
            </Popover>
            <List dense={true} className={styles.listEditor}>
              {formik.values.specialization.map((node: any) => (
                <ListEditorItem
                  key={node.id}
                  value={node.name}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onDelete={() => {}}
                />
              ))}
            </List>
            {formik.touched.specialization && formik.errors.specialization && (
              <div className={styles.err}>{formik.errors.specialization}</div>
            )}
          </div>
        </ModalContent>
        <ModalActions>
          <div className={styles.buttonContainer}>
            <ButtonComponent
              htmlType='reset'
              text='Отменить'
              type={EButtonType.DEFAULT}
              onClick={onClose}
            />
            <ButtonComponent
              text='Сохранить'
              type={EButtonType.PRIMARY}
              htmlType='submit'
            />
          </div>
        </ModalActions>
      </form>
    </Dialog>
  )
}
