import {
  IAPIResult,
  TTableData,
  TRowData,
  IRow,
  IColumn,
  TSupplierModel,
  IKeyValuePair,
  TSort,
  TTreeList,
  UploadDocumentParams,
  TImportSuppliersResponse,
  TSearchRequest,
  TSearchResponse,
  TSuppliersFilters,
  SupplierInfo,
  ExportAllSuppliers,
} from '@grand-tender/types'
import { apiClient, ApiResponse } from '@grand-tender/base-api-service/src'

const headers: Record<string, string> = { 'Content-Type': 'application/json' }

export default class SuppliersService {
  static getSuppliersOld(
    page: number,
    perPage: number,
    categoryId: string,
    sortColumnKey: number,
    sortColumnDirection: TSort,
  ): Promise<ApiResponse<IAPIResult<TTableData>>> {
    let body: Record<string, number | string | boolean> = {
      page,
      perPage,
      categoryId,
    }

    if (sortColumnKey) {
      body = {
        ...body,
        fieldKey: sortColumnKey,
        ascending: sortColumnDirection === 'asc',
      }
    }

    return apiClient.post({ path: `/provider/page`, headers, body })
  }

  static getSuppliers(
    params: Partial<TSearchRequest>,
    suppliersFilters: TSuppliersFilters,
  ): Promise<ApiResponse<IAPIResult<TSearchResponse>>> {
    const body = {
      ...params,
      pageData: {
        ...params.pageData,
        categoryId:
          params.pageData?.categoryId === '0'
            ? null
            : params.pageData?.categoryId,
      },
      table: 'providers',
      fetchCategories: true,
      isAgreed: suppliersFilters.agreedSuppliers,
      isNotAgreed: suppliersFilters.notAgreedSuppliers,
      isExpired: suppliersFilters.expiredSuppliers,
    }
    return apiClient.post({ path: '/provider/table', headers, body })
  }

  static getSuppliersSelectAll(
    categoryId: string,
  ): Promise<ApiResponse<IAPIResult<Array<string>>>> {
    return apiClient.post({
      path: `/provider/select/all/${categoryId}`,
      headers,
    })
  }

  static getSuppliersActive(
    page: number,
    perPage: number,
    categoryId: string,
    sortColumnKey: number,
    sortColumnDirection: TSort,
  ): Promise<ApiResponse<IAPIResult<TTableData>>> {
    let body: Record<string, number | string | boolean> = {
      page,
      perPage,
      categoryId,
    }

    if (sortColumnKey) {
      body = {
        ...body,
        fieldKey: sortColumnKey,
        ascending: sortColumnDirection === 'asc',
      }
    }

    return apiClient.post({ path: `/provider/page/active`, headers, body })
  }

  static getSuppliersSelectAllActive(
    categoryId: string,
  ): Promise<ApiResponse<IAPIResult<Array<string>>>> {
    return apiClient.post({
      path: `/provider/select/active/${categoryId}`,
      headers,
    })
  }

  static addSupplier(
    supplier: TRowData,
  ): Promise<ApiResponse<IAPIResult<IRow>>> {
    return apiClient.put({
      path: `/provider`,
      headers,
      body: { data: supplier },
    })
  }

  static getSupplier(
    id: string,
  ): Promise<ApiResponse<IAPIResult<TSupplierModel>>> {
    return apiClient.get({ path: `/provider/detail/${id}`, headers })
  }

  // TODO 1193
  static exportAllSuppliers(
    params: ExportAllSuppliers,
  ): Promise<ApiResponse<IAPIResult<Blob>>> {
    return apiClient.post({
      path: `/export/providers/not-page`,
      body: params,
      responseType: 'blob',
    })
  }

  static exportSuppliersByIds(
    ids: Array<string>,
  ): Promise<ApiResponse<IAPIResult<Blob>>> {
    return apiClient.post({
      path: `/export/providers`,
      body: ids,
      responseType: 'blob',
    })
  }

  static deleteSuppliers(
    ids: Array<string>,
  ): Promise<ApiResponse<IAPIResult<string>>> {
    return apiClient.post({ path: `/provider/delete`, headers, body: ids })
  }

  static addProperty(
    model: IColumn,
    categoryId: string,
  ): Promise<ApiResponse<IAPIResult<IColumn>>> {
    const request = {
      categoryId,
      property: model,
      sectionId: model.section?.key || null,
    }

    return apiClient.put({
      path: `/category/provider/field`,
      headers,
      body: request,
    })
  }

  static editProperty(
    model: IColumn,
  ): Promise<ApiResponse<IAPIResult<IColumn>>> {
    return apiClient.post({
      path: `/category/provider/field`,
      headers,
      body: {
        ...model,
        sectionId: model.section?.key || null,
      },
    })
  }

  static removeProperty(key: string): Promise<ApiResponse<IAPIResult<{}>>> {
    return apiClient.delete({
      path: `/category/provider/field/${key}`,
      headers,
    })
  }

  static searchSuppliers(
    query: string,
    suppliersFilters: TSuppliersFilters,
  ): Promise<ApiResponse<IAPIResult<Array<SupplierInfo>>>> {
    const body = {
      query,
      isAgreed: suppliersFilters.agreedSuppliers,
      isNotAgreed: suppliersFilters.notAgreedSuppliers,
      isExpired: suppliersFilters.expiredSuppliers,
    }
    return apiClient.post({ path: `/provider/search/name`, headers, body })
  }

  static editSupplier(supplier: IRow): Promise<ApiResponse<IAPIResult<IRow>>> {
    return apiClient.post({ path: `/provider`, headers, body: supplier })
  }

  //TODO 1193
  static importSuppliers(
    file: File,
  ): Promise<ApiResponse<IAPIResult<TImportSuppliersResponse>>> {
    const formData = new FormData()
    formData.append('file', file)
    return apiClient.post({
      path: `/import/providers`,
      body: formData,
    })
  }

  static getSpecialisations(): Promise<ApiResponse<IAPIResult<TTreeList>>> {
    return apiClient.get({ path: `/provider/specialisations`, headers })
  }

  //TODO 1193

  static uploadDocument(params: UploadDocumentParams) {
    const formData = new FormData()

    Object.keys(params).forEach(key => {
      formData.append(key, params[key as keyof UploadDocumentParams])
    })
    return apiClient.post({
      path: `/attachment/savetoprovider`,
      body: formData,
    })
  }

  static deleteDocument(id: string | number): Promise<unknown> {
    return apiClient.delete({ path: `/attachment/delete/${id}` })
  }

  static addSection(
    name: string,
  ): Promise<ApiResponse<IAPIResult<IKeyValuePair>>> {
    return apiClient.put({
      path: `/section/provider`,
      headers,
      body: {
        name,
      },
    })
  }

  static editSection(
    id: string,
    name: string,
  ): Promise<ApiResponse<IAPIResult<IKeyValuePair>>> {
    return apiClient.post({
      path: `/section/provider`,
      headers,
      body: {
        id,
        name,
      },
    })
  }
  //TODO 1193
  static removeSection(id: string): Promise<ApiResponse<IAPIResult<void>>> {
    return apiClient.delete({ path: `/section/provider/${id}`, headers })
  }
  //TODO 1193
  static exportTemplate(): Promise<ApiResponse<IAPIResult<Blob>>> {
    return apiClient.get({
      path: '/export/providers/template',
      responseType: 'blob',
    })
  }

  static searchSections(
    query: string,
  ): Promise<ApiResponse<IAPIResult<TRowData>>> {
    return apiClient.post({
      path: '/section/provider/search',
      headers,
      body: {
        query,
      },
    })
  }

  static checkSupplierExistence(
    inn: string,
    kpp: string,
    excludeId: string,
  ): Promise<ApiResponse<IAPIResult<boolean>>> {
    return apiClient.get({
      path: `/provider/exists?inn=${inn}&kpp=${kpp}&excludeId=${excludeId}`,
      headers,
    })
  }
}
