import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Drawer } from '@material-ui/core'
import { TTreeList, TTreeModifyDict } from '@grand-tender/types'
import { DialogModal, EButtonType } from '@grand-tender/ui'

import { useAppDispatch } from '../../store'

import * as I from './IPositionCatalog'
import usePositionCatalogStyles from './styles/Style'
import { CatalogView } from './components/catalog-view'
import { CatalogSetting } from './components/catalog-setting-v2'
import { getCatalog } from './store/catalog-selectors'
import { setConfirmDialogOpen, reset } from './store/catalog-slice'

const PositionCatalog: React.FC<I.OwnProps> = ({
  editable,
  categoryId,
  categories,
  maxLevel,
  disableRootNodeAdding,
  disableNodeMovingConcat,
  onSave,
  onSelectNode,
  setIsCatalogExpanded,
  getCategories,
  withCopyingElements,
  withGroup,
  fetchPositions,
  onUpdateGroupColumnsVisibility,
  tabIndex,
}: I.OwnProps): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { confirmDialogOpen, modifyDict, hasUnsavedChanges } = useSelector(
    getCatalog,
  )
  const classes = usePositionCatalogStyles()
  const [treeList, setTreeList] = useState<TTreeList>([])
  /**
   * переключение режима настройки каталога
   */
  const [openSettings, setOpenSettings] = useState<boolean>(false)
  const [settingsTreeData, setSettingsTreeData] = useState<TTreeList>([])

  useEffect(() => {
    setTreeList(categories)
  }, [categories])

  const saveAllChanges = (modifyDictParam: TTreeModifyDict): void => {
    const dict = { ...modifyDictParam }

    if (modifyDictParam.remove.some(data => data === categoryId)) {
      onSelectNode()
    }

    onSave(dict)
    dispatch(setConfirmDialogOpen(false))
    dispatch(reset())
    setOpenSettings(false)
  }

  const handleOpenSettings = (): void => {
    if (hasUnsavedChanges) {
      dispatch(setConfirmDialogOpen(true))
      return
    }
    dispatch(reset())
    setOpenSettings(false)
  }

  const handleCloseConfirmDialog = (): void => {
    dispatch(setConfirmDialogOpen(false))
  }

  const handleDiscardChanges = (): void => {
    dispatch(setConfirmDialogOpen(false))
    dispatch(reset())
    setOpenSettings(false)
  }

  return (
    <React.Fragment>
      {openSettings && (
        <Drawer
          className={classes.drawerList}
          anchor={'left'}
          open={openSettings}
          onClose={handleOpenSettings}
        >
          <CatalogSetting
            handleOpenSettings={handleOpenSettings}
            saveAllChanges={saveAllChanges}
            settingsTreeData={settingsTreeData}
            disableRootNodeAdding={disableRootNodeAdding}
            maxLevel={maxLevel}
            withCopyingElements={withCopyingElements}
            disableNodeMovingConcat={disableNodeMovingConcat}
            getCategories={getCategories}
            handleDiscardChanges={handleDiscardChanges}
            withGroup={withGroup}
          />
        </Drawer>
      )}
      <CatalogView
        editable={editable}
        categoryId={Number(categoryId)}
        treeList={treeList}
        handleOpenSettingCatalog={(openModal, treeData): void => {
          setOpenSettings(openModal)
          setSettingsTreeData(treeData)
        }}
        setIsCatalogExpanded={setIsCatalogExpanded}
        onSelectNode={onSelectNode}
        fetchPositions={fetchPositions}
        onUpdateGroupColumnsVisibility={onUpdateGroupColumnsVisibility}
        tabIndex={tabIndex}
      />
      {confirmDialogOpen && (
        <DialogModal
          open={confirmDialogOpen}
          handleDiscardChanges={handleDiscardChanges}
          handleChanges={(): void => saveAllChanges(modifyDict)}
          handleClose={handleCloseConfirmDialog}
          modalTitle={'Внимание!'}
          modalContent={'Есть несохраненные изменения каталога. Сохранить?'}
          modalButtonRightText={'Да'}
          modalButtonRightType={EButtonType.PRIMARY}
          modalButtonLeftText={'Нет'}
          modalButtonLeftType={EButtonType.DEFAULT}
        />
      )}
    </React.Fragment>
  )
}

export default PositionCatalog
