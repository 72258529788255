import React from 'react'
import { SvgIcon } from '@material-ui/core'
import { EDisabledTreeItemActions, ITreeListItem } from '@grand-tender/types'

export const DEFAULT_DISPLAY_DATE_FORMAT = 'DD.MM.YYYY'
export const REVERSE_DISPLAY_DATE_FORMAT = 'YYYY-MM-DD'

export enum EColumnType {
  STRING = 'string',
  PHONE = 'phone',
  BOOLEAN = 'boolean',
  DATE = 'date',
  INTEGER = 'integer',
  DOUBLE = 'double',
  CURRENCY = 'currency',
  CURRENCY_INFO = 'string',
  LIST = 'list',
  TREE_LIST = 'tree_list',
}

export enum EFormKeys {
  POSITIONS = 'positions',
  AGREEMENTS = 'agreements',
  SUPPLIERS = 'suppliers',
  ASSIGNMENTS = 'assignments',
}

export enum EActionTypes {
  SET_POSITIONS = 'setPositions',
  ADD_POSITION = 'addPosition',
  REMOVE_POSITIONS = 'removePositions',
  EDIT_POSITION = 'editPosition',
  EXPORT_POSITIONS = 'exportPositions',
  RESET_POSITIONS = 'resetPositions',
  RESET_SUPPLIERS = 'resetSuppliers',
  SET_SELECTED_SUPPLIERS = 'setSelectedSuppliers',
  SET_SUPPLIERS = 'setSuppliers',
  ADD_SUPPLIER = 'addSupplier',
  REMOVE_SUPPLIERS = 'removeSuppliers',
  EDIT_SUPPLIER = 'editSupplier',
  SET_AGREEMENTS = 'setAgreements',
  ADD_AGREEMENT = 'addAgreement',
  REMOVE_AGREEMENT = 'removeAgreement',
  EDIT_AGREEMENT = 'editAgreement',
  SET_AGREEMENT_DETAIL = 'setAgreementDetail',
  SET_ASSIGNMENTS = 'setAssignments',
  EDIT_ASSIGNMENT = 'editAssignment',
  SET_ASSIGNMENT_DETAIL = 'setAssignmentDetail',
  RENAME_ASSIGNMENT_LOT = 'renameAssignmentLot',
  REMOVE_ASSIGNMENT_LOT_SUPPLIER = 'removeAssignmentLotSupplier',
  CHANGE_ASSIGNMENT_LOT_CATEGORY_PAGE = 'changeAssignmentLotCategoryPage',
  SET_DATA_LOADING = 'setDataLoading',
  SET_CATEGORY = 'setCategory',
  SET_CATEGORIES = 'setCategories',
  SAVE_CATEGORIES_CHANGES = 'saveCategoriesChanges',
  SET_FILTER_FIELDS = 'setFilterFields',
  SET_AVAILABLE_COLUMNS = 'setAvailableColumns',
  SEARCH_ASSIGNMENTS = 'searchAssignments',
  SET_TENDER = 'setTender',
  SEARCH_OTHER_PAGE = 'searchOtherPage',
  SET_CATALOG_STATE = 'setCatalogState',
  SET_CATALOG_STATE_AGREEMENTS_VIEW = 'setCatalogStateAgreementsView',
  SET_CATALOG_STATE_AGREEMENTS_LIST = 'setCatalogStateAgreementsList',
  SET_CATEGORY_SUPPLIER = 'setCategorySupplier',
  SET_CATEGORIES_SUPPLIER = 'setCategoriesSupplier',
  SET_CATALOG_STATE_SUPPLIER = 'setCatalogStateSupplier',
  SET_CATEGORY_AGREEMENT = 'setCategoryAgreement',
  SET_CATEGORIES_AGREEMENT = 'setCategoriesAgreement',
  SET_CATEGORY_ASSIGNMENTS = 'setCategoryAssignments',
  SET_SEARCH_PARAMS_ASSIGNMENTS = 'setSearchParamsAssignments',
  SET_CATEGORIES_ASSIGNMENTS = 'setCategoriesAssignments',
  SET_CATALOG_STATE_ASSIGNMENTS_LIST = 'setCatalogStateAssignmentsList',
  RESET_SEARCH_OTHER_PAGE = 'resetSearchOtherPage',
  SET_USER_DATA = 'setUserData',
  SET_USER_PERMISSIONS = 'setUserPermissions',
  SET_SPECIALISATIONS = 'setSpecialisations',
  SET_SUPPLIERS_FILTERS = 'setSuppliersFilters',
}

export enum EButtonType {
  PRIMARY = 'primary',
  WARNING = 'warning',
  DEFAULT = 'default',
}

export enum EButtonIcon {
  ADD = 'addIcon',
  DELETE = 'deleteIcon',
  FILTER = 'filterIcon',
  SETTINGS = 'settingIcon',
  UPLOAD = 'uploadIcon',
  DOWNLOAD = 'downloadIcon',
  SEARCH = 'searchIcon',
  SAVE = 'saveIcon',
  EDIT = 'editIcon',
  PHOTO = 'photo',
  MENU = 'menu',
  ARROW_BACK = 'arrow-back',
  ARROW_RIGHT = 'arrow-right',
  REFRESH = 'refresh',
  CHECK = 'check',
  CIRCLE_CLOSE = 'circleCloseIcon',
  EXCEL = 'excel',
  EYE = 'eye',
  EXTEND = 'extendIcon',
  ARROW_UP = 'arrowUpIcon',
  ARROW_DOWN = 'arrowDownIcon',
  DETAILS = 'detailsIcon',
}

export enum EFileUploadMainState {
  INITIAL = 'initial',
  UPLOADED = 'uploaded',
  SEARCH = 'search',
  GALLERY = 'gallery',
}

export enum EFileUploadListType {
  IMAGE = 'image',
  FILE = 'file',
}

export enum EEditableModalType {
  GROUP = 'group',
  INSERT = 'insert',
  EDIT = 'edit',
}

export enum EAPIResponseStatus {
  SUCCESS = 'success',
  FAIL = 'fail',
  ERROR = 'error',
}

export enum ETreeListItemType {
  FOLDER = 'folder',
  LIST = 'list',
  GROUP = 'group',
}

export enum ETreeListMovingType {
  MOVE = 'move',
  CONCAT = 'concat',
}

export enum EFilterOperator {
  EQ = 'eq',
  NE = 'ne',
  GT = 'gt',
  LT = 'lt',
  GTE = 'gte',
  LTE = 'lte',
  CONTAIN = 'contain',
  INTERVAL = 'interval',
}

export const DFormKeysNames: { [key in EFormKeys]: string } = {
  positions: 'Позиции',
  agreements: 'Соглашения',
  suppliers: 'Поставщики',
  assignments: 'Тендерные задания',
}

export enum EValidationMessage {
  REQUIRED_FIELD = 'Обязательное поле',
  OPTION_NOT_SELECTED = 'Значение не выбрано',
  OPTIONS_EMPTY = 'Отсутствует список допустимых значений',
  UNKNOWN_OPTION = 'Выбранное значение отсутствует в списке доступных значений',
  ONLY_POSITIVE = 'Только целые числа',
  INCORRECT_PHONE = 'Номер введён не полностью',
  ONLY_BOOLEAN = 'Только логическое значение',
  INCORRECT_DATE = 'Неверная дата',
  ONLY_DECIMAL_OR_INTEGER = 'Только целые или дробные числа',
  UNKNOWN_COLUMN = 'Неизвестная колонка',
  NONE = '',
}

export type TValidateResult = {
  valid: boolean
  message: EValidationMessage
}

export enum TAssignmentStatus {
  New = 'NEW',
  SendToProviders = 'SEND_TO_PROVIDERS',
  ChoseProvider = 'CHOSE_PROVIDER',
  Closed = 'CLOSED',
}

export enum TAssignmentStatusDict {
  NEW = 'Новое',
  SEND_TO_PROVIDERS = 'Отправлено поставщикам',
  CHOSE_PROVIDER = 'Выбор поставщика',
  CLOSED = 'Закрыто',
}

export const DSideBarItemContent: { [key in EFormKeys]: JSX.Element } = {
  positions: (
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.16667 1.66669C8.70643 1.66669 8.33333 2.03978 8.33333 2.50002C8.33333 2.96026 8.70643 3.33335 9.16667 3.33335H14.8333C15.3856 3.33335 15.8333 3.78107 15.8333 4.33335V11.6667C15.8333 12.1269 16.2064 12.5 16.6667 12.5C17.1269 12.5 17.5 12.1269 17.5 11.6667V3.33335C17.5 2.41669 16.75 1.66669 15.8333 1.66669H9.16667ZM6.66667 4.16669C6.20643 4.16669 5.83333 4.53978 5.83333 5.00002C5.83333 5.46026 6.20643 5.83335 6.66667 5.83335H12.3333C12.8856 5.83335 13.3333 6.28107 13.3333 6.83336V14.1667C13.3333 14.6269 13.7064 15 14.1667 15C14.6269 15 15 14.6269 15 14.1667V5.83335C15 4.91669 14.25 4.16669 13.3333 4.16669H6.66667ZM10.8333 6.66669H4.16667C3.25 6.66669 2.5 7.41669 2.5 8.33335V16.6667C2.5 17.5834 3.25 18.3334 4.16667 18.3334H10.8333C11.75 18.3334 12.5 17.5834 12.5 16.6667V8.33335C12.5 7.41669 11.75 6.66669 10.8333 6.66669ZM5.16667 16.6667C4.61438 16.6667 4.16667 16.219 4.16667 15.6667V9.33335C4.16667 8.78107 4.61438 8.33335 5.16667 8.33335H9.83333C10.3856 8.33335 10.8333 8.78107 10.8333 9.33335V15.6667C10.8333 16.219 10.3856 16.6667 9.83333 16.6667H5.16667Z'
      fill='inherit'
    />
  ),
  agreements: (
    <path
      d='M10.4061 1.01382C10.1476 0.898901 9.85243 0.898901 9.59386 1.01382L3.09386 3.90271C2.73273 4.06321 2.5 4.42133 2.5 4.81652V9.16665C2.5 13.7916 5.7 18.1166 10 19.1666C14.3 18.1166 17.5 13.7916 17.5 9.16665V4.81652C17.5 4.42133 17.2673 4.06321 16.9061 3.90271L10.4061 1.01382ZM15.8333 9.16665C15.8333 12.9333 13.35 16.4083 10 17.4416C6.65 16.4083 4.16667 12.9333 4.16667 9.16665V5.57496C4.16667 5.37735 4.28306 5.19827 4.46366 5.11803L9.79699 2.74851C9.92624 2.69108 10.0738 2.69108 10.203 2.74851L15.5363 5.11803C15.7169 5.19827 15.8333 5.37734 15.8333 5.57496V9.16665ZM7.5964 9.41077C7.27144 9.08707 6.74574 9.08757 6.4214 9.41191C6.09662 9.73669 6.09662 10.2633 6.4214 10.588L8.45956 12.6262C8.85008 13.0167 9.48325 13.0167 9.87377 12.6262L13.5792 8.92082C13.904 8.59601 13.9049 8.06969 13.5812 7.74374C13.256 7.41615 12.7264 7.41522 12.4 7.74165L9.51954 10.6221C9.32454 10.8171 9.00849 10.8174 8.81311 10.6228L7.5964 9.41077Z'
      fill='inherit'
    />
  ),
  suppliers: (
    <path
      d='M10 1.66669C5.40002 1.66669 1.66669 5.40002 1.66669 10C1.66669 14.6 5.40002 18.3334 10 18.3334C14.6 18.3334 18.3334 14.6 18.3334 10C18.3334 5.40002 14.6 1.66669 10 1.66669ZM5.89169 15.2334C6.25002 14.4834 8.43335 13.75 10 13.75C11.5667 13.75 13.7584 14.4834 14.1084 15.2334C12.975 16.1334 11.55 16.6667 10 16.6667C8.45002 16.6667 7.02502 16.1334 5.89169 15.2334ZM15.3 14.025C14.1084 12.575 11.2167 12.0834 10 12.0834C8.78335 12.0834 5.89169 12.575 4.70002 14.025C3.85002 12.9084 3.33335 11.5167 3.33335 10C3.33335 6.32502 6.32502 3.33335 10 3.33335C13.675 3.33335 16.6667 6.32502 16.6667 10C16.6667 11.5167 16.15 12.9084 15.3 14.025ZM10 5.00002C8.38335 5.00002 7.08335 6.30002 7.08335 7.91669C7.08335 9.53335 8.38335 10.8334 10 10.8334C11.6167 10.8334 12.9167 9.53335 12.9167 7.91669C12.9167 6.30002 11.6167 5.00002 10 5.00002ZM10 9.16669C9.30835 9.16669 8.75002 8.60835 8.75002 7.91669C8.75002 7.22502 9.30835 6.66669 10 6.66669C10.6917 6.66669 11.25 7.22502 11.25 7.91669C11.25 8.60835 10.6917 9.16669 10 9.16669Z'
      fill='inherit'
    />
  ),
  assignments: (
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.35 1.66667H15.8333C16.75 1.66667 17.5 2.41667 17.5 3.33333V16.6667C17.5 17.5833 16.75 18.3333 15.8333 18.3333H4.16667C3.25 18.3333 2.5 17.5833 2.5 16.6667V3.33333C2.5 2.41667 3.25 1.66667 4.16667 1.66667H7.65C8 0.7 8.91667 0 10 0C11.0833 0 12 0.7 12.35 1.66667ZM10.8333 2.5C10.8333 2.04167 10.4583 1.66667 10 1.66667C9.54167 1.66667 9.16667 2.04167 9.16667 2.5C9.16667 2.95833 9.54167 3.33333 10 3.33333C10.4583 3.33333 10.8333 2.95833 10.8333 2.5ZM15.3333 16.6667C15.6095 16.6667 15.8333 16.4428 15.8333 16.1667V3.83333C15.8333 3.55719 15.6095 3.33333 15.3333 3.33333H14.6667C14.3905 3.33333 14.1667 3.55719 14.1667 3.83333V4.83333C14.1667 5.38562 13.719 5.83333 13.1667 5.83333H6.83333C6.28105 5.83333 5.83333 5.38562 5.83333 4.83333V3.83333C5.83333 3.55719 5.60948 3.33333 5.33333 3.33333H4.66667C4.39052 3.33333 4.16667 3.55719 4.16667 3.83333V16.1667C4.16667 16.4428 4.39052 16.6667 4.66667 16.6667H15.3333ZM6.4214 11.4214L8.45956 13.4596C8.85008 13.8501 9.48325 13.8501 9.87377 13.4596L13.5792 9.75418C13.904 9.42938 13.9049 8.90306 13.5812 8.5771C13.256 8.24952 12.7264 8.24859 12.4 8.57502L9.51954 11.4555C9.32454 11.6505 9.00849 11.6508 8.81312 11.4562L7.5964 10.2441C7.27144 9.92043 6.74574 9.92094 6.4214 10.2453C6.09662 10.5701 6.09662 11.0966 6.4214 11.4214Z'
      fill='inherit'
    />
  ),
}

export const addWhiteIcon = (
  <SvgIcon viewBox='0 0 18 18'>
    <path
      d='M13.3078 9L4.69238 9'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9 13.3078L9 4.69238'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle cx='9' cy='9' r='8' stroke='white' strokeWidth='2' />
  </SvgIcon>
)

export const addDarkIcon = (
  <SvgIcon viewBox='0 0 18 18'>
    <path
      d='M13.3076 9L4.69226 9'
      stroke='#535F77'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9 13.3078L9 4.69238'
      stroke='#535F77'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle cx='9' cy='9' r='8' stroke='#535F77' strokeWidth='2' />
  </SvgIcon>
)

export const deleteWhiteIcon = (
  <SvgIcon viewBox='0 0 18 18'>
    <path
      d='M9.00025 13.9231L9.00024 6.53846M1.00024 3.46154H2.8464M17.0002 3.46154H15.1541M12.0772 3.46154H5.92332M12.0772 3.46154V2.23077C12.0772 1.55103 11.5261 1 10.8464 1H7.15409C6.47436 1 5.92332 1.55103 5.92332 2.23077V3.46154M12.0772 3.46154H15.1541M5.92332 3.46154H2.8464M15.1541 3.46154V14.5385C15.1541 15.8979 14.052 17 12.6926 17H5.30794C3.94847 17 2.8464 15.8979 2.8464 14.5385V3.46154'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export const deleteDarkIcon = (
  <SvgIcon viewBox='0 0 18 18'>
    <path
      d='M9 13.9231L9 6.53846M1 3.46154H2.84615M17 3.46154H15.1538M12.0769 3.46154H5.92308M12.0769 3.46154V2.23077C12.0769 1.55103 11.5259 1 10.8462 1H7.15385C6.47411 1 5.92308 1.55103 5.92308 2.23077V3.46154M12.0769 3.46154H15.1538M5.92308 3.46154H2.84615M15.1538 3.46154V14.5385C15.1538 15.8979 14.0518 17 12.6923 17H5.30769C3.94822 17 2.84615 15.8979 2.84615 14.5385V3.46154'
      stroke='#616184'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export const settingIcon = (
  <SvgIcon
    viewBox='0 0 18 18'
    htmlColor='white'
    style={{ fontSize: '16px', verticalAlign: 'middle' }}
  >
    <path
      d='M9 1C10.0196 1 10.8462 1.82655 10.8462 2.84615V2.84615C10.8462 3.01382 10.9554 3.16092 11.1129 3.21848C11.2765 3.27827 11.4368 3.3448 11.5936 3.41775C11.7458 3.48858 11.9273 3.46185 12.046 3.34315V3.34315C12.767 2.62218 13.9359 2.62218 14.6569 3.34315C15.3778 4.06412 15.3778 5.23306 14.6569 5.95403V5.95403C14.5382 6.07272 14.5114 6.25426 14.5823 6.40646C14.6552 6.5632 14.7217 6.72353 14.7815 6.88711C14.8391 7.04457 14.9862 7.15385 15.1538 7.15385V7.15385C16.1735 7.15385 17 7.98039 17 9C17 10.0196 16.1735 10.8462 15.1538 10.8462V10.8462C14.9862 10.8462 14.8391 10.9554 14.7815 11.1129C14.7217 11.2765 14.6552 11.4368 14.5822 11.5936C14.5114 11.7458 14.5381 11.9273 14.6569 12.046V12.046C15.3778 12.767 15.3778 13.9359 14.6569 14.6569C13.9359 15.3778 12.767 15.3778 12.046 14.6569V14.6569C11.9273 14.5382 11.7458 14.5114 11.5936 14.5823C11.4368 14.6552 11.2765 14.7217 11.1129 14.7815C10.9554 14.8391 10.8462 14.9862 10.8462 15.1538V15.1538C10.8462 16.1735 10.0196 17 9 17C7.98039 17 7.15385 16.1735 7.15385 15.1538V15.1538C7.15385 14.9862 7.04457 14.8391 6.88709 14.7815C6.72352 14.7217 6.5632 14.6552 6.40646 14.5823C6.25426 14.5114 6.07272 14.5382 5.95401 14.6569V14.6569C5.23304 15.3778 4.06412 15.3778 3.34315 14.6569C2.62218 13.9359 2.62218 12.767 3.34315 12.046V12.046C3.46186 11.9273 3.4886 11.7458 3.41776 11.5936C3.34481 11.4368 3.27826 11.2765 3.21846 11.1129C3.1609 10.9554 3.01381 10.8462 2.84615 10.8462V10.8462C1.82655 10.8462 1 10.0196 1 9C1 7.98039 1.82655 7.15385 2.84615 7.15385V7.15385C3.01381 7.15385 3.1609 7.04457 3.21846 6.88711C3.27826 6.72353 3.3448 6.56319 3.41775 6.40644C3.48858 6.25425 3.46185 6.07273 3.34315 5.95403V5.95403C2.62218 5.23302 2.62218 4.06412 3.34315 3.34315C4.06412 2.62218 5.23304 2.62218 5.95401 3.34315V3.34315C6.07271 3.46185 6.25423 3.48858 6.40643 3.41775C6.56317 3.3448 6.72351 3.27827 6.88709 3.21848C7.04457 3.16092 7.15385 3.01382 7.15385 2.84615V2.84615C7.15385 1.82655 7.98039 1 9 1Z'
      stroke='#535F77'
      strokeWidth='1.5'
    />
    <circle
      cx='9.00002'
      cy='8.99977'
      r='3.07692'
      stroke='#535F77'
      strokeWidth='1.5'
    />
  </SvgIcon>
)

export const uploadIcon = (
  <SvgIcon viewBox='0 0 18 18'>
    <path
      d='M17 11.4615V14.5385C17 15.8979 15.8979 17 14.5385 17H3.46154C2.10207 17 1 15.8979 1 14.5385V11.4615M9 13.3077V1M9 1L5.30769 4.69231M9 1L12.6923 4.69231'
      stroke='#616284'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export const downloadDarkIcon = (
  <SvgIcon viewBox='0 0 18 18'>
    <path
      d='M17 11.4614V14.5383C17 15.8978 15.8979 16.9999 14.5385 16.9999H3.46154C2.10207 16.9999 1 15.8978 1 14.5383V11.4614'
      stroke='#616284'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.99995 0.999924L8.99995 13.3076M8.99995 13.3076L12.6923 9.61531M8.99995 13.3076L5.30765 9.61531'
      stroke='#616284'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export const downloadWhiteIcon = (
  <SvgIcon viewBox='0 0 18 18'>
    <path
      d='M17 11.4614V14.5383C17 15.8978 15.8979 16.9999 14.5385 16.9999H3.46154C2.10207 16.9999 1 15.8978 1 14.5383V11.4614'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.99995 0.999924L8.99995 13.3076M8.99995 13.3076L12.6923 9.61531M8.99995 13.3076L5.30765 9.61531'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export const searchIcon = (
  <SvgIcon viewBox='0 0 18 18'>
    <path
      d='M16.2935 17.7078C16.6844 18.0979 17.3176 18.0973 17.7078 17.7065C18.0979 17.3156 18.0973 16.6824 17.7065 16.2922L16.2935 17.7078ZM13.0775 13.0847L12.3709 12.3771L13.0775 13.0847ZM8.07692 14.1538C4.72073 14.1538 2 11.4331 2 8.07692H0C0 12.5377 3.61616 16.1538 8.07692 16.1538V14.1538ZM2 8.07692C2 4.72073 4.72073 2 8.07692 2V0C3.61616 0 0 3.61616 0 8.07692H2ZM8.07692 2C11.4331 2 14.1538 4.72073 14.1538 8.07692H16.1538C16.1538 3.61616 12.5377 0 8.07692 0V2ZM14.1538 8.07692C14.1538 9.75687 13.4735 11.276 12.3709 12.3771L13.7841 13.7923C15.247 12.3314 16.1538 10.3091 16.1538 8.07692H14.1538ZM12.3709 12.3771C11.2703 13.476 9.75373 14.1538 8.07692 14.1538V16.1538C10.3049 16.1538 12.3238 15.2504 13.7841 13.7923L12.3709 12.3771ZM12.371 13.7924L16.2935 17.7078L17.7065 16.2922L13.7839 12.3769L12.371 13.7924Z'
      fill='#535F77'
    />
  </SvgIcon>
)

export const filterDarkIcon = (
  <SvgIcon viewBox='0 0 18 18'>
    <path
      d='M10.8462 10.2308L10.1062 9.55809L9.84615 9.84416L9.84615 10.2308L10.8462 10.2308ZM7.15385 10.2308L8.15385 10.2308L8.15385 9.84416L7.89379 9.55809L7.15385 10.2308ZM1.46167 3.96937L2.20161 3.2967L1.46167 3.96937ZM15.2248 9.28033e-06L2.77523 5.70308e-07L2.77523 2L15.2248 2.00001L15.2248 9.28033e-06ZM15.7984 3.29669L10.1062 9.55809L11.5861 10.9034L17.2783 4.64204L15.7984 3.29669ZM7.89379 9.55809L2.20161 3.2967L0.721727 4.64204L6.41391 10.9034L7.89379 9.55809ZM9.84615 10.2308L9.84615 14.6282L11.8462 14.6282L11.8462 10.2308L9.84615 10.2308ZM8.15385 14.6282L8.15385 10.2308L6.15385 10.2308L6.15385 14.6282L8.15385 14.6282ZM6.15385 14.6282C6.15385 15.7063 6.76293 16.6918 7.72716 17.1739L8.62159 15.3851C8.33493 15.2417 8.15385 14.9487 8.15385 14.6282L6.15385 14.6282ZM18 2.77523C18 1.24252 16.7575 1.03606e-05 15.2248 9.28033e-06L15.2248 2.00001C15.6529 2.00001 16 2.34709 16 2.77523L18 2.77523ZM2 2.77522C2 2.34708 2.34708 2 2.77523 2L2.77523 5.70308e-07C1.24251 -5.09968e-07 1.89675e-06 1.24251 1.82975e-06 2.77522L2 2.77522ZM9.84615 14.6282C9.84615 15.2573 9.1842 15.6664 8.62159 15.3851L7.72716 17.1739C9.61957 18.1201 11.8462 16.744 11.8462 14.6282L9.84615 14.6282ZM1.82975e-06 2.77522C1.79957e-06 3.46561 0.257326 4.1312 0.721727 4.64204L2.20161 3.2967C2.07188 3.154 2 2.96807 2 2.77522L1.82975e-06 2.77522ZM16 2.77523C16 2.96807 15.9281 3.154 15.7984 3.29669L17.2783 4.64204C17.7427 4.1312 18 3.46561 18 2.77523L16 2.77523Z'
      fill='#535F77'
    />
  </SvgIcon>
)

export const filterWhiteIcon = (
  <SvgIcon viewBox='0 0 18 18'>
    <path
      d='M10.8462 10.2308L10.1062 9.55809L9.84615 9.84416L9.84615 10.2308L10.8462 10.2308ZM7.15385 10.2308L8.15385 10.2308L8.15385 9.84416L7.89379 9.55809L7.15385 10.2308ZM1.46167 3.96937L2.20161 3.2967L1.46167 3.96937ZM15.2248 9.28033e-06L2.77523 5.70308e-07L2.77523 2L15.2248 2.00001L15.2248 9.28033e-06ZM15.7984 3.29669L10.1062 9.55809L11.5861 10.9034L17.2783 4.64204L15.7984 3.29669ZM7.89379 9.55809L2.20161 3.2967L0.721727 4.64204L6.41391 10.9034L7.89379 9.55809ZM9.84615 10.2308L9.84615 14.6282L11.8462 14.6282L11.8462 10.2308L9.84615 10.2308ZM8.15385 14.6282L8.15385 10.2308L6.15385 10.2308L6.15385 14.6282L8.15385 14.6282ZM6.15385 14.6282C6.15385 15.7063 6.76293 16.6918 7.72716 17.1739L8.62159 15.3851C8.33493 15.2417 8.15385 14.9487 8.15385 14.6282L6.15385 14.6282ZM18 2.77523C18 1.24252 16.7575 1.03606e-05 15.2248 9.28033e-06L15.2248 2.00001C15.6529 2.00001 16 2.34709 16 2.77523L18 2.77523ZM2 2.77522C2 2.34708 2.34708 2 2.77523 2L2.77523 5.70308e-07C1.24251 -5.09968e-07 1.89675e-06 1.24251 1.82975e-06 2.77522L2 2.77522ZM9.84615 14.6282C9.84615 15.2573 9.1842 15.6664 8.62159 15.3851L7.72716 17.1739C9.61957 18.1201 11.8462 16.744 11.8462 14.6282L9.84615 14.6282ZM1.82975e-06 2.77522C1.79957e-06 3.46561 0.257326 4.1312 0.721727 4.64204L2.20161 3.2967C2.07188 3.154 2 2.96807 2 2.77522L1.82975e-06 2.77522ZM16 2.77523C16 2.96807 15.9281 3.154 15.7984 3.29669L17.2783 4.64204C17.7427 4.1312 18 3.46561 18 2.77523L16 2.77523Z'
      fill='white'
    />
  </SvgIcon>
)

export const saveIcon = (
  <SvgIcon viewBox='0 0 18 18'>
    <circle cx='9' cy='9' r='8' stroke='white' strokeWidth='2' />
    <path
      d='M5.30762 8.56572L8.04266 11.4617L12.6922 6.53857'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export const editIcon = (
  <SvgIcon viewBox='0 0 18 18'>
    <path
      d='M5.30769 16.9691H1L1 12.6614L9.92308 3.7383M5.30769 16.9691L17 16.9691M5.30769 16.9691L14.2308 8.04599M9.92308 3.7383L11.7692 1.89215C12.9588 0.702609 14.8874 0.702611 16.0769 1.89215C17.2665 3.08168 17.2665 5.0103 16.0769 6.19984L14.2308 8.04599M9.92308 3.7383L14.2308 8.04599'
      stroke='#616184'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export const photoIcon = (
  <SvgIcon viewBox='0 0 24 24'>
    <path
      fill='#616284'
      d='M6.93701993,5.84537513 C7.00786835,5.74688122 7.08655624,5.62630565 7.18689485,5.46372088 C7.24312147,5.37261346 7.44826977,5.03326382 7.48180254,4.97841195 C8.31078564,3.62238725 8.91339481,3 10,3 L14,3 C15.0866052,3 15.6892144,3.62238725 16.5181975,4.97841195 C16.5517302,5.03326382 16.7568785,5.37261346 16.8131052,5.46372088 C16.9134438,5.62630565 16.9921316,5.74688122 17.0629801,5.84537513 C17.1097019,5.91032811 17.1505105,5.96193936 17.1838035,6 L20,6 C21.6568542,6 23,7.34314575 23,9 L23,18 C23,19.6568542 21.6568542,21 20,21 L4,21 C2.34314575,21 1,19.6568542 1,18 L1,9 C1,7.34314575 2.34314575,6 4,6 L6.81619653,6 C6.84948947,5.96193936 6.8902981,5.91032811 6.93701993,5.84537513 Z M4,8 C3.44771525,8 3,8.44771525 3,9 L3,18 C3,18.5522847 3.44771525,19 4,19 L20,19 C20.5522847,19 21,18.5522847 21,18 L21,9 C21,8.44771525 20.5522847,8 20,8 L17,8 C16.3356579,8 15.8876309,7.6364073 15.4393863,7.01325501 C15.3362526,6.86987789 15.2340812,6.71331789 15.1111283,6.51408981 C15.0490387,6.41348225 14.8408368,6.06908144 14.8118025,6.02158805 C14.359498,5.28172234 14.0867281,5 14,5 L10,5 C9.91327186,5 9.64050203,5.28172234 9.18819746,6.02158805 C9.15916317,6.06908144 8.95096127,6.41348225 8.88887167,6.51408981 C8.76591877,6.71331789 8.66374737,6.86987789 8.56061366,7.01325501 C8.11236912,7.6364073 7.66434214,8 7,8 L4,8 Z M19,11 C19.5522847,11 20,10.5522847 20,10 C20,9.44771525 19.5522847,9 19,9 C18.4477153,9 18,9.44771525 18,10 C18,10.5522847 18.4477153,11 19,11 Z M12,18 C9.23857625,18 7,15.7614237 7,13 C7,10.2385763 9.23857625,8 12,8 C14.7614237,8 17,10.2385763 17,13 C17,15.7614237 14.7614237,18 12,18 Z M12,16 C13.6568542,16 15,14.6568542 15,13 C15,11.3431458 13.6568542,10 12,10 C10.3431458,10 9,11.3431458 9,13 C9,14.6568542 10.3431458,16 12,16 Z'
    />
  </SvgIcon>
)

export const menuIcon = (
  <SvgIcon viewBox='0 0 24 24' style={{ fontSize: '18px' }}>
    <path
      d='M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z'
      stroke='#616184'
      fill='#616184'
    />
  </SvgIcon>
)

export const arrowBackIcon = (
  <SvgIcon
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15 6.99997H3.82998L8.70998 2.11997C9.09998 1.72997 9.09998 1.08997 8.70998 0.699971C8.31998 0.309971 7.68998 0.309971 7.29998 0.699971L0.70998 7.28997C0.31998 7.67997 0.31998 8.30997 0.70998 8.69997L7.29998 15.29C7.68998 15.68 8.31998 15.68 8.70998 15.29C9.09998 14.9 9.09998 14.27 8.70998 13.88L3.82998 8.99997H15C15.55 8.99997 16 8.54997 16 7.99997C16 7.44997 15.55 6.99997 15 6.99997Z'
      fill='#4C5ECF'
    />
  </SvgIcon>
)

export const pencilCreateIcon = (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M12.3023235,7.94519388 L4.69610276,15.549589 C4.29095108,15.9079238 4.04030835,16.4092335 4,16.8678295 L4,20.0029438 L7.06398288,20.004826 C7.5982069,19.9670062 8.09548693,19.7183782 8.49479322,19.2616227 L16.0567001,11.6997158 L12.3023235,7.94519388 Z M13.7167068,6.53115006 L17.4709137,10.2855022 L19.8647941,7.89162181 C19.9513987,7.80501747 20.0000526,7.68755666 20.0000526,7.56507948 C20.0000526,7.4426023 19.9513987,7.32514149 19.8647932,7.23853626 L16.7611243,4.13485646 C16.6754884,4.04854589 16.5589355,4 16.43735,4 C16.3157645,4 16.1992116,4.04854589 16.1135757,4.13485646 L13.7167068,6.53115006 Z M16.43735,2 C17.0920882,2 17.7197259,2.26141978 18.1781068,2.7234227 L21.2790059,5.82432181 C21.7406843,6.28599904 22.0000526,6.91216845 22.0000526,7.56507948 C22.0000526,8.21799052 21.7406843,8.84415992 21.2790068,9.30583626 L9.95750718,20.6237545 C9.25902448,21.4294925 8.26890003,21.9245308 7.1346,22.0023295 L2,22.0023295 L2,21.0023295 L2.00324765,16.7873015 C2.08843822,15.7328366 2.57866679,14.7523321 3.32649633,14.0934196 L14.6953877,2.72462818 C15.1563921,2.2608295 15.7833514,2 16.43735,2 Z'
    />
  </SvgIcon>
)

export const circleXIcon = (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M12,13.4142136 L8.70710678,16.7071068 L7.29289322,15.2928932 L10.5857864,12 L7.29289322,8.70710678 L8.70710678,7.29289322 L12,10.5857864 L15.2928932,7.29289322 L16.7071068,8.70710678 L13.4142136,12 L16.7071068,15.2928932 L15.2928932,16.7071068 L12,13.4142136 Z'
    />
  </SvgIcon>
)

export const lopeIcon = (
  <SvgIcon width='18' height='18' viewBox='0 0 18 18'>
    <path
      d='M16.2935 17.7078C16.6844 18.0979 17.3176 18.0973 17.7078 17.7065C18.0979 17.3156 18.0973 16.6824 17.7065 16.2922L16.2935 17.7078ZM13.0775 13.0847L12.3709 12.3771L13.0775 13.0847ZM8.07692 14.1538C4.72073 14.1538 2 11.4331 2 8.07692H0C0 12.5377 3.61616 16.1538 8.07692 16.1538V14.1538ZM2 8.07692C2 4.72073 4.72073 2 8.07692 2V0C3.61616 0 0 3.61616 0 8.07692H2ZM8.07692 2C11.4331 2 14.1538 4.72073 14.1538 8.07692H16.1538C16.1538 3.61616 12.5377 0 8.07692 0V2ZM14.1538 8.07692C14.1538 9.75687 13.4735 11.276 12.3709 12.3771L13.7841 13.7923C15.247 12.3314 16.1538 10.3091 16.1538 8.07692H14.1538ZM12.3709 12.3771C11.2703 13.476 9.75373 14.1538 8.07692 14.1538V16.1538C10.3049 16.1538 12.3238 15.2504 13.7841 13.7923L12.3709 12.3771ZM12.371 13.7924L16.2935 17.7078L17.7065 16.2922L13.7839 12.3769L12.371 13.7924Z'
      fill='#535F77'
    />
  </SvgIcon>
)

export const closeIcon = (
  <SvgIcon width='13' height='13' viewBox='0 0 13 13'>
    <path
      opacity='0.5'
      d='M11.8315 1.70712C12.222 1.3166 12.222 0.683431 11.8315 0.292907C11.4409 -0.0976177 10.8078 -0.0976177 10.4172 0.292907L11.8315 1.70712ZM0.292893 10.4173C-0.0976311 10.8078 -0.0976311 11.4409 0.292893 11.8315C0.683418 12.222 1.31658 12.222 1.70711 11.8315L0.292893 10.4173ZM10.4172 11.8315C10.8078 12.222 11.4409 12.222 11.8315 11.8315C12.222 11.4409 12.222 10.8078 11.8315 10.4172L10.4172 11.8315ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM10.4172 0.292907L0.292893 10.4173L1.70711 11.8315L11.8315 1.70712L10.4172 0.292907ZM11.8315 10.4172L1.70711 0.292893L0.292893 1.70711L10.4172 11.8315L11.8315 10.4172Z'
      fill='#535F77'
    />
  </SvgIcon>
)

export const arrowRightIcon = (
  <SvgIcon width='11' height='11' viewBox='0 0 11 11'>
    <path
      d='M5.53516 2L9.07069 5.53553L5.53516 9.07107'
      stroke='#4C5ECF'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export const refreshIcon = (
  <SvgIcon width='22' height='17' viewBox='0 0 22 17' fill='none'>
    <path
      d='M13.1429 15.2857H6.71429C5.13633 15.2857 3.85714 14.0065 3.85714 12.4286V1M1 3.85714L3.85714 1M3.85714 1L6.71429 3.85714M8.85714 1L15.2857 1C16.8637 1 18.1429 2.27919 18.1429 3.85714L18.1429 15.2857M21 12.4286L18.1429 15.2857M18.1429 15.2857L15.2857 12.4286'
      stroke='#a49696'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export const checkIcon = (
  <SvgIcon width='18' height='18' viewBox='0 0 18 18' fill='none'>
    <circle cx='9' cy='9' r='8' stroke='white' strokeWidth='2' />
    <path
      d='M5.30859 8.56572L8.04364 11.4617L12.6932 6.53857'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export const circleCloseIcon = (
  <SvgIcon width='18' height='18' viewBox='0 0 18 18' fill='none'>
    <path
      d='M12.1152 5.92309L5.8848 12.1535M12.1152 12.1534L5.8848 5.92308M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z'
      stroke='#535F77'
      strokeWidth='1.5'
    />
  </SvgIcon>
)

export const excelIcon = (
  <SvgIcon
    width='15'
    height='18'
    viewBox='0 0 15 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.00002 5.30769L0.292913 4.60059C0.105378 4.78812 2.09808e-05 5.04247 2.02656e-05 5.30769L1.00002 5.30769ZM5.30771 1V0C5.04249 0 4.78814 0.105357 4.6006 0.292893L5.30771 1ZM13.3077 3.46154H12.3077H13.3077ZM13.3077 14.5385H14.3077H13.3077ZM1 14.5385L2 14.5385V14.5385L1 14.5385ZM5.92307 5.92308V6.92308C6.47536 6.92308 6.92307 6.47536 6.92307 5.92308H5.92307ZM1.00002 5.92308L1.87159e-05 5.92307L1.00002 5.92308ZM1.70713 6.0148L6.01482 1.70711L4.6006 0.292893L0.292913 4.60059L1.70713 6.0148ZM3.46154 18H10.8462V16H3.46154V18ZM12.3077 3.46154L12.3077 14.5385H14.3077L14.3077 3.46154H12.3077ZM3.46154 16C2.65435 16 2 15.3456 2 14.5385L0 14.5385C-4.88758e-06 16.4502 1.54978 18 3.46154 18V16ZM10.8462 18C12.7579 18 14.3077 16.4502 14.3077 14.5385H12.3077C12.3077 15.3456 11.6534 16 10.8462 16V18ZM10.8462 2C11.6534 2 12.3077 2.65435 12.3077 3.46154H14.3077C14.3077 1.54978 12.7579 0 10.8462 0V2ZM5.30771 2H5.92307V0H5.30771V2ZM5.92307 2H10.8462V0H5.92307V2ZM2.02656e-05 5.30769L1.87159e-05 5.92307L2.00002 5.92308L2.00002 5.30769L2.02656e-05 5.30769ZM1.87159e-05 5.92307L0 14.5385L2 14.5385L2.00002 5.92308L1.87159e-05 5.92307ZM4.92307 1V5.92308H6.92307V1H4.92307ZM5.92307 4.92308H1.00002V6.92308H5.92307V4.92308Z'
      fill='#616284'
    />
    <path
      d='M7.15325 8.38464H4.46094C3.90865 8.38464 3.46094 8.83236 3.46094 9.38464V11.4616M7.15325 8.38464H9.84555C10.3978 8.38464 10.8456 8.83236 10.8456 9.38464V11.4616M7.15325 8.38464V14.5385M7.15325 14.5385H4.46094C3.90865 14.5385 3.46094 14.0908 3.46094 13.5385V11.4616M7.15325 14.5385H9.84555C10.3978 14.5385 10.8456 14.0908 10.8456 13.5385V11.4616M10.8456 11.4616H3.46094'
      stroke='#616284'
      strokeWidth='2'
      strokeLinecap='round'
    />
  </SvgIcon>
)

export const eyeIcon = (
  <SvgIcon
    width='24'
    height='16'
    viewBox='0 0 24 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9995 0C14.7271 0 17.3352 1.43062 19.7658 3.78115C20.595 4.58305 21.3452 5.43916 22.0056 6.29562C22.4041 6.81244 22.6871 7.21899 22.842 7.4612L23.1866 8L22.842 8.5388C22.6871 8.781 22.4041 9.18756 22.0056 9.70438C21.3452 10.5608 20.595 11.417 19.7658 12.2189C17.3352 14.5694 14.7271 16 11.9995 16C9.27199 16 6.66386 14.5694 4.23324 12.2189C3.40403 11.417 2.65384 10.5608 1.99344 9.70438C1.59494 9.18756 1.31201 8.781 1.1571 8.5388L0.8125 8L1.1571 7.4612C1.31201 7.21899 1.59494 6.81244 1.99344 6.29562C2.65384 5.43916 3.40403 4.58305 4.23324 3.78115C6.66386 1.43062 9.27199 0 11.9995 0ZM20.4218 7.51688C19.8171 6.73272 19.1297 5.9482 18.3755 5.21885C16.282 3.19438 14.1046 2 11.9995 2C9.89446 2 7.71703 3.19438 5.62358 5.21885C4.86937 5.9482 4.18192 6.73272 3.57728 7.51688C3.44669 7.68624 3.32602 7.84782 3.2157 8C3.32602 8.15218 3.44669 8.31376 3.57728 8.48312C4.18192 9.26728 4.86937 10.0518 5.62358 10.7811C7.71703 12.8056 9.89446 14 11.9995 14C14.1046 14 16.282 12.8056 18.3755 10.7811C19.1297 10.0518 19.8171 9.26728 20.4218 8.48312C20.5524 8.31376 20.673 8.15218 20.7834 8C20.673 7.84782 20.5524 7.68624 20.4218 7.51688ZM7.99953 8C7.99953 10.2091 9.7904 12 11.9995 12C14.2087 12 15.9995 10.2091 15.9995 8C15.9995 5.79086 14.2087 4 11.9995 4C9.7904 4 7.99953 5.79086 7.99953 8ZM13.9995 8C13.9995 9.10457 13.1041 10 11.9995 10C10.895 10 9.99953 9.10457 9.99953 8C9.99953 6.89543 10.895 6 11.9995 6C13.1041 6 13.9995 6.89543 13.9995 8Z'
      fill='#616284'
    />
  </SvgIcon>
)

export const collapseIcon = (
  <SvgIcon
    width='17'
    height='14'
    viewBox='0 0 17 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.6923 10.6924L12 7.00007L15.6923 3.30777'
      stroke='#535F77'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15 2C15.5523 2 16 1.55228 16 1C16 0.447715 15.5523 0 15 0H1C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2H15ZM9 4C9.55229 4 10 4.44772 10 5C10 5.55228 9.55228 6 9 6H1C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4H9ZM9 10C9.55229 10 10 9.55229 10 9C10 8.44771 9.55228 8 9 8H1C0.447715 8 0 8.44771 0 9C0 9.55229 0.447715 10 1 10H9ZM15 14C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H1C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H15Z'
      fill='#535F77'
    />
  </SvgIcon>
)

export const extendIcon = (
  <SvgIcon
    width='17'
    height='14'
    viewBox='0 0 17 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15 2C15.5523 2 16 1.55228 16 1C16 0.447715 15.5523 0 15 0H1C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2H15ZM9 4C9.55229 4 10 4.44772 10 5C10 5.55228 9.55228 6 9 6H1C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4H9ZM9 10C9.55229 10 10 9.55229 10 9C10 8.44771 9.55228 8 9 8H1C0.447715 8 0 8.44771 0 9C0 9.55229 0.447715 10 1 10H9ZM15 14C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H1C0.447715 12 0 12.4477 0 13C0 13.5523 0.447715 14 1 14H15Z'
      fill='#535F77'
    />
    <path
      d='M12 10.6924L15.6923 7.00007L12 3.30777'
      stroke='#535F77'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
)

export const arrowDownIcon = (
  <SvgIcon
    width='8'
    height='8'
    viewBox='0 0 8 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.53551 0.000110686L7.07104 3.53564H-2.2769e-05L3.53551 0.000110686Z'
      fill='white'
    />
  </SvgIcon>
)

export const arrowUpIcon = (
  <SvgIcon
    width='8'
    height='8'
    viewBox='0 0 8 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ transform: 'rotate(180deg)' }}
  >
    <path
      d='M3.53551 0.000110686L7.07104 3.53564H-2.2769e-05L3.53551 0.000110686Z'
      fill='white'
    />
  </SvgIcon>
)

export const logoGTImage = (
  <SvgIcon
    width='51'
    height='52'
    viewBox='0 0 51 52'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.7079 51.6408C39.6364 51.4959 50.8835 39.9915 50.8835 25.8211C50.8835 20.5402 49.3214 15.6296 46.6407 11.5392C34.8982 12.654 25.7079 22.6845 25.7079 34.8936V51.6408ZM22.4251 51.4625C9.79585 49.9483 0 39.0457 0 25.8211C0 24.5429 0.0914998 23.2865 0.268214 22.0581C9.04951 21.1637 17.9107 25.5342 22.4448 33.848C22.4317 34.1949 22.4251 34.5434 22.4251 34.8936V51.4625ZM0.991977 18.6571C9.41116 18.1505 17.7709 21.836 23.1054 28.8239C25.4818 18.4119 33.8392 10.3257 44.3057 8.49484C39.6505 3.27744 32.9232 0 25.4417 0C13.8398 0 4.0517 7.88164 0.991977 18.6571Z'
      fill='white'
    />
  </SvgIcon>
)

export const closeButton = (
  <SvgIcon
    width='24px'
    fill='none'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12L17 7ZM4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z'
      fill='#fff'
    />
  </SvgIcon>
)

export const detailsIcon = (
  <SvgIcon viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M14.4 0H1.6C0.7176 0 0 0.797333 0 1.77778V14.2222C0 15.2027 0.7176 16 1.6 16H14.4C15.2824 16 16 15.2027 16 14.2222V1.77778C16 0.797333 15.2824 0 14.4 0ZM1.6 14.2222V1.77778H14.4L14.4016 14.2222H1.6Z' />
    <path d='M4 4H12V5.6H4V4ZM4 7.2H12V8.8H4V7.2ZM4 10.4H8V12H4V10.4Z' />
  </SvgIcon>
)

export const logoGTText = (
  <SvgIcon
    width='177'
    height='18'
    viewBox='0 0 177 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.80013 2.75021H3.7122V14.317H0.336426V0.10498H9.80013V2.75021Z'
      fill='white'
    />
    <path
      d='M16.6844 9.49505V14.317H13.3086V0.10498H18.8965C19.9672 0.10498 20.9129 0.306708 21.7336 0.710162C22.5608 1.10711 23.1987 1.6765 23.6475 2.41833C24.1028 3.15366 24.3304 3.98985 24.3304 4.92691C24.3304 6.31297 23.8399 7.42247 22.8589 8.25541C21.8843 9.08184 20.5443 9.49505 18.8387 9.49505H16.6844ZM16.6844 6.84982H18.8965C19.5504 6.84982 20.0474 6.68389 20.3872 6.35201C20.7334 6.02014 20.9065 5.55161 20.9065 4.94643C20.9065 4.28268 20.7302 3.75234 20.3776 3.35539C20.0249 2.95844 19.544 2.75672 18.9349 2.75021H16.6844V6.84982Z'
      fill='white'
    />
    <path
      d='M34.35 11.662H29.7239L28.9161 14.317H25.3095L30.4453 0.10498H33.6191L38.7933 14.317H35.1675L34.35 11.662ZM30.5318 9.01676H33.5421L32.0322 4.08746L30.5318 9.01676Z'
      fill='white'
    />
    <path
      d='M53.4967 14.317H50.1402V8.3823H45.1679V14.317H41.7921V0.10498H45.1679V5.74683H50.1402V0.10498H53.4967V14.317Z'
      fill='white'
    />
    <path
      d='M70.9218 17.8114H67.8923V14.317H60.2656V17.8017H57.1398L56.8994 11.6815H57.89C58.4094 11.3301 58.8261 10.7282 59.1403 9.87573C59.4609 9.01676 59.6885 7.78688 59.8231 6.18608L60.2559 0.10498H69.787V11.6815H71.2777L70.9218 17.8114ZM61.7082 11.6815H66.4208V2.75021H63.4394L63.1797 6.30321C62.9873 8.6068 62.4968 10.3996 61.7082 11.6815Z'
      fill='white'
    />
    <path
      d='M85.0963 2.75021H80.8934V14.317H77.5176V2.75021H73.3916V0.10498H85.0963V2.75021Z'
      fill='white'
    />
    <path
      d='M97.1836 8.29445H91.8747V11.6815H98.1454V14.317H88.499V0.10498H98.1646V2.75021H91.8747V5.74683H97.1836V8.29445Z'
      fill='white'
    />
    <path
      d='M113.195 14.317H109.838V8.3823H104.866V14.317H101.49V0.10498H104.866V5.74683H109.838V0.10498H113.195V14.317Z'
      fill='white'
    />
    <path
      d='M130.62 17.8114H127.591V14.317H119.964V17.8017H116.838L116.598 11.6815H117.588C118.108 11.3301 118.524 10.7282 118.839 9.87573C119.159 9.01676 119.387 7.78688 119.521 6.18608L119.954 0.10498H129.485V11.6815H130.976L130.62 17.8114ZM121.407 11.6815H126.119V2.75021H123.138L122.878 6.30321C122.686 8.6068 122.195 10.3996 121.407 11.6815Z'
      fill='white'
    />
    <path
      d='M142.794 8.29445H137.485V11.6815H143.756V14.317H134.109V0.10498H143.775V2.75021H137.485V5.74683H142.794V8.29445Z'
      fill='white'
    />
    <path
      d='M150.477 9.49505V14.317H147.101V0.10498H152.689C153.759 0.10498 154.705 0.306708 155.526 0.710162C156.353 1.10711 156.991 1.6765 157.44 2.41833C157.895 3.15366 158.123 3.98985 158.123 4.92691C158.123 6.31297 157.632 7.42247 156.651 8.25541C155.677 9.08184 154.336 9.49505 152.631 9.49505H150.477ZM150.477 6.84982H152.689C153.343 6.84982 153.84 6.68389 154.179 6.35201C154.526 6.02014 154.699 5.55161 154.699 4.94643C154.699 4.28268 154.522 3.75234 154.17 3.35539C153.817 2.95844 153.336 2.75672 152.727 2.75021H150.477V6.84982Z'
      fill='white'
    />
    <path
      d='M165.276 4.99524H167.017C168.126 4.99524 169.107 5.1872 169.96 5.57113C170.813 5.95507 171.467 6.49843 171.922 7.20122C172.384 7.8975 172.614 8.6979 172.614 9.60242C172.614 11.0015 172.101 12.137 171.076 13.009C170.056 13.881 168.684 14.317 166.959 14.317H161.891V0.10498H165.276V4.99524ZM177 14.317H173.634V0.10498H177V14.317ZM165.276 7.6307V11.6815H167.007C167.693 11.6815 168.235 11.4863 168.633 11.0959C169.037 10.7054 169.239 10.2011 169.239 9.5829C169.239 8.99724 169.04 8.52546 168.642 8.16756C168.245 7.80965 167.693 7.6307 166.988 7.6307H165.276Z'
      fill='white'
    />
  </SvgIcon>
)

export const SPECIALISATION_ITEM_ID = '99999999999'

export const allSpecialisationsItem: ITreeListItem = {
  // eslint-disable-next-line no-undefined
  parentId: undefined,
  serialNumber: '000000001',
  name: 'Все',
  id: '0',
  type: ETreeListItemType.LIST,
  content: [],
  disabledActions: {
    [EDisabledTreeItemActions.ALL]: true,
    [EDisabledTreeItemActions.CHECK]: true,
  },
}

export const specialisationItem: ITreeListItem = {
  // eslint-disable-next-line no-undefined
  parentId: undefined,
  serialNumber: '000000002',
  name: 'Специализации',
  id: SPECIALISATION_ITEM_ID,
  type: ETreeListItemType.FOLDER,
  content: [],
  disabledActions: {
    [EDisabledTreeItemActions.DELETE]: true,
    [EDisabledTreeItemActions.RENAME]: true,
    [EDisabledTreeItemActions.MOVE]: true,
    [EDisabledTreeItemActions.CONCAT]: true,
  },
}
