/* eslint-disable no-undefined */
import React, { useEffect, useRef, useState } from 'react'
import {
  MenuItem,
  Tooltip,
  Paper,
  Popper,
  ClickAwayListener,
} from '@material-ui/core'
import { IAssignmentLotTableToolbar, ELotStatusDesc } from '@grand-tender/types'
import clsx from 'clsx'

import {
  useToolbarStyles,
  ButtonContainer,
  Description,
  MenuList,
  Title,
} from './Styles'

import ChangeAssignmentModal from '../ChangeAssignmentModal/ChangeAssignmentModal'
import {
  DeleteFilled,
  NodeMoreActionButton,
  PlusCircle,
  Cross,
} from '@gmini/ui-kit'
import {
  Button,
  CrossIconWrapper,
  DeleteButton,
  Toolbar,
} from './AssignmentLotTableToolbar.styled'
import { CloseLotModal } from '../CloseLotModal/CloseLotModal'
import { usePermissions } from '@grand-tender/app/src/hooks/usePermissions'
import { Authorities } from '@grand-tender/auth-service'

const AssignmentLotTableToolbar: React.FC<IAssignmentLotTableToolbar> = ({
  search = false,
  lot,
  selectedRowsCount = 0,
  assignmentId,
  supplierColumns,
  dataIsLoading = false,
  onAddBtnClick,
  onAddBtnPositionsClick,
  onCloseLot,
  onRemoveLotBtnClick,
  onRemovePositionsBtnClick,
  onExportBtnClick,
  onExportTemplateBtnClick,
  onSendBtnClick,
  onChangeLotAssignmentClick,
  onEditLotClick,
  onMovePositionsBtnClick,
  toggleCreateDialogModal,
  disableActionButtons,
  openLotWinnerModal,
  openAddAgreementModal,
  onResetSelectedRows,
  assignmentClosed,
}): React.ReactElement => {
  const toolbarRef = useRef<HTMLDivElement>(null)
  const [isSticky, setIsSticky] = useState(false)
  const checkPermissions = usePermissions()
  const suppliers = lot.suppliers.data
  useEffect(() => {
    if (!toolbarRef.current) {
      return
    }
    const handleScroll = () => {
      if (
        !toolbarRef.current ||
        toolbarRef.current?.nextSibling?.nodeName !== 'DIV'
      ) {
        return
      }
      const nextSibling = toolbarRef.current.nextSibling as HTMLDivElement
      const toolbarEnd =
        toolbarRef.current.offsetTop + toolbarRef.current.offsetHeight

      if (toolbarEnd > nextSibling.offsetTop) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }
    document.body.addEventListener('wheel', handleScroll)
    return () => {
      document.body.removeEventListener('wheel', handleScroll)
    }
  }, [toolbarRef])

  const classes = useToolbarStyles()
  const [lotMenuAnchorEl, setLotMenuAnchorEl] = useState<HTMLElement | null>(
    null,
  )
  const [
    openChangeLotAssignmentModal,
    setOpenChangeLotAssignmentModal,
  ] = useState<boolean>(false)

  const [closeLotModalOpen, setCloseLotModalOpen] = useState<boolean>(false)
  const openLotMenu = (event: React.MouseEvent<HTMLElement>): void => {
    if (event.isPropagationStopped()) {
      return
    }

    event.stopPropagation()
    setLotMenuAnchorEl(event.currentTarget || null)
  }
  const closeLotMenu = (event: React.MouseEvent<EventTarget>): void => {
    event.stopPropagation()
    setLotMenuAnchorEl(null)
  }

  const canAddAgreement = suppliers.some(s => !!s.position && !s.hasAgreement)

  const lotClosed = lot.status === ELotStatusDesc.CLOSED

  const canCloseLot = suppliers.some(s => s.hasAgreement) && !lotClosed

  const supplierPositionButton = supplierColumns.length ? (
    <Button hide={lotClosed} onClick={() => openLotWinnerModal?.()}>
      Определить победителей
    </Button>
  ) : null

  const addAgreementButton = canAddAgreement ? (
    <Button
      hide={lotClosed}
      color='secondary'
      onClick={() => openAddAgreementModal?.()}
    >
      Добавить соглашение
    </Button>
  ) : null

  const addCloseLotButton = (
    <Tooltip title={!canCloseLot ? 'Заключите хотя бы одно соглашение' : ''}>
      <div>
        <Button
          disabled={!canCloseLot}
          hide={lotClosed}
          color='secondary'
          onClick={() => setCloseLotModalOpen(true)}
        >
          Закрыть лот
        </Button>
      </div>
    </Tooltip>
  )
  const assignmentHeaderHeight =
    document.getElementById('assignment-sticky-header')?.offsetHeight || 0
  return (
    <Toolbar
      disableGutters={true}
      innerRef={toolbarRef}
      isSticky={isSticky}
      offsetTop={assignmentHeaderHeight}
      className={clsx(classes.root, {
        [classes.highlight]: selectedRowsCount > 0,
      })}
    >
      <CloseLotModal
        lotName={search ? `Лот ${lot.position || ''}: ${lot.name}` : lot.name}
        assignmentId={assignmentId}
        lotId={lot.id}
        suppliers={suppliers}
        open={closeLotModalOpen}
        onClose={() => setCloseLotModalOpen(false)}
        onSubmit={onCloseLot}
      />
      <Title variant='subtitle1'>
        <Description>
          <div>
            {search ? `Лот ${lot.position || ''}: ${lot.name}` : lot.name}
          </div>
        </Description>
      </Title>
      {selectedRowsCount > 0 ? (
        <ButtonContainer>
          <DeleteButton
            rightIcon={
              <CrossIconWrapper onClick={onResetSelectedRows}>
                <Cross />
              </CrossIconWrapper>
            }
          >
            Выбрано: {selectedRowsCount}
          </DeleteButton>

          {!assignmentClosed && supplierPositionButton}
          {!assignmentClosed && addAgreementButton}
          {addCloseLotButton}
          {!suppliers.length && (
            <Button color='secondary' onClick={onMovePositionsBtnClick}>
              Переместить в другой лот
            </Button>
          )}
          {!lot.hasAgreement &&
            checkPermissions(
              Authorities.ASSIGNMENT_DETAILS_REMOVE_ITEM_FROM_LOT,
            ) && (
              <Button
                hide={lotClosed}
                leftIcon={<DeleteFilled />}
                color='warning'
                onClick={onRemovePositionsBtnClick}
              >
                Удалить
              </Button>
            )}
        </ButtonContainer>
      ) : (
        <ButtonContainer>
          {!search &&
            checkPermissions(Authorities.TENDER_CONDITIONS_CREATE_CONDITION) &&
            !assignmentClosed &&
            !lot.hasAgreement &&
            !suppliers.length && (
              <Button
                hide={lotClosed}
                color='secondary'
                leftIcon={<PlusCircle width='20px' height='20px' />}
                onClick={toggleCreateDialogModal}
              >
                Добавить тендерное условие
              </Button>
            )}

          {!search &&
            !lot.hasAgreement &&
            !suppliers.length &&
            checkPermissions(Authorities.ITEMS_ADD_ITEM_TO_TENDER) && (
              <Button
                hide={lotClosed}
                color='secondary'
                leftIcon={<PlusCircle width='20px' height='20px' />}
                onClick={onAddBtnPositionsClick}
              >
                Добавить позиции
              </Button>
            )}
          {!search &&
            !assignmentClosed &&
            checkPermissions(
              Authorities.ASSIGNMENT_DETAILS_ADD_SUPPLIER_TO_LOT,
            ) && (
              <Button
                hide={lotClosed}
                color='secondary'
                leftIcon={<PlusCircle width='20px' height='20px' />}
                onClick={onAddBtnClick}
              >
                Добавить поставщика
              </Button>
            )}
          {!assignmentClosed && supplierPositionButton}
          {!assignmentClosed && addAgreementButton}
          {addCloseLotButton}
          {(checkPermissions(Authorities.ASSIGNMENT_DETAILS_RENAME_LOT) ||
            checkPermissions(Authorities.ASSIGNMENT_DETAILS_REMOVE_LOT) ||
            checkPermissions(
              Authorities.ASSIGNMENT_DETAILS_CHANGE_TENDER_FOR_LOT,
            )) && <NodeMoreActionButton onClick={openLotMenu} show={true} />}
          <Popper
            open={Boolean(lotMenuAnchorEl)}
            anchorEl={lotMenuAnchorEl}
            style={{ zIndex: 10 }}
          >
            <Paper>
              <ClickAwayListener onClickAway={closeLotMenu}>
                <MenuList>
                  <MenuItem disabled className='menu-title'>
                    <b>Действия с Лотом</b>
                  </MenuItem>
                  <MenuItem
                    disabled={
                      lot.hasAgreement ||
                      !checkPermissions(
                        Authorities.ASSIGNMENT_DETAILS_CHANGE_TENDER_FOR_LOT,
                      )
                    }
                    onClick={(): void => {
                      setLotMenuAnchorEl(null)
                      setOpenChangeLotAssignmentModal(true)
                    }}
                  >
                    Сменить тендерное задание
                  </MenuItem>
                  {!lot.hasAgreement && !search && (
                    <Tooltip
                      classes={{
                        tooltipPlacementBottom: classes.listItemTooltip,
                      }}
                      title={
                        disableActionButtons
                          ? 'Для рассылки лота добавьте позиции в лот'
                          : ''
                      }
                      arrow
                    >
                      <div>
                        <MenuItem
                          disabled={
                            disableActionButtons ||
                            lot.status !== ELotStatusDesc.NEW
                          }
                          onClick={onSendBtnClick}
                        >
                          Отправить
                        </MenuItem>
                      </div>
                    </Tooltip>
                  )}
                  {!search && (
                    <Tooltip
                      classes={{
                        tooltipPlacementBottom: classes.listItemTooltip,
                      }}
                      title={
                        disableActionButtons
                          ? 'Для экспорта лота добавьте позиции в лот'
                          : ''
                      }
                      arrow
                    >
                      <div>
                        <MenuItem
                          disabled={disableActionButtons}
                          onClick={onExportBtnClick}
                        >
                          Экспорт лота
                        </MenuItem>
                      </div>
                    </Tooltip>
                  )}
                  {!search && (
                    <Tooltip
                      classes={{
                        tooltipPlacementBottom: classes.listItemTooltip,
                      }}
                      title={
                        disableActionButtons
                          ? 'Для экспорта шаблона добавьте позиции в лот'
                          : ''
                      }
                      arrow
                    >
                      <div>
                        <MenuItem
                          disabled={disableActionButtons}
                          onClick={onExportTemplateBtnClick}
                        >
                          Экспорт шаблона
                        </MenuItem>
                      </div>
                    </Tooltip>
                  )}
                  {!search && (
                    <MenuItem
                      disabled={
                        !checkPermissions(
                          Authorities.ASSIGNMENT_DETAILS_RENAME_LOT,
                        )
                      }
                      onClick={(event: React.MouseEvent<HTMLElement>): void => {
                        event.stopPropagation()
                        setLotMenuAnchorEl(null)
                        onEditLotClick()
                      }}
                    >
                      Редактировать
                    </MenuItem>
                  )}
                  <MenuItem
                    disabled={
                      lot.hasAgreement ||
                      !checkPermissions(
                        Authorities.ASSIGNMENT_DETAILS_REMOVE_LOT,
                      )
                    }
                    onClick={(event: React.MouseEvent<HTMLElement>): void => {
                      event.stopPropagation()
                      setLotMenuAnchorEl(null)
                      onRemoveLotBtnClick((): number => 0)
                    }}
                  >
                    Удалить
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
          {openChangeLotAssignmentModal && (
            <ChangeAssignmentModal
              open={openChangeLotAssignmentModal}
              dataIsLoading={dataIsLoading}
              onSave={(assignmentId: string): void => {
                onChangeLotAssignmentClick(assignmentId)
                setOpenChangeLotAssignmentModal(false)
              }}
              onClose={(): void => setOpenChangeLotAssignmentModal(false)}
            />
          )}
        </ButtonContainer>
      )}
    </Toolbar>
  )
}

export default AssignmentLotTableToolbar
