import React, { useCallback, useEffect, useState } from 'react'
import * as I from './IListEditor'
import { List, Popover } from '@material-ui/core'
import { EButtonType, ETreeListItemType } from '../../constants'
import ButtonComponent from '../Button/Button'
import { useStyles } from './Style'
import PositionCatalogMini from '../PositionCatalogMini/PositionCatalogMini'
import { PopoverOrigin } from '@material-ui/core/Popover/Popover'
import { ListEditorItem } from './ListEditorItem/ListEditorItem'
import { flatten } from '../../utils'

const popupAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
}

const popupTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
}

export const ListEditor: React.FC<I.OwnProps> = ({
  ids,
  catalog,
  onAdd,
  onDelete,
}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const [names, setNames] = useState<Record<string, string>>({})

  useEffect(() => {
    const obj: Record<string, string> = {}
    flatten(catalog)
      .filter(item => item.type === ETreeListItemType.LIST)
      .forEach(item => {
        obj[item.id] = item.name
      })
    setNames(obj)
  }, [catalog])

  const openPopup = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget)
    },
    [],
  )

  const closePopup = useCallback((): void => {
    setAnchorEl(null)
  }, [])

  const selectItem = useCallback(
    (selectedId: string) => {
      closePopup()
      if (!ids.some(id => id === selectedId)) {
        onAdd(selectedId)
      }
    },
    [closePopup, ids, onAdd],
  )

  return (
    <div className={classes.root}>
      <ButtonComponent
        text='Добавить'
        type={EButtonType.DEFAULT}
        onClick={openPopup}
      />
      <Popover
        anchorEl={anchorEl}
        id='catalog-popover'
        className={classes.catalog}
        anchorOrigin={popupAnchorOrigin}
        transformOrigin={popupTransformOrigin}
        open={Boolean(anchorEl)}
        onClose={closePopup}
      >
        <PositionCatalogMini
          anchorEl={anchorEl}
          catalog={catalog}
          onSelectNode={selectItem}
        />
      </Popover>
      <List dense={true} className={classes.list}>
        {ids.map((id: string) => (
          <ListEditorItem
            key={id}
            value={names[id] ?? '???'}
            onDelete={(): void => onDelete(id)}
          />
        ))}
      </List>
    </div>
  )
}
