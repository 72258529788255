import { downloadFile } from '@grand-tender/base-api-service/src/apiService/utils'
import {
  apiClient,
  CPM_API_ENDPOINT,
  HM_API_ENDPOINT,
} from '@grand-tender/base-api-service/src'

import { toastr } from 'react-redux-toastr'
import { get, isNil, union } from 'lodash'
import { AssignmentService } from '@grand-tender/assignment-service'
import { SearchService } from '@grand-tender/search-service'
import {
  IAPIResult,
  TTableData,
  IRow,
  TRowData,
  IKeyValuePair,
  IColumn,
  TAssignmentModel,
  TTenderSearchPaginationRequestModel,
  TAssignmentSectionData,
  TAssignmentLotPage,
  ILotAssigmentAction,
  TAssignmentLots,
  TAssignmentRequest,
  AssignmentTab,
  TAssignmentLotRequest,
  TTenderConditionSuggestion,
  ELotStatusDesc,
  EmptyPricePositions,
  ProjectObjects,
} from '@grand-tender/types'
import { EActionTypes } from '@grand-tender/ui'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { setDataLoading } from '../RequestWrapper'
import { setAvailableColumns } from '../Filter'
import { IActionCreator, actionCreator } from '../BaseAction'

export const getAssignments = createAsyncThunk<
  TTableData,
  {
    params: TAssignmentRequest
    tab: AssignmentTab
    setLoading: (data: boolean) => void
  }
>(
  'assignments/getAssignments',
  async ({ params, tab, setLoading }, { rejectWithValue, dispatch }) => {
    setLoading(true)
    const [err, result] = await AssignmentService.getTenders(params, tab)

    if (err) {
      setLoading(false)
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      dispatch(setAssignments(result.data.data))
      dispatch(
        setAvailableColumns(
          result.data.data.columns.map(column => ({
            name: column.title,
            type: column.type,
          })),
        ),
      )
      setLoading(false)
    }
    const data: TTableData = result.data.data || {
      columns: [],
      data: [],
      pagination: { page: 0, rowsPerPage: 10, total: 0 },
    }
    return data
  },
)

export const getAssignmentDetail = createAsyncThunk<
  unknown,
  { assignmentId: string; callback: () => void }
>(
  'assignments/getAssignmentDetail',
  async ({ assignmentId, callback }, { rejectWithValue, dispatch }) => {
    dispatch(setDataLoading(true))
    const [err, result] = await AssignmentService.getTenderData(assignmentId)

    if (err) {
      throw rejectWithValue(err)
    }

    callback()
    if (result.data.data) {
      const data = { ...result.data.data }
      data.lots.forEach((l, i) => (l.position = i + 1))
      dispatch(setAssignmentDetail(result.data.data))
    }
    dispatch(setDataLoading(false))
  },
)

export const getLotCategory = createAsyncThunk<unknown, TAssignmentLotRequest>(
  'assignments/getLotCategory',
  async (params, { rejectWithValue, dispatch }) => {
    const [err, result] = await AssignmentService.getLotCategory(params)

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      dispatch(
        changeAssignmentLotCategoryPage(
          +params.pageData.categoryId,
          params.pageData.lotId,
          result.data.data,
        ),
      )
    }
  },
)

export const getSearchOtherPageLotCategory = createAsyncThunk<
  unknown,
  TTenderSearchPaginationRequestModel
>(
  'assignments/getSearchOtherPageLotCategory',
  async (params, { rejectWithValue, dispatch }) => {
    const [err, result] = await SearchService.getSearchOtherPageLotCategory(
      params,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      dispatch(
        changeAssignmentLotCategoryPage(
          params.pageData.categoryId,
          params.pageData.lotId,
          result.data.data,
        ),
      )
    }
  },
)

export const createAssignment = createAsyncThunk<
  unknown,
  { tender: TRowData; callback: () => void }
>(
  'assignments/createAssignment',
  async ({ tender, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await AssignmentService.addTender(
      tender.filter(td => td.value && td.value.length),
    )

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      callback()
    }
  },
)

export const removeAssignments = createAsyncThunk<
  unknown,
  { ids: Array<string>; callback: () => void }
>(
  'assignments/removeAssignments',
  async ({ ids, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await AssignmentService.deleteTenders(ids)

    if (err) {
      throw rejectWithValue(err)
    }

    callback()
  },
)

export const addAssignmentProperty = createAsyncThunk<
  unknown,
  {
    model: IColumn
    categoryId: string
    sectionId: string
  }
>(
  'assignments/addAssignmentProperty',
  async ({ model, categoryId, sectionId }, { rejectWithValue }) => {
    const [err] = await AssignmentService.addProperty(
      model,
      categoryId,
      sectionId,
    )

    if (err) {
      throw rejectWithValue(err)
    }
  },
)

export const editAssignmentProperty = createAsyncThunk<
  unknown,
  { model: IColumn; sectionId: string }
>(
  'assignments/editAssignmentProperty',
  async ({ model, sectionId }, { rejectWithValue }) => {
    const [err] = await AssignmentService.editProperty(model, sectionId)

    if (err) {
      throw rejectWithValue(err)
    }
  },
)

export const removeAssignmentProperty = createAsyncThunk<
  unknown,
  { key: string }
>(
  'assignments/removeAssignmentProperty',
  async ({ key }, { rejectWithValue }) => {
    const [err] = await AssignmentService.removeProperty(key)

    if (err) {
      throw rejectWithValue(err)
    }
  },
)

export const exportAssignmentsByIds = createAsyncThunk<
  unknown,
  { ids: Array<string> }
>(
  'assignments/exportAssignmentsByIds',
  async ({ ids }, { rejectWithValue, dispatch }) => {
    dispatch(setDataLoading(true))
    const [err, blob] = await AssignmentService.exportTendersByIds(ids)

    if (err) {
      throw rejectWithValue(err)
    }
    dispatch(setDataLoading(false))
    downloadFile(blob)
  },
)

export const addToAssignment = createAsyncThunk<
  unknown,
  { positionIds: Array<string>; lotId: string; callback: () => void }
>(
  'assignments/addToAssignment',
  async ({ positionIds, lotId, callback }, { rejectWithValue, dispatch }) => {
    const state = (window as any).getState()
    const lots: TAssignmentLots = get(state, 'viewDetails.assignment.lots')
    const mapPositions = lots.flatMap(el => el.positions)
    const mapPositionsData = mapPositions.flatMap(el => el.data)
    const mapRowIds = mapPositionsData.map(el => el.rowId)
    const wasAddedIds = union(
      mapRowIds
        .map(el => {
          if (positionIds.includes(el)) {
            return el
          }
          return null
        })
        .filter(el => el),
    )
    if (wasAddedIds.length > 0) {
      const positions = get(state, 'positions.data')
      const positionColumnName = positions.columns.find((el: IColumn) => {
        const normalizeTitle =
          el.title.lastIndexOf('[') === -1
            ? el.title
            : el.title.substring(0, el.title.lastIndexOf('['))

        return normalizeTitle === 'Название'
      })

      const mapAddedRow = wasAddedIds.map(
        (el): IRow =>
          positions.data.find(
            (item: IRow) => String(item.rowId) === String(el),
          ),
      )

      const mapRowData = mapAddedRow
        .flatMap(el => el.data)
        .map(el => {
          if (el.key === positionColumnName.key) {
            return el.value
          }
          return null
        })
        .filter(el => el)

      toastr.error('', `Позиция ${mapRowData.join(',')} уже есть в ТЗ`, {
        progressBar: false,
        showCloseButton: false,
      })
    }

    const withOutAddedIds = positionIds.filter(el => !wasAddedIds.includes(el))
    dispatch(setDataLoading(true))
    const [err] = await AssignmentService.addToAssignment(
      withOutAddedIds,
      lotId,
    )

    if (err) {
      const errText = 'При добавлении позиций в лот произошла ошибка'
      const errMsg = isNil(err) ? errText : `${errText}: ${err}`
      toastr.error('', errMsg, {
        progressBar: false,
        showCloseButton: false,
      })
      throw rejectWithValue(err)
    }

    if (withOutAddedIds.length > 0) {
      toastr.success('', 'Позиции успешно добавлены в лот')
    }
    dispatch(setDataLoading(false))
    callback()
  },
)

export const addCategoriesToLot = createAsyncThunk<
  unknown,
  { categoryIds: Array<string>; lotId: number; callback: () => void }
>(
  'assignments/addCategoriesToLot',
  async ({ categoryIds, lotId, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await AssignmentService.addCategoriesToLot(categoryIds, lotId)

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.success('', 'Позиции успешно добавлены в лот')
    callback()
  },
)

export const addLotToAssignment = createAsyncThunk<
  unknown,
  ILotAssigmentAction
>(
  'assignments/addLotToAssignment',
  async (addLotData, { rejectWithValue, dispatch }) => {
    const [err] = await AssignmentService.addLotToAssignment(
      addLotData.idAddTo,
      addLotData.name,
      addLotData.discription,
      addLotData.specializationIds,
    )

    if (err) {
      toastr.error(
        '',
        `При добавлении лота [${addLotData.name}] тендерное задание ${addLotData.idAddTo} произошла ошибка!`,
      )
      throw rejectWithValue(err)
    }
    toastr.success(
      '',
      `Лот [${addLotData.name}] успешно добавлен в тендерное задание ${addLotData.idAddTo}`,
    )

    addLotData.callback()
  },
)

export const editLotAssignment = createAsyncThunk<
  unknown,
  { data: IRow; callback: () => void }
>(
  'assignments/editLotAssignment',
  async ({ data, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await AssignmentService.editAssignment(data)

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.success('', `Тендерное задание успешно отредактировано!`)
    callback()
  },
)

export const deleteLotAssignment = createAsyncThunk<
  unknown,
  { id: number; callback: () => void }
>(
  'assignments/deleteLotAssignment',
  async ({ id, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await AssignmentService.deleteAssignment(id)

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.success('', `Тендерное задания ${id} успешно удалено!`)
    callback()
  },
)

export const changeStatusAssignment = createAsyncThunk<
  unknown,
  { tenderId: number; status: string; callback: () => void }
>(
  'assignments/changeStatusAssignment',
  async ({ tenderId, status, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await AssignmentService.changeStatusAssignment(
      tenderId,
      status,
    )

    if (err) {
      toastr.error('', `При изменении статуса произошла ошибка!`)
      throw rejectWithValue(err)
    }

    toastr.success('', `Статус упешно изменен!`)

    callback()
  },
)

export const addAssignmentLotProperty = createAsyncThunk<
  unknown,
  { lotId: string; name: string; callback: () => void }
>(
  'assignments/addAssignmentLotProperty',
  async ({ lotId, name, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await AssignmentService.addLotProperty(lotId, name)

    if (err) {
      throw rejectWithValue(err)
    }

    callback()
  },
)

export const editAssignmentLotProperty = createAsyncThunk<
  unknown,
  { key: string; name: string; callback: () => void }
>(
  'assignments/editAssignmentLotProperty',
  async ({ key, name, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await AssignmentService.editLotProperty(key, name)

    if (err) {
      throw rejectWithValue(err)
    }

    callback()
  },
)

export const removeAssignmentLotProperty = createAsyncThunk<
  unknown,
  { key: string; callback: () => void }
>(
  'assignments/removeAssignmentLotProperty',
  async ({ key, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await AssignmentService.removeLotProperty(key)

    if (err) {
      throw rejectWithValue(err)
    }

    callback()
  },
)

export const exportAssignmentTemplate = createAsyncThunk<
  unknown,
  {
    lotId: string
    categories: Array<{
      categoryId: string
      propositionCustomFieldIds: Array<string>
      columnIds: Array<string>
    }>
  }
>(
  'assignments/exportAssignmentTemplate',
  async ({ lotId, categories }, { rejectWithValue, dispatch }) => {
    dispatch(setDataLoading(true))
    const [err, blob] = await AssignmentService.exportTemplate(
      lotId,
      categories,
    )

    if (err) {
      throw rejectWithValue(err)
    }
    dispatch(setDataLoading(false))
    downloadFile(blob)
  },
)

export const exportAssignmentLot = createAsyncThunk<
  unknown,
  {
    lotId: string
    providersIds: Array<string>
    categories: Array<{
      categoryId: string
      propositionCustomFieldIds: string[]
    }>
  }
>(
  'assignments/exportAssignmentLot',
  async (params, { rejectWithValue, dispatch }) => {
    dispatch(setDataLoading(true))
    const [err, blob] = await AssignmentService.exportLot(params)

    if (err) {
      throw rejectWithValue(err)
    }

    dispatch(setDataLoading(false))
    downloadFile(blob)
    toastr.success('', 'Лот успешно экспортирован!')
  },
)

export const removeAssignmentLotPositions = createAsyncThunk<
  unknown,
  { lotId: string; positionIds: Array<string>; callback: () => void }
>(
  'assignments/removeAssignmentLotPositions',
  async ({ lotId, positionIds, callback }, { rejectWithValue, dispatch }) => {
    dispatch(setDataLoading(true))
    const [err] = await AssignmentService.removeAssignmentLotPositions(
      lotId,
      positionIds,
    )

    if (err) {
      toastr.error('', 'При удалении позиций произошла ошибка!', {
        progressBar: false,
        showCloseButton: false,
      })
      throw rejectWithValue(err)
    }

    toastr.success('', 'Позиции успешно удалены!')
    dispatch(setDataLoading(false))
    callback()
  },
)

export const removeAssignmentLotSupplier = createAsyncThunk<
  unknown,
  { lotId: string; providerId: string; callback: () => void }
>(
  'assignments/removeAssignmentLotSupplier',
  async ({ lotId, providerId, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await AssignmentService.removeAssignmentLotSupplier(
      lotId,
      providerId,
    )

    if (err) {
      toastr.error('', 'При удалении поставщика из лота произошла ошибка!', {
        progressBar: false,
        showCloseButton: false,
      })

      throw rejectWithValue(err)
    }

    dispatch(removeLotSupplier({ key: lotId, value: providerId }))
    toastr.success('', 'Поставщик успешно удалён из лота!')
    callback()
  },
)

export const removeAssignmentLot = createAsyncThunk<
  unknown,
  { lotId: string; callback: () => void }
>(
  'assignments/removeAssignmentLot',
  async ({ lotId, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await AssignmentService.removeAssignmentLot(lotId)

    if (err) {
      toastr.error('', 'При удалении лота произошла ошибка!', {
        progressBar: false,
        showCloseButton: false,
      })

      throw rejectWithValue(err)
    }

    toastr.success('', 'Лот успешно удалён!')
    callback()
  },
)

export const combineAssignmentLot = createAsyncThunk<
  unknown,
  { sourceId: string; destinationId: string; callback: () => void }
>(
  'assignments/combineAssignmentLot',
  async (
    { sourceId, destinationId, callback },
    { rejectWithValue, dispatch },
  ) => {
    const [err] = await AssignmentService.combineAssignmentLot(
      sourceId,
      destinationId,
    )

    if (err) {
      const errText = 'При объединение лотов произошла ошибка'
      const errMsg = isNil(err) ? errText : `${errText}: ${err}`
      toastr.error('', errMsg, {
        progressBar: false,
        showCloseButton: false,
      })
      throw rejectWithValue(err)
    }

    toastr.success(
      '',
      `Лот [${sourceId}] и [${destinationId}] успешно объединены!`,
    )
    callback()
  },
)

export const changeLotAssignment = createAsyncThunk<
  unknown,
  { lotId: string; assignmentId: string; callback: () => void }
>(
  'assignments/changeLotAssignment',
  async ({ lotId, assignmentId, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await AssignmentService.changeLotAssignment(
      lotId,
      assignmentId,
    )

    if (err) {
      toastr.error('', 'При перемещении лота произошла ошибка!', {
        progressBar: false,
        showCloseButton: false,
      })
      throw rejectWithValue(err)
    }
    toastr.success('', 'Лот успешно перемещен!')
    callback()
  },
)

export const getEmptyPricePositions = createAsyncThunk<
  unknown,
  {
    lotId: string
    tenderId: string
    callback: (data: EmptyPricePositions) => void
  }
>(
  'assignments/getEmptyPricePositions',
  async ({ lotId, tenderId, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await AssignmentService.getEmptyPricePositions(
      lotId,
      tenderId,
    )

    if (err) {
      toastr.error(
        '',
        `При запросе позиций, не попавших в соглашение(я), произошла ошибка!`,
      )
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      callback(result.data.data)
    }
  },
)

export const changeLotName = createAsyncThunk<
  unknown,
  {
    lotId: string
    name: string
    description: string
    status: keyof typeof ELotStatusDesc
    callback: () => void
    specializationIds?: Array<number>
  }
>(
  'assignments/changeLotName',
  async (
    { lotId, name, description, status, specializationIds, callback },
    { rejectWithValue, dispatch },
  ) => {
    const [err] = await AssignmentService.changeLotName(
      lotId,
      name,
      description,
      status,
      specializationIds,
    )

    if (err) {
      toastr.error('', 'При изменении лота произошла ошибка!', {
        progressBar: false,
        showCloseButton: false,
      })
      throw rejectWithValue(err)
    }

    if (status === 'CLOSED') {
      toastr.success('', `Лот "${name}" успешно закрыт.`)
    } else {
      toastr.success('', 'Лот успешно изменен!')
    }
    callback()
    dispatch(renameAssignmentLot({ key: lotId, value: name }))
  },
)

export const movePositions = createAsyncThunk<
  unknown,
  {
    fromLotId: string
    toLotId: string
    positionsIds: Array<string>
    removeFromCurrentLot: boolean
    callback: () => void
  }
>(
  'assignments/movePositions',
  async (
    { fromLotId, toLotId, positionsIds, removeFromCurrentLot, callback },
    { rejectWithValue, dispatch },
  ) => {
    const [err] = await AssignmentService.movePositions(
      fromLotId,
      toLotId,
      positionsIds,
      removeFromCurrentLot,
    )

    if (err) {
      toastr.error(
        '',
        `При ${
          removeFromCurrentLot ? 'перемещении' : 'копировании'
        } позиций произошла ошибка!`,
      )

      throw rejectWithValue(err)
    }

    toastr.success(
      '',
      `Позиции успешно ${removeFromCurrentLot ? 'перемещены' : 'скопированы'}!`,
    )
    callback()
  },
)

export const addAssignmentLotSupplier = createAsyncThunk<
  unknown,
  { lotId: string; supplierId: string; file: File; callback: () => void }
>(
  'assignments/addAssignmentLotSupplier',
  async (
    { lotId, supplierId, file, callback },
    { rejectWithValue, dispatch },
  ) => {
    dispatch(setDataLoading(true))
    const [err] = await AssignmentService.addAssignmentLotSupplier(
      lotId,
      supplierId,
      file,
    )

    if (err) {
      throw rejectWithValue(err)
    }
    dispatch(setDataLoading(false))
    toastr.success('', `Поставщик успешно импортирован!`)
    callback()
  },
)

export const updateAssignmentLotSupplier = createAsyncThunk<
  unknown,
  { lotId: string; supplierId: string; file: File; callback: () => void }
>(
  'assignments/updateAssignmentLotSupplier',
  async (
    { lotId, supplierId, file, callback },
    { rejectWithValue, dispatch },
  ) => {
    const [err] = await AssignmentService.updateAssignmentLotSupplier(
      lotId,
      supplierId,
      file,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    toastr.success('', `Прайс успешно обновлён!`)
    callback()
  },
)

export const addSection = createAsyncThunk<
  unknown,
  { categoryId: string; name: string; callback: (model: IKeyValuePair) => void }
>(
  'assignments/addSection',
  async ({ categoryId, name, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await AssignmentService.addSection(categoryId, name)

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      const has = <T extends Record<string, unknown>, K extends string>(
        key: K,
        x: T,
      ): x is T => key in x
      if (
        typeof result.data.data !== 'object' ||
        Object.keys(result.data.data).some(k => !has(k, { key: '', value: '' }))
      ) {
        throw new Error(`Получен неверный ответ: ${result.data.data}`)
      }

      callback(result.data.data)
    }
  },
)

export const editSection = createAsyncThunk<
  unknown,
  { value: IKeyValuePair; callback: (model: IKeyValuePair) => void }
>(
  'assignments/editSection',
  async ({ value, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await AssignmentService.editSection(
      value.key,
      value.value,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      const has = <T extends Record<string, unknown>, K extends string>(
        key: K,
        x: T,
      ): x is T => key in x
      if (
        typeof result.data.data !== 'object' ||
        Object.keys(result.data.data).some(k => !has(k, { key: '', value: '' }))
      ) {
        throw new Error(`Получен неверный ответ: ${result.data.data}`)
      }

      callback(result.data.data)
    }
  },
)

export const removeSection = createAsyncThunk<
  unknown,
  { key: string; callback: () => void }
>(
  'assignments/removeSection',
  async ({ key, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await AssignmentService.removeSection(key)

    if (err) {
      throw rejectWithValue(err)
    }

    callback()
  },
)

export const getAssignmentSectionData = createAsyncThunk<
  unknown,
  { assignmentId: string; callback: (data: TAssignmentSectionData) => void }
>(
  'assignments/getAssignmentSectionData',
  async ({ assignmentId, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await AssignmentService.getAssignmentSectionData(
      assignmentId,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      callback(result.data.data)
    }
  },
)

export function setAssignments(
  assignments: TTableData,
): IActionCreator<TTableData> {
  return actionCreator<TTableData>(EActionTypes.SET_ASSIGNMENTS, assignments)
}

export function setAssignmentDetail(
  assignmentDetail: TAssignmentModel,
): IActionCreator<TAssignmentModel> {
  return actionCreator<TAssignmentModel>(
    EActionTypes.SET_ASSIGNMENT_DETAIL,
    assignmentDetail,
  )
}

export function changeAssignmentLotCategoryPage(
  categoryId: number,
  lotId: number,
  lotData: TAssignmentLotPage,
): IActionCreator<{
  categoryId: number
  lotId: number
  lotData: TAssignmentLotPage
}> {
  return actionCreator<{
    categoryId: number
    lotId: number
    lotData: TAssignmentLotPage
  }>(EActionTypes.CHANGE_ASSIGNMENT_LOT_CATEGORY_PAGE, {
    categoryId,
    lotId,
    lotData,
  })
}

export function renameAssignmentLot(
  lot: IKeyValuePair,
): IActionCreator<IKeyValuePair> {
  return actionCreator<IKeyValuePair>(EActionTypes.RENAME_ASSIGNMENT_LOT, lot)
}

export function removeLotSupplier(
  model: IKeyValuePair,
): IActionCreator<IKeyValuePair> {
  return actionCreator<IKeyValuePair>(
    EActionTypes.REMOVE_ASSIGNMENT_LOT_SUPPLIER,
    model,
  )
}

const createNewCondition = (name: string, measureUnit?: string) => ({
  name,
  isRequired: false,
  fieldType: 'string',
  measureUnit: measureUnit ? measureUnit : '',
})

export const createTenderCondition = createAsyncThunk<
  unknown,
  Record<string, unknown>
>(
  'assignments/createTenderCondition',
  async (values, { rejectWithValue, dispatch }) => {
    dispatch(setDataLoading(true))

    const name = get(values, 'conditionName', '')
    const isAllLots = get(values, 'isAllLots', false)

    const additionalConditions = get(values, 'additionalConditions', [])
    const mappingAdditionalConditions = (additionalConditions as string[]).map(
      el => createNewCondition(el),
    )

    const path = isAllLots
      ? `/tender-tasks/${values.tenderAssignmentId}/tender-conditions`
      : `/lot/${values.selectedLotId}/tender-conditions`

    const conditionItems = name
      ? [
          createNewCondition(name as string, 'string'),
          ...mappingAdditionalConditions,
        ]
      : [...mappingAdditionalConditions]

    const [err, result] = await apiClient.post<
      any,
      IAPIResult<Record<string, unknown>>
    >({
      path,
      headers: {
        'Content-Type': 'application/json',
      },
      body: { conditions: conditionItems },
    })

    dispatch(setDataLoading(false))

    if (err) {
      throw rejectWithValue(err)
    }

    return result.data.data
  },
)

export const removeTenderCondition = createAsyncThunk<
  unknown,
  { lotId: number; tenderConditionId: number }
>(
  'assignments/removeTenderCondition',
  async ({ lotId, tenderConditionId }, { rejectWithValue, dispatch }) => {
    dispatch(setDataLoading(true))
    const [err, result] = await apiClient.delete<unknown>({
      path: `/lot/${lotId}/tender-conditions/${tenderConditionId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    dispatch(setDataLoading(false))
    if (err) {
      throw rejectWithValue(err)
    }

    return result.data
  },
)

export const editTenderCondition = createAsyncThunk<
  unknown,
  Record<string, unknown>
>(
  'assignments/editTenderCondition',
  async (values, { rejectWithValue, dispatch }) => {
    const lotId = get(values, 'lotId')
    const tenderConditionId = get(values, 'tenderConditionId')
    const name = get(values, 'conditionName')
    dispatch(setDataLoading(true))

    const [err, result] = await apiClient.put<any, unknown>({
      path: `/lot/${lotId}/tender-conditions/${tenderConditionId}`,
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        conditions: [
          {
            name,
            id: tenderConditionId,
            fieldType: 'string',
            isRequired: false,
          },
        ],
      },
    })

    dispatch(setDataLoading(false))

    if (err) {
      throw rejectWithValue(err)
    }

    return result.data
  },
)

export const editNotePromise = createAsyncThunk<
  unknown,
  { lotId: number; note: string }
>(
  'assignments/editNotePromise',
  async ({ lotId, note }, { rejectWithValue, dispatch }) => {
    dispatch(setDataLoading(true))

    const [err, result] = await apiClient.post<any, unknown>({
      path: '/lot/note',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        lotId,
        note,
      },
    })

    dispatch(setDataLoading(false))

    if (err) {
      throw rejectWithValue(err)
    }

    return result.data
  },
)

export const getAssignmentsCondition = createAsyncThunk<
  TTenderConditionSuggestion,
  unknown
>(
  'assignments/getAssignmentsCondition',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setDataLoading(true))

    const [err, result] = await apiClient.get<
      IAPIResult<TTenderConditionSuggestion>
    >({
      baseUrl: CPM_API_ENDPOINT,
      path: '/construction-projects-management/tender-conditions',
    })

    dispatch(setDataLoading(false))

    if (err) {
      toastr.error('', 'Не удалось получить тендерные условия', {
        progressBar: false,
        showCloseButton: false,
      })
      throw rejectWithValue(err)
    }

    return result.data.data || {}
  },
)

export const setExportSectionFlag = createAsyncThunk<
  unknown,
  {
    tenderId: number
    sectionId: number
    value: boolean
    callback: VoidFunction
  }
>(
  'assignments/setExportSectionFlag',
  async (
    { tenderId, sectionId, value, callback },
    { rejectWithValue, dispatch },
  ) => {
    const [err] = await AssignmentService.setExportSectionFlag(
      tenderId,
      sectionId,
      value,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    callback()
  },
)

export const dragTenderCondition = createAsyncThunk<
  unknown,
  { lotId: number; newTenderConditions: any }
>(
  'assignments/dragTenderCondition',
  async ({ lotId, newTenderConditions }, { rejectWithValue, dispatch }) => {
    const [err, result] = await apiClient.patch({
      path: `/lot/${lotId}/tender-conditions`,
      body: newTenderConditions,
    })

    if (err) {
      throw rejectWithValue(err)
    }

    return result.data
  },
)

export const getAllProjects = createAsyncThunk<ProjectObjects, void>(
  'assignments/getAllProjects',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setDataLoading(true))
    const [err, result] = await apiClient.get<IAPIResult<ProjectObjects>>({
      baseUrl: HM_API_ENDPOINT,
      path: '/projects',
      params: {
        'with-objects': true,
      },
    })

    dispatch(setDataLoading(false))

    if (err) {
      toastr.error('', 'Не удалось получить проекты', {
        progressBar: false,
        showCloseButton: false,
      })
      throw rejectWithValue(err)
    }

    return result.data.data || []
  },
)
