/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { ArrowForwardIos, ExpandLess } from '@material-ui/icons'
import { ITreeListItem } from '@grand-tender/types'
import * as _ from 'lodash'
import * as I from './IPositionCatalogMini'
import usePositionCatalogStyles from './Style'
import { EButtonType, ETreeListItemType } from '../../constants'
import { flatten, LocalStorageHelper } from '../../utils'
import ButtonComponent from '../Button/Button'
import { useAppDispatch, useAppSelector } from '@grand-tender/app/src/store'
import { getCategories } from '@grand-tender/app/src/actions'

const PositionCatalogMini: React.FC<I.OwnProps> = ({
  catalog,
  anchorEl,
  isFullNodeNeed,
  onSelectNode,
  onSelectFullNode,
  onGetCheckedCategories,
}): React.ReactElement => {
  const [
    settingsTreeListItemMovingMenuTreeNodeOpen,
    setSettingsTreeListItemMovingMenuTreeNodeOpen,
  ] = useState<{ [key: string]: boolean }>({})
  const dispatch = useAppDispatch()
  const categories = useAppSelector(state => state.categories)
  const treeList = useMemo(() => (catalog ? catalog : categories), [
    catalog,
    categories,
  ])
  const location = useLocation()
  const classes = usePositionCatalogStyles()
  const { assignmentId } = useParams<{ assignmentId?: string }>()
  const [checkedList, setCheckedList] = useState<Set<string>>(new Set())

  useEffect(() => {
    if (anchorEl) {
      dispatch(
        getCategories({
          tabIndex: 0,
          params: {
            object: '',
            endDate: '',
            project: '',
            startDate: '',
          },
        }),
      )
    }
  }, [Boolean(anchorEl)])

  const getBelowNodeIds = useCallback((node: ITreeListItem): string[] => {
    const nodeId = node.id
    const subNodeIds = _.flatten(node.content.map(n => getBelowNodeIds(n)))

    return [nodeId, ...subNodeIds]
  }, [])

  const handleHigherNodes = useCallback(
    (
      node: ITreeListItem,
      newCheckedList: Set<string>,
      checked: boolean,
    ): void => {
      const list = flatten(treeList)
      const parentNode = list.find(n => n.id === node.parentId)

      if (!parentNode) {
        return
      }

      if (!checked) {
        const hasChildChecked = parentNode.content.some(c =>
          newCheckedList.has(c.id),
        )

        if (!hasChildChecked) {
          newCheckedList.delete(parentNode.id)
        }
      } else {
        const hasAllChildChecked = parentNode.content.every(c =>
          newCheckedList.has(c.id),
        )

        if (hasAllChildChecked) {
          newCheckedList.add(parentNode.id)
        }
      }

      handleHigherNodes(parentNode, newCheckedList, checked)
    },
    [treeList],
  )

  const renderSettingsTreeListItemMovingMenuTreeNode = (
    node: ITreeListItem,
    level = 0,
  ): JSX.Element => (
    <div key={node.id} className={classes.root}>
      <ListItem
        button
        onClick={(event: React.MouseEvent<HTMLElement>): void => {
          if (
            node.type === ETreeListItemType.FOLDER ||
            node.type === ETreeListItemType.GROUP
          ) {
            handleSettingsTreeListItemMovingMenuTreeNodeClick(node.id, event)
          } else {
            isFullNodeNeed
              ? onSelectFullNode && onSelectFullNode(node)
              : onSelectNode && onSelectNode(node.id)
            const page = location.pathname.replace('/', '')
            if (page.includes('positions')) {
              LocalStorageHelper.set<string>(`/positions_categoryId`, node.id)
            }
            if (page.includes('suppliers')) {
              LocalStorageHelper.set<string>(`/suppliers_categoryId`, node.id)
            }
            if (page.includes('assignments')) {
              LocalStorageHelper.set<string>(`/assignments_categoryId`, node.id)
            }
            if (assignmentId) {
              LocalStorageHelper.set<string>(`/positions_categoryId`, node.id)
            }
            if (page.includes('agreements')) {
              LocalStorageHelper.set<string>(`/agreements_categoryId`, node.id)
            }
          }
        }}
        className={classes.listItem}
        disableGutters={true}
        style={{ paddingLeft: `${level === 0 ? 5 : level * 30}px` }}
      >
        {settingsTreeListItemMovingMenuTreeNodeOpen[node.id] &&
        node.type === ETreeListItemType.FOLDER ? (
          <ExpandLess
            style={{
              fontSize: '20px',
              color: '#5162d0',
            }}
          />
        ) : node.type === ETreeListItemType.LIST ? (
          ''
        ) : (
          <ArrowForwardIos
            style={{
              fontSize: '12px',
              marginRight: '5px',
              color: '#5162d0',
            }}
          />
        )}
        <ListItemText primary={node.name} />
        {onGetCheckedCategories && (
          <Checkbox
            color='primary'
            checked={checkedList.has(node.id)}
            onClick={event => {
              event.stopPropagation()
            }}
            onChange={(event, checked) => {
              const belowIds = getBelowNodeIds(node)

              const newList = new Set(checkedList)
              if (checked) {
                belowIds.forEach(id => newList.add(id))
              } else {
                belowIds.forEach(id => newList.delete(id))
              }
              handleHigherNodes(node, newList, checked)

              setCheckedList(newList)
            }}
          />
        )}
      </ListItem>
      <Collapse
        in={settingsTreeListItemMovingMenuTreeNodeOpen[node.id]}
        timeout='auto'
        unmountOnExit
      >
        <List component='div' disablePadding>
          {node.content.map((data: ITreeListItem) =>
            renderSettingsTreeListItemMovingMenuTreeNode(data, level + 1),
          )}
        </List>
      </Collapse>
    </div>
  )

  const handleSettingsTreeListItemMovingMenuTreeNodeClick = (
    rowId: string,
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    if (event.defaultPrevented) {
      return
    }
    event.stopPropagation()
    const newState = _.cloneDeep(settingsTreeListItemMovingMenuTreeNodeOpen)
    const value = newState[rowId] || false

    newState[rowId] = !value
    setSettingsTreeListItemMovingMenuTreeNodeOpen(newState)
  }

  return (
    <>
      {onGetCheckedCategories && (
        <ButtonComponent
          text={'Добавить'}
          type={EButtonType.PRIMARY}
          disabled={!checkedList.size}
          className={classes.button}
          onClick={() => {
            onGetCheckedCategories(Array.from(checkedList))
          }}
        />
      )}
      <div className={classes.catalogWrapper}>
        {treeList.map((data, index) =>
          renderSettingsTreeListItemMovingMenuTreeNode(data),
        )}
      </div>
    </>
  )
}

export default PositionCatalogMini
