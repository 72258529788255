import React from 'react'
import {
  Accordion as MiuiAccordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useStyles } from './Style'

type Props = {
  title: string
  actions?: React.ReactElement
}

export const Accordion: React.FC<Props> = ({ title, children, actions }) => {
  const classes = useStyles()

  return (
    <MiuiAccordion className={classes.root}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{ root: classes.summary, content: classes.summaryContent }}
      >
        <div className={classes.title}>{title}</div>
        <div className={classes.actions}>{actions}</div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.details }}>
        {children}
      </AccordionDetails>
    </MiuiAccordion>
  )
}
