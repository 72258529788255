/* eslint-disable import/no-anonymous-default-export */
import * as _ from 'lodash'
import {
  TTableData,
  IRow,
  TAssignmentState,
  TAssignmentLotPage,
} from '@grand-tender/types'
import { EActionTypes } from '@grand-tender/ui'

import { IBaseActionCreator } from '../../actions/BaseAction'

export type ISetAssignmentsAction = IBaseActionCreator<
  EActionTypes.SET_ASSIGNMENTS,
  TTableData
>

export type IEditAssignmentAction = IBaseActionCreator<
  EActionTypes.EDIT_ASSIGNMENT,
  IRow
>

export type IChangeAssignmentLotCategoryPageAction = IBaseActionCreator<
  EActionTypes.CHANGE_ASSIGNMENT_LOT_CATEGORY_PAGE,
  TAssignmentLotPage
>

export type ISetAssignmentsCategoryAction = IBaseActionCreator<
  EActionTypes.SET_CATEGORY_ASSIGNMENTS,
  string
>

export type TAssignmentsAction =
  | ISetAssignmentsAction
  | IEditAssignmentAction
  | IChangeAssignmentLotCategoryPageAction
  | ISetAssignmentsCategoryAction

const initialState: TAssignmentState = {
  categoryId: '',
  data: {
    columns: [],
    data: [],
    pagination: {
      page: 1,
      rowsPerPage: 10,
      total: 1,
    },
  },
}

export default (
  state = initialState,
  action: TAssignmentsAction,
): TAssignmentState => {
  switch (action.type) {
    case EActionTypes.SET_ASSIGNMENTS: {
      const newState = _.chain(state)
        .cloneDeep()
        .extend({ data: action.payload })
        .value()
      return newState
    }
    case EActionTypes.EDIT_ASSIGNMENT: {
      const row = state.data.data.find(
        item => item.rowId === action.payload.rowId,
      )

      if (row) {
        _.extend(row, { ...action.payload })
      }

      return _.cloneDeep(state)
    }
    case EActionTypes.SET_CATEGORY_ASSIGNMENTS: {
      return _.chain(state)
        .cloneDeep()
        .extend({ categoryId: action.payload })
        .value()
    }
    default:
      return state
  }
}
