import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  lot: {
    display: 'flex',
    width: '100%',
  },
  table: {
    width: '270px',
    '& .MuiTableCell-root': {
      padding: '0',
    },
    '& .MuiIconButton-label:hover, .MuiIconButton-label:focus, .MuiIconButton-label:active': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& p': {
      fontSize: '12px',
      color: '#535F77',
      fontWeight: 'bold',
      padding: '0',
    },
  },
  checkedRow: {
    margin: '0 0 0 1px',
    '&:hover, &:focus, &:active': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '&$checkboxChecked, &$checkboxChecked:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '& .MuiTableCell-root': {
      border: '0',
    },
  },
  checkboxChecked: {},
  popover: {
    '& .MuiPaper-root': {
      '&::-webkit-scrollbar': {
        width: '8px !important',
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
  },
  drawerList: {
    '& .MuiList-root:first-child': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '& .MuiList-root:last-child': {
      padding: '8px 24px 7px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '59px',
      boxShadow: '0px -5px 30px 0px rgba(50, 50, 50, 0.05)',
      width: '645px',
    },
    '& .MuiButton-label': {
      fontWeight: 'normal',
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '& .MuiDrawer-paper': {
      width: '660px',
      '&::-webkit-scrollbar': {
        width: '8px !important',
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
  },
  drawerListHeader: {
    '& h5': {
      fontSize: '24px',
      color: '#000',
      margin: '20px 0 15px 34px',
      fontWeight: 'normal',
    },
    '& .MuiList-padding': {
      maxWidth: '625px',
    },
  },
  drawerListHeaderCloseBtn: {
    width: '40px',
    height: '40px',
    marginRight: '20px',
  },
  drawerFooter: {
    position: 'sticky',
    right: 0,
    bottom: 0,
    backgroundColor: '#ffffff',
  },
  secondPartFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& button': {
      margin: '0 0 0 10px',
    },
  },
  drawerContent: {
    minHeight: 'calc(100vh - 145px)',
    '& hr': {
      margin: '0 0 0 34px',
    },
  },
  cardPhotoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: '20px 34px 20px 20px',
  },
  cardMainPhoto: {
    minWidth: '255px',
    minHeight: '255px',
    border: '1px solid #E2E4E9',
    '& .MuiAvatar-img': {
      objectFit: 'contain',
    },
  },
  cardOtherPhotoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: '-7.5px 0',
    maxHeight: '255px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px !important',
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  cardEmptyDiv: {
    minWidth: '114px',
  },
  cardOtherPhoto: {
    minWidth: '75px',
    minHeight: '75px',
    margin: '7.5px 0',
    border: '1px solid #E2E4E9',
    cursor: 'pointer',
  },
  cardRowContainer: {
    margin: '0 24px 20px 34px;',
  },
  cardRowTitle: {
    color: '#4C5ECF',
  },
  cardRowBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0 -10px',
    '& .MuiFormControl-root': {
      margin: '0 10px',
    },
    '& .MuiInputBase-input': {
      lineHeight: '25px',
    },
    '& label': {
      marginLeft: '0',
    },
  },
  cardRowSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    '&>div': {
      flex: '1 1 48%',
    },
  },
  cardRowSpaceBetweenText: {
    '& input': {
      color: '#616284',
      fontSize: '0.85em',
    },
    '& .MuiInputBase-input': {
      textAlign: 'right',
    },
  },
  cardMultiline: {
    display: 'flex',
    margin: '0 -5px',
    justifyContent: 'flex-end',
    '& .flex-item': {
      margin: '0 5px',
    },
    '& .column': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}))

export default useStyles
