/* eslint-disable no-undefined */
import React, { useState, useEffect } from 'react'
import {
  CircularProgress,
  FormControlLabel,
  TextField,
} from '@material-ui/core'
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from '@material-ui/lab'
import { useStyles } from './Style'
import * as I from './ISearchInput'
import { IKeyValuePair } from '@grand-tender/types'
import ButtonComponent from '../Button/Button'
import { EButtonIcon, EButtonType } from '../../constants'

interface OptionType extends IKeyValuePair {
  inputValue?: string
}

export const SearchInput: React.FC<I.OwnProps> = ({
  disabled = false,
  label,
  helperText,
  dataIsLoading,
  open,
  data,
  fullWidth = true,
  clearOnEscape = false,
  presetValue = null,
  freeSolo = false,
  onOpen,
  onClose,
  filterFunc,
  onChange,
  onInputChange,
  onAddOption,
  onEditOption,
  onRemoveOption,
}): React.ReactElement => {
  const classes = useStyles()
  const [selectedValue, setSelectedValue] = useState<IKeyValuePair | null>(
    presetValue,
  )

  useEffect(() => {
    if (
      !freeSolo &&
      selectedValue &&
      !data.some(i => i.key === selectedValue.key)
    ) {
      setSelectedValue(null)
    } else {
      if (
        freeSolo &&
        (presetValue?.key !== selectedValue?.key ||
          presetValue?.value !== selectedValue?.value)
      ) {
        setSelectedValue(presetValue)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, presetValue])

  const onChangeValue = React.useCallback(
    (
      event: React.ChangeEvent<{}>,
      value: string | OptionType | null,
      reason: AutocompleteChangeReason,
    ): void => {
      if (typeof value === 'string') {
        if (onAddOption) {
          onAddOption(value)
        }
      } else if (value && value.inputValue) {
        if (onAddOption) {
          onAddOption(value.inputValue)
        }
      } else {
        setSelectedValue(value)
        if (onChange) {
          onChange(value)
        }
      }
    },
    [onAddOption, onChange],
  )

  const getOptionSelected = React.useCallback(
    (option: OptionType, value: OptionType): boolean =>
      option.key === value.key,
    [],
  )

  const getOptionLabel = React.useCallback((option: OptionType): string => {
    // e.g value selected with enter, right from the input
    if (typeof option === 'string') {
      return option
    }

    if (option.inputValue) {
      return option.inputValue
    }

    return option.value
  }, [])

  const onEditOptionClick = React.useCallback((): void => {
    if (onEditOption && selectedValue) {
      onEditOption(selectedValue)
    }
  }, [onEditOption, selectedValue])

  const onRemoveOptionClick = React.useCallback((): void => {
    if (onRemoveOption && selectedValue) {
      onRemoveOption()
    }
  }, [onRemoveOption, selectedValue])

  return (
    <FormControlLabel
      className={fullWidth ? 'fullWidth' : 'prop'}
      disabled={disabled}
      control={
        <Autocomplete
          disabled={disabled}
          handleHomeEndKeys
          filterOptions={filterFunc}
          className={classes.autocomplete}
          classes={{
            popper: classes.autocompletePaper,
          }}
          style={{ width: '100%' }}
          fullWidth
          clearOnEscape={clearOnEscape}
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          onChange={onChangeValue}
          onInputChange={(e, value, reason): void => {
            if (onInputChange) {
              onInputChange(value)
            }
          }}
          noOptionsText={'Ничего не найдено'}
          loadingText={'Загрузка данных'}
          getOptionSelected={getOptionSelected}
          getOptionLabel={getOptionLabel}
          renderOption={(option: OptionType): string => option.value}
          options={data}
          value={selectedValue}
          loading={dataIsLoading}
          freeSolo={freeSolo}
          renderInput={(params: AutocompleteRenderInputParams): JSX.Element => (
            <>
              <TextField
                {...params}
                style={{ width: freeSolo && selectedValue ? '463px' : '100%' }}
                disabled={disabled}
                variant='filled'
                helperText={helperText}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {dataIsLoading ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
              {freeSolo && selectedValue && (
                <ButtonComponent
                  text=''
                  type={EButtonType.DEFAULT}
                  typeIcon={EButtonIcon.EDIT}
                  className={classes.editBtn}
                  onClick={onEditOptionClick}
                />
              )}
              {freeSolo && selectedValue && (
                <ButtonComponent
                  text=''
                  type={EButtonType.DEFAULT}
                  typeIcon={EButtonIcon.DELETE}
                  className={classes.editBtn}
                  onClick={onRemoveOptionClick}
                />
              )}
            </>
          )}
        />
      }
      label={label}
      labelPlacement='top'
    />
  )
}
