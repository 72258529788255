/* eslint-disable @typescript-eslint/ban-ts-comment */
import { SearchService } from '@grand-tender/search-service'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { TAvailableColumn, TFilterFieldsModel } from '@grand-tender/types'
import { EActionTypes } from '@grand-tender/ui'

import { actionCreator, IActionCreator } from '../BaseAction'

export const getFilterFields = createAsyncThunk<unknown, boolean>(
  'filter/getFilterFields',
  async (isPassed, { rejectWithValue, dispatch }) => {
    const [err, result] = await SearchService.getFilterFields(isPassed)

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      const fields = {
        // @ts-ignore
        agreement: result.data.data.agreementFields,
        // @ts-ignore
        items: result.data.data.itemsFields,
        // @ts-ignore
        provider: result.data.data.providerFields,
        // @ts-ignore
        tender: result.data.data.tenderFields,
      } as TFilterFieldsModel
      dispatch(setFilterFields(fields))
    }
  },
)

export function setFilterFields(
  filterItem: TFilterFieldsModel,
): IActionCreator<TFilterFieldsModel> {
  return actionCreator<TFilterFieldsModel>(
    EActionTypes.SET_FILTER_FIELDS,
    filterItem,
  )
}

export function setAvailableColumns(
  columns: Array<TAvailableColumn>,
): IActionCreator<Array<TAvailableColumn>> {
  return actionCreator<Array<TAvailableColumn>>(
    EActionTypes.SET_AVAILABLE_COLUMNS,
    columns,
  )
}
