import { connect, ConnectedProps } from 'react-redux'

import {
  removePosition,
  modifyPosition,
  addToAssignment,
  addPositionProperty,
  editPositionProperty,
  removePositionProperty,
  getSearchOtherPageFilterItems,
  exportPositionsByIds,
} from '../../actions'

import { RootState } from '../../store'

import * as I from './position-search-types'
import PositionSearch from './position-search'

const mapStateToProps = (state: RootState): I.StateProps => ({
  positions: state.searchOtherPage.responseParams.positionPage,
  categoriesData: state.searchOtherPage.responseParams.categoriesData,
  searchParams: {
    ...state.searchOtherPage.requestParams,
    pageData: {
      ...state.searchOtherPage.requestParams.pageData,
      categoryId: state.searchOtherPage.responseParams.categoryId,
    },
  },
  dataIsLoading: state.viewState.dataIsLoading,
  user: state.user || null,
})

const mapDispatch = {
  deletePositions: removePosition,
  editPosition: modifyPosition,
  addToAssignment,
  addProperty: addPositionProperty,
  editProperty: editPositionProperty,
  removeProperty: removePositionProperty,
  getSearchResult: getSearchOtherPageFilterItems,
  exportPositions: exportPositionsByIds,
}

const connector = connect(mapStateToProps, mapDispatch)

export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(PositionSearch)
