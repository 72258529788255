import { connect, ConnectedProps } from 'react-redux'

import {
  removeAgreements,
  addAgreementProperty,
  editAgreementProperty,
  removeAgreementProperty,
  exportAgreementsByIds,
  saveCategoriesAgreementChanges,
  getCategoriesAgreement,
  getCategoriesAgreementFinished,
  getAgreementsNew,
} from '../../../actions'
import {
  addSectionAgreement as addSection,
  editSectionAgreement as editSection,
  removeSectionAgreement as removeSection,
} from '../../../actions/Agreements'
import { RootState } from '../../../store'

import * as I from './agreement-types'
import Agreement from './agreement'

const mapStateToProps = (state: RootState): I.StateProps => ({
  agreements: {
    ...state.agreements.data,
    columns: state.agreements.data.columns.map((column: any) => ({
      ...column,
      title:
        column.title.lastIndexOf('[') === -1
          ? column.title
          : column.title.substring(0, column.title.lastIndexOf('[')),
    })),
  },
  categories: state.categories,
})

const mapDispatch = {
  deleteAgreements: removeAgreements,
  getAgreements: getAgreementsNew,
  addProperty: addAgreementProperty,
  editProperty: editAgreementProperty,
  removeProperty: removeAgreementProperty,
  exportAgreementsByIds,
  saveCategoriesChanges: saveCategoriesAgreementChanges,
  getCategories: getCategoriesAgreement,
  getCategoriesFinished: getCategoriesAgreementFinished,
  addSection,
  editSection,
  removeSection,
}

const connector = connect(mapStateToProps, mapDispatch)

export type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Agreement)
