import { makeStyles, Theme } from '@material-ui/core/styles'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'

export const useToolbarStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'sticky',
    zIndex: 10,
    background: '#fff',
    margin: '0 -34px',
    padding: '16px 34px',
    '&.MuiToolbar-root': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '&.MuiToolbar-regular': {
      minHeight: '0',
    },
    '& .MuiTypography-root': {
      maxWidth: '100vw',
      wordBreak: 'break-word',
      padding: '0 10px 0 0',
    },
  },
  highlight: {
    color: '#000000',
  },
  menuButton: {
    padding: 0,
    borderRadius: '5px',
    width: '44px',
    height: '44px',
    backgroundColor: '#F9F9FB',
    // marginLeft: '15px',
    '&.active': {
      background: '#4C5ECF',
      color: '#FFFFFF',
    },
  },
  listItemTooltip: {
    marginTop: '0',
  },
}))

export const Description = styled.div`
  width: 370px;
  display: flex;
  flex-direction: column;
  @media (min-width: 1536px) {
    width: 500px;
  }
  @media (min-width: 1680px) {
    width: 700px;
  }
`

export const Title = styled(Typography).attrs({ component: 'div' })`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 29px;
  font-family: Proxima Nova Regular;
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

export const MenuList = styled.div`
  & li {
    font-family: 'Proxima Nova Regular';
  }
  & .menu-title {
    opacity: 1;
  }
`
