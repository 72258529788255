import { Button, Popup, RoundedCross } from '@gmini/ui-kit'
import { ILotSupplier } from '@grand-tender/types'
import React from 'react'
import { ArrowIcon } from '@grand-tender/ui/src/icons/ArrowIcon'

import {
  CloseButton,
  Container,
  Footer,
  Header,
  Title,
} from '../lotWinner/LotWinnerModal.styled'

import {
  AgreementInfoText,
  Divider,
  Content,
  AgreementHeaderRow,
  AgreementText,
  AgreementContentWrap,
  ProviderContent,
  CompleteStatus,
  AgreementContent,
} from './AgreementModal.styled'

type AgreementModalProps = {
  open: boolean
  onClose: () => void
  suppliers: ILotSupplier[]
  onAddAgreement: (supplier: ILotSupplier) => void
}

export const AgreementModal = ({
  open,
  onClose,
  suppliers,
  onAddAgreement,
}: AgreementModalProps) => (
  <Popup open={open} onClose={onClose} width='600px'>
    <Container>
      <Header>
        <Title>Добавить соглашение(я)</Title>
        <CloseButton onClick={onClose}>
          <RoundedCross />
        </CloseButton>
      </Header>
      <Content>
        <AgreementInfoText>
          После определения списка победителей, что бы закончить работу с лотом{' '}
          <br /> необходимо добавить соглашения с поставщиками (победителями)
        </AgreementInfoText>

        <AgreementHeaderRow>
          <AgreementText>Победители лота</AgreementText>
          <AgreementText>Статус соглашения</AgreementText>
        </AgreementHeaderRow>
        <Divider />
      </Content>
      <AgreementContentWrap>
        {suppliers.map(supplier => (
          <React.Fragment key={supplier.rowId}>
            <AgreementContent>
              <ProviderContent>{supplier.name}</ProviderContent>
              {supplier.hasAgreement ? (
                <CompleteStatus>Добавлено</CompleteStatus>
              ) : (
                <Button
                  onClick={() => onAddAgreement(supplier)}
                  color='tertiary'
                  rightIcon={<ArrowIcon />}
                >
                  Добавить
                </Button>
              )}
            </AgreementContent>
            <Divider />
          </React.Fragment>
        ))}
      </AgreementContentWrap>

      <Footer>
        <Button onClick={onClose}>Закрыть</Button>
      </Footer>
    </Container>
  </Popup>
)
