import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    fontFamily: 'Proxima Nova Regular',
    margin: '0 -15px',
    height: '100%',
    '& .flex-item': {
      margin: '0 15px',
      '&.tab-panel': {
        flex: '1 1 auto',
        width: '100px',
      },
    },
  },
  actions: {
    display: 'flex',
    'align-items': 'center',
    'margin-bottom': '20px',
    '& button': {
      'margin-right': '8px',
    },
  },
  selected: {
    flex: '1 1 auto',
  },
  title: {
    'margin-top': '20px !important',
  },
  tabPanel: {
    display: 'flex',
    'flex-direction': 'column',
  },
  table: {
    '& .MuiDataGrid-cell.rowError': {
      backgroundColor: '#fce6e6',
    },
    '& .MuiDataGrid-cell.rowError:nth-child(even)': {
      backgroundColor: '#f3dddd',
    },
  },
}))

export default useStyles
