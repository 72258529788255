import { Button, IconButton, RoundedCross } from '@gmini/ui-kit'
import { Dialog } from '@material-ui/core'
import { getEmptyPricePositions } from '@grand-tender/app/src/actions'
import { formatPrice } from '@grand-tender/app/src/utils'
import {
  EmptyPricePositions,
  IKeyValuePair,
  ILotSupplier,
  TColumn,
  TRow,
} from '@grand-tender/types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { EColumnType } from '../../constants'
import { CheckmarkIcon } from './CheckmarkIcon'
import {
  Agreement,
  ButtonContainer,
  InfoText,
  List,
  ListHead,
  ListItem,
  NoAgreement,
  PositionsWarning,
  SupplierAgreementList,
  Title,
  TitleContainer,
  UnderlinedText,
  Wrapper,
} from './CloseLotModal.styled'
import NoAgreementPositionsModal from './NoAgreementPositionsModal'
import { getSupplierText } from '@grand-tender/app/src/pages/assignment/view/lotWinner/LotWinnerModal.utils'
import { useAppDispatch } from '@grand-tender/app/src/store'

type CloseLotModalProps = {
  assignmentId: string
  lotName: string
  lotId: string
  onSubmit: () => void
  onClose: () => void
  open: boolean
  suppliers: ILotSupplier[]
}

const columnDefaultProps = {
  required: false,
  base: false,
  type: EColumnType.CURRENCY,
  hidden: false,
  editable: false,
}

export const CloseLotModal = ({
  assignmentId,
  lotName,
  onClose,
  open,
  onSubmit,
  suppliers,
  lotId,
}: CloseLotModalProps) => {
  const dispatch = useAppDispatch()
  const [emptyPricePositions, setEmptyPricePositions] = useState<
    EmptyPricePositions
  >()
  const suppliersSet = Object.values(
    suppliers.reduce(
      (acc: { [x: string]: ILotSupplier }, s) => ({ ...acc, [s.rowId]: s }),
      {},
    ),
  )

  const [noAgreementPositionsOpen, setNoAgreementPositionsOpen] = useState<
    boolean
  >(false)
  const noAgreementSuppliersCount = suppliersSet.filter(s => !s.hasAgreement)
    .length

  useEffect(() => {
    if (open) {
      dispatch(
        getEmptyPricePositions({
          lotId,
          tenderId: assignmentId,
          callback: setEmptyPricePositions,
        }),
      )
    }
  }, [lotId, open, assignmentId, dispatch])

  const rows =
    useMemo(
      () =>
        emptyPricePositions?.positions?.reduce(
          (acc: TRow, table) => [
            ...acc,
            ...table.data.map(row => {
              const currRowSuppliers = suppliers.filter(
                s => s.positionId === row.rowId,
              )
              const newRows = currRowSuppliers.reduce(
                (acc: IKeyValuePair[], s) => [
                  ...acc,
                  {
                    key: s.rowId,
                    value: s.price ? formatPrice(s.price) : s.price,
                  },
                ],
                [],
              )
              return { ...row, data: [...row.data, ...newRows] }
            }),
          ],
          [],
        ),
      [emptyPricePositions, suppliers],
    ) || []

  const columns =
    useMemo(
      () =>
        emptyPricePositions?.positions?.reduce(
          (acc: TColumn, table) => [
            ...acc,
            ...table.columns,
            ...suppliersSet.map(s => ({
              ...s,
              title: s.name,
              key: s.rowId,
              ...columnDefaultProps,
            })),
          ],
          [],
        ),
      [emptyPricePositions, suppliersSet],
    ) || []

  const agreementNumbersBySupplier = useMemo(
    () =>
      emptyPricePositions?.itemsInfoWithOutPrices.reduce(
        (acc: { [x: string]: string }, item) => ({
          ...acc,
          [String(item.supplierId)]: String(item.agreementNumber),
        }),
        {},
      ),
    [emptyPricePositions],
  )

  const handleSubmit = useCallback(() => {
    onClose()
    onSubmit()
  }, [onClose, onSubmit])

  return (
    <Dialog onClose={onClose} open={open}>
      <NoAgreementPositionsModal
        open={noAgreementPositionsOpen}
        onClose={() => setNoAgreementPositionsOpen(false)}
        positions={rows}
        columns={columns}
      />
      <Wrapper>
        <TitleContainer>
          <Title>Закрытие лота</Title>
          <IconButton onClick={onClose}>
            <RoundedCross />
          </IconButton>
        </TitleContainer>
        {rows.length ? (
          <PositionsWarning>
            <UnderlinedText onClick={() => setNoAgreementPositionsOpen(true)}>
              {rows.length} позиции
            </UnderlinedText>{' '}
            не попали в соглашение(я)
          </PositionsWarning>
        ) : null}
        <InfoText>
          Вы уверены что хотите закрыть лот?
          {noAgreementSuppliersCount
            ? ` У вас есть еще ${noAgreementSuppliersCount} ${getSupplierText(
                noAgreementSuppliersCount,
              )} с ценами, но без соглашений.
            При закрытии лота позиции которые не попали ни в одно соглашение
            будут вынесены в отдельный лот.`
            : ''}
        </InfoText>
        <SupplierAgreementList>
          <ListHead>
            <span>Поставщик</span>
            <span>Заключенные соглашения</span>
          </ListHead>
          <List>
            {suppliersSet.map(s => (
              <ListItem key={s.rowId}>
                <span>{s.name}</span>
                {s.hasAgreement ? (
                  <Agreement>
                    <span>
                      № {agreementNumbersBySupplier?.[s.rowId]}: {lotName}
                    </span>
                    <CheckmarkIcon />
                  </Agreement>
                ) : (
                  <NoAgreement>Нет соглашения</NoAgreement>
                )}
              </ListItem>
            ))}
          </List>
        </SupplierAgreementList>
        <ButtonContainer>
          <Button color='secondary' onClick={onClose}>
            Отмена
          </Button>
          <Button disabled={!emptyPricePositions} onClick={handleSubmit}>
            Да, закрыть лот
          </Button>
        </ButtonContainer>
      </Wrapper>
    </Dialog>
  )
}
