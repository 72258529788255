import React, { useState } from 'react'
import { Checkbox, TableCell, TableRow, IconButton } from '@material-ui/core'
import * as I from './IImprovedTableHeadDisplayColumnsRow'
import DialogModal from '../../../../../DialogModal/DialogModal'
import {
  pencilCreateIcon,
  circleXIcon,
  EButtonType,
} from '../../../../../../constants'

import useImprovedTableHeadDisplayColumnsStyles from './Style'
import { useLocation } from 'react-router-dom'

const titleMap: { [x: string]: string } = {
  '#': 'Хэш-код позиции',
}

const ImprovedTableHeadDisplayColumnsRow: React.FC<I.OwnProps> = ({
  index,
  column,
  isVisible,
  showEditPropertyButtons,
  showSections,
  onColumnVisibilityChanged,
  onEditPropertyBtnClick = () => (): number => 0,
  removePropertyHandler,
}): React.ReactElement => {
  const classes = useImprovedTableHeadDisplayColumnsStyles()
  const [openRemoveDialogModal, setOpenRemoveDialogModal] = useState<boolean>(
    false,
  )
  const location = useLocation()

  const isDisabled =
    column.title === 'Автор' ||
    column.title === 'Дата создания' ||
    column.title === '#'
      ? false
      : column.base || column.required

  return (
    <TableRow key={index}>
      <TableCell align='left'>
        <div className={classes.flexContainer}>
          {!column.section || showSections ? (
            <Checkbox
              className={classes.checkedRow}
              classes={{ checked: classes.checkboxChecked }}
              color='primary'
              onClick={(event: React.MouseEvent<HTMLButtonElement>): void =>
                onColumnVisibilityChanged(event, column)
              }
              checked={isVisible}
              disabled={isDisabled}
              disableRipple
            />
          ) : (
            <div className={classes.empty} />
          )}
          {titleMap[column.title] || column.title}
        </div>
      </TableCell>
      <TableCell align='right'>
        {location.pathname.indexOf('assignments/') === -1 && (
          <>
            <div className={classes.actionsContainer}>
              {!column.base &&
                showEditPropertyButtons &&
                column.section?.value !== 'Колонки поставщика' && (
                  <IconButton
                    className={classes.actionButton}
                    onClick={onEditPropertyBtnClick(column)}
                  >
                    {pencilCreateIcon}
                  </IconButton>
                )}
              {!column.base && showEditPropertyButtons && (
                <IconButton
                  className={classes.actionButton}
                  onClick={(): void => setOpenRemoveDialogModal(true)}
                >
                  {circleXIcon}
                </IconButton>
              )}
            </div>
            {openRemoveDialogModal && (
              <DialogModal
                open={openRemoveDialogModal}
                modalTitle={'Удалить'}
                modalContent={'Вы действительно хотите удалить свойство?'}
                modalButtonLeftText={'Отменить'}
                modalButtonRightText={'Удалить'}
                modalButtonLeftType={EButtonType.DEFAULT}
                modalButtonRightType={EButtonType.WARNING}
                handleDiscardChanges={(): void =>
                  setOpenRemoveDialogModal(false)
                }
                handleChanges={(): void => {
                  removePropertyHandler?.(column.id, () =>
                    setOpenRemoveDialogModal(false),
                  )
                }}
              />
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  )
}

export default ImprovedTableHeadDisplayColumnsRow
