import { apiClient, ApiResponse } from '@grand-tender/base-api-service/src'

import {
  IAPIResult,
  TFilterFieldsModel,
  TPositionSearchRequestModel,
  TAssignmentSearchResponseDTO,

  TSearchResultListAssignments,
  TAssignmentSearchRequestModel,
  TTenderSearchPaginationRequestModel,
  TSearchRequest,
  TSearchResponse,
  TSearchResultListOtherPage,

  TAssignmentLotPage,
} from '@grand-tender/types'

const headers: Record<string, string> = { 'Content-Type': 'application/json' }

export default class SearchService {
  static getFilterFields(
    isPassed: boolean,
  ): Promise<ApiResponse<IAPIResult<TFilterFieldsModel>>> {
    const params: Record<string, boolean> = {}
    if (isPassed) {
      params.passed = true
    }

    return apiClient.post({ path: `/filter/fields`, headers, body: params })
  }

  static searchPositionsFilterItemsSelectAll(
    params: TPositionSearchRequestModel,
    categoryId: string,
  ): Promise<ApiResponse<IAPIResult<Array<string>>>> {
    return apiClient.post({
      path: '/item/select/filter',
      headers,
      body: {
        ...params,
        pageData: {
          categoryId,
          page: params.pageData.page,
          perPage: params.pageData.perPage,
        },
      },
    })
  }

  static getAssignmentSearch(
    key: TAssignmentSearchRequestModel,
  ): Promise<ApiResponse<IAPIResult<TSearchResultListAssignments>>> {
    return apiClient.post({ path: `/filter/tender/short`, headers, body: key })
  }

  static searchAssignmentsFilterItems(
    params: TAssignmentSearchRequestModel,
  ): Promise<ApiResponse<IAPIResult<TAssignmentSearchResponseDTO>>> {
    return apiClient.post({
      path: '/filter/tender',
      headers,
      body: params,
    })
  }

  static searchAssignmentsFilterItemsSelectAll(
    params: TAssignmentSearchRequestModel,
    lotId: string,
    categoryId: string,
    assignmentId: string,
  ): Promise<ApiResponse<IAPIResult<Array<string>>>> {
    return apiClient.post({
      path: '/tender/select/filter',
      headers,
      body: {
        ...params,
        lotId,
        categoryId,
        tenderId: assignmentId,
      },
    })
  }

  static getOtherSearch(
    params: Partial<TSearchRequest>,
  ): Promise<ApiResponse<IAPIResult<TSearchResultListOtherPage>>> {
    return apiClient.post({
      path: `/filter/table/short`,
      headers,
      body: params,
    })
  }

  static searchOtherSearhFilterItems(
    params: TSearchRequest,
  ): Promise<ApiResponse<IAPIResult<TSearchResponse>>> {
    return apiClient.post({ path: '/filter/table', headers, body: params })
  }

  static searchAgreementFilterItemsSelectAll(
    params: TSearchRequest,
  ): Promise<ApiResponse<IAPIResult<Array<string>>>> {
    return apiClient.post({
      path: '/agreement/select/filter',
      headers,
      body: params,
    })
  }

  static searchProviderFilterItemsSelectAll(
    params: TSearchRequest,
  ): Promise<ApiResponse<IAPIResult<Array<string>>>> {
    return apiClient.post({
      path: '/provider/select/filter',
      headers,
      body: params,
    })
  }

  static searchTenderFilterItemsSelectAll(
    params: TSearchRequest,
  ): Promise<ApiResponse<IAPIResult<Array<string>>>> {
    return apiClient.post({
      path: '/tender/select/filter',
      headers,
      body: params,
    })
  }

  static getSearchOtherPageLotCategory(
    params: TTenderSearchPaginationRequestModel,
  ): Promise<ApiResponse<IAPIResult<TAssignmentLotPage>>> {
    return apiClient.post({
      path: `/filter/tender/page`,
      headers,
      body: params,
    })
  }
}
